import React from 'react';
import Sidebar from '../Sidenav/index';
import Institutionmain from './Components/Insitutionmain/Insitutionmain';

function Institution({ route }) {
  return (
    <div
      className="col-sm-12 p-0"
      style={{
        overflowX: 'hidden',
        height: '100%'
      }}>
      <Sidebar component={<Institutionmain route={route} />} />
    </div>
  );
}

export default Institution;
