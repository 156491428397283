import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import ToggleButton from '../ToggleButton/ToggleButton';
import Changepicturemodal from './Changepicturemodal/Changepicturemodal';
import TwoFactactorAuthenticationModal from './Modals/2FAModals';
import { UPDATE_EDUCATOR } from '../../../../Services/mutation/educator';
import ChangePasswordModal from './ChangePasswordModal';
import NoProfileImg from '../../../../Assets/images/no-img.jpg';
import { API, Storage } from 'aws-amplify';
import AlertToast from '../../../../Components/common/Alerts/ToastAlert';

import './style.css';
import { getInitial, getObjectsDifference, sendAppNotification, sendEmailNotification } from '../../../../utils/helpers';
import { MAXCHARACTERLENGTH } from '../../../../utils/data';
import { axiosClient, tasksClient } from '../../../../libs/axiosClient';

const ProfileAccount = ({ user, refetch }) => {
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [updateUser] = useMutation(UPDATE_EDUCATOR);
  const [TwoFA, setTwoFA] = useState(user?.twoFA);
  const [showTwoFAModal, setShowTwoFAModal] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [toast, setToast] = useState('');
  const [loading, setLoading] = useState(false);
  const [otpToken, setOtpToken] = useState(null);

  const getNames = (name, type) => {
    if (name) {
      const names = name.split(' ');
      return type === 'fname' ? names[0] : names[1];
    }
  };

  const [state, setState] = useState({
    twoFA: false,
    fname: getNames(user?.name, 'fname'),
    lname: getNames(user?.name, 'lname'),
    email: user?.email,
    role: user?.role,
    personality: user?.personality
  });
  const [imageProps, setImageProps] = useState({ image: user?.image });

  const handleSwitch = async(status) => {
    try {
      if (status){
        const resp = await axiosClient.post('/request-verification-code', {
          email: user?.email,
          name: user?.name
        });

        setOtpToken(resp.data.data);
        setShowTwoFAModal(true)
      }
      else {
        await API.graphql({
          query: UPDATE_EDUCATOR,
          variables: { id: user?.id, twoFA: false }
        });
        await sendAppNotification(
          user?.id,
          'You have successfully disabled 2FA',
          'success',
          `/my-account`,
          'Click here to view',
          'mentor',
          'inapp',
        )
  
        await sendEmailNotification(
          user?.email,
          '2fa-disabled',
          '2FA - disabled',
          {
            fname: user?.name?.split(' ')[0],
            account: user?.name,
            organization: user?.school?.name,
            moduleUrl: window.location.origin
          }
        )

        setTwoFA(status);
      }

      
      setState({
        ...state,
        twoFA: status
      });
      
    } catch (error) {
      console.error(error)
    }
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value.slice(0, MAXCHARACTERLENGTH[e.target.name])
    });
  };

  const saveChanges = async () => {
    try {
      setLoading(true);
      let savedImage = '';
      if (imageProps.image) {
        const imgSaveResp = await Storage.put(
          `${state?.email}-${imageProps?.imageFile?.name}`,
          imageProps.imageFile
        );
        savedImage =
          'https://junity-counselor-storage204659-staging.s3.amazonaws.com/public/' +
          imgSaveResp.key;
      }
      const name = state?.fname?.trim() + ' ' + state?.lname?.trim();
      const obj = { ...state };
      if (savedImage) {
        obj.image = savedImage;
      }
      delete obj.fname;
      delete obj.lname;
      const inputData = {
        ...obj,
        name: name,
        id: user?.id
      };
  
      const changedInfo = getObjectsDifference(user, inputData)

      await updateUser({ variables: { ...inputData } });
      if (Object.prototype.hasOwnProperty.call(changedInfo, 'email')){
        const response = await tasksClient.post('/token',{
          name: user?.name,
          email: user?.email,
          requiresInvitation: false,
          schoolName: user?.school?.name
        })
        const { success, message, data} = response.data

        if (success){
          await sendEmailNotification(
            user?.email,
            'confirm-new-email',
            'Confirm New Email address',
            {
              fname: user?.name?.split(' ')[0],
              account: user?.name,
              organization: user?.school?.name,
              moduleUrl: window.location.origin,
              btnLink: `/verify-email/redirect?id=${data}`
            }
          )
        }
      }
      
      setLoading(false);
      setActiveToast(true);
      setToast('Account updated successfully');
      refetch();
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <>
      <AlertToast
        variant={'success'}
        icon={'check'}
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
      <div
        className="col-sm-12 pt-2 pb-3 flex-center"
        style={{
          backgroundColor: '#F0F0F0'
        }}>
        <div className="flex-center mt-3 add_border account-settings-profile">
          <div className="my-4 rounded-2 bg-white p-4">
            <div className="mb-4">
              <div className="logo-preview mx-auto position-relative">
                {imageProps?.image ? (
                  <img src={imageProps?.image} className="thumbnail" alt="" />
                ) : (
                  <div className="no-image large">{getInitial(user?.name)}</div>
                )}
                {imageProps?.image && (
                  <i
                    onClick={() => setImageProps({ image: user?.image })}
                    className="fas fa-trash-alt delete-icon"></i>
                )}
              </div>
              <div className="text-center py-3">
                <Changepicturemodal
                  image={imageProps?.image || NoProfileImg}
                  saveImage={setImageProps}
                />
                <h6 className="supported-images">PNG, JPG or GIF min 400px x 400px</h6>
              </div>
              <div className="row add_border">
                <div className="col-sm-6">
                  <div className="form-group mb-4">
                    <label className="institution-form-label">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      name="fname"
                      value={state?.fname}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group mb-4">
                    <label className="institution-form-label">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      name="lname"
                      value={state?.lname}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group mb-3">
                    <label className="institution-form-label">About you</label>
                    <textarea
                      value={state?.personality}
                      name="personality"
                      onChange={handleChange}
                      className="form-control"
                      maxLength={1000}
                      placeholder="Write a maximum of 1000 characters about yourself"></textarea>
                    <small
                      className={`d-block w-100 text-end fw-semibold ${
                        state?.personality && state?.personality?.length < 750
                          ? 'text-secondary'
                          : 'text-danger'
                      }`}>
                      {state?.personality?.length || 0}/750
                    </small>
                  </div>
                </div>
              </div>
              <div className="form-group mb-4 pb-3 mt-3 add_border">
                <h5 className="form-legend">EMAIL ADDRESS</h5>
                <label className="institution-form-label">Work Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Work Email"
                  name="email"
                  value={state?.email}
                  onChange={handleChange}
                />
                <h6 className="form-control-info mt-1" style={{ fontSize: '90%' }}>
                  If you change this email address. you would have to verify your new email address
                  before you can access your account
                </h6>
              </div>
              <div className="form-group mb-4 pb-3 add_border">
                <h5 className="form-legend">ROLE</h5>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Role name"
                  value={state?.role}
                  name="role"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mb-4 pb-3 add_border">
                <h5 className="form-legend">PASSWORD</h5>
                <label className="custom-form-label mb-0">Change password</label>
                <h6 className="form-control-info bold">
                  Change your password frequently to keep your account secure, especially if you
                  think it has been compromised.
                </h6>
                <button
                  className="btn-change-password shadow-sm mt-2"
                  onClick={() => setChangePasswordModal(true)}>
                  Change Password
                </button>
              </div>
              <ChangePasswordModal
                modalShow={changePasswordModal}
                setModalShow={setChangePasswordModal}
              />
              <div className="form-group mb-4">
                <h5 className="form-legend d-flex align-items-center">
                  TWO-FACTOR AUTHENTICATION
                  <i className="fas fa-circle text-danger ml-1" style={{ fontSize: 6 }}></i>
                </h5>
                <div className="d-flex justify-content-between align-items-center">
                  <label className="custom-form-label">Authentication App(TOTP)</label>
                  <ToggleButton name="toggle" isChecked={TwoFA} onChange={handleSwitch} />
                </div>
                <h6 className="form-control-info">
                  Use your mobile phone to recieve a time-based, one-time code to log in and
                  authorize transactions.
                </h6>
              </div>
              <button
                className="btn-save-changes shadow-sm"
                onClick={saveChanges}
                disabled={loading}>
                {loading ? 'Loading...' : 'Save Changes'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <TwoFactactorAuthenticationModal 
        show={showTwoFAModal} 
        onHide={setShowTwoFAModal} 
        otpToken={otpToken}
        setTwoFA={setTwoFA}
      />
    </>
  );
};

export default ProfileAccount;
