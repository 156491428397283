import React, { useState, useEffect } from 'react';

import {
  AddressElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';

import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { ClipLoader } from 'react-spinners';
import { GetSingleSchool } from '../../../../../Services/query/school';

export const UpdatePaymentMethodModal = ({
  educator,
  updatePaymentMethodModal,
  setUpdatePaymentMethodModal,
  onUpdatePaymentMethod,
  onDeletePaymentMethod,
  updatePaymentMethod,
  setBillingDetails,
  getCardLogo,
  activeCard,
  loading,
  progress
}) => {
  const [cardholderName, setCardholderName] = useState(educator?.name);

  const [organization, setOrganization] = useState();

  const handleBillingDetailsChange = (event) => {
    if (event.complete) {
      setBillingDetails(event.value);
    }
  };

  const fetchOrg = async () => {
    console.log(activeCard);
    try {
      const org = await GetSingleSchool(educator?.schoolID);
      if (org && Object.keys(org).length) setOrganization(org);
    } catch (error) {
      console.log(error);
    }
  };

  const handleExpiryChange = (event) => {
    console.log(event);
  };

  useEffect(() => {
    if (educator && Object.keys(educator).length) fetchOrg();
  }, [educator]);

  return (
    <Modal
      show={updatePaymentMethodModal}
      onHide={() => {
        setUpdatePaymentMethodModal(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="mx-auto"
      centered>
      <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
        <Modal.Title>Edit Payment Details</Modal.Title>
        <div
          className="close_btn drawer rounded-2"
          onClick={() => {
            setUpdatePaymentMethodModal(false);
          }}>
          <IoMdClose fill="#515A6A" size={18} />
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0 w-100 px-3 payment-modal institution-form">
        <h6 className="section-subheading">CARD DETAILS</h6>
        <div className="border rounded-3 mt-2 p-3 top-0 w-100 bg-white filter-card">
          <div className="d-flex justify-content-between align-items-center">
            <span className="payment-card-type">{activeCard?.card?.brand?.toUpperCase()}</span>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3">
            <h6 className="mb-0">{getCardLogo(activeCard?.card?.brand)}</h6>
            <span className="payment-card-number">xxxx xxxx xxxx {activeCard?.card.last4}</span>
          </div>
          <h6 className="mb-0 mt-2 payment-card-expiry">
            Expires {activeCard?.card?.exp_month}/{activeCard?.card?.exp_year}
          </h6>
          <h6 className="mb-0 mt-4 payment-card-address">
            {activeCard?.billing_details?.address?.line1}
            <br /> {activeCard?.billing_details?.address?.postal_code},
            {activeCard?.billing_details?.address?.city}{' '}
            {activeCard?.billing_details?.address?.state}
          </h6>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label className="institution-form-label">Expiry Date</label>
              <CardExpiryElement
                className="form-control number-input border"
                onChange={handleExpiryChange}
              />
            </div>
          </div>
        </div>
        <h6 className="section-subheading mt-2">BILLING DETAILS</h6>
        <AddressElement
          options={{
            mode: 'billing',
            display: { name: 'organization' },
            allowedCountries: ['US'],
            autocomplete: {
              mode: 'google_maps_api',
              apiKey: process.env.REACT_APP_GOOGLE_API_KEY
            },
            defaultValues: {
              name: organization?.name,
              address: {
                line1: '',
                line2: '',
                city: '',
                state: '',
                postal_code: '',
                country: ''
              }
            }
          }}
          onChange={handleBillingDetailsChange}
        />
        <button
          type="button"
          className="btn btn-success w-100 mt-4"
          onClick={() => {
            updatePaymentMethod();
          }}>
          {loading ? progress() : 'Update payment method'}
        </button>
        <div
          className="subheading mt-3 cursor-pointer text-danger"
          onClick={() => {
            onDeletePaymentMethod();
          }}>
          <i className="fas fa-trash-alt fa-fw"></i>
          Delete Card
        </div>
      </Modal.Body>
    </Modal>
  );
};
