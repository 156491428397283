import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { ClipLoader } from 'react-spinners';
import { AllCreditValues } from '../../../../../Services/query/creditValues';

export const PurchaseCreditsModal = ({
  purchaseCreditsModal,
  setPurchaseCreditsModal,
  selectedPackage,
  setSelectedPackage,
  transactionFee,
  transactionFeeObject,
  totalCost,
  proceedToPayments
}) => {
  const [value, setValue] = useState({});

  useEffect(() => {
    AllCreditValues().then((values) => {
      setValue(values[0]);
    });
  }, []);

  return (
    <Modal
      show={purchaseCreditsModal}
      onHide={() => {
        setPurchaseCreditsModal(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="mx-auto"
      centered>
      <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
        <Modal.Title>Purchase Credits</Modal.Title>
        <div
          className="close_btn drawer rounded-2"
          onClick={() => {
            setPurchaseCreditsModal(false);
          }}>
          <IoMdClose fill="#515A6A" size={18} />
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0 w-100 px-3 payment-modal">
        <h6 className="purchase-description">
          Credits allow you to use Junity’s additional, growth features to support your engagement
          with youth who connect to your institution on Junity.
        </h6>
        <h6 className="purchase-description my-3">1 Credit = ${value?.value}</h6>
        <h6 className="fw-bolder mb-0 purchase-subheading">
          How many credits would you like to purchase?
        </h6>
        <div className="mb-2 d-flex gap-3">
          <label className="filter-badge-radio mb-0 mr-1">
            <input
              type={'radio'}
              name="package"
              value={30}
              onChange={(e) => {
                setSelectedPackage(e.target.value);
              }}
              className="d-none"
            />
            <span className="btn btn-outline-light background_allopp_btn">30 Credits</span>
          </label>
          <label className="filter-badge-radio mb-0 mr-1">
            <input
              type={'radio'}
              name="package"
              value={50}
              onChange={(e) => {
                setSelectedPackage(e.target.value);
              }}
              className="d-none"
            />
            <span className="btn btn-outline-light background_allopp_btn">50 Credits</span>
          </label>
          <label className="filter-badge-radio mb-0 mr-1">
            <input
              type={'radio'}
              name="package"
              value={100}
              onChange={(e) => {
                setSelectedPackage(e.target.value);
              }}
              className="d-none"
            />
            <span className="btn btn-outline-light background_allopp_btn">100 Credits</span>
          </label>
        </div>
        <h6 className="credit-pricing">
          {selectedPackage} Credits = ${(totalCost - transactionFee).toFixed(2)}
        </h6>
        <div className="py-2">
          <h6 className="purchase-summary-label">ORDER SUMMARY</h6>
          <div className="d-flex flex-column justify-content-center gap-1">
            <div className="purchase-summary-list">
              <span className="property">{selectedPackage} Credits</span>
              <span className="value">${(totalCost - transactionFee).toFixed(2)}</span>
            </div>
            <div className="purchase-summary-list">
              <span className="property">
                Transaction Fee ({(transactionFeeObject?.percentage / 100 || 5)}%)
              </span>
              <span className="value">${transactionFee}</span>
            </div>
            <div className="purchase-summary-list mb-3">
              <span className="total-label">Total</span>
              <span className="total-value">${totalCost}</span>
            </div>
          </div>
        </div>

        <button
          type="button"
          className={`btn btn-success w-100 ${selectedPackage ? '' : 'inactive'}`}
          onClick={proceedToPayments}>
          Proceed to payment methods
        </button>
      </Modal.Body>
    </Modal>
  );
};
