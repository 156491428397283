import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_NOTIFICATION_SETTING,
  UPDATE_NOTIFICATION_SETTING
} from '../../../../Services/mutation/notification';
import { GET_NOTIFICATION_SETTING } from '../../../../Services/query/notifications';
import ToggleButton from '../ToggleButton/ToggleButton';
import './style.css';

const NotificationAccount = ({ userData }) => {
  const [createNotification] = useMutation(CREATE_NOTIFICATION_SETTING);
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION_SETTING);
  const [state, setState] = useState({});
  const [refreshed, setRefreshed] = useState(false);

  const { data, refetch } = useQuery(GET_NOTIFICATION_SETTING);

  const notifications = data?.listNotificationSettings?.items;
  const notification = notifications?.filter((elt) => elt?.roleID === userData?.id);

  useEffect(() => {
    refetch();
    setRefreshed(!refreshed);
  }, [state]);

  useEffect(() => {
    const obj = {
      ...state,
      roleID: userData?.id,
      createdAt: new Date(),
      updatedAt: new Date()
    };
    if (notification !== undefined) {
      if (notification?.length === 0) {
        createNotification({
          variables: { ...obj }
        })
          .then(refetch)
          .catch((err) => console.log(err));
      } else {
        obj.id = notification[0]?.id;
        updateNotification({
          variables: { ...obj }
        })
          .then(refetch)
          .catch((err) => console.log(err));
      }
    }
  }, [refreshed]);

  useEffect(() => {
    setState(notification !== undefined ? notification[0] : {});
  }, [data]);

  return (
    <div className="row justify-content-center pt-2">
      <div className="col-lg-8 col-sm-10 col-xl-6 mt-3">
        <div className="my-4 rounded-2 bg-white p-4">
          <div className="bg-white p-4 mt-2 rounded-2">
            <h6 className="section-heading mb-0">NOTIFICATION SETTINGS</h6>
            <h6 className="section-subheading">Customize your email notification setting</h6>

            <div className="form-group my-4">
              <h6 className="form-legend text-gray">OPPORTUNITIES</h6>
              <div className="d-flex align-items-center justify-content-between">
                <label className="institution-form-label">
                  Closing date approaching for Opportunities I saved
                </label>
                <ToggleButton
                  width={56}
                  name="saved_closing_date"
                  isChecked={state?.saved_closing_date}
                  onChange={() =>
                    setState((prev) => ({
                      ...prev,
                      saved_closing_date: !state?.saved_closing_date
                    }))
                  }
                />
              </div>
            </div>
            <div className="form-group my-4">
              <h6 className="form-legend text-gray">STUDENT</h6>
              <div className="d-flex align-items-center justify-content-between">
                <label className="institution-form-label">New student joining Junity</label>
                <ToggleButton
                  width={56}
                  isChecked={state?.new_student_joining}
                  name="new_student_joining"
                  onChange={() =>
                    setState((prev) => ({
                      ...prev,
                      new_student_joining: !state?.new_student_joining
                    }))
                  }
                />
              </div>
            </div>
            <div className="form-group my-4">
              <h6 className="form-legend text-gray">CHATS</h6>
              <div className="d-flex align-items-center justify-content-between">
                <label className="institution-form-label">New chat started with me</label>
                <ToggleButton
                  width={56}
                  isChecked={state?.new_chat}
                  name="new_chat"
                  onChange={() =>
                    setState((prev) => ({
                      ...prev,
                      new_chat: !state?.new_chat
                    }))
                  }
                />
              </div>
              <div className="d-flex align-items-center justify-content-between mt-1">
                <label className="institution-form-label">
                  New reply to an existing chat I am involved in
                </label>
                <ToggleButton
                  width={56}
                  isChecked={state?.new_reply}
                  name="new_reply"
                  onChange={() =>
                    setState((prev) => ({
                      ...prev,
                      new_reply: !state?.new_reply
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationAccount;
