import awsmobile from './aws-exports';
import AWS from 'aws-sdk';

AWS.config.update({
  region: awsmobile.aws_cognito_region,
  credentials: new AWS.CognitoIdentityCredentials({
    RoleArn: 'arn:aws:iam::620156083350:role/Counselors_role',
    IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
  })
});

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY
});

export const cognitoidentity = new AWS.CognitoIdentityServiceProvider({
  apiVersion: '2016-04-18'
});

export const paramsWithPassword = (username, password) => {
  const data = {
    Password: password,
    UserPoolId: awsmobile.aws_user_pools_id,
    Username: username,
    Permanent: true
  };
  return data;
};

export const paramsWithUsername = (username) => {
  const data = {
    UserPoolId: awsmobile.aws_user_pools_id,
    Username: username
  };

  return data;
};

export const paramsCreateUser = (username, password, data) => {
  const params = {
    UserPoolId: awsmobile.aws_user_pools_id,
    Username: username,
    DesiredDeliveryMediums: ['EMAIL'],
    ForceAliasCreation: true,
    MessageAction: 'SUPPRESS',
    TemporaryPassword: password,
    UserAttributes: [
      {
        Name: 'name',
        Value: data.name
      },
      {
        Name: 'gender',
        Value: data.gender || 'Male'
      },
      {
        Name: 'birthdate',
        Value: data.birthdate || '1900-10-01'
      },
      {
        Name: 'phone_number',
        Value: data.contact_phone
      },
      {
        Name: 'email',
        Value: data.contact_email
      },
      {
        Name: 'email_verified',
        Value: 'True'
      }
    ]
  };

  return params;
};
