import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CreateGroupModal from './CreateGroupModal';
import { GetGroupsByRoleID } from '../../../../Services/query/myGroups';
import { formatDate } from '../../../../utils/helpers';
import { AllStudents } from '../../../../Services/query/students';
import { useSelector } from 'react-redux';
import { GetSingleSchool } from '../../../../Services/query/school';
import { SUBSCRIPTIONS } from '../../../../utils/data';
import OutlineGroup from '../../../../Assets/images/OutlineGroup.svg';

import './Mygroupsmain.css';
import { AllCategories } from '../../../../Services/query/categories';
import { useLayoutEffect } from 'react';
import { filterYouthsByInterests } from '../../../../utils/Youth/utils';

function Mygroupsmain() {
  const user = useSelector((store) => store.educatorUser.data);
  const [currentSubscription, setCurrentSubscription] = useState(SUBSCRIPTIONS[user?.subscription]);
  const [interests, setInterests] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const navigate = useNavigate();

  const [myGroups, setMyGroups] = useState([]);
  const [connectedStudents, setConnectedStudents] = useState([]);

  useEffect(() => {
    if (user && Object.keys(user).length) {
      const sub = user?.subscription || 'free';
      setCurrentSubscription(SUBSCRIPTIONS[sub]);
    }
  }, [user]);

  const GroupComponent = ({ elt }) => {
    return (
      <div className="card-group-box p-3" onClick={() => navigate(`/my-groups?id=${elt?.id}`)}>
        <h5 className="card-group-name">{elt?.name}</h5>
        <div className="d-flex align-items-center gap-2">
          <img src={OutlineGroup} width={15} alt="outline-group" />
          <h6 className="card-group-count m-0">{elt?.studentIDs?.length} students</h6>
        </div>
        <p className="card-group-created-on">Created {formatDate(elt?.createdAt)}</p>
      </div>
    );
  };

  const handleSetInterest = async () => {
    const categories = await AllCategories();
    const interests = categories.reduce((acc, category) => {
      const resp = { ...acc };
      resp[category.name] = 0;
      return resp;
    }, {});

    setInterests(interests);

    const allstudents = await AllStudents(user?.schoolID);
    const students = allstudents?.filter((elt) => user?.school?.connections?.includes(elt.id));
    GetSingleSchool(user.schoolID).then((school) => {
      const connectedStudents = school.connections || [];
      const connectedStudentsData = students.filter((elt) => connectedStudents.includes(elt.id));

      const studentInterests = connectedStudentsData.reduce((acc, student) => {
        const interestData = student.interests.reduce((innerAcc, interest) => {
          const innerResp = { ...innerAcc };

          innerResp[interest] = filterYouthsByInterests(students, [interest])?.length;
          return innerResp;
        }, {});

        return { ...acc, ...interestData };
      }, {});
      console.log(studentInterests);
      setConnectedStudents(connectedStudentsData);
      setInterests({ ...interests, ...studentInterests });
    });
  };

  useLayoutEffect(() => {
    handleSetInterest();
  }, []);

  useEffect(() => {
    GetGroupsByRoleID(user?.id).then((groups) => setMyGroups(groups));
    GetSingleSchool(user.schoolID).then((school) => {
      setConnectedStudents(school.connections || []);
    });
  }, [refetch]);

  return (
    <>
      <div className="col-sm-12 background_color pl-0 pr-0">
        <div className="col-sm-12 pt-3 pb-2 background_color_opportunities mb-4">
          <div className="row">
            <div className="col-sm-6">
              <h4 className="page-name">My Groups</h4>
            </div>
            <div className="col-sm-6 m-auto text-right"></div>
          </div>
        </div>

        <div className="col-sm-12 pb-3">
          <div className="row">
            <div className="col-sm-12 div1_height mb-4">
              <div className="col-sm-12 backcolor_mygroups  mb-4  ">
                {/*------------------------|| Header  ||-----------------------------*/}
                <div className="col-sm-12 pt-3  text-left ">
                  <h5 className="subheading m-0">
                    <b>MY GROUPS</b>
                  </h5>
                  <h6 className="subtext m-0">Create and manage custom youth groups</h6>
                </div>

                {/*------------------------|| Cards ||-----------------------------*/}
                <div className="col-sm-12 mt-3 pb-3">
                  <div className="d-flex gap-3" style={{ flexWrap: 'wrap' }}>
                    {myGroups?.map((elt, index) => (
                      <GroupComponent elt={elt} key={index} />
                    ))}
                    {[
                      ...Array(currentSubscription?.ALLOWED_GROUPS_LIMIT - myGroups?.length).keys()
                    ].map(() => (
                      <div
                        key={`createGroup{index}`}
                        className="card-create-group"
                        onClick={() => setModalShow(true)}>
                        <i className="fas fa-plus fa-2x"></i>
                      </div>
                    ))}
                  </div>
                </div>

                {/*------------------------|| Divider ||-----------------------------*/}
                <div className="col-sm-12">
                  <hr></hr>
                </div>

                {/*------------------------|| DEFAULT GROUPS ||-----------------------------*/}
                <div className="col-sm-12 pt-3  text-left ">
                  <h5 className="subheading m-0">
                    <b>DEFAULT GROUPS</b>
                  </h5>
                  <h6 className="subtext m-0">
                    Automatically created groups based on youths' interests
                  </h6>
                </div>

                <div className="row pl-2">
                  <div className="col-sm-3 mt-1 pb-4">
                    <Link to={'/youth'} className="d-flex gap-3" style={{ flexWrap: 'wrap' }}>
                      <div className="card-group-box p-3">
                        <h5 className="card-group-name">All Youths</h5>
                        <div className="d-flex align-items-center gap-2">
                          <img src={OutlineGroup} width={15} alt="outline-group" />
                          <h6 className="card-group-count m-0">
                            {connectedStudents.length}{' '}
                            {connectedStudents.length === 1 ? 'youth' : 'youths'}
                          </h6>
                        </div>
                        <p className="card-group-created-on">Last updated today</p>
                      </div>
                    </Link>
                  </div>
                  {Object.keys(interests).map((elt) => (
                    <Link to={`/youth?interest=${elt}`} className="col-sm-3 mt-1 pb-4" key={elt}>
                      <div className="d-flex gap-3" style={{ flexWrap: 'wrap' }}>
                        <div className="card-group-box p-3">
                          <h5 className="card-group-name">{elt}</h5>
                          <div className="d-flex align-items-center gap-2">
                            <img src={OutlineGroup} width={15} alt="outline-group" />
                            <h6 className="card-group-count m-0">
                              {interests[elt]} {parseInt(interests[elt]) === 1 ? 'youth' : 'youths'}
                            </h6>
                          </div>
                          <p className="card-group-created-on">Last updated today</p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateGroupModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        setRefetch={setRefetch}
        connections={connectedStudents}
      />
    </>
  );
}

export default Mygroupsmain;
