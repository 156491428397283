import { gql } from '@apollo/client';

export const CREATE_MYGROUPS = gql`
  mutation CreateMyGroupsMutation(
    $createdAt: AWSDateTime
    $name: String!
    $roleID: ID!
    $studentIDs: [String!]
    $description: String
  ) {
    createMyGroup(
      input: {
        createdAt: $createdAt
        name: $name
        roleID: $roleID
        studentIDs: $studentIDs
        description: $description
      }
    ) {
      id
      name
    }
  }
`;

export const UPDATE_MYGROUPS = gql`
  mutation CreateMyGroupsMutation(
    $updatedAt: AWSDateTime
    $id: ID!
    $name: String
    $studentIDs: [String!]
  ) {
    updateMyGroup(input: { id: $id, name: $name, studentIDs: $studentIDs, updatedAt: $updatedAt }) {
      id
      name
    }
  }
`;

export const DELETE_MYGROUP = gql`
  mutation CreateMyGroupsMutation($id: ID!) {
    deleteMyGroup(input: { id: $id }) {
      id
    }
  }
`;
