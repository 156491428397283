import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { FiSettings, FiChevronLeft } from 'react-icons/fi';
import Notifications from './Notifications';
import Settings from './Settings';
import './styles.css';

const NotificationPopup = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState('notification');
  useImperativeHandle(ref, () => ({
    showPopup(params) {
      setOpen(true);
      setOption(params);
    }
  }));
  const hidePopup = () => {
    setOpen(false);
  };
  const openSettings = (params) => {
    setOption(params);
  };
  return (
    <>
      <Modal size="md" show={open} onHide={hidePopup} centered className="rounded--lg right">
        <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
          <Modal.Title>
            {option === 'notification' 
             ? 'Notifications' 
             : (
               <div className="d-flex align-items-center gap-2">
                 <FiChevronLeft
                  className="lightPrimary mr-2 cursor-pointer"
                  size={18}
                  onClick={() => openSettings('notification')}
                />
                <span>Settings</span>
              </div>
                 
             )
            }
          </Modal.Title>
          <div class="d-flex gap-2">
            <div
              className="close_btn drawer rounded-2"
              onClick={hidePopup}>
              <IoMdClose fill="#515A6A" size={18} />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0 px-3 w-100 overflow-hidden">
          <Notifications default="all" hidePopup={hidePopup}/>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default NotificationPopup;
