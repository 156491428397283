import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { Storage } from 'aws-amplify';
import { setInstitutionDataAction } from '../../Redux/actions/institutionAction';
import { setSignUpDataAction } from '../../Redux/actions/signUpDataAction';
import { useMutation } from '@apollo/client';
import { CREATE_SCHOOL } from '../../Services/mutation/school';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { isRequiredFieldsPassed } from '../../utils/validators';
import UploadImage from '../../Assets/images/upload-logo.svg';
import AlertToast from '../../Components/common/Alerts/ToastAlert';

import './style.css';

const SignUpInstitution = () => {
  const imgRef = useRef(null);
  const [state, setState] = useState({});
  const [imageProps, setImageProps] = useState({});
  const [verified, setVerified] = useState(false);
  const signupData = useSelector((store) => store.signUp.data);
  const [activeToast, setActiveToast] = useState(false);
  const [toast, setToast] = useState('');
  const [createSchool] = useMutation(CREATE_SCHOOL);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const progress = () => {
    return <ClipLoader size={10} color={loading ? '#fff' : ''} />;
  };

  useEffect(() => {
    setState({
      ...state,
      contact_email: signupData.email,
      address: signupData.address
    });
  }, []);

  useEffect(() => {
    setVerified(isRequiredFieldsPassed(state, 4, 'eq') && imageProps.image);
  }, [state, imageProps]);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  const teamSizeData = ['1-4', '5-10', '11-15', 'Over 15', "I'm not sure"];

  const teamSizeDiv = [];

  teamSizeData.forEach((elt, index) => {
    teamSizeDiv.push(
      <span key={index}>
        <button
          className={`btn rounded-pill btn_institute_style ${
            state.team_size === elt ? 'active' : ''
          } btn-sm ml-1`}
          onClick={() => teamSizeChange(elt)}>
          {elt}
        </button>
      </span>
    );
  });

  const handleImageChange = (e) => {
    const image = e.target.files[0];
    const imageSize = image.size / 1024 / 1024;

    if (imageSize > 1) {
      setActiveToast(true);
      setToast('Image size is more than 1mb');
    } else {
      const imgUrl = window.URL.createObjectURL(image);

      setImageProps({
        image: imgUrl,
        imageFile: image
      });
    }
  };

  const teamSizeChange = (value) => {
    setState({
      ...state,
      team_size: value
    });
  };

  const handleImgClick = () => imgRef.current.click();

  const proceed = () => {
    setLoading(true);
    Storage.put(`${signupData.email}-${imageProps.imageFile.name}`, imageProps.imageFile)
      .then((data) => {
        const inputData = { ...state };
        inputData.image = `https://junity-counselor-storage204659-staging.s3.amazonaws.com/public/${data.key}`;
        inputData.is_verified = false;
        inputData.createdAt = new Date();
        inputData.website = signupData.website;
        inputData.full_address = signupData.full_address;
        inputData.type = 'mentor';

        createSchool({
          variables: { ...inputData }
        })
          .then((data) => {
            const obj = { ...state };
            obj.id = data?.data?.createSchool?.id;
            setLoading(false);
            dispatch(setInstitutionDataAction(obj));
            dispatch(
              setSignUpDataAction({
                schoolName: inputData.name,
                schoolID: obj.id
              })
            );
            navigate('/setup-account');
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <AlertToast
        variant={'error'}
        icon={'delete'}
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
      <div className="col-sm-12 bgimage-signup mt-0 pt-0 overflow-auto">
        <div className="row pl-sm-5 pb-4">
          <div className="card col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-2 mt-sm-5 offset-sm-1 p-2 pb-4">
            <div className="col-sm-12">
              <img src="../images/logo.png" alt="" />
            </div>
            <div className="col-sm-12 pt-3 ">
              <h6 style={{ color: '#969CA5' }}>
                <b>Welcome, {signupData.fname}</b>
              </h6>
            </div>
            <div className="col-sm-12 ">
              <div className="d-flex flex-column heading-text mb-0 opacity-85">
                <span className="lh-1">
                  <b>Let’s set up your</b>
                </span>
                <span className="lh-1 mt-1">
                  <b>institution account.</b>
                </span>
              </div>
            </div>
            <div className="col-sm-12 mt-3">
              <div className="row">
                <div style={{ display: 'flex' }}>
                  <div
                    onClick={handleImgClick}
                    style={{ display: imageProps?.image ? 'block' : 'none' }}>
                    <img
                      src={imageProps.image}
                      alt=""
                      width="120px"
                      height="120px"
                      style={{ borderRadius: '50%' }}
                    />
                  </div>

                  <div
                    className="col-sm-3"
                    style={{ display: imageProps?.image ? 'none' : 'block' }}>
                    <button className="institution_logo_pick btn rounded-circle dashed point">
                      <label className="pt-3 pl-2 pr-2 point">
                        <img src={UploadImage} alt="" />
                        <input
                          id="select_logo"
                          type="file"
                          size="10"
                          ref={imgRef}
                          onChange={handleImageChange}
                        />
                      </label>
                    </button>
                  </div>
                  <div className="col-sm-9 m-auto">
                    <h6 className="m-0 p-0" style={{ fontSize: '80%', color: '#226E54' }}>
                      <b>Upload your institution’s logo</b>
                    </h6>
                    <h6 className="mt-1 pt-1" style={{ fontSize: '70%', color: '#969CA5' }}>
                      <b>The image must be at least 400x400px and a maximum of 1mb.</b>
                    </h6>
                  </div>
                </div>
                <div className="col-sm-12 mt-3">
                  <label className="input-label-text opacity-75">Organization Name</label>
                  <input
                    className="form-control"
                    onChange={handleChange}
                    type="text"
                    name="name"
                    placeholder="Organization Name"
                    required
                  />
                </div>
                <div className="col-sm-12 mt-3">
                  <label className="input-label-text opacity-75">Official Email Address</label>
                  <div className="input-group">
                    <input
                      className="form-control signupinput"
                      onChange={handleChange}
                      type="text"
                      name="contact_email"
                      value={signupData.email}
                      placeholder="Official Email Address"
                      aria-describedby="email_checked"
                      required
                      disabled
                    />
                    <div className="input_checked pl-2 pr-2">
                      <span className="" id="email_checked">
                        <CheckCircleFill size={20} fill="#2D9371" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 mt-3">
                  <label className="input-label-text opacity-75">Office Address</label>
                  <div className="input-group">
                    <input
                      className="form-control signupinput"
                      onChange={handleChange}
                      type="text"
                      name="address"
                      value={signupData.address}
                      placeholder="Office Address"
                      aria-describedby="email_checked"
                      required
                      disabled
                    />
                    <div className="input_checked pl-2 pr-2">
                      <span className="" id="email_checked">
                        <CheckCircleFill size={20} fill="#2D9371" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 mt-3 mb-1">
                  <label className="input-label-text opacity-75">Team Size </label>
                  <div className="col-sm-12 p-0 pb-2">{teamSizeDiv}</div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 mt-2">
              <button
                className={`btn btn-success btn-block py-2 ${verified ? '' : 'inactive'}`}
                onClick={proceed}
                style={{ fontSize: '14px' }}>
                {loading ? progress() : 'Next'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpInstitution;
