import React, { useEffect } from 'react';
import './style.css';
import { Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const Creatingaccount = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/account-created');
    }, 3000);
  }, []);

  return (
    <div className="col-sm-12 bgimage-signup1">
      <div className="row pl-sm-5">
        <div className="card col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-2 mt-sm-5 offset-sm-1 p-2">
          <div className="col-sm-12">
            <img src="../images/logo.png" />
          </div>

          <div className="col-sm-12 mt-5 text-center">
            <Spinner animation="border" size="lg" role="status" />
          </div>
          <Link to="/account-created" style={{ textDecoration: 'none', color: 'black' }}>
            <div className="col-sm-12 mb-5 text-center mt-5">
              <p>Creating Your Account....</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Creatingaccount;
