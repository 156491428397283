import React from 'react';
import { useCollapse } from 'react-collapsed';
import NoProfileImg from '../../../../../Assets/images/no-img.jpg';

function CreditCollpse({
  upgradeRequests,
  showSubscriptionUpgradeRequestModal,
  showSubscriptionUpgradeDeclineModal
}) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const UpgradeListItem = ({ request }) => {
    return (
      <div className="d-flex flex-column justify-content-center border-bottom p-3">
        <div className="d-flex align-items-center gap-2">
          <img
            src={request?.user?.image || NoProfileImg}
            className="thumbnail"
            width={50}
            height={50}
          />
          <div className="d-flex flex-column justify-content-center">
            <span className="invitation-date">
              {new Date(request?.updatedAt).toDateString().slice(4)}
            </span>
            <span className="invitation-text">
              <span className="invitation-user-name">{request?.user?.name}</span> is requesting to
              upgrade to Growth
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center gap-2 mt-3">
          <button
            type="button"
            className="btn btn-approve-invite"
            onClick={() => {
              showSubscriptionUpgradeRequestModal(request?.id);
            }}>
            Approve Upgrade 👍
          </button>
          <button
            type="button"
            className="btn btn-decline-invite"
            onClick={() => {
              showSubscriptionUpgradeDeclineModal(request?.id);
            }}>
            Decline
          </button>
        </div>
        {/*
        <span
          className="show-more-link mt-3"
          data-toggle="collapse" 
          data-parent="#accordianId" 
          aria-expanded="true" 
          aria-controls="section1ContentId">
          Show more <i className="fas fa-angle-down"></i>
        </span>
*/}
      </div>
    );
  };

  return (
    <div className="react-collapsible">
      <div
        className={`react-collapsible-header p-3 ${isExpanded ? 'rounded-bottom-0' : ''}`}
        {...getToggleProps()}>
        <div className="d-flex align-items-center gap-2">
          <div className="react-collapsible-icon-container">
            <i className="far fa-bell react-collapsible-icon"></i>
          </div>
          <div className="d-flex flex-column justify-content-ceneter">
            <span className="react-collapsible-header-title">Pending update request</span>
            <span className="react-collapsible-header-text">
              {upgradeRequests?.length || 0} staff member requested to upgrade their subscription plan
            </span>
          </div>
        </div>
        <i className={`fas fa-angle-${isExpanded ? 'up' : 'down'} react-collapsible-icon`}></i>
      </div>
      <section className={`react-collapsible-body rounded-bottom`} {...getCollapseProps()}>
        {upgradeRequests.map((request, index) => (
          <UpgradeListItem request={request} key={`request${index}`} />
        ))}
      </section>
    </div>
  );
}

export default CreditCollpse;
