/* eslint-disable no-unused-expressions */
import { gql } from '@apollo/client';
import { API } from 'aws-amplify';
export const GET_ALL_STUDENTS = gql`
  query AllStudents {
    listStudents(limit: 1000) {
      items {
        email
        name
        state
        id
        educatorID
        dob
        gender
        phone
        points
        schoolID
        updatedAt
        zipcode
        createdAt
        username
        interests
        interestIDs
        address
        balance
        apartment
        graduation_year
        graduation_term
        verification_date_started
        verification_progress
        verification_progress_dates
        verification_status
        is_verified
        school
        schooling_mode
        profile_sharing
        employed
        current_employer
        current_occupation
        email_verified
        dont_show_app_pop
      }

      nextToken
    }
  }
`;

export const GET_ALL_STUDENTS_WITH_TOKEN = gql`
  query StudentsQuery($limit: Int, $nextToken: String) {
    listStudents(limit: $limit, nextToken: $nextToken) {
      items {
        email
        name
        state
        id
        educatorID
        dob
        interests
        interestIDs
        gender
        phone
        points
        schoolID
        updatedAt
        zipcode
        createdAt
        address
        balance
        apartment
        graduation_year
        graduation_term
        verification_date_started
        verification_progress
        verification_progress_dates
        verification_status
        is_verified
        school
        schooling_mode
        profile_sharing
        employed
        current_employer
        current_occupation
        email_verified
        username
        dont_show_app_pop
      }
      nextToken
    }
  }
`;

export async function AllStudents() {
  const resp = await API.graphql({
    query: GET_ALL_STUDENTS
  });

  const data = resp?.data?.listStudents;
  let nextToken = data?.nextToken;
  let students = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_ALL_STUDENTS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listStudents;

    const previousData = [...students, ...respData?.items];
    students = [...previousData];

    nextToken = respData?.nextToken;
  }

  return students || [];
}

export const GET_ALL_STUDENTS_SAME_SCHOOL = gql`
  query AllStudents($schoolID: ID) {
    listStudents(limit: 1000, filter: { schoolID: { eq: $schoolID } }) {
      items {
        email
        name
        state
        id
        educatorID
        dob
        gender
        phone
        points
        schoolID
        updatedAt
        zipcode
        createdAt
        username
        address
        balance
        apartment
        interests
        interestIDs
        graduation_year
        graduation_term
        verification_date_started
        verification_progress
        verification_progress_dates
        verification_status
        is_verified
        school
        schooling_mode
        profile_sharing
        employed
        current_employer
        current_occupation
        email_verified
        dont_show_app_pop
      }

      nextToken
    }
  }
`;

export const GET_ALL_STUDENTS_WITH_TOKEN_SAME_SCHOOL = gql`
  query StudentsQuery($limit: Int, $nextToken: String, $schoolID: ID) {
    listStudents(limit: $limit, nextToken: $nextToken, filter: { schoolID: { eq: $schoolID } }) {
      items {
        email
        name
        state
        id
        educatorID
        dob
        gender
        phone
        interests
        interestIDs
        points
        schoolID
        updatedAt
        zipcode
        createdAt
        address
        balance
        apartment
        graduation_year
        graduation_term
        verification_date_started
        verification_progress
        verification_progress_dates
        verification_status
        is_verified
        school
        schooling_mode
        profile_sharing
        employed
        current_employer
        current_occupation
        email_verified
        username
        dont_show_app_pop
      }
      nextToken
    }
  }
`;

export const GET_STUDENTS_BY_SEARCH = gql`
  query AllStudents($limit: Int) {
    listStudents(limit: $limit) {
      items {
        email
        name
        state
        id
      }
    }
  }
`;

export const GET_STUDENTS_BY_SEARCH_WITH_TOKEN = gql`
  query AllStudents($limit: Int, $nextToken: String) {
    listStudents(limit: $limit, nextToken: $nextToken) {
      items {
        email
        name
        state
        id
      }
    }
  }
`;

export async function GetSameSchoolStudents(schoolID) {
  const resp = await API.graphql({
    query: GET_ALL_STUDENTS_SAME_SCHOOL,
    variables: { schoolID }
  });

  const data = resp?.data?.listStudents;
  let nextToken = data?.nextToken;
  let students = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_ALL_STUDENTS_WITH_TOKEN_SAME_SCHOOL,
      variables: { limit: 1000, nextToken, schoolID }
    });
    const respData = resp?.data?.listStudents;

    const previousData = [...students, ...respData?.items];
    students = [...previousData];

    nextToken = respData?.nextToken;
  }

  return students || [];
}

export async function GetStudentsBySearch(name = '', connections = [], limit = 10) {
  const resp = await API.graphql({
    query: GET_STUDENTS_BY_SEARCH,
    variables: { limit },
    cache: true
  });

  let students = resp?.data?.listStudents?.items;
  const data = resp?.data?.listStudents;
  let nextToken = data?.nextToken;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_ALL_STUDENTS_WITH_TOKEN_SAME_SCHOOL,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listStudents;

    const previousData = [...students, ...respData?.items];
    students = [...previousData];

    nextToken = respData?.nextToken;
  }

  return students?.filter(
    (elt) => connections?.includes(elt.id) && elt.name.toLowerCase().startsWith(name)
  );
}

export const GET_STUDENT = gql`
  query SingleStudent($id: ID!) {
    getStudent(id: $id) {
      email
      name
      state
      id
      educatorID
      dob
      gender
      phone
      points
      schoolID
      updatedAt
      zipcode
      createdAt
      address
      balance
      apartment
      graduation_year
      graduation_term
      is_verified
      verification_date_started
      verification_progress
      verification_progress_dates
      verification_status
      schooling_mode
      interests
      interestIDs
      school
      profile_sharing
      current_employer
      current_occupation
      employed
      email_verified
      dont_show_app_pop
      username
    }
  }
`;

export async function GetSingleStudent(id) {
  try {
    const respStudent = await API.graphql({
      query: GET_STUDENT,
      variables: { id },
      cache: true
    });
    return respStudent?.data?.getStudent;
  } catch (err) {
    console.error(err);
    return {};
  }
}

export async function GetStudentByEmail(email) {
  const students = await AllStudents();
  const student = students.find((elt) => elt.email === email);
  return student || {};
}

export async function GetStudentByPhone(phone) {
  const students = await AllStudents();
  const student = students.find((elt) => elt.phone === phone);
  return student || {};
}
