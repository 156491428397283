import React from 'react';

function Messages() {
  return (
    <div className="col-sm-12 text-center">
      <div className="col-sm-12">
        <div className="col-sm-12 padding_msgs">
          <img src="./images/msg1.png" className="img-fluid img_width_msg" />
        </div>
        <div className="col-sm-12 text-center mt-3">
          <div className="col-sm-12 fontcolor">
            <b>
              {' '}
              Stay Connected with your Students, Colleagues and <br /> Resource Organizations on
              Junity.{' '}
            </b>
            <br />
            Keep the chat space safe and in accordance with our Code of <br /> Conduct and any other
            applicable rules at your school.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Messages;
