/* eslint-disable no-unused-expressions */
import { gql, useQuery } from '@apollo/client';
import { API } from 'aws-amplify';
import { GetResource } from './resources';
import { GetStudentOpportunityApplicationByOpportunityID } from './applications';
import { GetSingleSchool } from './school';

export const GET_OPPORTUNITIES = gql`
  query OpportunitiesQuery {
    listOpportunities(limit: 1000) {
      items {
        id
        name
        description
        tag
        expiry_date
        questions
        createdAt
        remote
        duration
        facilitatorIDs
        facilitators
        resourceID
        state
        saved_by
        liked_by
        likes
        category
        interests
      }
    }
  }
`;

export const GET_OPPORTUNITIES_GRAPHQL = gql`
  query OpportunitiesQuery {
    listOpportunities(limit: 1000) {
      items {
        id
        name
        description
        tag
        expiry_date
        questions
        createdAt
        remote
        duration
        saved_by
        resourceID
        facilitatorIDs
        facilitators
        state
        liked_by
        likes
        category
        interests
      }

      nextToken
    }
  }
`;

export const GET_OPPORTUNITIES_WITH_TOKEN = gql`
  query OpportunitiesQuery($limit: Int, $nextToken: String) {
    listOpportunities(limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        tag
        expiry_date
        questions
        createdAt
        duration
        resourceID
        facilitatorIDs
        facilitators
        state
        remote
        liked_by
        saved_by
        likes
        category
        interests
      }
      nextToken
    }
  }
`;

export const GET_OPPORTUNITIES_BY_SEARCH = gql`
  query Opportunities($limit: Int) {
    listOpportunities(limit: $limit) {
      items {
        id
        name
        description
        expiry_date
      }
    }
  }
`;

export const GET_OPPORTUNITIES_BY_SEARCH_WITH_TOKEN = gql`
  query Opportunities($limit: Int, $nextToken: String) {
    listOpportunities(limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        expiry_date
      }
    }
  }
`;

export async function AllOpportunitiesAPI() {
  const resp = await API.graphql({
    query: GET_OPPORTUNITIES_GRAPHQL,
    cache: true
  });

  const data = resp?.data?.listOpportunities;
  let nextToken = data?.nextToken;
  let opportunities = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_OPPORTUNITIES_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listOpportunities;

    const previousData = [...opportunities, ...respData?.items];
    opportunities = [...previousData];

    nextToken = respData?.nextToken;
  }

  return await opportunities?.reduce(async (previousPromise, opportunity) => {
    let opportunitiesData = await previousPromise;
    if (new Date(opportunity?.expiry_date) > new Date()) {
      const resource = await GetResource(opportunity?.resourceID);
      const apps = await GetStudentOpportunityApplicationByOpportunityID(opportunity.id);
      const school = await GetSingleSchool(resource.organizationID);
      const obj = { ...opportunity, resource, apps, school };
      opportunitiesData.push(obj);
      return opportunitiesData;
    }
    return opportunitiesData;
  }, Promise.resolve([]));
}

export async function GetOpportunitiesBySearch(filter = '', limit = 10) {
  const resp = await API.graphql({
    query: GET_OPPORTUNITIES_BY_SEARCH,
    cache: true,
    variables: { limit }
  });

  const data = resp?.data?.listOpportunities;
  let nextToken = data?.nextToken;
  let opportunities = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_OPPORTUNITIES_BY_SEARCH_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listOpportunities;

    const previousData = [...opportunities, ...respData?.items];
    opportunities = [...previousData];

    nextToken = respData?.nextToken;
  }

  return opportunities.filter((opportunity) => {
    return (
      new Date(opportunity.expiry_date) >= new Date() &&
      opportunity.name.toLowerCase().startsWith(filter.toLowerCase())
    );
  });
}

export async function GetOpportunitiesByLikedBy(id, userID) {
  const opps = await AllOpportunitiesAPI();
  const opp = opps.find((opp) => (opp.id === id) & opp.liked_by.includes(userID));
  return opp || {};
}

export const GET_OPPORTUNITY = gql`
  query SingleOpportunity($id: ID!) {
    getOpportunity(id: $id) {
      id
      name
      description
      tag
      expiry_date
      facilitatorIDs
      facilitators
      createdAt
      questions
      duration
      resourceID
      automatic_message
      saved_by
      state
      liked_by
      remote
      likes
      category
      interests
    }
  }
`;

export function GetOpportunity(id) {
  const resp = useQuery(GET_OPPORTUNITY, {
    variables: {
      id: id
    }
  });

  if (resp?.data) {
    return resp?.data?.getOpportunity?.items;
  }

  return '';
}

export async function GetSingleOpportunity(id) {
  try {
    const respOpportunity = await API.graphql({
      query: GET_OPPORTUNITY,
      variables: { id }
    });
    const opportunity = respOpportunity?.data?.getOpportunity;
    const resource = await GetResource(opportunity.resourceID);
    const school = await GetSingleSchool(resource.organizationID);
    const apps = await GetStudentOpportunityApplicationByOpportunityID(opportunity.id);
    return { ...opportunity, resource, apps, school };
  } catch (err) {
    console.error(err);
    return {};
  }
}

export const GET_OPPORTUNITY_WITH_LIMIT = gql`
  query Opportunities($limit: Int) {
    listOpportunities(limit: $limit) {
      items {
        id
        name
        description
        tag
        expiry_date
        facilitatorIDs
        facilitators
        createdAt
        questions
        duration
        resourceID
        automatic_message
        state
        liked_by
        saved_by
        remote
        likes
        category
        interests
      }
    }
  }
`;

export async function GetOpportunitiesWithLimit(limit) {
  const resp = await API.graphql({
    query: GET_OPPORTUNITY_WITH_LIMIT,
    variables: { limit }
  });
  if (resp?.data) {
    const opportunities = resp?.data?.listOpportunities.items;
    return await opportunities?.reduce(async (previousPromise, opportunity) => {
      let opportunitiesData = await previousPromise;
      if (new Date(opportunity?.expiry_date) > new Date()) {
        const resource = await GetResource(opportunity.resourceID);
        const apps = await GetStudentOpportunityApplicationByOpportunityID(opportunity.id);
        const school = await GetSingleSchool(resource.organizationID);
        const obj = { ...opportunity, resource, apps, school };
        opportunitiesData.push(obj);
        return opportunitiesData;
      }

      return opportunitiesData;
    }, Promise.resolve([]));
  }
  return [];
}

export async function GetOpportunityBySchoolID(id) {
  const opportunities = await AllOpportunitiesAPI();
  const activeFilteredOpps = opportunities?.filter(
    (elt) => elt?.resource?.organizationID === id && new Date(elt?.expiry_date) > new Date()
  );
  const closedFilteredOpps = opportunities?.filter(
    (elt) => elt?.resource?.organizationID === id && new Date() > new Date(elt?.expiry_date)
  );

  return {
    active: activeFilteredOpps,
    closed: closedFilteredOpps
  };
}
