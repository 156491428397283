import { gql } from '@apollo/client';

export const UPDATE_OPPORTUNITY = gql`
  mutation UpdateOpportunity($id: ID!, $saved_by: [ID!]) {
    updateOpportunity(input: { id: $id, saved_by: $saved_by }) {
      id
      name
      description
      expiry_date
      createdAt
      schoolID
      state
      saved_by
      category
    }
  }
`;
