import { ToastContainer } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import AddStudentsDiv from './ManageStudents';
import { Modal } from 'react-bootstrap';
import { setGroupDataAction } from '../../../../Redux/actions/groupAction';
import { useDispatch } from 'react-redux';
import { MAXCHARACTERLENGTH } from '../../../../utils/data';
import { IoMdClose } from 'react-icons/io';

function MyVerticallyCenteredModal(props) {
  const [state, setState] = useState({});
  const [valid, setValid] = useState(false);
  const [next, setNext] = useState('details');
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value.slice(0, MAXCHARACTERLENGTH[e.target.name])
    });
  };

  useEffect(() => {
    setValid(state?.name !== '');
  }, [state]);

  const addGroup = () => {
    dispatch(setGroupDataAction(state));
    setNext('addStudents');
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal_body_style"
      centered>
      <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
        <Modal.Title>{next === 'details' ? 'Create a New Group' : 'Manage Youth'}</Modal.Title>
        <div className="close_btn drawer rounded-2" onClick={props.onHide}>
          <IoMdClose fill="#515A6A" size={18} />
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0 w-100 px-3">
        {next === 'details' ? (
          <>
            <p className="fs-14">This group can only be seen and managed by you.</p>
            <div className="form-group">
              <label className="form-group-label">Group Name</label>
              <input
                type="text"
                className="form-control"
                placeholder={`"Youths About to Graduate", "My Grade 12 Youths"`}
                name="name"
                value={state.name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label className="form-group-label">Description (Optional)</label>
              <textarea
                rows={3}
                className="form-control"
                placeholder="What is this group for?"
                name="description"
                value={state?.description}
                onChange={handleChange}></textarea>
              <div className="d-flex w-100 justify-content-end py-1">
                <small
                  className={`input-info ${
                    state?.description?.length - 100 > 0 ? 'text-danger' : ''
                  }`}>
                  {`${state?.description?.length ? 100 - state?.description?.length : '0'}`}/100
                  characters left
                </small>
              </div>
            </div>
            <div className="form-group">
              <button
                type="button"
                className="btn btn-success"
                style={{ width: '100%', opacity: valid ? 1 : 0.6 }}
                disabled={!valid}
                onClick={addGroup}>
                Create Group
              </button>
            </div>
          </>
        ) : (
          <AddStudentsDiv
            onHide={props.onHide}
            setState={setState}
            setNext={setNext}
            setRefetch={props.setRefetch}
            connections={props.connections}
          />
        )}
        <ToastContainer />
      </Modal.Body>
    </Modal>
  );
}

export default function CreateGroupModal(props) {
  const { modalShow, setModalShow, setRefetch, connections } = { ...props };

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setRefetch={setRefetch}
        connections={connections}
      />
    </>
  );
}
