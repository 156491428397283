import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import './Confirmresetpassword.css';
import { validatePassword } from '../../utils/validators';
import { cognitoidentity, paramsWithPassword } from '../../userPool';
import { axiosClient } from '../../libs/axiosClient';

const Confirmresetpassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({});
  const [userData, setUserData] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [passswordsMatch, setPasswordsMatch] = useState(false);
  const search = new URLSearchParams(location.search);
  const token = search.get('id');

  const [loading, setLoading] = useState(false);

  const progress = () => {
    return <ClipLoader size={10} color={loading ? '#fff' : ''} />;
  };

  const resetPassword = async () => {
    setLoading(true);
    try {
      await cognitoidentity
        .adminSetUserPassword(paramsWithPassword(userData.email, state.password))
        .promise();
      setLoading(false);
      navigate('/reset-password-successful');
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  const handleChange = (e) => {
    const newState = state;

    newState[e.target.name] = e.target.value;

    if (e.target.type === 'password') {
      const validPassword = validatePassword(e.target.value);

      e.target.classList.remove('border');

      if (e.target.value) {
        e.target.classList.add('border');

        if (validPassword) {
          e.target.classList.remove('border-danger');
          e.target.classList.add('border-success');
        } else {
          e.target.classList.remove('border-success');
          e.target.classList.add('border-danger');
        }
      }
    }

    setState({ ...newState });
  };

  useEffect(() => {
    setIsValid(
      Object.keys(state).length === 2 &&
        state?.password?.length >= 8 &&
        state?.password === state?.confirmPassword
    );

    if (state?.confirmPassword?.length) {
      const confirmPassInput = document.querySelector("[name='confirmPassword']");

      if (state?.confirmPassword === state?.password) {
        setPasswordsMatch(true);
        confirmPassInput.classList.remove('border-danger');
        confirmPassInput.classList.add('border-success');
      } else {
        setPasswordsMatch(false);
        confirmPassInput.classList.remove('border-success');
        confirmPassInput.classList.add('border-danger');
      }
    }
  }, [state]);

  useEffect(() => {
    if (token) {
      axiosClient
        .post('/decode-token', { token })
        .then((data) => {
          setUserData(data.data);
        })
        .catch((err) => console.log(err));
    }
  }, [token]);

  return (
    <div className="col-sm-12 bgimage-signup reset-pass">
      <div className="row pl-sm-5">
        <div className="card col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-2 mt-sm-5 offset-sm-1 p-2">
          <div className="col-sm-12">
            <img src="../images/logo.png" />
          </div>
          <div className="col-sm-12 pt-3">
            <Link to="/login" style={{ textDecoration: 'none' }}>
              <button className="btn btn-light btn_style_reset p-0 green-text btn-sm font-weight-bold">
                <i className="fas fa-angle-left"></i>&nbsp; Back to Log In{' '}
              </button>
            </Link>
          </div>
          <div className="col-sm-12  ">
            <p className="heading-text text-neutral font-weight-bold my-1">
              <b>Reset Password</b>
            </p>
          </div>
          <div className="col-sm-12 pt-1">
            <p className="paragraph-text fw-semibold opacity-75 mb-0">
              {' '}
              Your new password should be at least 8 characters long and contain a combination of
              alphabets, numbers and special characters.{' '}
            </p>
          </div>
          <div className="col-sm-12 mt-3">
            <div className="row">
              <div className="col-sm-12 mt-1">
                <label className="input-label-text opacity-85">New Password</label>
                <input
                  className="form-control signupinput"
                  name="password"
                  onInput={handleChange}
                  type="password"
                  placeholder="Choose a new password"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 mt-3">
            <div className="row">
              <div className="col-sm-12 mt-1">
                <label className="input-label-text opacity-85">Confirm Password</label>
                <input
                  className="form-control signupinput"
                  name="confirmPassword"
                  onInput={handleChange}
                  type="password"
                  placeholder="Type your new password again"
                />
                {state?.confirmPassword?.length && (
                  <span
                    className={`small text-danger font-weight-bold ${
                      passswordsMatch ? 'd-none' : 'd-block'
                    }`}
                    style={{ fontSize: '11px' }}>
                    Passwords do not match
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="col-sm-12 mt-4 pb-3">
            <button
              onClick={resetPassword}
              className={`btn btn-block font-weight-bolder border-0 py-2 ${
                !isValid ? 'inactive btn-neutral' : 'btn-success'
              }`}>
              {loading ? progress() : 'Reset Password'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmresetpassword;
