import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { cognitoidentity } from '../../../../../userPool';
import { paramsWithPassword } from '../../../../Setupaccount/utils/utils';
import AlertToast from '../../../../../Components/common/Alerts/ToastAlert';
import { sendEmailNotification } from '../../../../../utils/helpers';

function MyVerticallyCenteredModal(props) {
  const user = useSelector((store) => store.educatorUser?.data);
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [state, setState] = useState({});
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [toast, setToast] = useState('');

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const setUserPasswordCallback = async(err = false) => {
    setLoading(false);
    try {
      if (err) {
        console.error(err);
        setActiveToast(true);
        setToast('An error occurred, try again');
      } else {
        props.onHide();
        setPasswordChanged(true);
        await sendEmailNotification(
          user?.email,
          'password-changed',
          'Password Changed',
          {
              fname: user?.name?.split(' ')[0],
              account: user?.name,
              organization: user?.school?.name,
              moduleUrl: window.location.origin
          }
        )
      }
      
    } catch (error) {
      console.log(error)
    }
  };

  const resetPassword = async() => {
    setLoading(true);
    try {
        await Auth.signIn(user?.email, state?.oldPassword)

        cognitoidentity.adminSetUserPassword(
          paramsWithPassword(state?.newPassword, user?.email),
          setUserPasswordCallback()
        )
        await sendEmailNotification(
                user?.contact_email,
                'password-changed',
                'Password Changed',
                {
                    fname: user?.name?.split(' ')[0],
                    account: user?.name,
                    organization: user?.school?.name,
                    moduleUrl: window.location.origin
                }
            )
      } catch (err) {
        console.error(err);
        setLoading(false);
        setUserPasswordCallback(err);
    }
  };

  useEffect(() => {
    if (state) {
      setVerified(state?.confirmNewPassword === state?.newPassword);
    }
  }, [state]);

  return (
    <>
      {/* Change Password Modal */}
      <AlertToast
        variant={'error'}
        icon={'delete'}
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal_body_style"
        centered>
        <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
          <Modal.Title>Change Password</Modal.Title>
          <div className="close_btn drawer rounded-2" onClick={props.onHide}>
            <IoMdClose fill="#515A6A" size={18} />
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 w-100 px-3 payment-modal">
          <h5 className="info-text">
            Change your password to keep your account secure, especially if you think it has been
            compromised.
          </h5>
          <div className="form-group mb-3">
            <label className="institution-form-label">Old password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter old password"
              name="oldPassword"
              onChange={handleChange}
            />
          </div>
          <div className="form-group mb-3">
            <label className="institution-form-label">New password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Choose a new password"
              name="newPassword"
              onChange={handleChange}
            />
            <div className="form-control-info mt-1">
              Password should be at least 8 characters long and contain a combination of alphabets,
              numbers and special characters.
            </div>
          </div>
          <div className="form-group">
            <label className="institution-form-label">Confirm new password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Type your new password again"
              name="confirmNewPassword"
              onChange={handleChange}
            />
          </div>
          <Button
            onClick={resetPassword}
            disabled={!verified}
            className="btn btn-success w-100 mt-1"
            style={{ opacity: verified ? 1 : 0.6 }}>
            {loading ? 'Loading...' : 'Change Password'}
          </Button>
        </Modal.Body>
      </Modal>

      {/* Password Change Success Moal */}
      <Modal
        show={passwordChanged}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal_body_style"
        centered>
        <Modal.Body className="modal-body modal-info w-100 text-center">
          <img src="../images/lock.png" className="image-info mx-auto mt-3" />
          <h3 className="info-heading mt-4">Password Changed Successfully!</h3>
          <h6 className="info-description mb-4">
            You will now be logged out everywhere and redirected to the log in page ...
          </h6>
          <button
            type="button"
            className="btn btn-light text-dark w-100"
            onClick={() => {
              setPasswordChanged(false);
            }}>
            Close
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default function ChangePasswordModal(props) {
  const { modalShow, setModalShow } = { ...props };

  return (
    <>
      <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
