import React from 'react';
import Collpasetwo from '../../Collpasetwo/Collpasetwo';
import Youthcollapse from '../../Youthcollapse/Youthcollapse';
import { BsExclamationCircle } from 'react-icons/bs';
import '../Youthtab.css';

function Youthprofile({ data }) {
  return (
    <div className="mt-4">
      <div className="d-flex align-items-center mb-3">
        <div className="tab-heading mr-1">Profile</div>
        <BsExclamationCircle size={17} />
      </div>
      <div className="mt-3">
        <Youthcollapse data={data} />
      </div>
      <div className="mt-3">
        <Collpasetwo data={data} />
      </div>
    </div>
  );
}

export default Youthprofile;
