import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

export const SubscriptionUpgradeModal = ({
  subscriptionUpgrade,
  setSubscriptionUpgrade,
  selectedUpgradeRequest,
  approveUpgrade,
  loading,
  progress
}) => {
  return (
    <Modal
      show={subscriptionUpgrade}
      onHide={() => {
        setSubscriptionUpgrade(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="mx-auto"
      centered>
      <Modal.Body className="pt-0 w-100 px-3 payment-modal institution-form">
        <div className="w-100 d-flex justify-content-center">
          <img src="../images/caution1.png" className="image-info mx-auto mt-3" />
        </div>
        <h5 className="info-heading mt-3">Approve Subscription Upgrade?</h5>
        <h6 className="info-description">
          You are about to approve <b>{selectedUpgradeRequest?.user?.name?.split(' ')[0]}’s</b>{' '}
          request to upgrade her subscription to the Growth Plan. This will cost{' '}
          <b>70 credits/month</b> and will be charged monthly.
        </h6>
        <h6 className="purchase-subheading my-4 text-center">
          You may cancel this subscription at any time.
        </h6>
        <div className="d-flex justify-content-between align-items-center gap-2">
          <button
            type="button"
            className="btn btn-light text-dark"
            onClick={() => {
              setSubscriptionUpgrade(false);
            }}>
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              approveUpgrade();
            }}>
            {loading ? progress() : 'Approve'}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
