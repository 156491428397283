import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import SharedCards from '../../../Dashboard/Components/SharedCards/SharedCards';
import '../Allopportunities/Allopportunities.css';
import {
  CustomDateComponent,
  SearchByDateComponent,
  SearchByOrganizationComponent
} from './FilterComponents';
import { BsFunnel } from 'react-icons/bs';
import CalendarOutline from '../../../../Assets/images/calendar-outline.svg';

import './Allopportunities.css';
import {
  filterItemsByDateStrings,
  getCities,
  sameStateOppMultiplePlaces
} from '../../../../utils/helpers';
import { GetCategoryWithInterests } from '../../../../Services/query/categories';

// const handleFilter = (filterBy, filterParams, reset) => {
//   const handleFilterProps = {
//     setFilteredSearch,
//     setHeaderTextState,
//     reset,
//     filterBy,
//     filterParams,
//     setOverlay,
//     sameInterestOpps
//   };

//   HandleFilter(handleFilterProps);
// };

const animatedComponents = makeAnimated();

function Allopportunities({ opps, setRefetch }) {
  const [filteredOpps, setFilteredOpps] = useState(opps);
  const [oppType, setOppType] = useState('All');
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [interests, setInterests] = useState([]);
  const [drawSide, setDrawSide] = useState(false);
  const [places, setPlaces] = useState([]);
  const [show, setShow] = useState(false);
  const [isRemoteFilter, setIsRemoteFilter] = useState(false);
  const [showAllInterests, setShowAllInterests] = useState(false);
  const [dueDate, setDueDate] = useState('');
  const [postDate, setPostDate] = useState('');
  const [currentDatePickerDateType, setCurrentDatePickerDateType] = useState('');

  const dropdownRef = useRef();

  const modifiedStates = getCities({
    keys: ['label', 'value'],
    requiresAll: false,
    returnObj: true,
    sort: true
  });

  const handleInterestSelect = (e) => {
    if (e.target.checked) {
      setSelectedInterests((prev) => Array.from(new Set([...prev, e.target.name])));
    } else {
      setSelectedInterests(selectedInterests.filter((item) => item !== e.target.name));
    }
  };

  const resetFilter = () => {
    setFilteredOpps(opps);
    setOppType('All');
    setSelectedInterests([]);
    handlePlacesChange([]);
    setIsRemoteFilter(false);
    setDueDate('');
    setPostDate('');
  };

  const filterOppsByType = (e) => {
    setOppType(e.target.value);
  };

  const handlePlacesChange = (e) => {
    setPlaces(e);
    const placesRetrieved = e.reduce((acc, place) => {
      return [...acc, place.label];
    }, []);

    if (e.length > 0)
      setFilteredOpps(opps.filter((opp) => sameStateOppMultiplePlaces(opp.state, placesRetrieved)));
    else setFilteredOpps(opps);
  };

  const handleRemoteFilter = () => {
    setIsRemoteFilter((prev) => !prev);
    setFilteredOpps(
      opps.filter((opps) => {
        return opps.remote === !isRemoteFilter;
      })
    );
  };

  const dateTypes = {
    week: 'This Week',
    month: 'This Month',
    nextmonth: 'Next Month'
  };

  const openDropdown = () => {
    if (dropdownRef.current) {
      dropdownRef.current.classList.add('show');
    }
  };

  const closeDropdown = () => {
    if (dropdownRef.current) {
      dropdownRef.current.classList.remove('show');
    }
  };

  const handleToggleClick = () => {
    // Open or close the dropdown based on its current state
    if (dropdownRef.current) {
      const isOpen = dropdownRef.current.classList.contains('show');
      isOpen ? closeDropdown() : openDropdown();
    }
  };

  const handleCustomDateSet = (dateObj) => {
    const fieldType = currentDatePickerDateType === 'dueDate' ? 'expiry_date' : 'createdAt';
    setFilteredOpps(
      filterItemsByDateStrings('custom', opps, fieldType, dateObj?.startDate, dateObj?.endDate)
    );
  };

  useEffect(() => {
    if (oppType !== 'All') setFilteredOpps(opps.filter((opp) => opp?.category === oppType));
  }, [oppType, opps]);

  useEffect(() => {
    if (selectedInterests.length) {
      setFilteredOpps(
        filteredOpps?.filter((opp) =>
          opp.interests?.some((value) => selectedInterests.includes(value))
        )
      );
    } else {
      setFilteredOpps(opps);
    }
  }, [selectedInterests]);

  useEffect(() => {
    if (dueDate !== '') {
      setFilteredOpps(filterItemsByDateStrings(dueDate, opps, 'expiry_date'));
    }
  }, [dueDate]);

  useEffect(() => {
    if (postDate !== '') {
      setFilteredOpps(filterItemsByDateStrings(postDate, opps, 'createdAt'));
    }
  }, [postDate]);

  useEffect(() => {
    const handleSetDependencies = async () => {
      const interests = await GetCategoryWithInterests();
      const allSubInterests = interests.reduce((acc, curr) => [...acc, ...curr.interests], []);
      setInterests(allSubInterests);
    };
    handleSetDependencies();
  }, []);

  return (
    <div className="col-sm-12">
      <div className="row">
        <div
          style={{
            width: show ? '70%' : '100%',
            height: '80vh'
          }}
          className="opportunities-card">
          <div className="col-sm-12 mt-3 mb-3 backcolor_allopp">
            <div className="col-sm-12 pt-3 pb-3 pl-0 justify-between">
              <h6 className="subheading left">Showing {filteredOpps.length} opportunities</h6>
              {!show && (
                <div
                  className="d-flex align-items-center justify-start point subheading right"
                  style={{ color: '#226E54' }}
                  onClick={() => setShow(true)}>
                  <span>
                    <BsFunnel size={14} />
                  </span>
                  <span className="ml-1" style={{ color: '#226E54' }}>
                    Show Filters
                  </span>
                </div>
              )}
            </div>
            <div
              className="opp_cards pb-2 pl-2 d-flex gap-3"
              style={{
                width: '100%',
                flexWrap: 'wrap'
              }}>
              {filteredOpps.map((item, index) => (
                <React.Fragment key={index}>
                  <SharedCards
                    item={item}
                    drawSide={drawSide}
                    setDrawSide={setDrawSide}
                    id={item?.id}
                    setRefetch={setRefetch}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        {show ? (
          <div className="col-sm-4 mt-3 mb-3 filter-card" style={{ width: '30%' }}>
            <div className="col-sm-12 backcolor_allopp">
              <div className="row pt-3 pb-3">
                <div className="col-sm-6">
                  <div
                    className="close-filter cursor-pointer d-flex gap-1"
                    onClick={() => setShow(false)}>
                    <span
                      className="bg--lighterGray rounded-circle d-flex align-items-center justify-content-center"
                      style={{ width: 24, height: 24 }}>
                      <i className="fas fa-times" style={{ fontSize: 14 }}></i>
                    </span>
                    <span className="subheading">Close Filter</span>
                  </div>
                </div>
                <div className="col-sm-6 text-right">
                  <h6
                    className="textcolor_allopp subheading text-danger cursor-pointer"
                    onClick={resetFilter}>
                    {' '}
                    Reset Filter{' '}
                  </h6>
                </div>
              </div>
              <div className="col-sm-12 pl-1">
                <h6 className="filter-subheading">
                  <b>Opportunity Type</b>
                </h6>
              </div>
              <div className="col-sm-12 pl-1 pt-1">
                <div className="row">
                  <div className="col-sm-12">
                    <label className="filter-badge-radio mb-0 mr-1">
                      <input
                        type={'radio'}
                        name="opportunityType"
                        value={'Education'}
                        className="d-none"
                        checked={oppType === 'Education'}
                        onClick={filterOppsByType}
                      />
                      <span className="btn btn-outline-light background_allopp_btn ">
                        Education
                      </span>
                    </label>
                    <label className="filter-badge-radio mb-0 mr-1">
                      <input
                        type={'radio'}
                        name="opportunityType"
                        value={'Employment'}
                        checked={oppType === 'Employment'}
                        className="d-none"
                        onClick={filterOppsByType}
                      />
                      <span className="btn btn-outline-light background_allopp_btn ">
                        Employment
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 pl-1 pt-4">
                <h6 className="filter-subheading">
                  <b>Interests</b>
                </h6>
              </div>
              <div className="col-sm-12 pl-1 pt-1">
                <div className="row">
                  <div className="col-sm-12">
                    {interests?.slice(0, 5)?.map((elt, index) => (
                      <label className="filter-badge-radio mb-0 mr-1" key={index}>
                        <input
                          type={'checkbox'}
                          name={elt.name}
                          className="d-none"
                          checked={selectedInterests.includes(elt.name)}
                          onClick={handleInterestSelect}
                        />
                        <span className="btn btn-outline-light background_allopp_btn ">
                          {elt.name}
                        </span>
                      </label>
                    ))}
                    <span className={`pl-1 ${showAllInterests ? 'd-none' : ''}`}>
                      {' '}
                      <button
                        type="button"
                        className="btn btn-outline-light background_allopp_btn"
                        onClick={() => {
                          setShowAllInterests((prev) => !prev);
                        }}>
                        {' '}
                        +{interests?.length - 5} others{' '}
                      </button>{' '}
                    </span>
                    <div className={`${showAllInterests ? '' : 'd-none'}`}>
                      {interests?.slice(5)?.map((interest, index) => (
                        <label className="filter-badge-radio mb-0 mr-1" key={index}>
                          <input
                            type={'checkbox'}
                            name={interest.name}
                            className="d-none"
                            checked={selectedInterests.includes(interest.name)}
                            onClick={handleInterestSelect}
                          />
                          <span className="btn btn-outline-light background_allopp_btn ">
                            {interest.name}
                          </span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mt-2 pl-1 pt-2 pb-3 add_border">
                <h6
                  className="filter-subheading-dropdown cursor-pointer"
                  onClick={() => {
                    setShowAllInterests((prev) => !prev);
                  }}>
                  <b>
                    Show {showAllInterests ? 'Less' : 'All'}{' '}
                    <i className={`fas fa-angle-${showAllInterests ? 'up' : 'right'}`}></i>
                  </b>
                </h6>
              </div>
              <div className="col-sm-12 pl-1 pt-3">
                <h6 className="filter-subheading-dropdown-options">
                  <b>Location(s)</b>
                </h6>
              </div>
              <div className="col-sm-12 p-1">
                <Select
                  closeMenuOnSelect={false}
                  className="grayed"
                  components={animatedComponents}
                  isMulti
                  options={modifiedStates}
                  isClearable={true}
                  placeholder="Start typing..."
                  name="state"
                  value={places}
                  onChange={handlePlacesChange}
                />
              </div>
              <div className="col-sm-12 pl-1 pt-3">
                <h6 className="filter-subheading-dropdown-options">
                  <b>Organization</b>
                </h6>
              </div>
              <div className="col-sm-12 p-1">
                <div className="btn-group w-100">
                  <button
                    className="filter-button position-relative"
                    type="button"
                    id="triggerId"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <span>All Organizations</span>
                    <i className="fas fa-caret-down"></i>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-left dropup"
                    aria-labelledby="triggerId"
                    style={{ transform: 'translate3d(0px, -240px, 0px) !important' }}>
                    <SearchByOrganizationComponent />
                  </div>
                </div>
              </div>

              <div className="col-sm-12 p-1 pb-2 add_border">
                <div className="form-check ps-0">
                  <input
                    className="form-check-input m-0"
                    type="checkbox"
                    checked={isRemoteFilter}
                    id="defaultCheck1"
                    onClick={handleRemoteFilter}
                  />
                  <label className="form-check-label ml-1" htmlFor="defaultCheck1">
                    Show only Remote/Virtual Opportunities
                  </label>
                </div>
              </div>
              <div className="col-sm-12 pl-1 pt-2">
                <h6 className="filter-subheading-dropdown-options">
                  <b>Date Posted</b>
                </h6>
              </div>
              <div className="col-sm-12 p-1">
                <div className="btn-group w-100">
                  <button
                    className="filter-button position-relative"
                    type="button"
                    id="triggerId"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <div className="d-flex align-items-center">
                      <img src={CalendarOutline} alt="calendar" width={20} height={20} />
                      <span className="ml-2">{dateTypes[postDate] ?? 'Select a date range'}</span>
                    </div>
                    <i className="fas fa-caret-down"></i>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-left dropup"
                    aria-labelledby="triggerId"
                    style={{ transform: 'translate3d(0px, -240px, 0px) !important' }}>
                    <SearchByDateComponent
                      dueDate={postDate}
                      setDueDate={setPostDate}
                      setDateType={() => setCurrentDatePickerDateType('postDate')}
                    />
                  </div>
                </div>
              </div>
              <div
                className="dropdown w-100"
                id="customDateDropdown"
                data-bs-backdrop="false"
                onClick={(e) => e.stopPropagation()}>
                <div
                  className="dropdown-menu dropdown-menu-left dropup dropup-calendar"
                  aria-labelledby="customDateDropdown"
                  ref={dropdownRef}>
                  <CustomDateComponent
                    hide={handleToggleClick}
                    handleCustomDateSet={handleCustomDateSet}
                  />
                </div>
              </div>
              <div className="col-sm-12 pl-1 pt-1 pb-2">
                <h6 className="filter-subheading-dropdown-options">
                  <b>Application Deadline</b>
                </h6>
              </div>
              <div className="col-sm-12 p-1 mb-2">
                <div className="btn-group w-100">
                  <button
                    className="filter-button position-relative"
                    type="button"
                    id="triggerId"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <div className="d-flex align-items-center">
                      <img src={CalendarOutline} alt="calendar" width={20} height={20} />
                      <span className="ml-2">{dateTypes[dueDate] ?? 'Select a date range'}</span>
                    </div>
                    <i className="fas fa-caret-down"></i>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-left dropup"
                    aria-labelledby="triggerId"
                    style={{ transform: 'translate3d(0px, -240px, 0px) !important' }}>
                    <SearchByDateComponent
                      dueDate={dueDate}
                      setDueDate={setDueDate}
                      setDateType={() => setCurrentDatePickerDateType('dueDate')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default Allopportunities;
