import React, { useState, useEffect } from 'react';
import {
  // CardExpiryElement,
  // CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';

import CreditCollpse from './CreditCollapse/CreditCollpse';
import CreditTable from './CreditTable/CreditTable';
import '../Credit/CreditCollapse/Credit.css';
import CardCrousal from './CardCrousal/CardCrousal';
import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import AlertToast from '../../../../Components/common/Alerts/ToastAlert';
import { UPDATE_EDUCATOR } from '../../../../Services/mutation/educator';
import { CREATE_PAYMENT } from '../../../../Services/mutation/payment';
import { UPDATE_UPGRADE_REQUEST } from '../../../../Services/mutation/subscription';
import { setUserDataAction } from '../../../../Redux/actions/userAction';
import { GetEducator } from '../../../../Services/query/educator';
import { GetUserPayments } from '../../../../Services/query/payment';
import { GetOrganizationUpgradeRequests } from '../../../../Services/query/subscription';
import { onCreatePayment } from '../../../../graphql/subscriptions';
import { API } from 'aws-amplify';
import NoPurchases from '../../../../Assets/images/no-purchases.svg';

import { getCardLogo, progress, getPDFTemplate } from './utils';
import {
  PurchaseCreditsModal,
  PaymentMethodsModal,
  AddPaymentMethodModal,
  PaymentMethodAddSuccessModal,
  PaymentSuccessModal,
  ConfirmPaymentMethodDeletion,
  UpdatePaymentMethodModal,
  PurchaseOrderModal,
  SubscriptionUpgradeModal,
  NotEnoughCreditsModal,
  SubscriptionUpgradeSuccessModal,
  ConfirmUpgradeDeclineModal
} from './Modals';

import {
  ListCustomerPaymentMethods,
  createStripeCustomerAccount,
  attachPaymentMethodToCustomer,
  createPaymentIntent,
  // UpdatePaymentMethod,
  DetachPaymentMethod,
  GenerateInvoice,
  SendPurchaseOrderToEmail,
  GetPaymentSettings,
  getStripeCustomerAccount
} from '../../../../utils/Stripe';
import { sendEmailNotification } from '../../../../utils/helpers';

const Credit = () => {
  const dispatch = useDispatch();
  const [updateEducator] = useMutation(UPDATE_EDUCATOR);
  const [updateUpgradeRequest] = useMutation(UPDATE_UPGRADE_REQUEST);
  const [createPayment] = useMutation(CREATE_PAYMENT);
  const educator = useSelector((store) => store.educatorUser.data);
  const [user, setUser] = useState({});
  const [upgradeRequests, setUpgradeRequests] = useState([]);
  const [creditBalance, setCreditBalance] = useState(educator?.credits?.toFixed(2) || 0.0);
  const [purchaseCreditsModal, setPurchaseCreditsModal] = useState(false);
  const [upgradeDecline, setUpgradeDecline] = useState(false);
  const [paymentsModal, setPaymentsModal] = useState(false);
  const [addPaymentModal, setAddPaymentModal] = useState(false);
  const [paymentMethodAddedModal, setPaymentMethodAddedModal] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [updatePaymentMethodModal, setUpdatePaymentMethodModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  // const [selectedPaymentDetails, setSelectedPaymentDetails] = useState({});
  const [selectedPackage, setSelectedPackage] = useState(0);
  const [subscriptionUpgrade, setSubscriptionUpgrade] = useState(false);
  const [selectedUpgradeRequest, setSelectedUpgradeRequest] = useState({});
  const [notEnoughCredits, setNotEnoughtCredits] = useState(false);
  const [upgradeSuccessful, setUpgradeSuccessful] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [lastPurchaseDate, setLastPurchaseDate] = useState(null);
  const [priceObject, setPriceObject] = useState(null);
  const [transactionFeeObject, setTransactionFeeObject] = useState(null);
  const [transactionFee, setTransactionFee] = useState(0.0);
  const [upgradePrice, setUpgradePrice] = useState(70);
  const [totalCost, setTotalCost] = useState(0.0);
  const [billingDetails, setBillingDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastVariant, setToastVariant] = useState('success');
  const [toastIcon, setToastIcon] = useState('check');
  const [toastInfo, setToastInfo] = useState('');

  const stripe = useStripe();
  const elements = useElements();

  const fetchUpgradeRequests = async (organizationID) => {
    try {
      const requests = await GetOrganizationUpgradeRequests(organizationID);
      console.log(requests)
      setUpgradeRequests(requests);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPayments = async (educatorID) => {
    try {
      const payments = await GetUserPayments(educatorID);
      setPaymentHistory(payments);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ListCustomerPaymentMethods(educator?.stripeID);
        setPaymentMethods(data);
        await fetchPayments(educator?.id);
        await fetchUpgradeRequests(educator?.schoolID);
      } catch (error) {
        console.log(error);
      }
    };

    if (educator && Object.keys(educator)?.length && educator?.stripeID) {
      fetchData();

      // Payment Mutation Subscription
      let paymentSubscription = API.graphql({
        query: onCreatePayment,
        variables: { roleID: educator?.id }
      }).subscribe({
        next: async ({ value }) => {
          const newPayment = value?.data?.onCreatePayment;
          setPaymentHistory([...paymentHistory, newPayment]);
          let newBalance =
            newPayment?.type === 'credit'
              ? parseFloat(creditBalance) + newPayment?.credits
              : parseFloat(creditBalance) - newPayment?.credits;
          setCreditBalance(newBalance.toFixed(2));

          await updateEducator({
            variables: {
              id: educator?.id,
              credits: newBalance.toFixed(2)
            }
          });
          const data = await GetEducator(educator?.id);
          setUser(data);
          dispatch(setUserDataAction(data));
          showToast('success', 'Credits updated');
        },
        error: (error) => console.log(error)
      });

      // Return cleanup functions to unsubscribe from subscriptions
      return () => {
        paymentSubscription.unsubscribe();
      };
    }
  }, [educator]);

  useEffect(() => {
    if (paymentHistory && paymentHistory?.length)
      setLastPurchaseDate(new Date(paymentHistory[0].createdAt).toDateString().slice(4));
  }, [paymentHistory]);

  useEffect(() => {
    if (selectedPackage) {
      const subtotal = selectedPackage * (priceObject?.unit_amount / 100);
      const tranxfee = subtotal * ((transactionFeeObject?.percentage / 100) || 0.05);
      const total = subtotal + tranxfee;
      setTransactionFee(tranxfee.toFixed(2));
      setTotalCost(total.toFixed(2));
    }
  }, [selectedPackage]);

  useEffect(() => {
    const fetchPaymentSettings = async () => {
      try {
        const { price, transaction_fees } = await GetPaymentSettings();
        setPriceObject(price);
        setTransactionFeeObject(transaction_fees);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPaymentSettings();
  }, []);

  const showToast = (variant, message) => {
    setToastVariant(variant);
    setToastIcon(variant === 'success' ? 'check' : 'delete');
    setToastInfo(message);
    setToastActive(true);
  };

  const showSubscriptionUpgradeRequestModal = (requestID) => {
    setSelectedUpgradeRequest(upgradeRequests?.filter((elt) => elt?.id === requestID)[0]);
    setSubscriptionUpgrade(true);
  };

  const showSubscriptionUpgradeDeclineModal = (requestID) => {
    setSelectedUpgradeRequest(upgradeRequests?.filter((elt) => elt?.id === requestID)[0]);
    setUpgradeDecline(true);
  };

  const onUpdatePaymentMethod = (paymentID) => {
    setActiveCard(paymentMethods?.filter((elt) => elt?.id === paymentID)[0]);
    setUpdatePaymentMethodModal(true);
  };

  const onDeletePaymentMethod = () => {
    setUpdatePaymentMethodModal(false);
    setConfirmDeleteModal(true);
  };

  const proceedToPayments = () => {
    setPurchaseCreditsModal(false);
    setPaymentsModal(true);
  };

  const proceedToNewCardModal = () => {
    setPaymentsModal(false);
    setAddPaymentModal(true);
  };

  const updatePaymentMethod = async () => {
    // const cardElement = elements.getElement(CardExpiryElement);
    // console.log(cardElement);
    // console.log(billingDetails);
  };

  const approveUpgrade = async () => {
    if (educator?.credits && educator?.credits >= upgradePrice) {
      setLoading(true);
      try {
        await updateUpgradeRequest({
          variables: {
            id: selectedUpgradeRequest?.id,
            approval: 'approved',
            status: true,
            updatedAt: new Date()
          }
        });
        await updateEducator({
          variables: {
            id: selectedUpgradeRequest?.user?.id,
            subscription: 'growth',
            credits: creditBalance - upgradePrice
          }
        });
        await fetchUpgradeRequests(educator?.schoolID);
        if (selectedUpgradeRequest?.user?.id === educator?.id) {
          const data = await GetEducator(educator?.id);
          setUser(data);
          dispatch(setUserDataAction(data));
        }
        setCreditBalance(creditBalance - upgradePrice);
        await savePayment(
          0,
          `Subscription_${selectedUpgradeRequest?.user?.name?.split(' ')[0]}`,
          upgradePrice,
          'debit',
          { subscriptionUpgradeRedquestID: selectedUpgradeRequest?.id }
        );
        await sendEmailNotification(
          selectedUpgradeRequest?.user?.email,
          'resource-upgrade-approved',
          'Subscription Upgrade Approved',
          {
            fname: selectedUpgradeRequest?.user?.name?.split(' ')[0],
            account: selectedUpgradeRequest?.user?.name,
            organization: educator?.school?.name,
            moduleUrl: window.location.origin
          }
        )
        setLoading(false);
        setSubscriptionUpgrade(false);
        setUpgradeSuccessful(true);
        showToast('success', 'Upgrade Request Approved');
      } catch (error) {
        setLoading(false);
        console.log(error);
        setSubscriptionUpgrade(false);
        showToast('error', error?.message);
      }
    } else {
      setSubscriptionUpgrade(false);
      setNotEnoughtCredits(true);
    }
  };

  const declineUpgrade = async () => {
    setLoading(true);
    try {
      await updateUpgradeRequest({
        variables: {
          id: selectedUpgradeRequest?.id,
          approval: 'declined',
          status: true,
          updatedAt: new Date()
        }
      });
      setLoading(false);
      setUpgradeDecline(false);
      await fetchUpgradeRequests(educator?.schoolID);
      showToast('success', 'Upgrade Request Declined');
    } catch (error) {
      setLoading(false);
      console.log(error);
      showToast('error', error?.message);
    }
  };

  const createInvoice = async () => {
    setLoading(true);
    try {
      if (!educator?.stripeID) {
        try {
          const stripeID = await createStripeCustomerAccount(
            educator?.email,
            educator?.name,
            educator?.phone,
            'mentor'
          );
          await updateEducator({
            variables: { id: educator?.id, stripeID }
          });
          const data = await GetEducator(educator?.id);
          setUser(data);
          dispatch(setUserDataAction(data));
        } catch (error) {
          console.log(error);
          showToast('error', error?.message);
          setLoading(false);
        }
      }

      const invoice = await GenerateInvoice(
        user?.stripeID || educator?.stripeID,
        priceObject?.id,
        transactionFeeObject?.id,
        'Credit Purchase',
        selectedPackage,
        {
          credits: selectedPackage,
          roleID: educator?.id,
          roleEmail: educator?.email,
          roleType: 'mentor',
          roleName: educator?.name,
          roleCredits: educator?.credits,
          roleSchool: educator?.school?.name,
          moduleUrl: window.location.origin
        }
      );

      setLoading(false);
      setPurchaseOrder(false);
      showToast('success', 'Invoice Generated.');
      window.open(invoice?.hosted_invoice_url);
      // window.open(invoice?.invoice_pdf);
    } catch (error) {
      console.log(error);
      showToast('error', error?.message);
      setLoading(false);
    }
  };

  const deletePaymentMethod = async () => {
    setLoading(true);
    try {
      await DetachPaymentMethod(activeCard?.id);

      setPaymentMethods(paymentMethods?.filter((elt) => elt !== activeCard));
      setLoading(false);
      setConfirmDeleteModal(false);
      showToast('success', 'Payment Method Deleted');
    } catch (error) {
      console.log(error);
      showToast('error', error?.message);
      setLoading(false);
    }
  };

  const savePayment = async (amount, description, credits, type = 'credit', metaData = {}) => {
    try {
      const result = await createPayment({
        variables: {
          roleID: educator?.id,
          roleType: 'mentor',
          type: type,
          amount,
          description,
          credits,
          status: 'succeeded',
          metaData: JSON.stringify(metaData),
          createdAt: new Date(),
          updatedAt: new Date()
        }
      });

      return result?.data?.createPayment;
    } catch (error) {
      console.log(error);
    }
  };

  const initiatePayment = async () => {
    setLoading(true);
    try {
      const intent = await createPaymentIntent(
        totalCost,
        user?.stripeID || educator?.stripeID,
        educator?.email,
        selectedPaymentMethod,
        'Credit Purchase',
        {
          credits: selectedPackage,
          roleType: 'mentor',
          roleID: educator?.id
        }
      );

      let paymentSuccess = false;
      let result = {};

      if (intent?.status === 'succeeded') {
        paymentSuccess = true;
        result = intent;
      } else if (intent?.status === 'requires_action') {
        setPaymentsModal(false);
        const { paymentIntent, error } = await stripe?.confirmCardPayment(intent?.client_secret, {
          receipt_email: educator?.email
        });

        if (error) {
          showToast('error', error?.message);
        } else if (paymentIntent?.status === 'succeeded') {
          paymentSuccess = true;
          result = paymentIntent;
        }
      }

      if (paymentSuccess) {
        await savePayment(
          intent?.amount,
          intent?.description,
          intent?.metadata?.credits,
          'credit',
          { paymentID: intent?.id }
        );

        const receiptUrl = intent?.charges?.data[0]?.receipt_url;
        await sendEmailNotification(
          educator?.email,
          'credit-purchase-receipt',
          'Credit Purchase Receipt',
          {
            fname: educator?.name?.split(' ')[0],
            account: educator?.name,
            amount: intent?.metadata?.credits,
            receiptUrl,
            organization: educator?.school?.name,
            moduleUrl: window.location.origin
          }
        )

      }
      setPaymentsModal(false);
      setPaymentSuccess(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      showToast('error', error?.message);
    }
  };

  const addPaymentMethod = async () => {
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);

    if (!educator?.stripeID) {
      try {
        const stripeID = await createStripeCustomerAccount(
          educator?.email,
          educator?.name,
          educator?.phone,
          'mentor'
        );
        await updateEducator({
          variables: { id: educator?.id, stripeID }
        });
        const data = await GetEducator(educator?.id);
        setUser(data);
        dispatch(setUserDataAction(data));
      } catch (error) {
        console.log(error);
        showToast('error', error?.message);
        setLoading(false);
      }
    }
    elements.submit();
    // const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: billingDetails
      }
    });

    if (error) {
      showToast('error', error?.message);
      setLoading(false);
    } else {
      try {
        setPaymentMethods([paymentMethod, ...paymentMethods]);
        await attachPaymentMethodToCustomer(
          user?.stripeID || educator?.stripeID,
          paymentMethod?.id
        );

        setAddPaymentModal(false);
        setPaymentMethodAddedModal(true);
        showToast('success', 'Card Added Successfully');
      } catch (error) {
        showToast('error', error?.message);
      }
      setLoading(false);
    }
  };

  const sendPurchaseOrder = async (info) => {
    setLoading(true);
    try {
      const response = await SendPurchaseOrderToEmail({
        ...info,
        amount: totalCost,
        quantity: selectedPackage
      });
      showToast('success', response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      showToast('error', error?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const getRequests = async()=>{
      const requests = await fetchUpgradeRequests(educator?.schoolID);
    }
    getRequests()
  }, [])

  return (
    <>
      <div className="w-100">
        <div className="row">
          <div className="col-md-5 mt-2">
            <CreditCollpse
              {...{
                upgradeRequests,
                showSubscriptionUpgradeRequestModal,
                showSubscriptionUpgradeDeclineModal
              }}
            />
            <div className="back_image_credit card-credit-balance w-100 p-3 mt-3">
              <div className="d-flex flex-column">
                <h6>Credit Balance</h6>
                <h5>{creditBalance || 0}</h5>
                <h6 style={{ fontSize: '100%' }}>
                  {paymentHistory?.length ? <>Last purchase: {lastPurchaseDate || ''}</> : null}
                </h6>
              </div>
              <button
                className="btn btn-purchase-credit"
                onClick={() => {
                  setPurchaseCreditsModal(true);
                }}>
                Purchase Credits
              </button>
            </div>
            <CardCrousal
              {...{
                paymentMethods,
                getCardLogo,
                onDeletePaymentMethod,
                setAddPaymentModal
              }}
            />
          </div>
          <div className="col-md-7">
            <div className="rounded-3 bg-white p-3 mt-2">
              <h6 className="subheading mb-0">PAYMENT HISTORY</h6>
              {paymentHistory?.length ? (
                <CreditTable payments={paymentHistory} />
              ) : (
                <div
                  className="bg-white d-flex align-items-center justify-content-center"
                  style={{ height: '65vh' }}>
                  <img src={NoPurchases} alt="No Purchases" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <PurchaseCreditsModal
        {...{
          purchaseCreditsModal,
          setPurchaseCreditsModal,
          selectedPackage,
          setSelectedPackage,
          transactionFee,
          transactionFeeObject,
          totalCost,
          proceedToPayments
        }}
      />
      <PaymentMethodsModal
        {...{
          paymentsModal,
          setPaymentsModal,
          paymentMethods,
          selectedPaymentMethod,
          setSelectedPaymentMethod,
          selectedPackage,
          purchaseOrder,
          setPurchaseOrder,
          getCardLogo,
          initiatePayment,
          proceedToNewCardModal,
          transactionFee,
          totalCost,
          loading,
          progress
        }}
      />
      <AddPaymentMethodModal
        {...{
          educator,
          addPaymentModal,
          setAddPaymentModal,
          addPaymentMethod,
          billingDetails,
          setBillingDetails,
          stripe,
          loading,
          progress
        }}
      />
      <UpdatePaymentMethodModal
        {...{
          educator,
          updatePaymentMethodModal,
          setUpdatePaymentMethodModal,
          onUpdatePaymentMethod,
          onDeletePaymentMethod,
          updatePaymentMethod,
          setBillingDetails,
          getCardLogo,
          activeCard,
          loading,
          progress
        }}
      />
      <PurchaseOrderModal
        {...{
          purchaseOrder,
          setPurchaseOrder,
          selectedPackage,
          sendPurchaseOrder,
          getPDFTemplate,
          createInvoice,
          educator,
          totalCost,
          loading,
          progress
        }}
      />
      <SubscriptionUpgradeModal
        {...{
          subscriptionUpgrade,
          setSubscriptionUpgrade,
          selectedUpgradeRequest,
          approveUpgrade,
          loading,
          progress
        }}
      />

      <PaymentMethodAddSuccessModal
        {...{
          educator,
          paymentMethodAddedModal,
          setPaymentMethodAddedModal,
          setPaymentsModal
        }}
      />
      <ConfirmPaymentMethodDeletion
        {...{
          confirmDeleteModal,
          setConfirmDeleteModal,
          callback: deletePaymentMethod,
          loading,
          progress
        }}
      />
      <PaymentSuccessModal
        {...{
          paymentSuccess,
          setPaymentSuccess,
          selectedPackage
        }}
      />
      <NotEnoughCreditsModal
        {...{
          notEnoughCredits,
          setNotEnoughtCredits,
          setPurchaseCreditsModal,
          creditBalance,
          upgradePrice
        }}
      />
      <SubscriptionUpgradeSuccessModal
        {...{
          upgradeSuccessful,
          setUpgradeSuccessful,
          selectedUpgradeRequest
        }}
      />
      <ConfirmUpgradeDeclineModal
        {...{
          upgradeDecline,
          setUpgradeDecline,
          declineUpgrade,
          loading,
          progress
        }}
      />
      <AlertToast
        variant={toastVariant}
        icon={toastIcon}
        active={toastActive}
        setActive={setToastActive}
        info={toastInfo}
      />
    </>
  );
};

export default Credit;
