import React from 'react';
import Switch from 'react-switch';

export default function ToggleButton(props) {
  const { height, width, handleDiameter, isChecked, onChange } = {
    ...props
  };
  // const [checked, setChecked] = useState(isChecked);
  //
  // useEffect(() => {
  //   if (Object.keys(state || {})?.length > 0) {
  //     setChecked(state[name]);
  //   }
  // }, [name, state]);

  return (
    <Switch
      checked={isChecked}
      uncheckedIcon={false}
      checkedIcon={false}
      onColor="#226e54"
      offColor="#B9BDC3"
      height={height}
      width={width}
      handleDiameter={handleDiameter}
      onChange={onChange}
    />
  );
}
