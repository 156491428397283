import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import './style.css';

function MyVerticallyCenteredModal(props) {
  const [step, setStep] = useState(props.step);

  const RequestVerification = () => {
    props.callback('inprogress');
    setStep('secondStep');
  };

  const AddTeamStep = () => {
    return (
      <React.Fragment>
        <p className="d-flex mt-2">
          Sorry, you are unable to accept connections until your institution has been verified.
        </p>
        <div className="d-flex mt-3 justify-between">
          <button
            type="button"
            className="btn btn_success"
            style={{ width: '50%' }}
            onClick={() => {
              setStep('firstStep'), props.setAddTeam(false);
            }}>
            Request Verification
          </button>
          <button type="button" className="btn btn_light2 ml-2 pl-4 pr-4" onClick={props.onHide}>
            I'll do this later{' '}
          </button>
        </div>
      </React.Fragment>
    );
  };

  const FirstStep = () => {
    return (
      <React.Fragment>
        <div className="d-flex mt-2">
          <h3 className="subtitle">
            <b>Verify Your Institution</b>
          </h3>
        </div>
        <div className="d-flex pt-1">
          <h6 className="modal-text">
            We want to keep Junity safe for everyone including you. Verify your account to:
          </h6>
        </div>
        <div className="d-flex pt-1 modal-list-items flex-column">
          <h6 className="mt-3">
            <i className="fas fa-check-circle" id="icon_color"></i>
            <span className="ml-2">Start recommending Opportunities</span>
          </h6>
          <h6 className="mt-3">
            <i className="fas fa-check-circle" id="icon_color"></i>
            <span className="ml-2">Connect with Youths</span>
          </h6>
          <h6 className="mt-3">
            <i className="fas fa-check-circle" id="icon_color"></i>
            <span className="ml-2">Enable your profile for public view</span>
          </h6>
        </div>
        <div className="d-flex mt-4 justify-between">
          <button
            type="button"
            className="btn btn_success"
            style={{ width: '50%' }}
            onClick={RequestVerification}>
            Request Verification
          </button>
          <button type="button" className="btn btn_light2 ml-2 pl-4 pr-4" onClick={props.onHide}>
            I'll do this later{' '}
          </button>
        </div>
      </React.Fragment>
    );
  };

  const SecondStep = () => {
    return (
      <React.Fragment>
        <div className="d-flex mt-3 justify-center">
          <img src="../../images/clock.png" />
        </div>
        <div className="d-flex mt-3 justify-center">
          <h3 className="subtitle">
            <b>Verification Is In Progress</b>
          </h3>
        </div>
        <div className="d-flex pt-1 text-center pl-5 pr-5 modal-text">
          A member of the junity team will reach out to you within 48 hours to get your account
          verified
        </div>
        <div className="d-flex mt-4">
          <button
            type="button"
            className="btn btn_success"
            style={{ width: '100%' }}
            onClick={props.onHide}>
            Continue Exploring Junity
          </button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="mx-auto"
      centered>
      <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
        <Modal.Title>Account Verification</Modal.Title>
        <div className="close_btn drawer rounded-2" onClick={props.onHide}>
          <IoMdClose fill="#515A6A" size={18} />
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0 w-100 px-4">
        {props.type ? <AddTeamStep /> : step === 'firstStep' ? <FirstStep /> : <SecondStep />}
      </Modal.Body>
    </Modal>
  );
}

export default function RequestVerificationModal(props) {
  const { modalShow, setModalShow, setAddTeam, type, step, callback = () => {} } = { ...props };

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setAddTeam={setAddTeam}
        type={type}
        step={step}
        callback={callback}
      />
    </>
  );
}
