import React, { useState, useEffect } from 'react';
import Tabswitchabout from '../Tabswitchabout/Tabswitchabout';
import './Abouttogradmain.css';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { AllStudents, GetSingleStudent } from '../../../../Services/query/students';
import { GetGroup } from '../../../../Services/query/myGroups';
import { BsCheckLg } from 'react-icons/bs';
import { BiEditAlt } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import { useMutation } from '@apollo/client';
import { UPDATE_MYGROUPS } from '../../../../Services/mutation/myGroups';
import DeleteMyGroupModal from './Modals/DeleteMyGroup';
import ManageStudentsModal from './Modals/ManageStudentsModal';
import { formatDate } from '../../../../utils/helpers';
import { calculateAge } from '../../../../utils/Youth/utils';
import OutlineCornerUpLeft from '../../../../Assets/images/outline-corner-up-left.svg';

function Abouttogradmain() {
  const [state, setState] = useState({});
  const [refetch, setRefetch] = useState(false);
  const [mygroup, setMygroup] = useState({});
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [manageStudentsModalShow, setManageStudentsModalShow] = useState(false);
  const [updateGroup] = useMutation(UPDATE_MYGROUPS);
  const [editing, setEditing] = useState(false);
  const [youths, setYouths] = useState([]);
  const search = new URLSearchParams(useLocation().search);
  const id = search.get('id');

  const [filteredStudents, setFilteredStudents] = useState([]);
  const [myGroupData, setMyGroupData] = useState([]);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    GetGroup(id).then((result) => setMygroup(result));
    AllStudents().then((result) => setYouths(result));
  }, []);

  useEffect(() => {
    if (Object.keys(mygroup).length) {
      const mygroupData = mygroup?.data?.getMyGroup;
      setState(mygroupData);
      mygroupData?.studentIDs?.forEach((id) => {
        const student = youths?.filter((youth) => youth?.id === id)[0];
        filteredStudents.push(student);
      });
    }
  }, [mygroup]);

  const updateGroupInfo = () => {
    const inputData = {
      ...state,
      id: mygroup?.id,
      updatedAt: new Date()
    };

    updateGroup({
      variables: { ...inputData }
    })
      .then((results) => {
        setMygroup({
          ...mygroup,
          name: results?.data?.updateMyGroup?.name
        });

        setEditing(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    GetGroup(id).then(async (group) => {
      setMyGroupData(group);
      const data = await group?.studentIDs?.reduce(async (acc, studentID) => {
        const previousData = await acc;
        let resp = [];
        const student = await GetSingleStudent(studentID);
        // const interests = await GetStudentInterestsByID(studentID);
        const studentObj = { ...student };
        studentObj.age = calculateAge(student?.dob);
        resp = [...previousData, studentObj];
        return resp;
      }, Promise.resolve([]));

      setFilteredStudents(data);
    });
  }, [refetch]);

  return (
    <>
      <div className="p-3">
        <Link to="/my-groups" className="link-back">
          <img src={OutlineCornerUpLeft} width={16} alt="back button" />
          <span className="ml-1">Back to Groups</span>
        </Link>
        <div className="mt-3">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex align-items-center">
                {editing ? (
                  <>
                    <input
                      defaultValue={mygroup?.name}
                      name="name"
                      onChange={handleChange}
                      className="form-control form-control-group border-bottom border-dark rounded-0"
                    />
                    <div className="align-items">
                      <BsCheckLg
                        className="ml-2 mr-3 point"
                        size={20}
                        fill="#2D9371"
                        onClick={updateGroupInfo}
                      />
                      <AiOutlineClose
                        className="point"
                        onClick={() => setEditing(false)}
                        size={20}
                        fill="#515A6A"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <h6 className="group-detail-page-name mb-1">{mygroup?.name}</h6>
                    <BiEditAlt
                      className="ml-1"
                      onClick={() => setEditing(true)}
                      size={20}
                      fill={'#969CA5'}
                    />
                  </>
                )}
              </div>
              <div className="d-flex align-items-center gap-2">
                <span className="group-students-count">{filteredStudents?.length} Students</span>
                <span className="dot"></span>
                <span className="group-created-on">
                  Created on {formatDate(mygroup?.createdAt)}
                </span>
              </div>
            </div>
            <div className="d-flext align-items-center">
              <button
                className="btn btn-manage-students"
                onClick={() => setManageStudentsModalShow(true)}>
                <i className="fas fa-users"></i> Manage Group
              </button>
              <button
                className="btn btn-delete-group ml-2"
                onClick={() => setDeleteModalShow(true)}>
                <i className="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-12 p-0">
          <Tabswitchabout data={filteredStudents} groupID={id} />
        </div>
      </div>
      <DeleteMyGroupModal
        modalShow={deleteModalShow}
        setModalShow={setDeleteModalShow}
        id={id}
        group={mygroup?.name}
      />
      <ManageStudentsModal
        modalShow={manageStudentsModalShow}
        setModalShow={setManageStudentsModalShow}
        setRefetch={setRefetch}
        id={id}
      />
    </>
  );
}

export default Abouttogradmain;
