import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { CREATE_NOTE, UPDATE_NOTE } from '../../../../../Services/mutation/note';
import { Modal } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { IoMdClose } from 'react-icons/io';
import { useAuth } from '../../../..';
import { sendAppNotification } from '../../../../../utils/helpers';

function MyVerticallyCenteredModal(props) {
  const [valid, setValid] = useState(false);
  const [state, setState] = useState({});
  const [descDisabled, setDescDisabled] = useState(false);
  const [createNote] = useMutation(CREATE_NOTE);
  const [updateNote] = useMutation(UPDATE_NOTE);
  const COLORS = ['DDDFE2', 'FFDA9A','AFBFD9','A1EED4','FFB4B1']
  const [loading, setLoading] = useState(false);
  const { addNotification, user } = useAuth()

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  const onSuccess = async(data, action) => {
    setLoading(false)
    setState({
      color: data?.color,
      title: data?.title,
      description: data?.description
    });
    await sendAppNotification(
      user?.id,
      action === 'create'? 'Note created successfully' : 'Note updated successfully',
      'success',
      "",
      'Click here to view',
      'mentor',
      'inapp',
      'messageCircle'
    )
    props.onHide();
    props.setRetreive(!props.retreive);
  };

  const onError = (err) => {
    setLoading(false)
    addNotification({
      status: 'error',
      text: 'An error occured',
      icon: 'compass',
      type: 'inapp',
      time: new Date().toISOString(),
      shown: false
    });
    console.log(err);
  };

  const addNote = () => {
    setLoading(true)
    if (props?.data) {
      const inputData = {
        ...state,
        id: props?.data?.id,
        updatedAt: new Date()
      };
      updateNote({
        variables: { ...inputData }
      })
        .then((data) => onSuccess(data?.data?.updateNote, 'update'))
        .catch((err) => onError(err));
    } else {
      const inputData = {
        ...state,
        studentID: props?.student?.id,
        createdAt: new Date()
      };
      createNote({
        variables: { ...inputData }
      })
        .then((data) => onSuccess(data?.data?.createNote, 'create'))
        .catch((err) => onError(err));
    }
  };

  useEffect(() => {
    if (Object.keys(state || {})?.length === 3) {
      if (Object.values(state).every((elt) => elt?.trim() !== '')) {
        setValid(true);
      } else {
        setValid(false);
      }
    } else {
      setValid(false);
    }

    if (state?.description?.length - 100 > 0) {
      setState({
        ...state,
        description: state?.description?.substring(0, 100)
      });
      //toast("Maximum description entry reached")
    } else {
      setDescDisabled(false);
    }
  }, [state]);

  useEffect(() => {
    if (props?.data) {
      setState({
        color: props?.data?.color,
        title: props?.data?.title,
        description: props?.data?.description
      });
    }
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal_body_style"
        centered>
        <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
          <Modal.Title>{state?.title ?? 'New note'}</Modal.Title>
          <div className="close_btn drawer rounded-2" onClick={props.onHide}>
            <IoMdClose fill="#515A6A" size={18} />
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 w-100">
          <div className="form-group d-flex gap-2">
            {COLORS.map(color =>(
              <label className="color-badge-radio mb-0 mr-1" key={color}>
                <input
                  type={'radio'}
                  name={'color'}
                  value={color}
                  className="d-none"
                  onChange={handleChange}
                  checked={state?.color === color}
                />
                <span className={`btn btn-picker ${color}`}>{''}</span>
              </label>
            ))}
          </div>
          <div className="form-group">
            <input
              className="form-control form-control-title"
              type="text"
              placeholder="Give it a title"
              onChange={handleChange}
              value={state?.title}
              name="title"
              defaultValue={state?.title}
            />
          </div>
          <div className="form-group">
            <textarea
              rows={3}
              className="form-control form-control-description"
              placeholder={`Write something about ${props?.student?.name}...`}
              onChange={handleChange}
              name="description"
              defaultValue={state?.description}
              value={state?.description}></textarea>
            <div className="d-flex w-100 justify-content-end py-1">
              <small
                className={`input-info ${
                  state?.description?.length - 100 >= 0 ? 'text-danger' : ''
                }`}>
                {`${state?.description?.length ? 100 - state?.description?.length : '0'}`}/100
                characters left
              </small>
            </div>
          </div>
          <div className="form-group">
            <button
              type="button"
              className="btn btn-success"
              style={{ width: '100%', opacity: valid ? 1 : 0.6 }}
              disabled={!valid || loading}
              onClick={addNote}>
              {loading 
              ? <i className="fas fa-spinner fa-spin text-white"></i>
              : `${props?.data ? 'Update' : 'Create'} Note`}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default function CreateNoteModal(props) {
  const { modalShow, setModalShow, setRetreive, retreive, data, student } = { ...props };
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setRetreive={setRetreive}
        retreive={retreive}
        data={data}
        student={student}
      />
    </>
  );
}
