import React from 'react';
import TabInstitution from '../TabInstitution/TabInstitution';

function Institutionmain({ route }) {
  return (
    <div className="col-sm-12 pl-0 pr-0" style={{ minHeight: '100%' }}>
      {/* background_color */}
      <div className="col-sm-12 py-3">
        <h4 className="page-name">Institution</h4>
      </div>
      <div className="col-sm-12 p-0" style={{ height: '93%' }}>
        <TabInstitution route={route} />
      </div>
    </div>
  );
}

export default Institutionmain;
