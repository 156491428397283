import { AllEducators, GetEducatorsByEmail } from '../../Services/query/educator';
import { AllSchools } from '../../Services/query/school';

export const counselorExists = async (email) => {
  const educator = await GetEducatorsByEmail(email);
  return Object.keys(educator).length > 0;
};

const getEmailDomain = (email) => {
  return email.split('@')[1];
};

export const getSchoolByDomain = async (email) => {
  const schools = await AllSchools();
  const domain = getEmailDomain(email);
  const school = schools.find((elt) => getEmailDomain(elt.contact_email) === domain);
  return school || {};
};

export const getSchoolByDomainAndAddress = async (email, address) => {
  const schools = await AllSchools();
  const domain = getEmailDomain(email);
  const school = schools.find(
    (elt) =>
      getEmailDomain(elt.contact_email) === domain &&
      elt.address === address &&
      elt.type === 'mentor'
  );
  return school || {};
};

export const checkIfDomainExists = async (email) => {
  if (email) {
    const domain = getEmailDomain(email);
    const educators = await AllEducators();
    const filteredEducators = educators.filter((elt) => getEmailDomain(elt.email) === domain);
    return filteredEducators.length !== 0;
  }
};

export const compareDomains = (website, email) => {
  const urlParts = new URL(website).hostname.split('.');
  const domain = urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join('.');

  const emailDomain = getEmailDomain(email);

  return domain.includes(emailDomain);
};
