import React from 'react';
import Sidebar from '../Sidenav/index';
import Home from './Components/Home/Home';

function Dashboardhome() {
  return (
    <div className="col-sm-12 p-0" style={{ overflowX: 'hidden' }}>
      <Sidebar component={<Home />} />
    </div>
  );
}

export default Dashboardhome;
