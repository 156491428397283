import React, { useState } from 'react';
import { IoMdClose, IoMdInformationCircleOutline } from 'react-icons/io';
import { BsCheck2Circle } from 'react-icons/bs';
import RequestVerificationModal from './Modals/RequestVerification/index';
import './style.css';
import { InfoTooltip } from '../../../../Components/common/ToolTip';

function VerificationBanner({ setHideBanner, status = 'unverified', callback = () => {} }) {
  const [modalShow, setModalShow] = useState(false);
  const [addTeam, setAddTeam] = useState(false);

  const proceedTeam = () => {
    setAddTeam(true);
    setModalShow(true);
  };

  const showVerificationModal = () => {
    setAddTeam(false);
    setModalShow(true);
  };

  return (
    <>
      <div
        className={`verification_banner ${
          status === 'inprogress' ? 'inprogress text-bg-warning' : ''
        }`}>
        <div className="d-flex flex-wrap height-fit-content justify-content-start align-items-center gap-2">
          <div
            className={`banner_title_icon point  ${
              status === 'inprogress' ? 'border-warning' : ''
            }`}
            onClick={showVerificationModal}>
            <BsCheck2Circle
              size={20}
              fill={`${status === 'inprogress' ? '#554223' : '#226E54'}`}
              className="icon"
            />
            <span className={`icon_text ml-2`}>
              {status === 'inprogress' ? 'Verification In Progress' : 'Verify Your Institution'}
            </span>
          </div>
          <div className="detail">
            {status === 'inprogress' ? (
              <span>Verification of your account is in progress.</span>
            ) : (
              <>
                <span>Verify your account to have your account public. In the meantime</span>
                <span
                  className="fw-bolder ml-1 text-decoration-underline cursor-pointer"
                  onClick={proceedTeam}>
                  add your team here
                </span>
              </>
            )}
          </div>
        </div>
        <div className="d-flex gap-2">
          <InfoTooltip title={'Verify your account to access the disabled content'} placement="top">
            <IoMdInformationCircleOutline size={20} className="cursor-pointer" />
          </InfoTooltip>
          <IoMdClose onClick={() => setHideBanner(true)} size={20} className="point" />
        </div>
      </div>
      <RequestVerificationModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        type={addTeam}
        step={status === 'inprogress' ? 'secondStep' : 'firstStep'}
        setAddTeam={setAddTeam}
        callback={callback}
      />
    </>
  );
}

export default VerificationBanner;
