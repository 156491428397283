import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate} from 'react-router-dom';
import { InAppNotifications } from '../Components/common/Notification';
import { chatClient } from '../libs/axiosClient';
import { GetSingleChatHandler } from '../Services/query/chatHandler';
import { API } from 'aws-amplify';
import { onCreateAppNotification } from '../graphql/subscriptions';
import { Compass } from 'react-feather';
import { matchDomain, sendAppNotification } from '../utils/helpers';
import { resetUserDataAction, setUserDataAction } from '../Redux/actions/userAction';
import { resetEducatorDataAction } from '../Redux/actions/educatorActions';
import { resetInstitutionDataAction } from '../Redux/actions/institutionAction';
import { resetSignUpDataAction } from '../Redux/actions/signUpDataAction';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const user = useSelector((store) => store.educatorUser?.data);
  const [notificationsList, setNotificationsList] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const chat_id = search.get("id");
  const pathname = location.pathname
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let sockets = [];

  const logout = () => {
    dispatch(resetUserDataAction());
    dispatch(resetEducatorDataAction());
    dispatch(resetInstitutionDataAction());
    dispatch(resetSignUpDataAction());
    window.localStorage.clear()
    window.sessionStorage.clear()
    navigate('/login');
  };

  const createPushMessage = (notification) => {
    return {
      body: notification?.text,
      timestamp: new Date(notification?.time),
      silent: false,
      icon: notification?.icon
    }
  }

  const createPushNotification = (messageObject) => {
    if (document.visibilityState === 'visible') {
      return;
    }
    let notification = null
    if (Notification.permission === 'granted') {
      notification = new Notification('Junity Mentor', {
        ...messageObject
      });
    }

    if (notification && messageObject?.linkUrl) {
      notification.onclick = ()=>{
        window.location.href = window.location.origin + messageObject?.linkUrl
      }
    }
  };

  const addNotification = (notification) => {
    setNotificationsList(prev => {
        let newArray = prev
        if (!prev.includes(notification)){
            newArray = [...prev, notification]
        }
        return newArray
    });
    setAllNotifications(prev => {
        let newArray = prev
        if (!prev.includes(notification)){
            newArray = [...prev, notification]
        }
        return newArray
    });
    if (notification?.type === 'push'){
      createPushNotification(createPushMessage(notification))
    }
  };

  const removeNotification = (index) => {
    let newNotifications = [...notificationsList];
    newNotifications.splice(index, 1);
    setNotificationsList(newNotifications);
  };

  const clearNotifications = (index) => {
    setNotificationsList([]);
    setAllNotifications([])
  };

  useEffect(() => {
    if (!user?.id) {
        return navigate('/login')
    }
  }, [])

  useEffect(() => {
    const getNewMessages = async () => {
        try {
            const chats = await chatClient.get('chats/')
            const youthChats = chats?.data?.filter(
              (elt) =>
                JSON.parse(elt?.custom_json)?.orgID === user?.schoolID &&
                JSON.parse(elt?.custom_json)?.creatorType === 'youth' &&
                JSON.parse(elt?.custom_json)?.destinationID === user?.id
            );

            youthChats.forEach(async(chat) => {
                const data = await GetSingleChatHandler(chat?.id)
                let socket = new WebSocket(
                    `wss://api.chatengine.io/chat/?projectID=${process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID}&chatID=${chat?.id}&accessKey=${data?.access_key}`
                );
                socket.onmessage = async(message) => {
                    message = JSON.parse(message?.data)
                    if ((message?.action === 'new_message') && 
                    (!window.location.pathname.includes('/chat') || (chat_id && (Number.parseInt(chat_id) !== chat?.id)))){
                        const sender = JSON.parse(message?.data?.message?.custom_json)
                        if (sender?.sender_id !== user?.id){
                          await sendAppNotification(
                            user?.id,
                            `You have received a new direct message`,
                            'log',
                            `/chats?id=${chat?.id}&idType=youth&name=${sender?.sender_name}`,
                            'Click here to view',
                            'mentor',
                            'push',
                            'messageCircle'
                          )
                        }
                    }
                };
                sockets.push(socket);
            })

            const directChats = chats?.data?.filter(
              (elt) =>
                JSON.parse(elt?.custom_json)?.type === 'sameOrgDirectChat' &&
                JSON.parse(elt?.custom_json)?.creatorType === 'educator' &&
                JSON.parse(elt?.custom_json)?.destinationType === 'educator' &&
                (JSON.parse(elt?.custom_json)?.creatorID === user?.id ||
                  JSON.parse(elt?.custom_json)?.destinationID === user?.id)
            );

            directChats.forEach(async(chat) => {
              const data = await GetSingleChatHandler(chat?.id)
              let socket = new WebSocket(
                  `wss://api.chatengine.io/chat/?projectID=${process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID}&chatID=${chat?.id}&accessKey=${data?.access_key}`
              );
              socket.onmessage = async(message) => {
                  message = JSON.parse(message?.data)
                  if ((message?.action === 'new_message') && 
                  (!window.location.pathname.includes('/chat') || (chat_id !== chat?.id))){
                      const sender = JSON.parse(message?.data?.message?.custom_json)
                      if (sender?.sender_id !== user?.id){
                        await sendAppNotification(
                          user?.id,
                          `You have received a new direct message`,
                          'log',
                          `/chats?id=${chat?.id}&idType=youth&name=${sender?.sender_name}`,
                          'Click here to view',
                          'mentor',
                          'push',
                          'messageCircle'
                        )
                      }
                  }
              };
              sockets.push(socket);
            })

            const groupChats = chats?.data?.filter(
              (elt) =>
                JSON.parse(elt?.custom_json)?.orgID === user?.schoolID &&
                JSON.parse(elt?.custom_json)?.type === 'sameOrgGroupChat'
            );

            groupChats.forEach(async(chat) => {
                const data = await GetSingleChatHandler(chat?.id)
                let socket = new WebSocket(
                    `wss://api.chatengine.io/chat/?projectID=${process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID}&chatID=${chat?.id}&accessKey=${data?.access_key}`
                );
                socket.onmessage = async(message) => {
                    message = JSON.parse(message?.data)
                    if ((message?.action === 'new_message') && 
                    (message?.data?.message?.text?.includes(user?.name))){
                      const sender = JSON.parse(message?.data?.message?.custom_json)
                      if (sender?.sender_id !== user?.id){
                        await sendAppNotification(
                          user?.id,
                          `You have received a mention in a group chat`,
                          'log',
                          `/chats?id=${chat?.id}&idType=team&other=&name=${user?.school?.name}`,
                          'Click here to view',
                          'mentor',
                          'push',
                          'messageCircle'
                        )
                      }
                    }
                };
                sockets.push(socket);
            })
        } catch (error) {
            console.log(error)
        }
    };

    getNewMessages()

    const notifier = API.graphql({
      query: onCreateAppNotification,
      variables: {userId: user?.id, module: 'mentor'}
    }).subscribe({
      next: async(data) => {
        const noti = data?.value?.data?.onCreateAppNotification
        addNotification({
          status: noti?.title,
          text: noti?.text,
          linkUrl: noti?.link,
          linkTitle: noti?.action,
          icon: noti?.icon || 'messageCircle',
          type: noti?.type,
          time: new Date().toISOString(),
          shown: false
        })
      },
    });



    return () => {
      notifier.unsubscribe()
      sockets.forEach((socket) => socket.close());
    };
    
  }, [pathname])

  return (
    <AuthContext.Provider value={{ 
      user, notificationsList, 
      allNotifications,
      addNotification, 
      removeNotification, 
      clearNotifications,
      logout 
      }}>
        <InAppNotifications 
          notifications={notificationsList} 
          removeNotification={removeNotification}
        />
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
