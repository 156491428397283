import React, { Component } from 'react';
import TabAccount from '../TabAccount/TabAccount';
import './MyAccountmain.css';

function MyAccountmain({ route }) {
  return (
    <div className="col-sm-12 pl-0 pr-0" style={{ height: '100%' }}>
      {/* background_color */}
      <div className="col-sm-12 pt-2">
        <h3>
          <b>My Account</b>
        </h3>
      </div>
      <div className="col-sm-12 p-0" style={{ height: '93%' }}>
        <TabAccount route={route} />
      </div>
    </div>
  );
}

export default MyAccountmain;
