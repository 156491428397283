import React from 'react';
import { BsExclamationCircle } from 'react-icons/bs';

const Savedopportunities = ({ data }) => {
  const likeOppsData = [];
  const filteredLikeOppsData = likeOppsData?.filter((elt) => elt?.studentID === data?.id);

  const OpportunityComponent = ({ opportunity }) => {
    return (
      <div className="p-3 mb-3 rounded-3 border">
        <h6 className="saved-opp-name mb-1">{opportunity?.name}</h6>
        <h6 className="saved-opp-desc mb-1">{`${opportunity?.description?.substring(
          0,
          80
        )}...`}</h6>
        <div className="d-flex align-items-center gap-2">
          {opportunity?.tag?.map((elt, index) => (
            <React.Fragment key={index}>
              <button className={`btn rounded-pill activity-card-btn mt-2`}>{elt}</button>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="mt-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <div className="tab-heading mr-1">Saved Opportunities</div>
          <BsExclamationCircle size={17} />
        </div>
      </div>
      <div className="my-2">
        {filteredLikeOppsData?.length ? (
          filteredLikeOppsData?.map((elt, index) => (
            <React.Fragment key={`opportunity${index}`}>
              <OpportunityComponent opportunity={elt} />
            </React.Fragment>
          ))
        ) : (
          <div className="d-flex justify-center flex-column align-items-center">
            <img src="./images/card3.png" className="img-fluid img_size_set_opportunity" alt="" />
            <h6 className="font_home1">NO OPPORTUNITIES YET</h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default Savedopportunities;
