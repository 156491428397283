import { combineReducers } from 'redux';
import educatorReducer from './educatorReducers';
import institutionReducer from './institutionReducers';
import userReducer from './userReducer';
import sendbirdReducer from './senbirdReducer';
import groupReducer from './groupReducer';
import signUpDataReducer from './signUpDataReducer';

const rootReducer = combineReducers({
  educator: educatorReducer,
  institution: institutionReducer,
  educatorUser: userReducer,
  sendbirdApp: sendbirdReducer,
  group: groupReducer,
  signUp: signUpDataReducer
});

export default rootReducer;
