import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { DELETE_MYGROUP } from '../../../../../Services/mutation/myGroups';
import { Modal } from 'react-bootstrap';

function MyVerticallyCenteredModal(props) {
  const [deteleGroup] = useMutation(DELETE_MYGROUP);
  const navigate = useNavigate();

  const removeGroup = () => {
    deteleGroup({
      variables: {
        id: props.id
      }
    })
      .then((data) => {
        props.onHide();
        navigate('/my-groups?refetch=true');
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal_body_style"
      centered>
      <Modal.Body className="pt-3 w-100">
        <div className="d-flex flex-column align-items-center justify-content-center pt-0">
          <img src="../images/caution.png" className="img-fluid" />
          <h5 className="delete-modal-subtitle">Delete Group?</h5>
          <h6 className="delete-modal-prompt d-inline px-3">
            Are you sure you want to delete{' '}
            <span className="delete-modal-name">“{props?.group}”</span> group? This action cannot be
            undone.
          </h6>
        </div>
        <div className="d-flex justify-content-between align-items-center gap-3 my-2">
          <button
            type="button"
            className="btn btn-light btn-cancel"
            style={{ border: '1px solid gray' }}
            onClick={props.onHide}>
            Cancel
          </button>
          <button type="button" className="btn btn-danger btn-block" onClick={() => removeGroup()}>
            Delete Group
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default function DeleteMyGroupModal(props) {
  const { modalShow, setModalShow, id, group } = { ...props };

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        id={id}
        group={group}
      />
    </>
  );
}
