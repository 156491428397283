import React from 'react';
import { Link } from 'react-router-dom';
import Wallet from '../../../Assets/images/icons/wallet.png';
import './style.css';

export const CreditsBadge = ({
  credits,
  template = 'badge',
  size = 'md',
  lowerLimit = 201,
  upperLimit = 500
}) => {
  return (
    <Link
      to="/credit-and-payment"
      className={`credit-balance-${template} credit-balance-${size} ${
        credits < lowerLimit ? 'danger' : credits >= upperLimit ? 'success' : 'warning'
      }`}>
      <img src={Wallet} alt="icon" />
      <span className="ms-1">{credits || 0}</span>
      <span className="d-none d-md-inline ms-1">
        {' '}
        {credits && credits > 1 ? 'Credits' : 'Credit'}
      </span>
    </Link>
  );
};
