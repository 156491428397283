import React from 'react';
import { useCollapse } from 'react-collapsed';

function Collapseone() {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  return (
    <div className="col-sm-12">
      <div className="row">
        <div className="col-sm-10">
          <div className=" contact-button-style" {...getToggleProps()}>
            {isExpanded ? 'Your Contact Information' : 'Your Contact Information'}
          </div>
        </div>
        <div className="col-sm-2 angle-down-contact-icon">
          <i className="fas fa-angle-down"></i>
        </div>
      </div>
      <div className="col-sm-12 p-0">
        <section {...getCollapseProps()}>
          <div className="col-sm-10 p-0">
            <p className="font-size-contact">
              {' '}
              An account manager from Junity will engage with you by email and phone call to help
              onboard your school.{' '}
            </p>
          </div>
          <div className="col-sm-12 p-0 font-size-contact-input">
            <label>Your Work Email Address</label>
            <input
              className="form-control input-email-style-contact"
              type="email"
              placeholder="j.williams@bulkeley.org"
            />
          </div>
          <div className="col-sm-12 p-0 font-size-contact-input mt-3">
            <label>Your Work Phone Number</label>
            <input
              className="form-control input-email-style-contact"
              type="email"
              placeholder="(406) 555-0120"
            />
          </div>
          <div className="col-sm-12 p-0 mt-3">
            <p className="font-size-contact-input">What’s your role?</p>
          </div>
          <div className="col-sm-12">
            <div className="row">
              <span>
                <button className="btn btn-light btn-md rounded-pill ml-1 role-butoon-style">
                  Conselor
                </button>
              </span>
              <span>
                <button className="btn btn-light btn-md rounded-pill ml-1 role-butoon-style">
                  Mentor
                </button>
              </span>
              <span>
                <button className="btn btn-light btn-md rounded-pill ml-1 role-butoon-style">
                  Other
                </button>
              </span>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Collapseone;
