import { gql } from '@apollo/client';

export const CREATE_SCHOOL = gql`
  mutation CreateNewSchool(
    $contact_email: AWSEmail
    $name: String!
    $team_size: String
    $is_verified: Boolean
    $image: String
    $banner: String
    $address: String
    $type: String
    $createdAt: AWSDateTime!
    $website: String
    $full_address: String
  ) {
    createSchool(
      input: {
        contact_email: $contact_email
        name: $name
        image: $image
        address: $address
        team_size: $team_size
        is_verified: $is_verified
        createdAt: $createdAt
        type: $type
        banner: $banner
        website: $website
        full_address: $full_address
      }
    ) {
      contact_email
      contact_phone
      createdAt
      id
      name
      state
      full_address
      updatedAt
      zipcode
      connections
      accepting_connection
      type
      address
      website
      team_size
      counselors_dont_show_again
      about
      banner
      connection_dates
      invitations
      invitations_names
      invitations_dates
      dont_show_again
    }
  }
`;

export const UPDATE_SCHOOL = gql`
  mutation UpdateSchool(
    $id: ID!
    $about: String
    $image: String
    $website: String
    $banner: String
    $contact_phone: AWSPhone
    $invitations: [AWSEmail]
    $invitations_names: [String]
    $invitations_dates: [AWSDateTime]
    $accepting_connection: Boolean
  ) {
    updateSchool(
      input: {
        id: $id
        about: $about
        website: $website
        contact_phone: $contact_phone
        invitations: $invitations
        image: $image
        banner: $banner
        invitations_names: $invitations_names
        invitations_dates: $invitations_dates
        accepting_connection: $accepting_connection
      }
    ) {
      contact_email
      contact_phone
      createdAt
      id
      name
      state
      accepting_connection
      full_address
      updatedAt
      zipcode
      connections
      type
      banner
      address
      website
      team_size
      counselors_dont_show_again
      about
      connection_dates
      invitations
      invitations_dates
      invitations_names
      dont_show_again
    }
  }
`;
