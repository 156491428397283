import React from 'react';
import '../Recomendation/Recomendation.css';
import CollapseRecommend from './CollapseRecommend/CollapseRecommend';
import { BsExclamationCircle } from 'react-icons/bs';

export default function Recomendation({ data }) {
  return (
    <div className="mt-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <div className="tab-heading mr-1">Recommendations</div>
          <BsExclamationCircle size={17} />
        </div>
      </div>
      <CollapseRecommend data={data} />
    </div>
  );
}
