import React, { useState } from 'react';
import './style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const InvitedToSignUp = () => {
  const navigate = useNavigate();
  const search = new URLSearchParams(useLocation().search);
  const userData = useSelector((store) => store.signUp);

  return (
    <div className="col-sm-12 bgimage-signup1">
      <div className="row pl-sm-5">
        <div className="card col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-2 mt-sm-5 offset-sm-1 p-2 pb-3">
          <div className="col-sm-12">
            <img src="../images/logo.png" />
          </div>
          <div className="col-sm-12 mt-4">
            <div className="row">
              <h3 className="opacity-90">
                <b>You’ve been invited to Junity</b>
              </h3>
            </div>
          </div>

          <div className="col-sm-12 pt-1">
            <p className="paragraph-text2 fw-normal mb-0">
              You have been invited to join Junity via{' '}
              <span className="green-text fw-bold">{userData.data.email}</span>. Signing up will
              give you access to your institution’s Junity account where you can support youth and
              many more.
            </p>
          </div>

          <div className="col-sm-12 pt-1 mt-2">
            <p className="paragraph-text2 mb-0 bold">Is the email above yours?</p>
          </div>
          <div className="col-sm-12 mt-3 mb-2" onClick={() => navigate('/setup-account')}>
            <button className="btn buttoncolorsignup1 active py-2" style={{ fontSize: '14px' }}>
              Yes, proceed to Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitedToSignUp;
