import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Modal } from 'react-bootstrap';
import { CREATE_RECOMMENDATION } from '../../../../../Services/mutation/recommendations';
import Tabswitchopp from '../../Tabmodal/Tabswitchopp';
import { useSelector } from 'react-redux';
import { AllRecommendedOpportunities } from '../../../../../Services/query/recommendation';
import AlertToast from '../../../../../Components/common/Alerts/ToastAlert';
import { IoMdClose } from 'react-icons/io';

export default function RecommendOppModal({ modalShow, setModalShow, opportunityID }) {
  const [groupID, setGroupID] = useState([]);
  const [categoryID, setCategoryID] = useState({});
  const recommendedOpps = AllRecommendedOpportunities();
  const [recommended, setRecommended] = useState(false);
  const [current, setCurrent] = useState(0);
  const [createRecommendation] = useMutation(CREATE_RECOMMENDATION);
  const [loading, setLoading] = useState(false);
  const user = useSelector((store) => store.educatorUser);
  const [toastActive, setToastActive] = useState(false);
  const [toastVariant, setToastVariant] = useState('success');
  const [toastIcon, setToastIcon] = useState('check');
  const [toastInfo, setToastInfo] = useState('');


  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal_body_style"
        centered>
        <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
          <Modal.Title>Recommend this Opportunity</Modal.Title>
          <div
            className="close_btn drawer rounded-2"
            onClick={() => {
              setModalShow(false);
            }}>
            <IoMdClose fill="#515A6A" size={18} />
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 w-100 px-3 payment-modal institution-form">
          <Tabswitchopp setModalShow={setModalShow} opportunityID={opportunityID} />
        </Modal.Body>
      </Modal>
      <AlertToast
        variant={toastVariant}
        icon={toastIcon}
        active={toastActive}
        setActive={setToastActive}
        info={toastInfo}
      />
    </>
  );
}
