import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

export const ConfirmChatDeletionModal = ({
  showDeleteModal,
  setShowDeleteModal,
  username,
  chatId
}) => {
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState('');
  const [valid, setValid] = useState(false);

  const deleteChat = () => {
    if (confirmation === 'YES') {
      /* Deletion code here */
    }
  };

  useEffect(() => {
    if (confirmation === 'YES') {
      setValid(true);
    }
  }, [confirmation]);

  return (
    <Modal
      show={showDeleteModal}
      onHide={() => {
        setShowDeleteModal(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="mx-auto"
      centered>
      <Modal.Body className="pt-0 w-100 px-4 payment-modal modal-info">
        <div className="w-100 d-flex justify-content-center">
          <img src="../images/delete.png" className="image-info mx-auto mt-3" />
        </div>
        <h5 className="info-heading mt-3">Are you sure you want to delete this chat?</h5>
        <h6 className="info-description mb-4">
          {`This chat will only be deleted for you. Messages sent will 
            still be visible to ${username}`}
        </h6>
        <div className="form-group mt-3">
          <label className="institution-form-label">
            {`Type "YES" in all caps below to confirm deletion`}
          </label>
          <input
            type="text"
            class="form-control bg-light"
            aria-describedby="helpId"
            placeholder=""
            onChange={(e) => {
              setConfirmation(e.target.value);
            }}
          />
        </div>
        <div className="d-flex justify-content-between align-items-center gap-2 my-2">
          <button
            type="button"
            className="btn btn-danger"
            disabled={!valid}
            onClick={() => {
              deleteChat();
            }}>
            {loading ? <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Yes, delete'}
          </button>
          <button
            type="button"
            className="btn btn-light text-dark"
            onClick={() => {
              setShowDeleteModal(false);
            }}>
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
