import React from 'react';
import { useCollapse } from 'react-collapsed';

function Collapsetwo() {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  return (
    <div className="col-sm-12">
      <div className="row">
        <div className="col-sm-10">
          <div className=" contact-button-style" {...getToggleProps()}>
            {isExpanded ? 'Invite your colleague' : 'Invite your colleague'}
          </div>
        </div>
        <div className="col-sm-2 angle-down-contact-icon">
          <i className="fas fa-angle-down"></i>
        </div>
      </div>
      <div className="col-sm-12 p-0">
        <section {...getCollapseProps()}>
          <div className="col-sm-12 mt-3">
            <div className="row">
              <span>
                <button className="btn btn-light btn-md ml-1 rounded-pill collapse-two-button-style">
                  Principal
                </button>
              </span>
              <span>
                <button className="btn btn-light btn-md ml-1 rounded-pill collapse-two-button-style">
                  Other School Admin
                </button>
              </span>
              <span>
                <button className="btn btn-light btn-md ml-1 rounded-pill collapse-two-button-style">
                  Teacher/Counselor
                </button>
              </span>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-6 pl-0 mt-3 text-style-collapse2">
                <label>First Name</label>
                <input
                  className="form-control form-control-sm input-email-collapse2"
                  type="email"
                  placeholder="Fred"
                />
              </div>
              <div className="col-sm-6 pl-0 mt-3 text-style-collapse2">
                <label>Last Name</label>
                <input
                  className="form-control form-control-sm input-email-collapse2"
                  type="email"
                  placeholder="Macaulay"
                />
              </div>
              <div className="col-sm-12 mt-3 pl-0 font-size-contact-input">
                <label>Your Work Email Address</label>
                <input
                  className="form-control form-control-sm input-email-style-contact"
                  type="email"
                  placeholder="j.williams@bulkeley.org"
                />
              </div>
              <div className="col-sm-12 pl-0 pr-0">
                <hr />
              </div>
              <div className="col-sm-12 pl-1">
                <button className="btn p-0 btn-light btn-collapse2-style">
                  <i className="fas fa-plus-circle"></i>&nbsp; Add colleague
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="col-sm-12 pl-0 pr-0">
        <hr />
      </div>
    </div>
  );
}

export default Collapsetwo;
