import { SET_SIGNUP_DATA, RESET_SIGNUP_DATA } from '../constants/signUpDataConstants';

export const setSignUpDataAction = (data) => async (dispatch) => {
  dispatch({
    type: SET_SIGNUP_DATA,
    payload: data
  });
};

export const resetSignUpDataAction = () => async (dispatch) => {
  dispatch({
    type: RESET_SIGNUP_DATA
  });
};
