import React, { useEffect, useState } from 'react';
import '../Notes/Notes.css';
import MenuNotes from './MenuNotes';
import { AllNotes } from '../../../../Services/query/note';
import CreateNoteModal from './Modal/CreateNoteModal';
import { formatDate } from '../../../../utils/helpers';
import { SUBSCRIPTIONS } from '../../../../utils/data';
import { BsExclamationCircle } from 'react-icons/bs';
import { useSelector } from 'react-redux';

function Notes({ student }) {
  const educator = useSelector((store) => store.educatorUser.data);
  const [currentSubscription, setCurrentSubscription] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [retrieve, setRetrieve] = useState(false);
  const notes = AllNotes();

  useEffect(() => {
    if (Object.keys(notes || {}).length > 0) {
      notes?.refetch();
    }
  }, [retrieve]);

  useEffect(() => {
    if (educator && Object.keys(educator).length) {
      const sub = educator?.subscription || 'free';
      setCurrentSubscription(SUBSCRIPTIONS[sub]);
    }
  }, [educator]);

  const notesData = notes?.data?.filter((elt) => elt?.studentID === student.id);

  return (
    <>
      <CreateNoteModal
        modalShow={showModal}
        setModalShow={setShowModal}
        setRetreive={setRetrieve}
        retreive={retrieve}
        student={student}
      />
      <div className="mt-2">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <div className="tab-heading mr-1">Notes ({notesData?.length})</div>
            <BsExclamationCircle size={17} />
          </div>

          <button
            className="btn btn-new-note"
            disabled={notesData?.length >= currentSubscription.ALLOWED_NOTES_LIMIT}
            onClick={() => setShowModal(true)}>
            New Note <i className="fas fa-pencil-alt ml-1"></i>
          </button>
        </div>
        {notesData?.map((elt, index) => (
          <React.Fragment key={index}>
            <div
              className="mb-3 border-bottom pt-2 px-3"
              key={index}
              style={{
                borderRadius: 5,
                backgroundColor: `#${elt?.color}`
              }}>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="note-title">{elt?.title}</h6>
                <div className="pr-0 justify-end text-right">
                  <MenuNotes data={elt} retreive={retrieve} setRetreive={setRetrieve} />
                </div>
              </div>
              <div className="pb-3">
                <h6 className="note-description">{elt?.description}</h6>
                <h6 className="note-datetime mb-2">{formatDate(elt?.createdAt)}</h6>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
}

export default Notes;
