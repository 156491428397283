export const MAXCHARACTERLENGTH = {
  name: 50,
  title: 70,
  description: 200,
  automatic_message: 200,
  fname: 30,
  lname: 50,
  job_title: 50,
  role: 50,
  contact_phone: 20,
  phone: 10,
  password: 200,
  personality: 750,
  email: 255
};

export const SUBSCRIPTIONS = {
  free: {
    ALLOWED_NOTES_LIMIT: 3,
    ALLOWED_GROUPS_LIMIT: 1,
    PRICE: 0
  },
  growth: {
    ALLOWED_NOTES_LIMIT: 10,
    ALLOWED_GROUPS_LIMIT: 4,
    PRICE: 70
  }
};
