import { gql } from '@apollo/client';

export const CREATE_UPGRADE_REQUEST = gql`
  mutation CreateUpgradeRequestMutation(
    $organizationID: ID!
    $roleID: ID!
    $cost: Float
    $plan: String
    $name: String
    $roleType: String
    $status: Boolean!
    $approval: String!
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    createSubscriptionUpgradeRequest(
      input: {
        organizationID: $organizationID
        roleID: $roleID
        roleType: $roleType
        status: $status
        cost: $cost
        plan: $plan
        name: $name
        approval: $approval
        createdAt: $createdAt
        updatedAt: $updatedAt
      }
    ) {
      id
      organizationID
      roleID
      roleType
      status
      approval
      cost
      plan
      name
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_UPGRADE_REQUEST = gql`
  mutation UpdateUpgradeRequestMutation(
    $id: ID!
    $organizationID: ID
    $cost: Float
    $plan: String
    $name: String
    $roleID: ID
    $roleType: String
    $status: Boolean
    $approval: String
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    updateSubscriptionUpgradeRequest(
      input: {
        id: $id
        organizationID: $organizationID
        roleID: $roleID
        roleType: $roleType
        status: $status
        cost: $cost
        plan: $plan
        name: $name
        approval: $approval
        createdAt: $createdAt
        updatedAt: $updatedAt
      }
    ) {
      id
      organizationID
      roleID
      roleType
      cost
      plan
      name
      status
      approval
      createdAt
      updatedAt
    }
  }
`;
