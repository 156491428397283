import React, { useState } from 'react';
import Sidebar from '../Sidenav/index';
import Opportunities from './Components/Opportunities/Opportunities';

function Allopportunity({ route }) {
  const [current, setCurrent] = useState(0);

  const currentHandler = (selected) => {
    setCurrent(selected);
  };
  return (
    <div className="col-sm-12 p-0" style={{ overflowX: 'hidden' }}>
      <Sidebar
        component={<Opportunities sectHandler={currentHandler} route={route} />}
        current={current}
      />
    </div>
  );
}

export default Allopportunity;
