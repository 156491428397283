import VisaLogo from '../../../../Assets/icon/visa.svg';
import AmexLogo from '../../../../Assets/icon/amex.svg';
import MasterCardLogo from '../../../../Assets/icon/mastercard.svg';
import DiscoverLogo from '../../../../Assets/icon/discover.svg';
import { ClipLoader } from 'react-spinners';

export const getCardLogo = (card) => {
  const cardLogos = {
    visa: VisaLogo,
    amex: AmexLogo,
    discover: DiscoverLogo,
    mastercard: MasterCardLogo
  };
  if (Object.keys(cardLogos).includes(card)) {
    return (
      <img src={cardLogos[card]} alt="card" width={36} height={32} className="rounded rounded-1" />
    );
  }
  return (
    <i
      className="fab fa-cc-visa payment-card-logo"
      style={{ fontSize: '190%', color: '#10408C' }}></i>
  );
};

export const progress = (loading = false, color = '#fff') => {
  return <ClipLoader size={10} color={loading ? color : ''} />;
};

export const getPDFTemplate = async (context) => {
  const {
    firstName,
    lastName,
    institution,
    email,
    vendorName,
    vendorEmail,
    item,
    quantity,
    amount
  } = context;
  return `
    <div class="main-container" style="padding: 0;
      margin: 0;
      width: 600px;
      height: 100vh;
      overflow-x: hidden;
      background-color: rgba(247, 249, 251, 1);
      display: flex;
      align-items: center;
      justify-content: center;">
    <div class="content" style="background: white;
      width: 500px;
      padding: 15px;
      border-radius: 10px;">
      <div class="img-container" style="display: flex;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 15px;">
        <img border="0" src="https://i.ibb.co/4SrsVr0/logo-tp.png" alt="Logo" />
      </div>
      <div class="title-container" style="display: flex;
      justify-content: space-between;
      align-items: center;">
        <div class="title" style="font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #2E394C;">Purchase Order</div>
        <div class="date" style="font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #969CA5;">
          ${new Date().toDateString()}
        </div>
      </div>
      <div class="main-body">
        <h5 class="header" style="font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #969CA5;
      margin-top: 15px;
      margin-bottom: 15px;">Please fill this form and submit to purchase credits</h5>
        <label style="font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #262F3F;">Requisitioner’s Information</label>
        <div class="row" style="display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-top: 10px;
      flex-wrap: wrap;">
          <div class="col" style="display: flex;
      flex-direction: column;
      flex-grow: 1;">
            <label style="font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #262F3F;">First Name</label>
            <input type="text" value="${firstName}" readonly style="margin-top: 5px;
      padding: 7px;
      background: rgba(244, 245, 247, 0.4);
      border: 1px solid #CFD9E8;
      border-radius: 6px;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #969CA5;" />
          </div>
          <div class="col" style="display: flex;
      flex-direction: column;
      flex-grow: 1;">
            <label style="font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #262F3F;">Last Name</label>
            <input type="text" value="${lastName}" readonly style="margin-top: 5px;
      padding: 7px;
      background: rgba(244, 245, 247, 0.4);
      border: 1px solid #CFD9E8;
      border-radius: 6px;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #969CA5;" />
          </div>
        </div>
        <div class="row" style="display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-top: 10px;
      flex-wrap: wrap;">
          <div class="col" style="display: flex;
      flex-direction: column;
      flex-grow: 1;">
            <label style="font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #262F3F;">Institution</label>
            <input type="text" value="${institution}" readonly style="margin-top: 5px;
      padding: 7px;
      background: rgba(244, 245, 247, 0.4);
      border: 1px solid #CFD9E8;
      border-radius: 6px;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #969CA5;" />
          </div>
          <div class="col" style="display: flex;
      flex-direction: column;
      flex-grow: 1;">
            <label style="font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #262F3F;">Work Email</label>
            <input type="text" value="${email}" readonly style="margin-top: 5px;
      padding: 7px;
      background: rgba(244, 245, 247, 0.4);
      border: 1px solid #CFD9E8;
      border-radius: 6px;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #969CA5;" />
          </div>
        </div>
        <div class="header" style="font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #969CA5;
      margin-top: 15px;
      margin-bottom: 15px;"></div>
        <label style="font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #262F3F;">Vendor's Information</label>
        <div class="row" style="display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-top: 10px;
      flex-wrap: wrap;">
          <div class="col" style="display: flex;
      flex-direction: column;
      flex-grow: 1;">
            <label style="font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #262F3F;">Name</label>
            <input type="text" value="${vendorName}" readonly style="margin-top: 5px;
      padding: 7px;
      background: rgba(244, 245, 247, 0.4);
      border: 1px solid #CFD9E8;
      border-radius: 6px;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #969CA5;" />
          </div>
          <div class="col" style="display: flex;
      flex-direction: column;
      flex-grow: 1;">
            <label style="font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #262F3F;">Email Address</label>
            <input type="text" value="${vendorEmail}" readonly style="margin-top: 5px;
      padding: 7px;
      background: rgba(244, 245, 247, 0.4);
      border: 1px solid #CFD9E8;
      border-radius: 6px;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #969CA5;" />
          </div>
        </div>
        <div class="header" style="font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #969CA5;
      margin-top: 15px;
      margin-bottom: 15px;"></div>
        <label style="font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #262F3F;">Item Information</label>
        <table style="border-collapse: collapse;
      width: 100%;
      margin-top: 7px; margin-bottom: 15px">
          <tr style="border-bottom: 1px solid rgba(207, 217, 232, 0.4);">
            <th style="font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #969CA5;
      text-align: left;
      padding: 5px;">Item</th>
            <th style="font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #969CA5;
      text-align: left;
      padding: 5px;">Quantity</th>
            <th style="font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #969CA5;
      text-align: left;
      padding: 5px;">Cost</th>
          </tr>
          <tr style="border-bottom: 1px solid rgba(207, 217, 232, 0.4);">
            <td style="padding: 5px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #2E394C;">${item}</td>
            <td style="padding: 5px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #2E394C;">${quantity}</td>
            <td style="padding: 5px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #2E394C;">&dollar;${amount}</td>
          </tr>
        </table>
        <div style="text-align: center; padding-top: 10px; color: grey; font-size: 12px; font-weight: 700; line-height: 18px;">
          By filling out and submitting this form,<br/> you accept Junity's 
        <a href="https://joinjunity.com" style="text-align: center; text-decoration: none; color: black; font-size: 12px;" target="_blank" rel="noreferrer">Terms &amp; Condition</a> and <a href="https://www.joinjunity.com/privacy-policy" style="text-align: center; text-decoration: none; color: black; font-size: 12px;" target="_blank" rel="noreferrer">Privacy Policy</a>.
        </div>
      </div>
    </div>
  `;
};
