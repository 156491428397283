import React, { useEffect, useState } from 'react';
import { BsStar } from 'react-icons/bs';
import { AllMyGroups } from '../../../../Services/query/myGroups';
import './Mygroups.css';

const Mygroups = ({ user }) => {
  const [filterMyGroups, setFilterMyGroups] = useState([]);

  useEffect(() => {
    AllMyGroups().then((groups) => {
      setFilterMyGroups(groups?.filter((elt) => elt?.roleID === user?.id))
    });
  }, []);

  return (
    <div className="col-sm-12 p-0 height_contain">
      {filterMyGroups?.map((elt, index) => (
        <div className="col-sm-12 justify-start mb-2 p-3 border-around" key={index}>
          <BsStar className="mr-2 align-items" fill="#969CA5" size={24} />
          <span
            style={{
              color: '#2E394C',
              fontSize: 16
            }}>
            {elt?.name}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Mygroups;
