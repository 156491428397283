import React, { useEffect, useLayoutEffect, useState } from 'react';
import { State } from 'country-state-city';
import { AllOpportunitiesAPI } from '../../../../Services/query/opportunities';
import { MapPin, Calendar, Briefcase, Search } from 'react-feather';
import CalendarOutline from '../../../../Assets/images/calendar-outline.svg';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './FilterComponents.css';

export const titleWords = (sentence) => {
  if (sentence) {
    const words = sentence?.split(' ');
    const newWords = [];
    words?.forEach((word) => {
      newWords.push(word);
    });
    return newWords.join(' ');
  }

  return '';
};

const stopProp = (e) => {
  e.stopPropagation();
};

const setDate = (dateString) => {
  let date = new Date();
  let dateSplit = date.toDateString().split(' ');

  if (dateString === 'today') {
    return dateSplit.splice(1, 2).join(' ');
  } else if (dateString === 'week') {
    let first = date.getDate() - date.getDay();
    let last = first + 6;

    let firstDay = new Date(date.setDate(first)).getDate();
    let lastDay = new Date(date.setDate(last)).getDate();

    return `${dateSplit[1]} ${firstDay}-${lastDay}`;
  } else if (dateString === 'month') {
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDate();
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

    let month = dateSplit[1];
    return `${month} ${firstDay}-${lastDay}`;
  } else if (dateString === 'nextmonth') {
    date.setMonth(date.getMonth() === 11 ? 0 : date.getMonth() + 1);
    dateSplit = date.toDateString().split(' ');

    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDate();
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

    let month = dateSplit[1];
    return `${month} ${firstDay}-${lastDay}`;
  }
};

const showFilterItem = (e) => {
  let _filter = e.currentTarget.getAttribute('data-filter');
  let items = document.getElementsByClassName(`${_filter}-filter`);
  if (items.length) {
    let filterElem = items[0];
    filterElem.classList.remove('d-none');
    filterElem.style.bottom = 0;
    filterElem.style.left = 0;
  }
};

const closeFilterItem = (filterItem) => {
  let items = document.getElementsByClassName(filterItem);
  if (items.length) {
    let filterElem = items[0];
    filterElem.classList.add('d-none');
  }
};

const CheckItem = ({ label, isSelected = false, onSelect = () => {} }) => {
  const [selected, setSelected] = useState(isSelected);

  const toggleSelected = (event) => {
    setSelected((prevState) => !prevState);
    if (!selected) {
      onSelect((prevState) => [...prevState, event.target.value]);
    } else {
      onSelect((prevState) => prevState.filter((f) => f !== event.target.value));
    }
  };

  return (
    <>
      <div className="d-flex align-items-center gap-2 mt-3">
        <input
          type="checkbox"
          className="form-check-input m-0"
          value={label}
          id={label}
          onClick={(e) => {
            toggleSelected(e);
          }}
        />
        <label className="form-check-label">{label}</label>
      </div>
    </>
  );
};

const RadioItem = ({ label, text = '', isSelected = false, onSelect = () => {} }) => {
  const handleSelect = () => {
    let textSplit = label.toLowerCase().split(' ');
    onSelect(textSplit.length < 2 ? textSplit[0] : textSplit[1]);
  };

  return (
    <>
      <div
        className={`radio-item ${
          isSelected ? 'selected' : ''
        } d-flex justify-content-between align-items-center`}
        onClick={handleSelect}>
        <div className="d-flex align-items-start">
          <div className="d-flex flex-column">
            <span className="font-weight-bold label">{label}</span>
            {text ? <span className="small text">{text}</span> : null}
          </div>
        </div>
      </div>
    </>
  );
};

export const SearchByLocationComponent = () => {
  const [locationSearch, setLocationSearch] = useState('');
  const [locations, setLocations] = useState([]);
  const [searchedLocations, setSearchedLocations] = useState([]);

  const handleLocationSelect = (e, code) => {
    if (locations.includes(code)) {
      const allSelectedLocations = [...locations];
      setLocations(allSelectedLocations.filter((elt) => elt !== code));
    } else {
      setLocations([...locations, code]);
    }
  };

  const filterByLocations = (reset = false) => {
    // if (reset) {
    //   removeOverlay("locations", locations, reset);
    // } else {
    //   removeOverlay("locations", locations);
    // }
    closeFilterItem('location-filter');
  };

  return (
    <>
      <div className="filter-item location-filter bg-white p-2 position-absolute">
        <div className="input-group px-0">
          <span className="input-group-text">
            <Search width={20} height={20} style={{ stroke: '#969CA5' }} />
          </span>
          <input
            type="text"
            name="locationSearch"
            placeholder="Search..."
            className="form-control pl-0"
            onChange={(e) => setLocationSearch(e.target.value)}
          />
        </div>
        <div className="listing locations p-1">
          <CheckItem label={'All Locations'} onSelect={(e) => handleLocationSelect(e, 0)} />
          {searchedLocations.map((location) => (
            <CheckItem
              label={location.name}
              onSelect={(e) => handleLocationSelect(e, location.code)}
              key={location.code}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export const SearchByOrganizationComponent = ({ opportunities = [] }) => {
  const [orgSearch, setOrgSearch] = useState('');
  const [searchedResources, setSearchedResources] = useState([]);

  const [organizations, setOrganizations] = useState([]);

  const handleOrganizationChange = (e, id) => {
    if (organizations.includes(id)) {
      const allSelectedOrganizations = [...organizations];
      setOrganizations(allSelectedOrganizations.filter((elt) => elt !== id));
    } else {
      setOrganizations([...organizations, id]);
    }
  };

  return (
    <>
      <div className="filter-item organization-filter bg-white p-2 position-absolute">
        <div className="input-group px-0">
          <span className="input-group-text">
            <Search width={20} height={20} style={{ stroke: '#969CA5' }} />
          </span>
          <input
            type="text"
            name="locationSearch"
            placeholder="Search..."
            className="form-control search-input bg-white ps-0"
            onChange={(e) => setOrgSearch(e.target.value)}
          />
        </div>
        <div className="listing locations p-1">
          <CheckItem label={'All Organizations'} onSelect={(e) => handleOrganizationChange(e, 0)} />
          {(searchedResources.length > 0 ? searchedResources : opportunities)?.map((elt, index) => (
            <CheckItem
              label={titleWords(elt.resource.name)}
              onSelect={(e) => handleOrganizationChange(e, elt.resourceID)}
              key={elt.resourceID}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export const SearchByDateComponent = ({ dueDate, setDueDate, setDateType }) => {
  return (
    <>
      <div className="filter-item organization-filter bg-white p-2 position-absolute">
        <div className="listing locations p-1">
          <RadioItem
            label="This Week"
            text={setDate('week')}
            onSelect={setDueDate}
            isSelected={dueDate === 'week'}
          />
          <RadioItem
            label="This Month"
            text={setDate('month')}
            onSelect={setDueDate}
            isSelected={dueDate === 'month'}
          />
          <RadioItem
            label="Next Month"
            text={setDate('nextmonth')}
            onSelect={() => setDueDate('nextmonth')}
            isSelected={dueDate === 'nextmonth'}
          />
          <div
            className="radio-item d-flex justify-content-between align-items-center"
            data-toggle="dropdown"
            data-target="#customDateDropdown"
            data-bs-auto-close="false"
            onClick={setDateType}>
            <span className="label">Custom Date</span>
            <i className="fas fa-angle-right"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export const CustomDateComponent = ({ hide, handleCustomDateSet }) => {
  const [customDate, setCustomDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const handleDateSaving = () => {
    handleCustomDateSet(customDate[0]);
    hide();
  };

  return (
    <>
      <div>
        <DateRange
          ranges={customDate}
          showDateDisplay={false}
          rangeColors={['#38609F', '#fff', '#red']}
          moveRangeOnFirstSelection={false}
          onChange={(item) => setCustomDate([item.selection])}
          className="date-range-picker"
        />
        <div className="d-flex justify-content-between my-2 px-4">
          <button className="btn calendar-button" onClick={hide}>
            {'Cancel'}
          </button>
          <button
            className="btn calendar-button warning"
            data-toggle="dropdown"
            data-target="#customDateDropdown"
            data-bs-auto-close="false"
            onClick={handleDateSaving}>
            {'Select'}
          </button>
        </div>
      </div>
    </>
  );
};

const FilterComponents = ({ removeOverlay, shown, position }) => {
  const [dueDate, setDueDate] = useState('');
  const [customDate, setCustomDate] = useState([
    { startDate: new Date(), endDate: new Date(), key: 'selection' }
  ]);
  const [locations, setLocations] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [generatedLocations, setGeneratedLocations] = useState([]);
  const [orgSearch, setOrgSearch] = useState('');
  const [locationSearch, setLocationSearch] = useState('');
  const [searchedResources, setSearchedResources] = useState([]);
  const [searchedLocations, setSearchedLocations] = useState([]);

  const handleLocationSelect = (e, code) => {
    if (locations.includes(code)) {
      const allSelectedLocations = [...locations];
      setLocations(allSelectedLocations.filter((elt) => elt !== code));
    } else {
      setLocations([...locations, code]);
    }
  };

  const handleOrganizationChange = (e, id) => {
    if (organizations.includes(id)) {
      const allSelectedOrganizations = [...organizations];
      setOrganizations(allSelectedOrganizations.filter((elt) => elt !== id));
    } else {
      setOrganizations([...organizations, id]);
    }
  };

  const filterByDueDate = (reset = false) => {
    if (reset) {
      removeOverlay('dueDate', dueDate, reset);
    } else {
      removeOverlay('dueDate', dueDate);
    }
    closeFilterItem('calendar-filter');
  };

  const filterByOrganizations = (reset = false) => {
    if (reset) {
      removeOverlay('organizations', organizations, reset);
    } else {
      removeOverlay('organizations', organizations);
    }
    closeFilterItem('organization-filter');
  };

  const selectCustomDate = (reset = false) => {
    removeOverlay('dueDate', {
      firstDay: customDate[0].startDate,
      lastDay: customDate[0].endDate
    });
    closeFilterItem('custom-date-filter');
  };

  useLayoutEffect(() => {
    const handleDependencies = async () => {
      const opps = await AllOpportunitiesAPI();
      setOpportunities(opps);

      const usStates = State.getStatesOfCountry('US');
      const modifiedStates = [];
      modifiedStates.push({ name: 'All', code: 'All' });
      usStates.forEach((elt) => {
        modifiedStates.push({ name: elt.name, code: elt.isoCode });
      });
      setGeneratedLocations(modifiedStates);
      setSearchedLocations(modifiedStates);
    };

    handleDependencies();
  }, []);

  useEffect(() => {
    const mainContainer = document.getElementsByClassName('listing locations')[0];
    const checkBox = mainContainer.getElementsByClassName('radio-item not-activated');
    if (locations.length === 5) {
      for (const element of checkBox) {
        element.style.pointerEvents = 'none';
      }
    } else {
      for (const element of checkBox) {
        element.style.pointerEvents = 'auto';
      }
    }
  }, [locations]);

  useEffect(() => {
    if (orgSearch.trim() !== '') {
      const re = new RegExp(orgSearch.toLowerCase(), 'g');
      const matches = opportunities.filter(
        (elt) => elt.resource.name.toLowerCase().match(re) !== null
      );

      setSearchedResources(matches);
    } else {
      setSearchedResources(opportunities);
    }
  }, [orgSearch]);

  useEffect(() => {
    if (locationSearch.trim() !== '') {
      const re = new RegExp(locationSearch.toLowerCase(), 'g');
      const matches = [];
      generatedLocations.forEach((elt) => {
        if (elt.name.toLowerCase().match(re) !== null) matches.push(elt);
      });
      setSearchedLocations(matches);
    } else {
      setSearchedLocations(generatedLocations);
    }
  }, [locationSearch]);

  return (
    <>
      <div className={`overlay_background ${shown}`} onClick={(e) => removeOverlay()}>
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="my-account">
              <div className="title">Filter</div>
              <div className="des">Select a category to apply filter</div>
              <div className="list">
                <div className="item" data-filter="location" onClick={(e) => showFilterItem(e)}>
                  <MapPin />
                  <span>Location</span>
                </div>
                <div className="item" data-filter="calendar" onClick={(e) => showFilterItem(e)}>
                  <Calendar />
                  <span>Due Date </span>
                </div>
                <div className="item" data-filter="organization" onClick={(e) => showFilterItem(e)}>
                  <Briefcase />
                  <span>Organization</span>
                </div>
              </div>
            </div>

            <div className="filter-item calendar-filter bg-white p-3 position-absolute w-100 d-none">
              <div className="head d-flex justify-content-between">
                <div className="back" onClick={(e) => closeFilterItem('calendar-filter')}>
                  {'<'}
                </div>
                <div className="action d-flex p-0">
                  <button className="reset p-0" onClick={() => filterByDueDate(true)}>
                    Reset
                  </button>
                  <button className="done" onClick={() => filterByDueDate()}>
                    Done
                  </button>
                </div>
              </div>
              <div className="title">Due Date</div>
              <div className="listing">
                <RadioItem label="All" hasIcon={false} />
                <RadioItem
                  label="Today"
                  text={setDate('today')}
                  hasIcon={true}
                  onSelect={setDueDate}
                  isSelected={dueDate === 'today'}
                />
                <RadioItem
                  label="This Week"
                  text={setDate('week')}
                  hasIcon={true}
                  onSelect={setDueDate}
                  isSelected={dueDate === 'week'}
                />
                <RadioItem
                  label="This Month"
                  text={setDate('month')}
                  hasIcon={true}
                  onSelect={setDueDate}
                  isSelected={dueDate === 'month'}
                />

                <div
                  className="radio-item d-flex justify-content-between align-items-center"
                  data-filter="custom-date"
                  onClick={(e) => showFilterItem(e)}>
                  <label>
                    <img src={CalendarOutline} alt="calendar-alt" />
                    Custom Date
                  </label>
                </div>
              </div>
            </div>

            <div className="filter-item custom-date-filter bg-white p-3 position-absolute w-100 d-none">
              <div className="head d-flex justify-content-between">
                <div className="back" onClick={(e) => closeFilterItem('organization-filter')}>
                  {'<'}
                </div>
                <div className="action d-flex p-0">
                  <button className="reset p-0" onClick={() => selectCustomDate(true)}>
                    Reset
                  </button>
                  <button className="done" onClick={() => selectCustomDate()}>
                    Done
                  </button>
                </div>
              </div>
              <div className="title">Custom Date</div>
              <div className="mt-3 d-flex justify-content-center">
                <DateRange
                  ranges={customDate}
                  showDateDisplay={false}
                  rangeColors={['#38609F', '#fff', '#red']}
                  moveRangeOnFirstSelection={false}
                  onChange={(item) => setCustomDate([item.selection])}
                  className="date-range-picker"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterComponents;
