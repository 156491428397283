import React, { useEffect, useState } from 'react';
import { AllStudents } from '../../../../Services/query/students';
import Youthchat from '../Youthchat/Youthchat';
import { useSelector } from 'react-redux';
import JunityInfluencerModal from './Modal/junityInfluencerModal';
import './Youth.css';
import { searchData } from '../../../../utils/helpers';
import {
  filterByAgesAndInterests,
  filterYouthsByAges,
  filterYouthsByInterests
} from '../../../../utils/Youth/utils';
import { useLocation } from 'react-router-dom';

function Youthmain() {
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const user = useSelector((store) => store.educatorUser.data);
  const [sameSchool, setSameSchool] = useState([]);
  const [youths, setYouths] = useState([]);
  const [filters, setFilters] = useState({ ages: [], interests: [] });
  const [filtersCount, setFiltersCount] = useState(0);
  const search = new URLSearchParams(useLocation().search);
  const paramInterest = search.get('interest');

  const onSearch = (value) => {
    if (value) {
      const filtered = searchData(sameSchool, value);
      setYouths(filtered);
    } else {
      setYouths(sameSchool);
    }
  };

  const onFilter = () => {
    let filtered = [];

    if (filters?.ages?.length && !filters?.interests?.length)
      filtered = filterYouthsByAges(sameSchool, filters?.ages);
    else if (filters?.interests?.length && !filters?.ages?.length)
      filtered = filterYouthsByInterests(sameSchool, filters?.interests);
    else if (filters?.interests?.length && filters?.ages?.length)
      filtered = filterByAgesAndInterests(sameSchool, filters?.ages, filters?.interests);
    else filtered = sameSchool;

    // const data1 = interestsFilter.filter(item => agesFilter.includes(item))
    // const data2 = agesFilter.filter(item => interestsFilter.includes(item))
    setYouths(filtered);
  };

  const clearFilters = () => {
    setFilters({ ages: [], interests: [] });
    setYouths(sameSchool);
  };

  useEffect(() => {
    AllStudents().then((students) => {
      const sameSchool = students?.filter((elt) => user?.school?.connections?.includes(elt.id));
      setSameSchool(sameSchool);
      setShow(sameSchool?.length === 0);
      setYouths(sameSchool);

      if (paramInterest) {
        setFilters({ ages: [], interests: [paramInterest] });
        setYouths(filterYouthsByInterests(sameSchool, [paramInterest]));
      }
    });
  }, []);

  useEffect(() => {
    setFiltersCount(filters?.ages?.length + filters?.interests?.length);
  }, [filters]);

  return (
    <>
      <div className="col-sm-12 background_color youth pl-0 pr-0">
        <div className="col-sm-12 pt-3 pb-2 background_color_opportunities mb-4">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ flexWrap: 'wrap' }}>
            <div>
              <h4 className="page-name" onClick={() => setShow(!show) || setHide(!hide)}>
                <b>Youth</b>
              </h4>
              <p className="page-subheading">Showing {youths?.length} youth</p>
            </div>
            <div className="influencer-banner" onClick={() => setModalShow(true)}>
              <i className="fas fa-check-circle fa-fw"></i>
              <span>Learn about the Junity Influencer Program</span>
            </div>
          </div>
        </div>

        {show ? (
          <div className="col-sm-12 pb-3">
            <div className="row">
              <div className="col-sm-12 div1_height">
                <div className="col-sm-12 backcolor text-center div2_height d-flex align-items-center justify-content-center">
                  <div>
                    <img src="./images/card1.png" className="img-fluid img_size_set_youth" alt="" />
                    <h6 className="font_home1">NO YOUTH ADDED YET</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-sm-12" style={{ height: '88%' }}>
            <Youthchat
              studentsData={youths}
              onSearch={onSearch}
              filters={filters}
              setFilters={setFilters}
              onFilter={onFilter}
              filtersCount={filtersCount}
              clearFilters={clearFilters}
            />
          </div>
        )}
      </div>
      <JunityInfluencerModal modalShow={modalShow} setModalShow={setModalShow} />
    </>
  );
}

export default Youthmain;
