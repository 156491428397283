import React, { useState, useEffect } from 'react';
import { formatDate } from '../../../../utils/MyGroups/utils';
import { AllRecommendedOpportunities } from '../../../../Services/query/recommendation';

const Recomendgrad = ({ groupID }) => {
  const [groupRecommendations, setGroupRecommendations] = useState([]);

  useEffect(() => {
    AllRecommendedOpportunities().then((results) => {
      setGroupRecommendations(results?.filter((elt) => elt?.groupID === groupID));
    });
  }, []);

  return (
    <div className="col-sm-12 mt-3">
      <div className="row">
        <div className="col-sm-4 ">
          <div
            className="col-sm-12 text-center pt-3 pb-3"
            style={{
              backgroundColor: 'white',
              color: '#969CA5',
              borderRadius: '10px'
            }}>
            <h6 style={{ fontSize: '120%' }}>RECOMMENDATIONS</h6>
            <p>Last 30 days</p>
            <div className="col-sm-12 text-center ">
              <h6 style={{ fontSize: '120%' }}>TOP 4 RECOMMENDATIONS</h6>
            </div>
            {groupRecommendations?.slice(0, 3)?.map((elt, index) => (
              <div
                className="col-sm-12 pt-2 pb-2 mt-3"
                style={{ border: '1px solid lightgray', borderRadius: '10px' }}
                key={index}>
                <div className="row">
                  <div className="col-sm-2 pt-1">
                    <img src="../images/dicon.png" className="img-fluid" />
                  </div>
                  <div className="col-sm-10 pt-2 text-left">
                    <h6 style={{ fontSize: '110%' }}>{elt?.opportunity?.name}</h6>
                    <h6 style={{ fontSize: '90%', color: '#969CA5' }}>0 applications sumbitted</h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-sm-8 ">
          <div className="col-sm-12 " style={{ backgroundColor: 'white', borderRadius: '10px' }}>
            <div
              className="col-sm-12 text-left pt-3 pb-3"
              style={{
                backgroundColor: 'white',
                color: '#969CA5',
                borderRadius: '10px'
              }}>
              <h6 style={{ fontSize: '120%' }}>
                Showing {groupRecommendations?.length} recommendations
              </h6>
            </div>
            <div className="row">
              <div className="col-sm-5">
                <div
                  className="col-sm-12 text-left pt-3 pb-3"
                  style={{
                    backgroundColor: 'white',
                    color: '#969CA5',
                    borderRadius: '10px'
                  }}>
                  <h6 style={{ fontSize: '120%', color: '#969CA5' }}>Opportunity</h6>
                </div>
                <div className="col-sm-12 pb-2">
                  {groupRecommendations?.map((elt, index) => (
                    <div className="row mb-3" key={index}>
                      <div className="col-sm-2">
                        <img src="../images/dicon.png" className="img-fluid" />
                      </div>
                      <div className="col-sm-10 pt-0 pl-0 ">
                        <div style={{ fontSize: '120%' }}>
                          <b>{elt?.opportunity?.name}</b>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-sm-4">
                <div
                  className="col-sm-12 text-left pt-3 pb-3"
                  style={{
                    backgroundColor: 'white',
                    color: '#969CA5',
                    borderRadius: '10px'
                  }}>
                  <h6 style={{ fontSize: '120%', color: '#969CA5' }}>Date Recommended </h6>
                  {groupRecommendations?.map((elt, index) => (
                    <div className="col-sm-12 pl-0 pt-3" key={index}>
                      <h6 style={{ fontSize: '120%', color: '#969CA5' }}>
                        {formatDate(elt?.createdAt)}
                      </h6>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-sm-3">
                <div
                  className="col-sm-12 text-left pt-3 pb-3"
                  style={{
                    backgroundColor: 'white',
                    color: '#969CA5',
                    borderRadius: '10px'
                  }}>
                  <h6 style={{ fontSize: '120%', color: '#969CA5' }}>EXPIRY DATE</h6>
                  {groupRecommendations?.map((elt, index) => (
                    <div className="col-sm-12 pt-3 pl-0" key={index}>
                      <h6 style={{ fontSize: '120%', color: '#969CA5' }}>
                        {formatDate(elt?.opportunity?.expiry_date)}
                      </h6>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recomendgrad;
