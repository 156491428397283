import { SET_GROUP_DATA, RESET_GROUP_DATA } from '../constants/groupConstants';

export const setGroupDataAction = (data) => async (dispatch) => {
  dispatch({
    type: SET_GROUP_DATA,
    payload: data
  });
};

export const resetGroupDataAction = () => async (dispatch) => {
  dispatch({
    type: RESET_GROUP_DATA
  });
};
