import { SET_SENDBIRD_APP } from '../constants/showAppConstants';

const initialAppState = {
  data: 0
};

const sendbirdReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case SET_SENDBIRD_APP:
      return { data: action.payload };
    default:
      return state;
  }
};

export default sendbirdReducer;
