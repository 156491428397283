import { isMobilePhone } from 'validator';
import { tasksClient } from '../libs/axiosClient';
import { City } from 'country-state-city';

export const calculateTime = (date) => {
  if (date) {
    const now = new Date();
    const previous = new Date(date);
    if (now.getFullYear() === previous.getFullYear()) {
      if (now.getMonth() === previous.getMonth()) {
        const res = now.getDate() - previous.getDate();
        let message = '';

        if (res === 0) message = 'Today';
        else if (res === 1) message = 'Yesterday';
        else message = 'days ago';
        return `${res > 1 ? res : ''} ${message}`;
      } else {
        const res = now.getMonth() - previous.getMonth();
        return `${res} ${res === 1 ? 'month' : 'months'} ago`;
      }
    } else {
      const monthInterval = 12 - previous.getMonth() + now.getMonth();
      if (monthInterval < 12) {
        return `${monthInterval} ${monthInterval === 1 ? 'month' : 'months'} ago`;
      }
      const res = now.getFullYear() - previous.getFullYear();
      return `${res} ${res === 1 ? 'year' : 'years'} ago`;
    }
  }
};

export const titleCase = (name) => {
  if (name) {
    name = name.toLowerCase();
    name = name.split(' ');
    for (let i = 0; i < name.length; i++) {
      name[i] = name[i].charAt(0).toUpperCase() + name[i].slice(1);
    }
    return name.join(' ');
  }
  return '';
};

export const generatePassword = (length) => {
  let password = '';
  const uAlpha = Array.from(Array(26)).map((e, i) => i + 65);
  const uAlphabet = uAlpha.map((x) => String.fromCharCode(x));
  const lAlpha = Array.from(Array(26)).map((e, i) => i + 97);
  const lAlphabet = lAlpha.map((x) => String.fromCharCode(x));
  const symbols = ['$', '!', '^', '*', '&', '>', '?', '{', '}', '@', '%'];
  const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  const elts = {
    1: uAlphabet,
    2: lAlphabet,
    3: symbols,
    4: numbers
  };

  for (let i = 0; i < length; i++) {
    const eltRandInt = Math.floor(Math.random() * 4) + 1;
    const randInt = Math.floor(Math.random() * elts[eltRandInt].length);
    password += elts[eltRandInt][randInt];
  }
  return password;
};

export const formatDate = (date) => {
  if (date) {
    const newDate = new Date(date);
    const monthStr = `${newDate}`.split(' ')[1];
    return monthStr + ' ' + newDate.getDate() + ', ' + newDate.getFullYear();
  }

  return '';
};

export const getInitial = (name) => {
  if (name) {
    let initials = '';
    const splittedName = name.split(' ');
    splittedName.forEach((elt) => {
      if (elt.length) initials += elt[0].toUpperCase();
    });
    return initials;
  }
  return '';
};

export const sameStateOppCheck = (states, userState) => {
  return states?.some((state) => state?.split(',')[1]?.trim() === userState?.trim());
};

export const sameStateOppMultiplePlaces = (states, places) => {
  return places?.some((place) => {
    return states.includes(place);
  });
};

export function ValidateEmail(input) {
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return input.match(validRegex);
}

export const removeCommas = (str = '') => {
  if (str) {
    if (str.startsWith(',') && str.endsWith(',')) {
      return str.slice(1, -1);
    }

    if (str.startsWith(',')) {
      return str.slice(1);
    }

    if (str.endsWith(',')) {
      return str.slice(0, -1);
    }
  }

  return str;
};

export const matchDomain = (schoolMail, email) => {
  const schoolAtSignIndex = schoolMail.indexOf('@') + 1;
  const schoolDomain = String(schoolMail).slice(schoolAtSignIndex);

  const mailAtSignIndex = email.indexOf('@') + 1;
  const emailDomain = String(email).slice(mailAtSignIndex);

  return schoolDomain === emailDomain;
};

export const isLinkSecured = (link) => {
  let url;
  try {
    url = new URL(link);
  } catch (_) {
    return false;
  }
  return url.protocol === 'https:';
};

export const getSpecificStringDate = (time) => {
  const currentDate = new Date();
  if (time === 'today') {
    return {
      firstDay: currentDate,
      lastDay: currentDate
    };
  } else if (time === 'week') {
    let first = currentDate.getDate() - currentDate.getDay();
    let last = first + 6;

    let firstDay = new Date(currentDate.setDate(first));
    let lastDay = new Date(currentDate.setDate(last));

    return {
      firstDay,
      lastDay
    };
  } else if (time === 'month') {
    let firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    let lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    return {
      firstDay,
      lastDay
    };
  }
};

export const calculateExpiryTime = (date) => {
  const now = new Date();
  const expiry = new Date(date);

  if (expiry >= now) {
    const diffInMilliseconds = expiry - now;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInDays === 0) {
      return 'Today';
    } else if (diffInDays === 1) {
      return '1 day left';
    } else if (diffInDays < 30) {
      return `${diffInDays} days left`;
    } else if (diffInDays < 365) {
      const diffInMonths = Math.floor(diffInDays / 30);
      return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} left`;
    } else {
      const diffInYears = Math.floor(diffInDays / 365);
      return `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} left`;
    }
  } else {
    return 'Expired';
  }
};

export const getRandomColorById = (id) => {
  if (id) {
    // Generate hash from the ID
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
      hash = id.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convert hash to a hex color
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).substr(-2);
    }

    return color;
  }

  return '#000000';
};

export const getState = (schoolAddress) => {
  if (schoolAddress) {
    const schoolAddressObj = JSON.parse(schoolAddress);
    const locality = schoolAddressObj.address_components.find((elt) => elt.types[0] === 'locality');
    const state = schoolAddressObj.address_components.find(
      (elt) => elt.types[0] === 'administrative_area_level_1'
    );
    return locality.long_name + ', ' + state.long_name;
  }

  return '';
};

export const cleanMessageDate = (timestamp) => {
  const splittedDate = timestamp?.split(' ');
  if (splittedDate?.length > 1) {
    const secondSplit = splittedDate[1]?.split('+');
    return splittedDate[0] + 'T' + secondSplit[0] + 'Z';
  }

  return splittedDate[0];
};
export const formatMessageTime = (timestamp) => {
  if (timestamp) {
    const messageDate = new Date(cleanMessageDate(timestamp));
    const currentDate = new Date();

    // Check if the message was sent today
    if (
      messageDate.getDate() === currentDate.getDate() &&
      messageDate.getMonth() === currentDate.getMonth() &&
      messageDate.getFullYear() === currentDate.getFullYear()
    ) {
      const hours = messageDate.getHours();
      const minutes = messageDate.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    } else {
      // Check if the message was sent yesterday
      const yesterday = new Date();
      yesterday.setDate(currentDate.getDate() - 1);
      if (
        messageDate.getDate() === yesterday.getDate() &&
        messageDate.getMonth() === yesterday.getMonth() &&
        messageDate.getFullYear() === yesterday.getFullYear()
      ) {
        return 'Yesterday';
      } else {
        // Format the message date in a long format
        return messageDate.toLocaleDateString();
      }
    }
  }

  return timestamp;
};
export const capitalizeFirstLetter = (title) => {
  if (title) return title[0]?.toUpperCase() + title.slice(1)?.toLowerCase();
  else return null;
};

export const titleWords = (sentence) => {
  if (sentence) {
    const words = sentence?.split(' ');
    const newWords = [];
    words?.forEach((word) => {
      newWords.push(capitalizeFirstLetter(word));
    });
    return newWords.join(' ');
  }

  return '';
};

export const messageCreatedDate = (time) => {
  if (/^\d{1,2}:\d{2}\s(AM|PM)$/.test(time)) {
    return 'Today';
  } else {
    return time;
  }
};

export const pluralize = (arr, plural, singular) => {
  if (arr) {
    if (arr.length > 1) {
      return arr.length + ' ' + plural;
    } else {
      return arr.length + ' ' + singular;
    }
  }

  return '';
};

export const sortDataByField = (data, field) => {
  data.sort(function (a, b) {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  });

  return data;
};

export const getSchoolState = (address) => {
  if (address) {
    const addressObj = JSON.parse(address);
    const locality = addressObj.address_components.find((elt) => elt.types[0] === 'locality');
    const state = addressObj.address_components.find(
      (elt) => elt.types[0] === 'administrative_area_level_1'
    );
    return locality.long_name + ', ' + state.long_name;
  }

  return '';
};

export const searchData = (data, searchTerm) => {
  return data.filter((item) => {
    for (const key in item) {
      if (item[key] && item[key].toString().toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      }
    }
    return false;
  });
};

export const validatePhone = (phone) => {
  const codeWithPhone = '+1' + phone;
  return isMobilePhone(codeWithPhone, 'en-US', {
    strictMode: true
  });
};

const filterByDateRange = (opp, startDate, endDate, field) => {
  const oppDate = new Date(opp[field]);

  return oppDate >= startDate && oppDate <= endDate;
};

const filterArrayByDateRange = (array, startDate, endDate, field) => {
  return array.filter((opp) => filterByDateRange(opp, startDate, endDate, field));
};

export const filterItemsByDateStrings = (selectedOption, opps, field, startDate, endDate) => {
  const today = new Date();
  const startOfWeek = new Date(today);
  const endOfWeek = new Date(today);

  // Set the start of the week (Sunday)
  startOfWeek.setDate(today.getDate() - today.getDay());

  // Set the end of the week (Saturday)
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const endOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);

  let filteredOpps;

  switch (selectedOption) {
    case 'week':
      filteredOpps = filterArrayByDateRange(opps, startOfWeek, endOfWeek, field);
      break;

    case 'month':
      filteredOpps = filterArrayByDateRange(opps, startOfMonth, endOfMonth, field);
      break;

    case 'nextmonth':
      filteredOpps = filterArrayByDateRange(opps, endOfMonth, endOfNextMonth, field);
      break;

    case 'custom':
      filteredOpps = filterArrayByDateRange(opps, startDate, endDate, field);
      break;

    default:
      filteredOpps = opps; // No filtering for other cases
      break;
  }

  return filteredOpps;
};

export const timePassed = (fromDate) => {
  const givenDate = new Date(fromDate);
  const timeDiff = Date.now() - givenDate.getTime();

  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30.44);

  if (months > 0) {
    return months + 'mon';
  } else if (weeks > 0) {
    return weeks + 'w';
  } else if (days > 0) {
    return days + 'd';
  } else if (hours > 0) {
    return hours + 'h';
  } else {
    return minutes + 'm';
  }
};

export const daysToExpiration = (dateString) => {
  const expirationDate = new Date(dateString);
  const currentDate = new Date();

  const timeDifference = expirationDate.getTime() - currentDate.getTime();
  return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
};

export const daysPassed = (date) => {
  const now = new Date();
  const givenDate = new Date(date);

  const oneDayInMs = 1000 * 60 * 60 * 24;

  const timeDifference = now.getTime() - givenDate.getTime();

  return Math.round(timeDifference / oneDayInMs);
};

export const generateDeviceID = () => {
  const { userAgent, hardwareConcurrency, language } = navigator;
  const { width, height } = window.screen;
  return `${userAgent}-${hardwareConcurrency}-${language}-${width}-${height}`;
};

export const hoursPassedSince = (date) => {
  var currentDate = new Date();
  var timeDifference = currentDate - date;
  return timeDifference / (1000 * 60 * 60);
};

export const sendEmailNotification = async (email, template, subject, context) => {
  return await tasksClient.post('/email', {
    template,
    subject,
    email,
    context
  });
};

export const sendAppNotification = async (
  userId,
  text,
  title = 'success',
  link = '',
  action = '',
  module = 'mentor',
  type = 'inapp',
  icon = 'compass',
  read = false
) => {
  return await tasksClient.post('/notification', {
    userId,
    title,
    text,
    link,
    action,
    module,
    type,
    icon,
    read
  });
};

export const getObjectsDifference = (obj1, obj2) => {
  const result = {};

  // Check properties in obj1
  for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj1, key) && obj1[key] !== obj2[key]) {
      result[key] = obj2[key];
    }
  }

  return result;
};

export const matchStartsWith = (text, searchText) => {
  const splitText = text.split(' ');
  return splitText.some((elt) => elt.toLowerCase().startsWith(searchText.toLowerCase()) === true);
};

const sortDataByType = (data, type, order) => {
  const dataToSort = [...data];
  dataToSort.sort((a, b) => {
    const itemA = a[type];
    const itemB = b[type];
    if (itemA < itemB) {
      return order === 'reverse' ? 1 : -1;
    }
    if (itemA > itemB) {
      return order === 'reverse' ? -1 : 1;
    }
    return 0;
  });
  return dataToSort;
};

export const getCities = (filters) => {
  const { keys, requiresAll, returnObj, sort } = filters;
  const cities = process.env.REACT_APP_CITIES_LIST;
  let modifiedStates = cities.split(',')?.reduce((acc, city) => {
    if (returnObj) {
      const usStates = City.getCitiesOfState('US', city)?.map((elt) => {
        return { [keys[0]]: `${elt.name}, ${city}`, [keys[1]]: `${elt.name}, ${city}` };
      });
      return [...acc, ...usStates];
    } else {
      return [...acc, ...City.getCitiesOfState('US', city)];
    }
  }, []);

  if (sort) {
    modifiedStates = sortDataByType(modifiedStates, keys[0], '');
  }

  if (requiresAll) {
    modifiedStates = [{ [keys[0]]: 'All', [keys[1]]: 'All' }, ...modifiedStates];
  }

  return modifiedStates;
};

String.prototype.toTitleCase = function () {
  return this.toLowerCase().replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
};
