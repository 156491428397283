import { gql } from '@apollo/client';
import { API } from 'aws-amplify';

export const GET_CHAT_HANDLERS = gql`
  query ChatHandlerQuery {
    listChatHandlers(limit: 1000) {
      items {
        access_key
        chat_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_CHAT_HANDLERS_WITH_TOKEN = gql`
  query ChatHandlerQuery($limit: Int, $nextToken: String) {
    listChatHandlers(limit: $limit, nextToken: $nextToken) {
      items {
        access_key
        chat_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export async function AllChatHandlers() {
  const resp = await API.graphql({
    query: GET_CHAT_HANDLERS
  });

  const data = resp?.data?.listChatHandlers;
  let nextToken = data?.nextToken;
  let chatHandlers = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_CHAT_HANDLERS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listChatHandlers;

    const previousData = [...chatHandlers, ...respData?.items];
    chatHandlers = [...previousData];
    nextToken = respData?.nextToken;
  }

  return chatHandlers || [];
}

export const GET_CHAT_HANDLER = gql`
  query SingleCategory($chat_id: ID!) {
    getChatHandler(chat_id: $chat_id) {
      chat_id
      access_key
      createdAt
      updatedAt
    }
  }
`;

export async function GetSingleChatHandler(chat_id) {
  try {
    const respChatHandler = await API.graphql({
      query: GET_CHAT_HANDLER,
      variables: { chat_id }
    });
    const chatHandler = respChatHandler?.data?.getChatHandler;
    return chatHandler;
  } catch (err) {
    console.error(err);
    return {};
  }
}
