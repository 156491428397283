import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetEducatorDataAction } from '../../../Redux/actions/educatorActions';
import { resetInstitutionDataAction } from '../../../Redux/actions/institutionAction';
import { resetUserDataAction } from '../../../Redux/actions/userAction';
import { resetSignUpDataAction } from '../../../Redux/actions/signUpDataAction';

function PageWrapper({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector((store) => store.educatorUser?.data);

  const notAllowed = () => {
    dispatch(resetUserDataAction());
    dispatch(resetEducatorDataAction());
    dispatch(resetInstitutionDataAction());
    dispatch(resetSignUpDataAction());
    navigate('/login');
  };

  useEffect(() => {
    if (!userData.id) notAllowed();
  }, [userData]);

  return <>{children}</>;
}

export default PageWrapper;
