import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { BsChatLeftDots, BsChatLeftDotsFill, BsHouseDoor, BsHouseDoorFill } from 'react-icons/bs';
import { FaBuilding, FaRegBuilding, FaUser } from 'react-icons/fa';
import { AiOutlineUser } from 'react-icons/ai';
import { BiHelpCircle } from 'react-icons/bi';
import { AiOutlineCompass, AiFillCompass } from 'react-icons/ai';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import SearchInput from '../../Widgets/SearchInput/index';
import SwitchWithText from '../../Widgets/SwitchWithText/index';
import NotificationPopup from '../../Components/common/NotificationPopup/index';
import { useDispatch, useSelector } from 'react-redux';
import { resetUserDataAction, setUserDataAction } from '../../Redux/actions/userAction';
import { resetEducatorDataAction } from '../../Redux/actions/educatorActions';
import { resetInstitutionDataAction } from '../../Redux/actions/institutionAction';
import { GetEducator } from '../../Services/query/educator';
import { resetSignUpDataAction } from '../../Redux/actions/signUpDataAction';
import FillPeople from '../../Assets/images/FillPeople.svg';
import OutlinePeople from '../../Assets/images/OutlinePeople.svg';
import FillGroup from '../../Assets/images/FillGroup.svg';
import OutlineGroup from '../../Assets/images/OutlineGroup.svg';
import { API, Auth } from 'aws-amplify';
import { onUpdateEducator } from '../../graphql/subscriptions';
import { useMutation } from '@apollo/client';
import { UPDATE_SCHOOL } from '../../Services/mutation/school';
import { CreditsBadge } from '../../Components/common/CreditsBadge';
import { getInitial } from '../../utils/helpers';
import { useSessionStorage } from '../../utils/storage';
import { ReactComponent as Bell } from '../../Assets/images/bell.svg';
import './Sidenav.css';
import { useAuth } from '..';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

export default function Sidebar(prop) {
  const location = useLocation();
  const user = useSelector((store) => store.educatorUser.data);
  const [creditBalance, setCreditBalance] = useState(parseFloat(user?.credits)?.toFixed(2) || 0);
  const [educatorUserData, setEducatorUserData] = useState(user);
  const [updateSchool] = useMutation(UPDATE_SCHOOL);
  const [open, setOpen] = useState(true);
  const [connAccept, setConnAccept] = useState(educatorUserData?.school?.accepting_connection);
  const [openNav, setNavOpen] = useState(false);
  const [openNavv, setNavOpenn] = useState(false);
  const [openNaav, setNavOpeen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [updateEducator, setUpdateEducator] = useState(false);
  const [path, setPath] = useState('');
  // const { addNotification } = useAuth();
  // const [oppsNotified, setOppsNotified] = useSessionStorage('opps_notified', false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  const roles = ['Teacher', 'Mentor', 'Counselor'];
  const openNavItems = ['/all-opportunity', '/saved-opportunity'];
  const openNavvItems = ['/institution', '/credit-and-payment', '/team'];
  const openNaavItems = [
    '/my-account',
    '/account-settings',
    '/team',
    '/notification',
    '/subscription'
  ];

  const popup = useRef(null);
  const openPopup = (mode) => {
    popup.current.showPopup(mode);
  };

  const logout = () => {
    dispatch(resetUserDataAction());
    dispatch(resetEducatorDataAction());
    dispatch(resetInstitutionDataAction());
    dispatch(resetSignUpDataAction());
    Auth.signOut();
  };

  //////////////////
  const nestedListHandler = () => {
    setNavOpen((prev) => !prev);
  };
  ///////////////////
  //////////////////
  const nestedListHandlerr = () => {
    setNavOpenn((prev) => !prev);
  };
  ///////////////////
  //////////////////
  const nestedListHandleer = () => {
    setNavOpeen((prev) => !prev);
  };

  const switchState = (state) => {
    setConnAccept(state);
    updateSchool({
      variables: { id: educatorUserData.school.id, accepting_connection: state }
    });
  };
  useEffect(() => {
    setPath(location.pathname);
    if (openNavItems.includes(location.pathname)) {
      setNavOpen(openNavItems.includes(location.pathname));
    } else if (openNavvItems.includes(location.pathname)) {
      setNavOpenn(openNavvItems.includes(location.pathname));
    } else if (openNaavItems.includes(location.pathname)) {
      setNavOpeen(openNaavItems.includes(location.pathname));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (updateEducator) {
      GetEducator(educatorUserData?.id).then((educator) => {
        setEducatorUserData(educator);
        dispatch(setUserDataAction(educator));
        setUpdateEducator(false);
      });
    }
  }, [updateEducator]);

  // const getSavedOpps = async () => {
  //   try {
  //     const opps = await AllOpportunitiesAPI();
  //     const savedOpps = opps?.filter((elt) => elt?.saved_by?.includes(user?.id));
  //     let notifyExpiration = false;
  //
  //     savedOpps?.forEach((opp) => {
  //       if ([7, 2, 1].includes(daysToExpiration(opp?.expiry_date))) {
  //         notifyExpiration = true;
  //       }
  //     });
  //     if (notifyExpiration && !oppsNotified) {
  //       addNotification({
  //         status: 'success',
  //         text: 'You have one or more saved opportunities closing soon.',
  //         linkUrl: `/opportunities`,
  //         linkTitle: 'Click here to apply',
  //         icon: 'compass',
  //         type: 'inapp',
  //         time: new Date().toISOString(),
  //         shown: false
  //       });
  //       setOppsNotified(true);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }

    const educatorUpdate = API.graphql({
      query: onUpdateEducator,
      variables: { id: user?.id }
    }).subscribe({
      next: () => setUpdateEducator(true),
      error: (error) => console.log(error)
    });

    return () => {
      educatorUpdate.unsubscribe();
    };
  }, []);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <div className={`fade show ${sidebarOpen ? 'sidebar-shown' : 'sidebar-hidden'}`}>
          <CssBaseline />
          <Drawer variant="permanent" open={open} className="sidebar-content">
            <DrawerHeader>
              <div className="col-sm-12 pt-2 pl-2  text-left">
                <div className="row">
                  <div className="col-sm-3 mb-3 mb-sm-0 col-3 align-items">
                    <img src={user?.school?.image} className="school_logo" alt="" />
                  </div>
                  <div className="col-sm-9 col-9 m-auto">
                    <div className="col-sm-12 pt-2 m-0 pl-2">
                      <h6
                        style={{
                          fontSize: '15px',
                          fontWeight: 700,
                          whiteSpace: 'break-spaces'
                        }}>
                        {user?.name}
                      </h6>
                      <h6 className="pt-0 mt-0" style={{ fontSize: '12px' }}>
                        {roles.includes(user?.role) ? user?.role : 'Mentor'}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </DrawerHeader>
            <List>
              <div className="col-sm-12 mt-2">
                <NavLink to="/dashboard-main" className="link_tag_decoration">
                  <ListItemButton
                    className="hover_home"
                    style={{
                      backgroundColor: path === '/dashboard-main' ? '#F3FDFA' : '#fff'
                    }}>
                    <ListItemIcon>
                      {path === '/dashboard-main' ? (
                        <BsHouseDoorFill className="icon_color active" size={20} />
                      ) : (
                        <BsHouseDoor className="icon_color" size={20} />
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      <span className="font-dashboard font_size">Home</span>
                    </ListItemText>
                  </ListItemButton>
                </NavLink>
              </div>

              <div className="col-sm-12 mt-2">
                {educatorUserData?.school?.is_verified ? (
                  <NavLink to={'/all-opportunity'} className="link_tag_decoration">
                    <ListItemButton className="hover_home" onClick={nestedListHandler}>
                      <ListItemIcon>
                        {['/all-opportunity', '/saved-opportunity'].includes(path) ? (
                          <AiFillCompass className="icon_color active" size={20} />
                        ) : (
                          <AiOutlineCompass className="icon_color" size={20} />
                        )}
                      </ListItemIcon>
                      <ListItemText>
                        <span className="font-dashboard font_size">Opportunities </span>
                      </ListItemText>
                      {openNav ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </NavLink>
                ) : (
                  <div className="d-flex align-items-center justify-start pl-3 pr-3 opacity-30 py-3">
                    <AiOutlineCompass className="icon_color active" size={20} />
                    <p className="font-dashboard font_size_dash mb-0">Opportunity</p>
                  </div>
                )}
                <Collapse in={openNav} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <NavLink to="/all-opportunity" className="link_tag_decoration">
                      <ListItemButton
                        sx={{ pl: 4 }}
                        style={{
                          backgroundColor:
                            path === '/all-opportunity' && !prop.current ? '#F3FDFA' : '#fff'
                        }}>
                        <ListItemText>
                          <span className="font-dashboard"> All Opportunities </span>
                        </ListItemText>
                      </ListItemButton>
                    </NavLink>
                    <NavLink to="/saved-opportunity" className="link_tag_decoration">
                      <ListItemButton
                        sx={{ pl: 4 }}
                        style={{
                          backgroundColor: path === '/saved-opportunity' ? '#F3FDFA' : '#fff'
                        }}>
                        <ListItemText>
                          <span className="font-dashboard"> Saved Opportunities </span>
                        </ListItemText>
                      </ListItemButton>
                    </NavLink>
                  </List>
                </Collapse>
              </div>

              <div className="col-sm-12 mt-2">
                {educatorUserData?.school?.is_verified ? (
                  <NavLink to="/youth" className=" link_tag_decoration">
                    <ListItemButton
                      className="hover_home"
                      style={{
                        backgroundColor: path === '/youth' ? '#F3FDFA' : '#fff'
                      }}>
                      <ListItemIcon>
                        {path === '/youth' ? (
                          <img src={FillPeople} width="20" alt="fill-people" />
                        ) : (
                          <img src={OutlinePeople} width="20" alt="outline-people" />
                        )}
                      </ListItemIcon>
                      <ListItemText>
                        <span className="font-dashboard font_size">Youth</span>
                      </ListItemText>
                    </ListItemButton>
                  </NavLink>
                ) : (
                  <div className="d-flex align-items-center justify-start pl-3 pr-3 opacity-30">
                    <img src={OutlinePeople} width="20" alt="outline-people" />
                    <p className="font-dashboard font_size_dash mb-0">Youth</p>
                  </div>
                )}
              </div>

              <div className="col-sm-12 mt-2">
                {educatorUserData?.school?.is_verified ? (
                  <NavLink to="/my-groups" className=" link_tag_decoration">
                    <ListItemButton
                      className="hover_home"
                      style={{
                        backgroundColor: path === '/my-groups' ? '#F3FDFA' : '#fff'
                      }}>
                      <ListItemIcon>
                        {path === '/my-groups' ? (
                          <img src={FillGroup} width="20" alt="fill-group" />
                        ) : (
                          <img src={OutlineGroup} width="20" alt="outline-group" />
                        )}
                      </ListItemIcon>
                      <ListItemText>
                        <span className="font-dashboard font_size">My Groups</span>
                      </ListItemText>
                    </ListItemButton>
                  </NavLink>
                ) : (
                  <div className="d-flex align-items-center justify-start pl-3 pr-3 opacity-30 py-3">
                    <img src={OutlineGroup} width="20" alt="outline-group" />
                    <p className="font-dashboard font_size_dash mb-0">My Groups</p>
                  </div>
                )}
              </div>
              <div className="col-sm-12 mt-2">
                {educatorUserData?.school?.is_verified ? (
                  <NavLink to="/chat" className=" link_tag_decoration">
                    <ListItemButton
                      className="hover_home"
                      style={{
                        backgroundColor: path === '/chat' ? '#F3FDFA' : '#fff'
                      }}>
                      <ListItemIcon>
                        {path === '/chat' ? (
                          <BsChatLeftDotsFill className="icon_color active" size={20} />
                        ) : (
                          <BsChatLeftDots className="icon_color" size={20} />
                        )}
                      </ListItemIcon>
                      <ListItemText>
                        <span className="font-dashboard font_size">Chats</span>
                      </ListItemText>
                    </ListItemButton>
                  </NavLink>
                ) : (
                  <div className="d-flex align-items-center justify-start pl-3 pr-3 opacity-30 py-3">
                    <BsChatLeftDots className="icon_color" size={20} />
                    <p className="font-dashboard font_size_dash mb-0">Chats</p>
                  </div>
                )}
              </div>
              <Divider />
              {educatorUserData?.isAdmin ? (
                <div className="col-sm-12 mt-2">
                  <ListItemButton
                    className="hover_home"
                    onClick={nestedListHandlerr}
                    style={{
                      backgroundColor: path === '/institution' ? '#F3FDFA' : '#fff'
                    }}>
                    <ListItemIcon>
                      {path === '/institution' ? (
                        <FaBuilding className="icon_color active" size={20} />
                      ) : (
                        <FaRegBuilding size={20} />
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      <span style={{ color: '#515A6A' }} className="font-dashboard font_size">
                        Institution{' '}
                      </span>
                    </ListItemText>
                    {openNavv ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openNavv} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <NavLink className="link_tag_decoration" to="/institution">
                        <ListItemButton
                          sx={{ pl: 4 }}
                          style={{
                            backgroundColor: path === '/institution' ? '#F3FDFA' : '#fff'
                          }}>
                          <ListItemText>
                            <span className="font-dashboard">Profile</span>
                          </ListItemText>
                        </ListItemButton>
                      </NavLink>
                      <NavLink to="/credit-and-payment" className="link_tag_decoration">
                        <ListItemButton
                          sx={{ pl: 4 }}
                          style={{
                            backgroundColor: path === '/credit-and-payment' ? '#F3FDFA' : '#fff'
                          }}>
                          <ListItemText>
                            <span className="font-dashboard">Credit & Payment</span>
                          </ListItemText>
                        </ListItemButton>
                      </NavLink>
                      <NavLink to="/team" className="link_tag_decoration">
                        <ListItemButton
                          sx={{ pl: 4 }}
                          style={{
                            backgroundColor: path === '/team' ? '#F3FDFA' : '#fff'
                          }}>
                          <ListItemText>
                            <span className="font-dashboard">Team</span>
                          </ListItemText>
                        </ListItemButton>
                      </NavLink>
                    </List>
                  </Collapse>
                </div>
              ) : (
                ''
              )}

              <div className="col-sm-12 mt-2">
                <ListItemButton
                  className="hover_home"
                  onClick={nestedListHandleer}
                  style={{
                    backgroundColor: path === '/my-account' ? '#F3FDFA' : '#fff'
                  }}>
                  <ListItemIcon>
                    {path === '/my-account' ? (
                      <FaUser className="icon_color active" size={20} />
                    ) : (
                      <AiOutlineUser size={20} />
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    <span style={{ color: '#515A6A' }} className="font-dashboard font_size">
                      My Account{' '}
                    </span>
                  </ListItemText>
                  {openNaav ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openNaav} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <NavLink className="link_tag_decoration" to="/my-account">
                      <ListItemButton
                        sx={{ pl: 4 }}
                        style={{
                          backgroundColor: path === '/my-account' ? '#F3FDFA' : '#fff'
                        }}>
                        <ListItemText>
                          <span className="font-dashboard">Profile</span>
                        </ListItemText>
                      </ListItemButton>
                    </NavLink>
                    <NavLink className="link_tag_decoration" to="/notification">
                      <ListItemButton
                        sx={{ pl: 4 }}
                        style={{
                          backgroundColor: path === '/notification' ? '#F3FDFA' : '#fff'
                        }}>
                        <ListItemText>
                          <span className="font-dashboard">Notification</span>
                        </ListItemText>
                      </ListItemButton>
                    </NavLink>
                    <NavLink className="link_tag_decoration" to="/subscription">
                      <ListItemButton
                        sx={{ pl: 4 }}
                        style={{
                          backgroundColor: path === '/subscription' ? '#F3FDFA' : '#fff'
                        }}>
                        <ListItemText>
                          <span className="font-dashboard">Subscription</span>
                        </ListItemText>
                      </ListItemButton>
                    </NavLink>
                  </List>
                </Collapse>
              </div>

              <div className="col-sm-12 mt-2 add_border">
                <ListItemButton className="hover_home">
                  <ListItemIcon>
                    <BiHelpCircle size={20} />
                  </ListItemIcon>
                  <ListItemText>
                    <span style={{ color: '#515A6A' }} className="font-dashboard font_size">
                      Get Help
                    </span>
                  </ListItemText>
                </ListItemButton>
              </div>
              {/* <div className="col-sm-12 mt-4">
                <div className="col-sm-12 meet_card_background p-3">
                  <h6 className="m-0 p-0">
                    Meet the Junity <br /> Influencer Program
                  </h6>
                  <p className="para_style mt-2">
                    Nominate the next Junity <br /> Influencers at your school.
                  </p>
                  <button className="btn btn-learn-more btn-block">Learn More </button>
                </div>
              </div> */}
            </List>
          </Drawer>
        </div>
        <div
          style={{
            height: '100vh',
            width: '100%'
          }}>
          <AppBar position="sticky" open={open} className="app_bar position-sticky">
            <Toolbar style={{ width: '100%' }}>
              <div style={{ width: '100%' }} className="justify-between align-items-center">
                <div>
                  <div onClick={toggleSidebar} className="cursor-pointer d-md-none d-lg-none">
                    <i className="fas fa-bars fa-fw fa-2x text-secondary"></i>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    {/*<button data-toggle="dropdown" aria-expanded="false" className="btn d-md-none">*/}
                    {/*  <i*/}
                    {/*    className="fas fa-search fa-fw text-black-50"*/}
                    {/*    style={{ fontSize: 25, width: 40 }}></i>*/}
                    {/*</button>*/}
                    {/*<div className="dropdown-menu dropdown-menu-right px-2 py-0 border-0 shadow-sm">*/}
                    {/*  <SearchInput*/}
                    {/*    user={user}*/}
                    {/*    bg="rgba(244, 245, 247, 0.64)"*/}
                    {/*    placeholder="Search for opportunities, youth ..."*/}
                    {/*  />*/}
                    {/*</div>*/}

                    <SearchInput
                      user={user}
                      bg="rgba(244, 245, 247, 0.64)"
                      placeholder="Search for opportunities, youth ..."
                      className="d-none d-md-flex"
                    />
                  </div>
                  {educatorUserData.isAdmin && <CreditsBadge credits={creditBalance} />}
                  <div className="ml-2">
                    {educatorUserData.isAdmin && (
                      <SwitchWithText
                        text="Accepting Connections"
                        state={connAccept}
                        setState={switchState}
                        switchBg="#2D9371"
                        height={27}
                        className="bg-md-light px-md-2"
                      />
                    )}
                  </div>
                  <div
                    className="ml-2 align-items cursor-pointer"
                    onClick={() => openPopup('notification')}>
                    <Bell width={25} height={25} />
                  </div>
                  <div className="header-profile break align-items">
                    <button
                      data-toggle="dropdown"
                      aria-expanded="false"
                      style={{ display: 'flex', alignItems: 'center' }}>
                      {educatorUserData?.image ? (
                        <img src={educatorUserData?.image} alt="img" className="rd_profile_pic" />
                      ) : (
                        <div className="no-image">{getInitial(educatorUserData?.name)}</div>
                      )}
                      <i className="fas fa-angle-down ml-2"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => navigate('/my-account')}>
                        <img src="../images/person.png" alt="icon" />
                        My Profile
                      </button>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => navigate('/my-account')}>
                        <img src="../images/settings.png" alt="icon" />
                        Account Settings
                      </button>
                      <div className="dropdown-item" type="button" onClick={logout}>
                        <img src="../images/logout.png" alt="icon" />
                        Log Out
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <Box
            component="main"
            style={{
              width: '100%',
              height: '92%'
            }}>
            {prop.component}
          </Box>
        </div>
      </Box>
      <NotificationPopup ref={popup} />
    </>
  );
}
