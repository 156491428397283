import React from 'react';
import Table from 'react-bootstrap/Table';
import './TableAccount.css';
import { formatDate, titleWords } from '../../../../../utils/helpers';

const TableAccount = ({ data }) => {
  return (
    <div className="col-sm-12 scrollremove">
      <Table hover>
        <thead>
          <tr>
            <th>DATE</th>
            <th>PLAN</th>
            <th>COST</th>
            <th>STATUS</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((elt, index) => (
            <tr key={index}>
              <td className="pt-3">{formatDate(elt?.createdAt)}</td>
              <td className="pt-3">{elt?.plan}</td>
              <td className="pt-3">{elt?.cost}</td>
              <td>
                <badge className="btn btn_outline_success btn-sm rounded-pill">
                  {' '}
                  {titleWords(elt.approval)}
                </badge>
              </td>
              <td className="pt-3">
                <i className="fas fa-ellipsis-h" style={{ fontSize: '150%', color: '#969CA5' }}></i>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TableAccount;
