import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const ElementsProvider = ({ children }) => {
  const options = {
    mode: 'setup',
    currency: 'usd',
    paymentMethodTypes: ['card'],
    paymentMethodCreation: 'manual',
    appearance: {
      theme: 'none',

      rules: {
        '.Input': {
          color: '#515A6A',
          fontSize: '13px',
          fontWeight: '500',
          lineHeight: '20px',
          backgroundColor: 'rgba(244, 245, 247, 0.64)',
          padding: '10px 12px'
        },
        '.Input:focus': {
          outline: 'none',
          border: 0
        },
        '.Label': {
          fontWeight: '700',
          fontSize: '13px',
          lineHeight: '18px',
          color: '#515A6A'
        }
      }
    }
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

export default ElementsProvider;
