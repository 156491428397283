import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { CREATE_NOTE, DELETE_NOTE } from '../../../../../Services/mutation/note';
import { Modal } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { IoMdClose } from 'react-icons/io';
import { useAuth } from '../../../..';
import { sendAppNotification } from '../../../../../utils/helpers';

function MyVerticallyCenteredModal(props) {
  const [deleteNote] = useMutation(DELETE_NOTE);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const onSuccess = async() => {
    setLoading(false)
    await sendAppNotification(
      user?.id,
      'Note deleted successfully',
      'success',
      "",
      'Click here to view',
      'mentor',
      'inapp',
      'messageCircle'
    )
    props.onHide();
    props.setRetreive(!props.retreive);
  };

  const onError = (err) => {
    setLoading(false)
    toast('Something occured');
    console.log(err);
  };

  const removeNote = () => {
    setLoading(true)
    const inputData = { id: props?.id };
    deleteNote({
      variables: { ...inputData }
    })
      .then(onSuccess)
      .catch((err) => onError(err));
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal_body_style"
        centered>
        <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
          <Modal.Title>Delete Note</Modal.Title>
          <div className="close_btn drawer rounded-circle" onClick={props.onHide}>
            <IoMdClose fill="#515A6A" size={18} />
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 w-100">
          <div className="d-flex flex-column align-items-center justify-content-center pt-0">
            <img src="../images/alert.png" width={80} height={80} />
            <h5 className="delete-modal-subtitle">Delete note</h5>
            <h6 className="delete-modal-prompt d-inline px-3">
              Are you sure you want to delete “
              <span className="delete-modal-name">{props?.title}</span>”? This action cannot be
              undone.
            </h6>
          </div>
          <div className="d-flex justify-content-between align-items-center gap-3 my-2">
            <button
              type="button"
              className="btn btn-danger"
              style={{ width: '100%' }}
              onClick={removeNote}
              disabled={loading}
              >
              {loading ? <i className='fas fa-spinner fa-spin'></i> : 'Delete Note'}
            </button>
            <button
              type="button"
              className="btn btn-light btn-cancel"
              style={{ width: '100%', border: '1px solid gray' }}
              onClick={props.onHide}>
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default function DeleteNoteModal(props) {
  const { modalShow, setModalShow, setRetreive, retreive, id, title } = { ...props };
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        id={id}
        title={title}
        setRetreive={setRetreive}
        retreive={retreive}
      />
    </>
  );
}
