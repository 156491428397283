import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import {
  checkIfDomainExists,
  compareDomains,
  counselorExists,
  getSchoolByDomainAndAddress
} from './utils';
import { isRequiredFieldsPassed, validateEmail } from '../../utils/validators';
import { axiosClient } from '../../libs/axiosClient';
import { useDispatch, useSelector } from 'react-redux';
import { isLinkSecured } from '../../utils/helpers';
import { setSignUpDataAction } from '../../Redux/actions/signUpDataAction';
import Autocomplete from 'react-google-autocomplete';
import AlertToast from '../../Components/common/Alerts/ToastAlert';
import WarnIcon from '../../Assets/images/warn-icon.svg';
import { Auth } from 'aws-amplify';
import { CheckCircleFill } from 'react-bootstrap-icons';

import './style.css';

const SignUp = () => {
  const signupData = useSelector((store) => store.signUp.data);
  const [state, setState] = useState({});
  const [verified, setVerified] = useState(true);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [toast, setToast] = useState('');
  const [errors, setErrors] = useState({});

  const search = new URLSearchParams(useLocation().search);
  const error = search.get('error');
  const proceed = search.get('continue');

  const googleMapsApi = process.env.REACT_APP_GOOGLE_API_KEY;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const progress = () => {
    return <ClipLoader size={10} color={loading ? '#fff' : ''} />;
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  // const loginWithGoogle = () => {
  //   Auth.signOut();
  //   Auth.federatedSignIn({ provider: 'Google' });
  // };

  const handleSubmit = async () => {
    setLoading(true);
    setSubmitted(true);
    let requiresInvitation = false;
    let invited = false;

    try {
      const reqData = { ...state };
      reqData.full_address = JSON.stringify(state.full_address);

      if (!proceed) {
        if (validateEmail(state.email)) {
          setLoading(false);
          setErrors({ ...errors, email: true });
          return;
        }

        if (!isLinkSecured(state.website)) {
          setLoading(false);
          setErrors({ ...errors, website: true });
          return;
        }

        if (state?.full_address?.address_components?.length < 4) {
          setLoading(false);
          setErrors({ ...errors, address: true });
          return;
        }
      }

      if (!compareDomains(state.website, state.email)) {
        setLoading(false);
        setErrors({ ...errors, email_not_matching: true });
        return;
      }

      if (await counselorExists(state.email)) {
        setLoading(false);
        setActiveToast(true);
        setToast('Counselor already exists');
        return;
      }

      const schoolExists = await getSchoolByDomainAndAddress(state.email, state.full_address);

      if ((await checkIfDomainExists(state.email)) && Object.keys(schoolExists || {}).length > 0) {
        reqData.schoolName = schoolExists.name;
        reqData.schoolID = schoolExists.id;
        requiresInvitation = true;
        invited = (schoolExists?.invitations || [])?.includes(state.email);
      }

      reqData.name = state.fname + ' ' + state.lname;
      reqData.requiresInvitation = requiresInvitation;
      reqData.invited = invited;

      dispatch(setSignUpDataAction({ ...reqData }));

      if (!proceed) {
        await axiosClient.post('/verify-email', reqData);
        setLoading(false);
        navigate('/sign-up-email');
      } else {
        setLoading(false);
        if (reqData.requiresInvitation && reqData.invited) {
          navigate('/invited-to-signup');
        } else if (reqData.requiresInvitation) {
          navigate('/invitation-required');
        } else {
          navigate('/sign-up-institution');
        }
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (submitted) {
      setErrors({
        ...errors,
        website: !isLinkSecured(state.website),
        email: validateEmail(state.email)
      });
    }
    setVerified(isRequiredFieldsPassed(state, 6, 'eq'));
  }, [state]);

  useEffect(() => {
    if (error === '403') {
      setActiveToast(true);
      setToast('Email must be an organization email address');
    }

    const data = { ...signupData };
    data.email = signupData.email || '';
    setState(data);
  }, [error, proceed]);

  return (
    <>
      <AlertToast
        variant={'error'}
        icon={'delete'}
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
      <div className="col-sm-12 bgimage-signup">
        <div className="row pl-sm-5">
          <div className="card col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-2 mt-sm-5 offset-sm-1 p-2">
            <div className="col-sm-12">
              <img src="../images/logo.png" alt="" />
            </div>
            <div className="col-sm-12 pt-3 ">
              <p className="heading-text text-neutral mb-0 ">
                <b>Get Started</b>
              </p>
            </div>
            <div className="col-sm-12 pt-1">
              <p className="paragraph-text mb-0">
                As a counselor, you can create an account to start connecting with students at your
                institution.
              </p>
            </div>
            <div className="col-sm-12 mt-3">
              <div className="row">
                {/*<div className="col-sm-12 mt-2">*/}
                {/*  <div*/}
                {/*    className="d-block btn btn-light mb-2 fontsize text-left rounded-3"*/}
                {/*    onClick={loginWithGoogle}>*/}
                {/*    <img src={google} className="border-right pr-3" alt="google logo" />*/}
                {/*    <span className="text-neutral pl-3">*/}
                {/*      <b> Signup with Google </b>*/}
                {/*    </span>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="col-sm-6 mt-3">
                  <label className="input-label-text">First Name</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="First Name"
                    onChange={handleChange}
                    name="fname"
                  />
                </div>
                <div className="col-sm-6 mt-3">
                  <label className="input-label-text">Last Name</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Last Name"
                    name="lname"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-sm-12 mt-3">
                  <label className="input-label-text">Organization Website</label>
                  <input
                    className="form-control"
                    type="text"
                    name="website"
                    onChange={handleChange}
                    placeholder="Organization Website"
                  />
                  {errors.website && (
                    <div className="error-banner">
                      <div>
                        <img src={WarnIcon} alt="" />
                      </div>
                      <div className="banner-text">
                        Add a secured website link starting with https://.
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-sm-12 mt-3 google-autocomplete">
                  <label className="input-label-text">Office Address</label>
                  {errors.address && (
                    <div className="error-banner">
                      <div>
                        <img src={WarnIcon} alt="" />
                      </div>
                      <div className="banner-text">Please enter a valid office address</div>
                    </div>
                  )}
                  <Autocomplete
                    apiKey={googleMapsApi}
                    className="form-control mb-3"
                    placeholder="Office Address"
                    onPlaceSelected={(place) =>
                      setState((prev) => ({
                        ...prev,
                        address: place.formatted_address,
                        full_address: place
                      }))
                    }
                    options={{
                      types: [],
                      componentRestrictions: {
                        country: ['us', 'ca']
                      }
                    }}
                  />
                </div>
                <div className="col-sm-12">
                  <label className="input-label-text">Work Email Address </label>
                  <div className={signupData.email ? 'input-group' : ''}>
                    <input
                      className="form-control"
                      name="email"
                      onChange={handleChange}
                      type="text"
                      required
                      placeholder="Work email address"
                      disabled={signupData?.email?.length > 0}
                      defaultValue={state?.email || ''}
                    />
                    {signupData.email && (
                      <div className="input_checked pl-2 pr-2">
                        <span className="" id="email_checked">
                          <CheckCircleFill size={20} fill="#2D9371" />
                        </span>
                      </div>
                    )}
                  </div>
                  {errors.email && (
                    <div className="error-banner">
                      <div>
                        <img src={WarnIcon} alt="" />
                      </div>
                      <div className="banner-text">Email should be a work email</div>
                    </div>
                  )}
                  {errors.email_not_matching && (
                    <div className="error-banner">
                      <div>
                        <img src={WarnIcon} alt="" />
                      </div>
                      <div className="banner-text">Email domain does not match website domain</div>
                    </div>
                  )}
                </div>
              </div>
              <p className="mt-3 fs-14">
                We’ll check if your school already has an account on Junity.
              </p>
            </div>
            <div className="col-sm-12 mt-3 pb-3 add_border">
              <button
                className={`btn btn-success btn-block font-weight-bold pt-2 pb-2 `}
                onClick={handleSubmit}>
                {loading ? progress() : 'Get Started'}
              </button>
            </div>
            <div className="col-sm-12 mt-2 paragraph-text">
              <p>
                <b>
                  Already have an account?{' '}
                  <span className="text-color-signup">
                    <Link to="/login" style={{ textDecoration: 'none', color: '#226E54' }}>
                      Log In
                    </Link>
                  </span>
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
