import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Resetpasswordsuccessful.css';

const Resetpasswordsuccessful = () => {
  return (
    <div className="col-sm-12 bgimage-signup">
      <div className="row pl-sm-5">
        <div className="card col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-2 mt-sm-5 offset-sm-1 p-2">
          <div className="col-sm-12">
            <img src="../images/logo.png" />
          </div>

          <div className="col-sm-12 mt-3 ">
            <p className="heading-text text-neutral font-weight-bold mb-0">
              <b>Password reset successful</b>
            </p>
          </div>
          <div className="col-sm-12 pt-1">
            <p className="paragraph-text fw-semibold opacity-75 mb-0">
              You can now log into your junity account with your new password.
            </p>
          </div>
          <div className="col-sm-12 pt-1">
            <hr />
          </div>
          <div className="col-sm-12 mb-3">
            <Link to="/login" style={{ textDecoration: 'none' }}>
              <button className="btn btn-light btn_style_reset green-text p-0 btn-sm font-weight-bold">
                <i className="fas fa-angle-left"></i>&nbsp; Back to Log In{' '}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resetpasswordsuccessful;
