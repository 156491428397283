import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { AllEducators } from '../../Services/query/educator';
import { useSelector } from 'react-redux';
import { axiosClient, chatClient } from '../../libs/axiosClient';

import { CREATE_CHAT_HANDLER } from '../../Services/mutation/chatHandler';

import './style.css';

const AccountCreated = () => {
  const user = useSelector((store) => store.educatorUser.data);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [createChatHandler] = useMutation(CREATE_CHAT_HANDLER);

  const createGroupChat = async (chats, alreadyCreatedUsers) => {
    const sameOrgGroupChat = chats.data.find(
      (elt) =>
        JSON.parse(elt.custom_json)['orgID'] === user.schoolID &&
        JSON.parse(elt.custom_json)['type'] === 'sameOrgGroupChat'
    );

    if (Object.keys(sameOrgGroupChat || {}).length > 0) {
      alreadyCreatedUsers.forEach(async (user) => {
        if (!sameOrgGroupChat.people.some((elt) => elt.person.username === user)) {
          await chatClient.post(`/chats/${sameOrgGroupChat.id}/people/`, {
            username: user
          });
        }
      });
    } else {
      const data = {
        usernames: alreadyCreatedUsers,
        title: user?.school?.name,
        is_direct_chat: false,
        custom_json: JSON.stringify({
          orgID: user?.schoolID,
          creatorID: user?.id,
          creatorType: 'educator',
          destinationType: 'educator',
          type: 'sameOrgGroupChat'
        })
      };

      const chat = await chatClient.put('/chats/', data);
      await createChatHandler({
        variables: {
          chat_id: chat.data.id,
          access_key: chat.data.access_key,
          createdAt: new Date()
        }
      });
    }
  };

  const createSingleChats = async (chats, alreadyCreatedUsers, alreadyCreatedUserNames) => {
    alreadyCreatedUsers.forEach(async (alreadyCreatedUser) => {
      if (alreadyCreatedUser !== user?.id) {
        const singleChat = chats.data.findIndex(
          (elt) =>
            (JSON.parse(elt.custom_json)['creatorID'] === user?.id &&
              JSON.parse(elt.custom_json)['destinationID'] === alreadyCreatedUser) ||
            (JSON.parse(elt.custom_json)['creatorID'] === alreadyCreatedUser &&
              JSON.parse(elt.custom_json)['destinationID'] === user?.id)
        );

        if (singleChat === -1) {
          const data = {
            usernames: [alreadyCreatedUser],
            title: user?.name,
            is_direct_chat: true,
            custom_json: JSON.stringify({
              orgID: user?.schoolID,
              creatorID: user?.id,
              creatorType: 'educator',
              destinationType: 'educator',
              destinationID: alreadyCreatedUser,
              senderName: user?.name,
              destinationName: alreadyCreatedUserNames[alreadyCreatedUser],
              type: 'sameOrgDirectChat'
            })
          };

          const chat = await chatClient.put('/chats/', data);
          createChatHandler({
            variables: {
              chat_id: chat.data.id,
              access_key: chat.data.access_key,
              createdAt: new Date()
            }
          });
        }
      }
    });
  };

  const handleProceed = async () => {
    if (Object.keys(user).length) {
      setLoading(true);
      try {
        const users = await chatClient.get('/users');
        const educators = await AllEducators();
        const sameSchoolEducators = educators?.filter((elt) => elt.schoolID === user?.schoolID);

        const alreadyCreatedUsers = [];
        const alreadyCreatedUserNames = {};

        sameSchoolEducators?.forEach(async (educator) => {
          if (users.data.some((user) => user.username === educator.id)) {
            alreadyCreatedUsers.push(educator.id);
            alreadyCreatedUserNames[educator.id] = educator.name;
          } else {
            await chatClient.post('/users/', {
              username: educator?.id,
              first_name: educator?.name?.split(' ')[0],
              last_name: educator?.name?.split(' ').splice(1).join(''),
              secret: educator?.id
            });
            alreadyCreatedUsers.push(educator.id);
            alreadyCreatedUserNames[educator.id] = educator.name;
          }
        });

        const chats = await chatClient.get('/chats');

        await createGroupChat(chats, alreadyCreatedUsers);
        await createSingleChats(chats, alreadyCreatedUsers, alreadyCreatedUserNames);

        await axiosClient.post('/post-onboarding', {
          name: user?.name,
          email: user?.email,
          school: user?.school?.name
        });

        setLoading(false);
        navigate('/dashboard-main');
      } catch (e) {
        console.error(e);
        setLoading(false);
        navigate('/dashboard-main');
      }
    } else navigate('/login');
  };

  return (
    <div className="col-sm-12 bgimage-signup1">
      <div className="row pl-sm-5">
        <div className="card col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-2 mt-sm-5 offset-sm-1 p-2 pb-3">
          <div className="col-sm-12">
            <img src="../images/logo.png" alt="" />
          </div>
          <div className="col-sm-12 mt-4">
            <div className="row">
              <h3 className="opacity-90">
                <b>
                  Your account has been <br />
                  created.
                  <i className="fas fa-check-square ms-1" style={{ color: 'limegreen' }}></i>
                </b>
              </h3>
            </div>
          </div>

          <div className="col-sm-12 pt-1">
            <p className="paragraph-text1 mb-2 opacity-75">
              <b>
                To help you get started, we sent you an email about your mentor account on Junity.
              </b>
            </p>
            <p className="paragraph-text2 mb-0">
              <b>Soon you would be ready to start connecting to youth on Junity.</b>
            </p>
          </div>

          <div className="col-sm-12 mt-3 mb-2">
            <button
              type="button"
              onClick={handleProceed}
              className="btn buttoncolorsignup1 active py-2"
              style={{ fontSize: '14px' }}>
              {loading ? 'Loading...' : 'Take me to my account'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountCreated;
