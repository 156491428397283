import { gql, useQuery } from '@apollo/client';
import { API } from 'aws-amplify';
import { GetSingleStudent } from './students';

export const GET_STUDENT_OPPORTUNITY_APPLICATION = gql`
  query StudentOpportunityApplicationsQuery {
    listStudentOpportunityApplications(limit: 1000) {
      items {
        createdAt
        dont_show_again
        employer
        employment_status
        household_income
        id
        state
        occupation
        studentID
        schoolID
        resourceID
        updatedAt
        opportunityID
        status
      }

      nextToken
    }
  }
`;

export const GET_STUDENT_OPPORTUNITY_APPLICATION_WITH_TOKEN = gql`
  query StudentOpportunityApplicationsQuery($limit: Int, $nextToken: String) {
    listStudentOpportunityApplications(limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        dont_show_again
        employer
        employment_status
        household_income
        id
        state
        occupation
        studentID
        resourceID
        updatedAt
        opportunityID
        schoolID
        status
      }
      nextToken
    }
  }
`;

export async function AllStudentOpportunityApplications() {
  const resp = await API.graphql({
    query: GET_STUDENT_OPPORTUNITY_APPLICATION,
    cache: true
  });

  const data = resp?.data?.listStudentOpportunityApplications;
  let nextToken = data?.nextToken;
  let studentOpportunityApplications = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_STUDENT_OPPORTUNITY_APPLICATION_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listStudentOpportunityApplications;

    const previousData = [...studentOpportunityApplications, ...respData?.items];
    studentOpportunityApplications = [...previousData];

    nextToken = respData?.nextToken;
  }

  // return studentOpportunityApplications || [];
  return await studentOpportunityApplications?.reduce(async (previousPromise, app) => {
    const student = await GetSingleStudent(app.studentID);
    let appsData = await previousPromise;
    const obj = { ...app, student };
    appsData.push(obj);
    return appsData;
  }, Promise.resolve([]));
}

export async function GetStudentOpportunityApplicationByOpportunityID(id) {
  const oppsApps = await AllStudentOpportunityApplications();
  const oppApps = oppsApps?.filter((apps) => apps.opportunityID === id);
  return oppApps || [];
}

export async function GetStudentOpportunityApplicationByResourceID(id) {
  const oppsApps = await AllStudentOpportunityApplications();
  const oppApps = oppsApps?.filter((apps) => apps.resourceID === id);
  return oppApps || [];
}

export async function GetStudentOpportunityApplicationBySchoolID(id) {
  const oppsApps = await AllStudentOpportunityApplications();
  const studentApps = oppsApps?.filter((apps) => apps.schoolID === id);
  return studentApps || [];
}

export async function GetApplicationByOpportunityIDAndStudentID(id, studentID) {
  const oppsApps = await AllStudentOpportunityApplications();
  const studentApps = oppsApps?.find(
    (elt) => elt?.opportunityID === id && elt.studentID === studentID
  );
  return studentApps || {};
}

export function AllStudentOpportunityApplicationsObj() {
  const resp = useQuery(GET_STUDENT_OPPORTUNITY_APPLICATION);
  if (resp?.data) {
    return {
      data: resp?.data?.listStudentOpportunityApplications?.items,
      refetch: resp?.refetch
    };
  }
  return [];
}

export const GET_SINGLE_APPLICATION = gql`
  query SingleApplication($id: ID!) {
    getStudentOpportunityApplication(id: $id) {
      createdAt
      dont_show_again
      employer
      employment_status
      household_income
      id
      occupation
      state
      resourceID
      studentID
      updatedAt
      opportunityID
      schoolID
      status
    }
  }
`;

export async function GetStudentOpportunityApplication(id) {
  try {
    const respStudentOpportunityApplication = await API.graphql({
      query: GET_SINGLE_APPLICATION,
      variables: { id }
    });
    const studentOpportunityApplication =
      respStudentOpportunityApplication?.data?.getStudentOpportunityApplication;
    return studentOpportunityApplication;
  } catch (err) {
    return {};
  }
}
