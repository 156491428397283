import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdClose, MdInfo } from 'react-icons/md';
import { ReactComponent as MessageCircle } from '../../../Assets/images/message-circle.svg';
import CheckMark from '../../../Assets/images/checkmark.png'
import { Compass, User } from 'react-feather';
import './style.css';

const icons = {
  compass: <Compass size={20} color="#226E54"/>,
  messageCircle: <MessageCircle/>,
  checkmark: <img src={CheckMark} alt='checkmark'/>,
  user: <User size={20} color="#262F3F"/>
}

export const PushNotificationItem = ({notification, onClose})=>{
  const [closed, setClosed] = useState(false);
  const [show, setShow] = useState(true)

  const handleClose = () => {
    setClosed(true);
    setTimeout(() => {
      setShow(false);
      onClose();
    }, 1000);
  }

  useEffect(()=>{
    const timer = setTimeout(() => {
      handleClose(); 
    }, 3000); // hide after 10 seconds

    return () => clearTimeout(timer)
  }, [])

  return (
    
      <div className={`notification push ${closed ? 'slide-out' : ''} mb-2`}>
        <div className={`notification-icon ${notification?.status}`}>
          {notification?.icon 
          ? <>{icons[notification?.icon]}</>
          : <MdInfo />
        }
        </div>
        <div className="notification-content">
          <p>{notification?.text}</p>
          {notification?.linkUrl &&
            <Link 
              to={notification?.linkUrl} 
              className='notification-link'
              onClick={handleClose}
              >{notification?.linkTitle}</Link>
          }
        </div>
        <MdClose
          className="close-icon"
          size={24}
          color='silver'
          onClick={handleClose}
        /> 
      </div>
    
  )
}

export const InAppNotificationItem = ({notification, onClose})=>{
  const [closed, setClosed] = useState(false);
  const [show, setShow] = useState(true)

  const handleClose = () => {
    setClosed(true);
    setTimeout(() => {
      setShow(false);
      onClose()
    }, 1000);
  }

  useEffect(()=>{
    const timer = setTimeout(() => {
      handleClose(); 
    }, 3000); // hide after 10 seconds

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
    {show && 
      <div className={`notification inapp mb-2`}>
        <div className="notification-content">
          <p>{notification?.text}</p>
          {notification?.linkUrl &&
            <Link 
              to={notification?.linkUrl} 
              className='notification-link'
              onClick={handleClose}
              >{notification?.linkTitle}</Link>
          }
        </div>
      </div>
      }
    </>
  )
}

export const InAppNotifications = ({notifications, removeNotification}) => {
  return (
    <div className="notifications-container">
        {notifications.map((notification, index) => (
          <>
            {notification?.type === 'push' ?
              <PushNotificationItem
                key={index}
                notification={notification}
                onClose={()=>removeNotification(index)}
              />
            : null
            }
          </>
        ))}
        {notifications.map((notification, index) => (
          <>
            {notification?.type === 'inapp'?
              <InAppNotificationItem
                key={index}
                notification={notification}
                onClose={()=>removeNotification(index)}
              />
            : null
            }
          </>
        ))}
      </div>
  );
};

