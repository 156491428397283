import React, { useEffect } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { BsXLg } from 'react-icons/bs';
import { iconFind } from './iconData';
import './style.css';

function AlertToast({ icon, variant, active, setActive, info }) {
  useEffect(() => {
    if (active) setTimeout(() => setActive(false), 10000);
  }, [active]);

  return (
    <>
      {active && (
        <ToastContainer className={`p-3 toast-wrapper ${variant}`}>
          <Toast>
            <Toast.Body className="toast-content d-flex align-items-center justify-content-between">
              <div className="left-container d-flex align-items-center gap-2">
                <div className="info-icon">{iconFind(icon)}</div>
                <div className="info">
                  <span>{info}</span>
                </div>
              </div>
              <div
                className="close-icon cursor-pointer"
                onClick={() => {
                  setActive(false);
                }}>
                <BsXLg className="close-icon-btn" />
              </div>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      )}
    </>
  );
}

export default AlertToast;
