import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

export const RemoveConnectionModal = ({
  showRemoveModal,
  setShowRemoveModal,
  userId,
  organizationID
}) => {
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState('');
  const [valid, setValid] = useState(false);

  const removeConnection = () => {
    if (confirmation === 'YES') {
      /* Deletion code here */
    }
  };

  useEffect(() => {
    if (confirmation === 'YES') {
      setValid(true);
    }
  }, [confirmation]);

  return (
    <Modal
      show={showRemoveModal}
      onHide={() => {
        setShowRemoveModal(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="mx-auto"
      centered>
      <Modal.Body className="pt-0 w-100 px-4 payment-modal modal-info">
        <div className="w-100 d-flex justify-content-center">
          <div className="image-info mt-3 bg-danger d-flex align-items-center justify-content-center">
            <i className="fas fa-unlink text-white fa-2x"></i>
          </div>
        </div>
        <h5 className="info-heading mt-3">Are you sure you want to remove connection?</h5>
        <h6 className="info-description mb-4">
          {`You will no longer be able to chat with this youth.`}
        </h6>
        <div className="form-group mt-3">
          <label className="institution-form-label">
            {`Type "YES" in all caps below to confirm removal`}
          </label>
          <input
            type="text"
            class="form-control bg-light"
            aria-describedby="helpId"
            placeholder=""
            onChange={(e) => {
              setConfirmation(e.target.value);
            }}
          />
        </div>
        <div className="d-flex justify-content-between align-items-center gap-2 my-2">
          <button
            type="button"
            className="btn btn-danger"
            disabled={!valid}
            onClick={() => {
              removeConnection();
            }}>
            {loading ? <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Yes, remove'}
          </button>
          <button
            type="button"
            className="btn btn-light text-dark"
            onClick={() => {
              setShowRemoveModal(false);
            }}>
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
