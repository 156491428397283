import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { ClipLoader } from 'react-spinners';
import { GetSingleSchool } from '../../../../../Services/query/school';
import {
  AddressElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';

export const AddPaymentMethodModal = ({
  educator,
  addPaymentModal,
  setAddPaymentModal,
  addPaymentMethod,
  billingDetails,
  setBillingDetails,
  stripe,
  loading,
  progress
}) => {
  const [cardholderName, setCardholderName] = useState(educator?.name);

  const [organization, setOrganization] = useState();

  const handleBillingDetailsChange = (event) => {
    if (event.complete) {
      setBillingDetails(event.value);
    }
  };

  const fetchOrg = async () => {
    try {
      const org = await GetSingleSchool(educator?.schoolID);
      if (org && Object.keys(org).length) setOrganization(org);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (educator && Object.keys(educator).length) fetchOrg();
  }, [educator]);

  const paymentElementOptions = {
    business: { name: 'Junity' },
    terms: { card: 'never' }
  };

  return (
    <Modal
      show={addPaymentModal}
      onHide={() => {
        setAddPaymentModal(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="mx-auto"
      centered>
      <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
        <Modal.Title>Add New Card</Modal.Title>
        <div
          className="close_btn drawer rounded-2"
          onClick={() => {
            setAddPaymentModal(false);
          }}>
          <IoMdClose fill="#515A6A" size={18} />
        </div>
      </Modal.Header>
      <Modal.Body 
        className="pt-0 w-100 px-3 payment-modal institution-form"
        style={{minHeight: 200}}>
        <h6 className="section-subheading">CARD DETAILS</h6>
        <PaymentElement options={paymentElementOptions} />
        <h6 className="section-subheading mt-4">BILLING DETAILS</h6>
        <div className="form-group mb-3">
          <AddressElement
            options={{
              mode: 'billing',
              display: { name: 'organization' },
              allowedCountries: ['US'],
              autocomplete: {
                mode: 'google_maps_api',
                apiKey: process.env.REACT_APP_GOOGLE_API_KEY
              },
              defaultValues: {
                name: organization?.name,
                address: {
                  line1: '',
                  line2: '',
                  city: '',
                  state: '',
                  postal_code: '',
                  country: ''
                }
              }
            }}
            onChange={handleBillingDetailsChange}
          />
        </div>
        <button
          type="button"
          className="btn btn-success w-100"
          disabled={!stripe}
          onClick={addPaymentMethod}>
          {loading ? progress(loading) : 'Add New Card'}
        </button>
      </Modal.Body>
    </Modal>
  );
};
