import * as React from 'react';
import { useDispatch } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './tabchat.css';
import { setSendbirdApp } from '../../../../Redux/actions/sendbirdAction';
import ChatYouth from '../ChatYouth/ChatYouth';
import ChatColleague from '../ChatColleague/ChatColleague';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function Tabswitchchat(props) {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  // props
  const { setCurrentTab, youthChats, teamChats } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentTab(newValue);
  };

  const TabBadgeComponent = ({ label, active = false }) => {
    return <span className={`ml-2 tab-badge ${active ? 'tab-badge-success' : ''}`}>{label}</span>;
  };

  return (
    <div className="col-sm-12 background_tab">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab
              label={`Youth`}
              icon={<TabBadgeComponent label={youthChats} active={!value} />}
              iconPosition="end"
              className={`tab-btn ml-1 ${!value ? 'active' : ''}`}
              {...a11yProps(0)}
            />
            <Tab
              label={`Colleague`}
              icon={<TabBadgeComponent label={teamChats} active={value === 1} />}
              iconPosition="end"
              className={`tab-btn ml-2 ${value ? 'active' : ''}`}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        {/* <TabPanel   value={value} index={0}>
       <div className='col-sm-12 p-0  '>
        <ChatYouth/>
       </div>
      </TabPanel>
        <TabPanel  value={value} index={1}>
        <ChatColleague/>
      </TabPanel> */}
      </Box>
    </div>
  );
}
