import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setEducatorDataAction } from '../../Redux/actions/educatorActions';
import { setSignUpDataAction } from '../../Redux/actions/signUpDataAction';
import { setUserDataAction } from '../../Redux/actions/userAction';
import { GetEducatorsByEmail } from '../../Services/query/educator';
import { validateEmail } from '../../utils/validators';
import { checkIfDomainExists, counselorExists, getSchoolByDomain } from '../SignUp/utils';
import './style.css';

function SocialAuthRedirectPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    async function getUserDataFromGoogle() {
      try {
        const googleUser = await Auth.currentAuthenticatedUser();
        if (googleUser) {
          dispatch(setEducatorDataAction(googleUser));
          const name = googleUser?.attributes?.name;
          const email = googleUser?.attributes?.email;

          const alreadyCreated = await counselorExists(email);
          if (alreadyCreated) {
            const resource = await GetEducatorsByEmail(email);
            dispatch(setUserDataAction(resource));
            navigate('/dashboard-main');
            return;
          }

          const userData = {
            fname: name?.split(' ')[0] || '',
            lname: name?.split(' ')[1] || '',
            email
          };

          if (validateEmail(email)) {
            navigate('/sign-up?error=403');
            return;
          }

          const domainExists = await checkIfDomainExists(email);

          if (domainExists) {
            const school = await getSchoolByDomain(email);
            userData.website = school.website || '';
            dispatch(setSignUpDataAction(userData));
            navigate('/sign-up?continue=true');
          } else {
            dispatch(setSignUpDataAction(userData));
            navigate('/sign-up?continue=true');
          }
        } else {
          navigate('/sign-up');
        }
      } catch (e) {
        console.error(e);
      }
    }

    getUserDataFromGoogle();
  }, []);

  return (
    <div className="redirect-container">
      <h3>Redirecting...</h3>
    </div>
  );
}

export default SocialAuthRedirectPage;
