import { gql, useQuery } from '@apollo/client';
import { API } from 'aws-amplify';
import { GetEducator } from './educator';

export const GET_UPGRADE_REQUESTS = gql`
  query PaymentsQuery {
    listSubscriptionUpgradeRequests(limit: 1000) {
      items {
        id
        organizationID
        roleID
        roleType
        status
        plan
        cost
        name
        approval
        createdAt
        updatedAt
      }

      nextToken
    }
  }
`;

export const GET_UPGRADE_REQUEST_BY_ROLE_ID = gql`
  query PaymentsQuery($roleID: ID) {
    listSubscriptionUpgradeRequests(filter: { roleID: { eq: $roleID } }, limit: 1000) {
      items {
        id
        organizationID
        roleID
        roleType
        status
        plan
        cost
        name
        approval
        createdAt
        updatedAt
      }

      nextToken
    }
  }
`;

export const GET_UPGRADE_REQUEST_BY_ROLE_ID_WITH_TOKEN = gql`
  query PaymentsQuery($roleID: ID, $limit: Int, $nextToken: String) {
    listSubscriptionUpgradeRequests(
      limit: $limit
      nextToken: $nextToken
      filter: { roleID: { eq: $roleID } }
      limit: 1000
    ) {
      items {
        id
        organizationID
        roleID
        roleType
        status
        plan
        cost
        name
        approval
        createdAt
        updatedAt
      }

      nextToken
    }
  }
`;

export const GET_UPGRADE_REQUESTS_BY_ORGANIZATION = gql`
  query UpgradeRequestsQuery($organizationID: ID, $roleType: String) {
    listSubscriptionUpgradeRequests(
      filter: {
        organizationID: { eq: $organizationID }
        roleType: { eq: $roleType }
        status: { eq: false }
      }
    ) {
      items {
        id
        organizationID
        roleID
        roleType
        plan
        cost
        name
        status
        approval
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_UPGRADE_REQUEST = gql`
  query SinglePayment($id: ID!) {
    getSubscriptionUpgradeRequest(id: $id) {
      id
      organizationID
      roleID
      roleType
      plan
      name
      cost
      status
      approval
      createdAt
      updatedAt
    }
  }
`;

export async function GetUpgradeRequest(id) {
  try {
    const resp = await API.graphql({
      query: GET_UPGRADE_REQUEST,
      variables: { id }
    });

    return resp?.data?.getSubscriptionUpgradeRequest;
  } catch (err) {
    console.error(err);
    return {};
  }
}

export const GET_UPGRADE_REQUESTS_WITH_TOKEN = gql`
  query UpgradeRequestsQuery($limit: Int, $nextToken: String) {
    listSubscriptionUpgradeRequests(limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleID
        roleType
        status
        plan
        cost
        name
        organizationID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export async function AllUpgradeRequests() {
  const resp = await API.graphql({
    query: GET_UPGRADE_REQUESTS
  });

  const data = resp?.data?.listSubscriptionUpgradeRequests;
  let nextToken = data?.nextToken;
  let requests = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_UPGRADE_REQUESTS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listSubscriptionUpgradeRequests;

    const previousData = [...requests, ...respData?.items];
    requests = [...previousData];

    nextToken = respData?.nextToken;
  }

  return requests;
}

export async function GetUserUpgradeRequest(roleID) {
  try {
    const resp = await API.graphql({
      query: GET_UPGRADE_REQUEST_BY_ROLE_ID,
      variables: { roleID },
      cache: true
    });

    const data = resp?.data?.listSubscriptionUpgradeRequests;
    let nextToken = data?.nextToken;
    let requests = data?.items;

    while (nextToken) {
      const resp = await API.graphql({
        query: GET_UPGRADE_REQUESTS_WITH_TOKEN,
        variables: { limit: 1000, nextToken }
      });
      const respData = resp?.data?.listSubscriptionUpgradeRequests;

      const previousData = [...requests, ...respData?.items];
      requests = [...previousData];

      nextToken = respData?.nextToken;
    }

    return requests;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function GetOrganizationUpgradeRequests(organizationID, roleType = 'mentor') {
  try {
    const resp = await API.graphql({
      query: GET_UPGRADE_REQUESTS_BY_ORGANIZATION,
      variables: { organizationID, roleType },
      cache: true
    });

    const data = resp?.data?.listSubscriptionUpgradeRequests;
    let requests = data?.items;

    return await requests?.reduce(async (previousPromise, currentRequest) => {
      const educator = await GetEducator(currentRequest.roleID);
      let requestsData = await previousPromise;
      const obj = { ...currentRequest, user: educator };
      requestsData.push(obj);
      return requestsData;
    }, Promise.resolve([]));
  } catch (err) {
    console.error(err);
    return [];
  }
}
