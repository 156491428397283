import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { AllStudents } from '../../../../Services/query/students';
import { getInitial } from '../../../../utils/helpers';

function Youthmodal({ user }) {
  const [sameSchool, setSameSchool] = useState([]);

  useEffect(() => {
    const getStudents = async () => {
      const students = await AllStudents();
      setSameSchool(students?.filter((student) => student.schoolID === user?.schoolID));
    };

    getStudents();
  }, []);

  return (
    <div className="col-sm-12">
      {sameSchool?.map((elt, index) => (
        <div className="col-sm-12 pt-3" key={elt?.id}>
          <div className="row">
            <div className="col-sm-2">
              <Avatar sx={{ bgcolor: '#226E54' }}>{getInitial(elt?.name)}</Avatar>
            </div>
            <div className="col-sm-8 pt-2">
              <h6>
                Bryce David <i style={{ color: '#0D3575' }} className="fas fa-check-circle"></i>
              </h6>
            </div>
            <div className="col-sm-2 pt-2 text-right">
              <h6>
                <i style={{ color: '#969CA5' }} className="fas fa-angle-right"></i>
              </h6>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Youthmodal;
