import { gql, useQuery } from '@apollo/client';
import { API } from 'aws-amplify';
import { matchDomain, removeCommas } from '../../utils/helpers';

export const GET_SCHOOLS = gql`
  query AllSchools {
    listSchools(limit: 1000) {
      items {
        contact_email
        contact_phone
        createdAt
        id
        name
        state
        image
        banner
        full_address
        accepting_connection
        updatedAt
        type
        zipcode
        invitations_names
        connections
        address
        website
        team_size
        counselors_dont_show_again
        about
        connection_dates
        invitations
        invitations_dates
        dont_show_again
        is_verified
      }

      nextToken
    }
  }
`;

export const GET_SCHOOLS_WITH_TOKEN = gql`
  query SchoolsQuery($limit: Int, $nextToken: String) {
    listSchools(limit: $limit, nextToken: $nextToken) {
      items {
        contact_email
        contact_phone
        createdAt
        id
        name
        state
        type
        updatedAt
        banner
        zipcode
        full_address
        connections
        invitations_names
        about
        address
        accepting_connection
        website
        image
        team_size
        connection_dates
        counselors_dont_show_again
        dont_show_again
        invitations
        invitations_dates
        is_verified
      }
      nextToken
    }
  }
`;

export async function AllSchools() {
  const resp = await API.graphql({
    query: GET_SCHOOLS
  });

  const data = resp?.data?.listSchools;
  let nextToken = data?.nextToken;
  let schools = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_SCHOOLS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listschools;

    const previousData = [...schools, ...respData?.items];
    schools = [...previousData];

    nextToken = respData?.nextToken;
  }

  return schools || [];
}

export function GetSchool() {
  const resp = useQuery(GET_SCHOOLS);
  if (resp?.data) {
    return resp?.data?.listSchools?.items;
  }
  return [];
}

export async function GetSchoolsByState(state) {
  const schools = await AllSchools();
  const stateArray = state.split(' ');
  const userState = removeCommas(stateArray[stateArray.length - 2]);
  const sameStateSchools = schools?.filter(
    (school) =>
      removeCommas(school.state?.split(' ')[school.state?.split(' ').length - 2]) === userState
  );
  return sameStateSchools || [];
}

export async function GetSchoolsByEmail(email) {
  const schools = await AllSchools();
  const school = schools.find((elt) => matchDomain(elt.contact_email, email) === true);
  return school || {};
}

export function GetSchoolObj() {
  const resp = useQuery(GET_SCHOOLS);
  if (resp?.data) {
    return {
      data: resp?.data?.listSchools?.items,
      refetch: resp?.refetch
    };
  }
  return {};
}

export const GET_SCHOOL = gql`
  query SingleSchool($id: ID!) {
    getSchool(id: $id) {
      contact_email
      contact_phone
      createdAt
      id
      name
      state
      updatedAt
      invitations_names
      zipcode
      connections
      type
      address
      accepting_connection
      website
      team_size
      full_address
      image
      banner
      about
      connection_dates
      counselors_dont_show_again
      dont_show_again
      invitations
      invitations_dates
      is_verified
    }
  }
`;

export async function GetSingleSchool(id) {
  try {
    const respSchool = await API.graphql({
      query: GET_SCHOOL,
      variables: { id }
    });
    return respSchool?.data?.getSchool;
  } catch (err) {
    return {};
  }
}
