import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { ClipLoader } from 'react-spinners';
import { GetSingleSchool } from '../../../../../Services/query/school';

export const PaymentMethodAddSuccessModal = ({
  educator,
  paymentMethodAddedModal,
  setPaymentMethodAddedModal,
  setPaymentsModal
}) => {
  const [organization, setOrganization] = useState();

  const fetchOrg = async () => {
    try {
      const org = await GetSingleSchool(educator?.schoolID);
      if (org && Object.keys(org).length) setOrganization(org);
    } catch (error) {
      console.log(error);
    }
  };

  const showPaymentMethodsModal = () => {
    setPaymentMethodAddedModal(false);
    setPaymentsModal(true);
  };

  useEffect(() => {
    if (educator && Object.keys(educator).length) fetchOrg();
  }, [educator]);

  return (
    <Modal
      show={paymentMethodAddedModal}
      onHide={() => {
        setPaymentMethodAddedModal(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="mx-auto"
      centered>
      <Modal.Body className="pt-0 w-100 px-3 payment-modal">
        <div className="w-100 d-flex justify-content-center">
          <img src="../images/check.png" className="image-info mx-auto mt-3" />
        </div>
        <h5 className="info-heading mt-3">New Card Created!</h5>
        <h6 className="info-description mb-3">
          This card will now be available for credit purchases by <b>{organization?.name}</b>.
        </h6>
        <button
          type="button"
          className="btn btn-light text-dark w-100"
          onClick={() => {
            showPaymentMethodsModal();
          }}>
          Close
        </button>
      </Modal.Body>
    </Modal>
  );
};

export const PaymentSuccessModal = ({ paymentSuccess, setPaymentSuccess, selectedPackage }) => {
  return (
    <Modal
      show={paymentSuccess}
      onHide={() => {
        setPaymentSuccess(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="mx-auto"
      centered>
      <Modal.Body className="pt-0 w-100 px-3 payment-modal">
        <div className="w-100 d-flex justify-content-center">
          <img src="../images/wallet-g.png" className="image-info mx-auto mt-3" />
        </div>
        <h5 className="info-heading mt-3">Credit Purchase Successful!</h5>
        <h6 className="info-description mb-3">
          {selectedPackage} Credits will be added to your Credit Balance.
        </h6>
        <button
          type="button"
          className="btn btn-light text-dark w-100"
          onClick={() => {
            setPaymentSuccess(false);
          }}>
          Close
        </button>
      </Modal.Body>
    </Modal>
  );
};

export const PaymentDetailsUpdatedModal = ({ paymentSuccessModal, setPaymentSuccessModal }) => {
  return (
    <Modal
      show={paymentSuccessModal}
      onHide={() => {
        setPaymentSuccessModal(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="mx-auto"
      centered>
      <Modal.Body className="pt-0 w-100 px-3 payment-modal">
        <div className="w-100 d-flex justify-content-center">
          <img src="../images/check.png" className="image-info mx-auto mt-3" />
        </div>
        <h5 className="info-heading mt-3">Payment Details Updated!</h5>
        <h6 className="info-description mb-3">
          Your new payment details will be used to process future credit purchases.
        </h6>
        <button
          type="button"
          className="btn btn-light text-dark w-100"
          data-dismiss="modal"
          data-toggle="modal"
          data-target="#approveSubscriptionModal">
          Close
        </button>
      </Modal.Body>
    </Modal>
  );
};

export const NotEnoughCreditsModal = ({
  notEnoughCredits,
  setNotEnoughtCredits,
  setPurchaseCreditsModal,
  creditBalance,
  upgradePrice
}) => {
  const buyMoreCredits = () => {
    setNotEnoughtCredits(false);
    setPurchaseCreditsModal(true);
  };

  return (
    <Modal
      show={notEnoughCredits}
      onHide={() => {
        setNotEnoughtCredits(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="mx-auto"
      centered>
      <Modal.Body className="pt-0 w-100 px-3 payment-modal">
        <div className="w-100 d-flex justify-content-center">
          <img src="../images/wallet.png" className="image-info mx-auto mt-3" />
        </div>
        <h5 className="info-heading mt-3">Not Enough Credits</h5>
        <h6 className="info-description mb-4">
          You need <b>{upgradePrice - creditBalance}</b> more credits to approve this request.
        </h6>
        <div className="d-flex justify-content-between align-items-center gap-2 my-2">
          <button
            type="button"
            className="btn btn-light text-dark"
            onClick={() => {
              setNotEnoughtCredits(false);
            }}>
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              buyMoreCredits();
            }}>
            Purchase Credits
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const ConfirmPaymentMethodDeletion = ({
  confirmDeleteModal,
  setConfirmDeleteModal,
  callback,
  loading,
  progress
}) => {
  return (
    <Modal
      show={confirmDeleteModal}
      onHide={() => {
        setConfirmDeleteModal(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="mx-auto"
      centered>
      <Modal.Body className="pt-0 w-100 px-3 payment-modal modal-info">
        <div className="w-100 d-flex justify-content-center">
          <img src="../images/delete.png" className="image-info mx-auto mt-3" />
        </div>
        <h5 className="info-heading mt-3">Are you sure you want to delete this card?</h5>
        <h6 className="info-description mb-4">
          {`Your organization will not be able to purchase any 
            more credits until you add a new payment method.`}
        </h6>
        <div className="d-flex justify-content-between align-items-center gap-2 my-2">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              callback();
            }}>
            {loading ? progress() : 'Yes, delete'}
          </button>
          <button
            type="button"
            className="btn btn-light text-dark"
            onClick={() => {
              setConfirmDeleteModal(false);
            }}>
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const SubscriptionUpgradeSuccessModal = ({
  upgradeSuccessful,
  setUpgradeSuccessful,
  selectedUpgradeRequest
}) => {
  return (
    <Modal
      show={upgradeSuccessful}
      onHide={() => {
        setUpgradeSuccessful(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="mx-auto"
      centered>
      <Modal.Body className="pt-0 w-100 px-3 modal-info">
        <div className="w-100 d-flex justify-content-center">
          <img src="../images/check.png" className="image-info mx-auto mt-3" />
        </div>
        <h5 className="info-heading mt-3">Subcription Upgrade Approved!</h5>
        <h6 className="info-description mb-3">
          {selectedUpgradeRequest?.user?.name?.split(' ')[0]} is now on the Growth Plan and can
          enjoy more features from Junity! 🎉
        </h6>
        <button
          type="button"
          className="btn btn-light text-dark w-100"
          onClick={() => {
            setUpgradeSuccessful(false);
          }}>
          Close
        </button>
      </Modal.Body>
    </Modal>
  );
};
