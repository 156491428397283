import React, { useState } from 'react';
import './style.css';
import { Link, useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  return (
    <div className="col-sm-12 bgimage-signup1">
      <div className="row pl-sm-5">
        <div className="card col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-2 mt-sm-5 offset-sm-1 p-2">
          <div className="col-sm-12">
            <img src="../images/logo.png" />
          </div>
          <div className="col-sm-12 mt-4">
            <p className="heading-text text-neutral font-weight-bold mb-0">
              <b>See you next time.</b>
            </p>
          </div>

          <div className="col-sm-12 pt-1">
            <p className="paragraph-text fw-semibold opacity-75 mb-0">
              <b>You have logged out of your Junity account.</b>
            </p>
          </div>

          <div className="col-sm-12 pt-1">
            <hr />
          </div>
          <div
            className="col-sm-12 paragraph-text txtdcoration point"
            onClick={() => navigate('/login')}>
            <p>
              <span className="loginthank">Forgot something? </span>
              <b>
                <span className="text-color-signup"> Log in.</span>
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
