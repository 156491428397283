import React, { useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlinePlusCircle } from 'react-icons/ai';
import NoCreditCard from '../../../../../Assets/images/no-credit-card.png';

export default function CardCrousal({
  paymentMethods,
  getCardLogo,
  onDeletePaymentMethod,
  setAddPaymentModal
}) {
  const [activeCard, setActiveCard] = useState(0);
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 1
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 1
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 1
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1
  //   }
  // };

  return (
    <div className="payment-methods mt-3 bg-white p-3 rounded-3">
      <div className="d-flex justify-content-between align-items-center py-1">
        <h5 className="payment-methods-heading mb-0">PAYMENT METHOD(S)</h5>
        <div className="d-flex align-items-center gap-2">
          <button
            type="button"
            className="btn btn-sm close_btn btn-arrow"
            onClick={() => {
              setActiveCard(!activeCard ? paymentMethods?.length - 1 : activeCard - 1);
            }}>
            <AiOutlineArrowLeft />
          </button>
          <button
            type="button"
            className="btn btn-sm close_btn btn-arrow"
            onClick={() => {
              setActiveCard(activeCard === paymentMethods?.length - 1 ? 0 : activeCard + 1);
            }}>
            <AiOutlineArrowRight />
          </button>
        </div>
      </div>
      <div className="position-relative w-100">
        {paymentMethods &&
          paymentMethods.map((elt, index) => (
            <div
              className={`border rounded-3 mt-2 p-3 top-0 w-100 bg-white filter-card ${
                index !== activeCard ? 'd-none' : ''
              }`}
              key={`card-${index}`}>
              <div className="d-flex justify-content-between align-items-center">
                <span className="payment-card-type">{elt?.card?.brand?.toUpperCase()}</span>
                <span
                  className="subheading mt-3 cursor-pointer text-danger"
                  onClick={() => {
                    onDeletePaymentMethod(elt?.id);
                  }}>
                  <i className="fas fa-trash-alt fa-fw"></i>
                </span>
              </div>
              <div className="d-flex align-items-center gap-2 mt-3">
                <h6 className="mb-0">{getCardLogo(elt?.card?.brand)}</h6>
                <span className="payment-card-number">xxxx xxxx xxxx {elt?.card.last4}</span>
              </div>
              <h6 className="mb-0 mt-2 payment-card-expiry">
                Expires {elt?.card?.exp_month}/{elt?.card?.exp_year}
              </h6>
              <h6 className="mb-0 mt-4 payment-card-address">
                {elt?.billing_details?.address?.line1}
                <br /> {elt?.billing_details?.address?.postal_code},
                {elt?.billing_details?.address?.city} {elt?.billing_details?.address?.state}
              </h6>
            </div>
          ))}
        {!paymentMethods ||
          (!paymentMethods?.length && (
            <div className="border rounded-3 mt-2 p-3 top-0 w-100 bg-white filter-card ">
              <div className="d-flex justify-content-between align-items-center">
                <p className="fs-13 font-weight-700 primary mb-0">Credit Card</p>
                <p
                  className="textColor fs-12 font-weight-700 mb-0 cursor-pointer"
                  onClick={() => setAddPaymentModal(true)}>
                  <AiOutlinePlusCircle size={18} className="mr-2" />
                  Add new card
                </p>
              </div>
              <div className="d-flex justify-content-center mt-5 mb-4">
                <img src={NoCreditCard} alt="No Credit Card" />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
