import { SET_EDUCATOR_DATA, RESET_EDUCATOR_DATA } from '../constants/educatorConstants';

export const setEducatorDataAction = (data) => async (dispatch) => {
  dispatch({
    type: SET_EDUCATOR_DATA,
    payload: data
  });
};

export const resetEducatorDataAction = () => async (dispatch) => {
  dispatch({
    type: RESET_EDUCATOR_DATA
  });
};
