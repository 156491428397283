import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Mygroupmodal from './Mygroupmodal';
import Defaultgroupmodal from './Defaultgroupmodal';
import { useSelector } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function Tabswitchopp({ opportunityID, setModalShow }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const user = useSelector((store) => store.educatorUser);
  const userData = user?.data;

  return (
    <div className="col-sm-12 background_tab p-0">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab
              className={`tab_style ${value === 0 ? 'active' : ''}`}
              label="My Groups"
              {...a11yProps(0)}
            />
            <Tab
              className={`tab_style ${value === 1 ? 'active' : ''}`}
              label="Default Groups"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className="col-sm-12 p-0  ">
            <Mygroupmodal setModalShow={setModalShow} user={userData} opportunityID={opportunityID} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="col-sm-12 p-0  ">
            <Defaultgroupmodal setModalShow={setModalShow} user={userData} opportunityID={opportunityID} />
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}
