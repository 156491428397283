import React, { useState } from 'react';
import { useCollapse } from 'react-collapsed';
import { BsChevronDown, BsChevronUp, BsBoxArrowUpRight } from 'react-icons/bs';
import { getSchoolState } from '../../../../utils/helpers';

function Collapsemygroups({ data }) {
  const [isExpanded, setExpanded] = useState(true);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  return (
    <div className="col-sm-12 p-0">
      <div
        className="row mt-3"
        {...getToggleProps({
          onClick: () => setExpanded((prevExpanded) => !prevExpanded)
        })}>
        <div className="col-sm-10">
          <div className="opportunity-detail-accordion-title">OPPORTUNITIES DETAILS</div>
        </div>
        <div className="col-sm-2 justify-end">
          {isExpanded ? <BsChevronUp /> : <BsChevronDown />}
        </div>
      </div>
      <div className="col-sm-12 p-0 mt-2">
        <section {...getCollapseProps()}>
          <div className="col-sm-12 pl-0">
            <p className="opportunity-detail-accordion-desc mb-1">DESCRIPTION</p>
            <p className="opportunity-detail-accordion-text">{data?.description}</p>
          </div>
          <div className="col-sm-12 mt-3 mb-3 pb-3 p-0 add_border">
            <h6 className="opportunity-detail-tags-intro">
              <b>TAGS</b>
            </h6>
            <div className="col-sm-12 mt-1 p-0">
              <button className="background_allopp_btn category mr-1 btn btn-outline-light active">
                {data?.category}
              </button>
              {data?.interests?.map((elt, index) =>
              <button key={index} className="mr-1 background_allopp_btn btn btn-outline-light active">
                {elt}
              </button>
              )}
            </div>
          </div>
          <div className="col-sm-12 p-0 pb-2 add_border">
            <h6 className="opportunity-detail-applications-from">
              <b>RECEIVING APPLICATIONS FROM</b>
            </h6>
            <h6 className="opportunity-detail-applications-state">
              <b>{data?.state?.join(", ")}</b>
            </h6>
          </div>
          <div className="col-sm-12 p-0 mt-3">
            <h6 className="opportunity-detail-postedby">
              <b>POSTED BY</b>
            </h6>
            <div className="col-sm-12 p-0">
              <div className="row">
                <div className="col-sm-8">
                  <div className="justify-start">
                    <div className="pr-0 mr-2">
                      <img src="../images/dicon.png" className="img-fluid" />
                    </div>
                    <div className="pt-1">
                      <h6 className="m-0 opportunity-detail-owner">
                        <b>{data?.resource?.school?.name}</b>
                      </h6>
                      <h6 className="m-0 opportunity-detail-state">
                        <b>{getSchoolState(data?.resource?.school?.full_address)}</b>
                      </h6>
                      <h6 className="" style={{ color: '#515A6A', fontSize: 13 }}>
                        <b>
                          {/* <span className="mr-1" style={{ color: '#FFC768' }}>
                            {' '}
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </span>
                          (5 Reviews) */}
                        </b>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 p-0">
                  <button className="opportunity-detail-btn-profile">
                    <span>View Profile</span>
                    <BsBoxArrowUpRight className="ml-1" size={16} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="col-sm-12 pl-0 pr-0"></div>
    </div>
  );
}

export default Collapsemygroups;
