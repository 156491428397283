import { useNavigate } from 'react-router-dom';
import InitialsWrapper from '../../../../Widgets/InitialsWrapper/InitialsWrapper';
import { formatMessageTime, getInitial, getRandomColorById } from '../../../../utils/helpers';

const YouthChats = ({ chat, user, chats, setCurrentItem }) => {
  const navigate = useNavigate();
  const customObj = JSON.parse(chat?.custom_json || '{}');

  // const isOnline = chat?.people?.some(
  //   (elt) => elt?.person?.username === user?.id && elt?.person?.is_online === true
  // );

  const viewChat = (id, type) => {
    setCurrentItem(chats.find((item) => item.id === id));
    navigate(`/chat?id=${id}&type=${type}&name=${customObj?.senderName}`);
  };

  return (
    <div className="user-view-item bg-white border-top">
      <div
        className="d-flex align-items-center justify-content-between"
        onClick={() => viewChat(chat?.id, 'youth')}>
        <div className="d-flex gap-2">
          <div className="org-img position-relative">
            <InitialsWrapper
              type="medium"
              text={getInitial(customObj?.senderName)}
              bg={getRandomColorById(customObj?.creatorID)}
            />
            {/*<div className={`${isOnline ? 'online' : 'offline'}-indicator`}></div>*/}
          </div>
          <div className="user-des d-flex flex-column">
            <div className="d-flex gap-0">
              <span className="user-name white-space-nowrap">{customObj?.senderName}</span>
              {user?.is_verified && (
                <i id="icon_style" className="fas fa-check-circle ml-1 youth-detail"></i>
              )}
            </div>
            <span className="short-msg white-space-nowrap">{chat?.last_message?.text}</span>
          </div>
        </div>
        <div className="d-flex flex-column align-items-end">
          <div className="last-msg-time">{formatMessageTime(chat?.created)}</div>
          <div className="user-action justify-content-end gap-2">
            {/* <div className="mute">
                  <img src={Mute} alt="" />
              </div> */}
            {/* <div class="dropdown menu" style={{marginTop: '-6px'}}>
                <i className="fas fa-ellipsis-h text-secondary"  id={`chat-${elt?.id}`} data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false"></i>
                <div class="dropdown-menu dropdown-menu-left" aria-labelledby={`chat-${elt?.id}`}>
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item disabled" href="#">Disabled action</a>
                  <h6 class="dropdown-header">Section header</h6>
                  <a class="dropdown-item" href="#">Action</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">After divider action</a>
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YouthChats;
