import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { AllStudents } from '../../../../../Services/query/students';
import { getRandomColorById } from '../../../../../utils/helpers';
import { UPDATE_MYGROUPS } from '../../../../../Services/mutation/myGroups';
import { GetGroup } from '../../../../../Services/query/myGroups';
import { Modal } from 'react-bootstrap';
import InitialWrapper from '../../../../../Components/common/InitialWrapper';

function MyVerticallyCenteredModal(props) {
  const user = useSelector((store) => store.educatorUser.data);
  const [state, setState] = useState({});
  const [valid, setValid] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [myGroupData, setMyGroupData] = useState({});
  const [updateGroup] = useMutation(UPDATE_MYGROUPS);
  const [students, setStudents] = useState([]);
  const [colors, setColors] = useState({});

  const handleChange = (e) => {
    const obj = { ...state };
    if (Object.keys(obj || {})?.includes(e.target.name)) {
      delete obj[e.target.name];
    } else {
      obj[e.target.name] = true;
    }

    setState(obj);
  };

  const updateGroupInfo = () => {
    const inputData = {
      id: props.id,
      studentIDs: Object.keys(state),
      updatedAt: new Date()
    };
    updateGroup({
      variables: { ...inputData }
    })
      .then(() => {
        setState({});
        props.onHide();
        props.setRefetch((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setValid(Object.keys(state || {})?.length > 0);
  }, [state]);

  useEffect(() => {
    const obj = {};
    myGroupData?.studentIDs?.forEach((id) => (obj[id] = true));
    setState(obj);
  }, [myGroupData]);

  useEffect(() => {
    const filtered = students.filter((elt) => elt?.name?.includes(searchFilter));
    setFilteredStudents(filtered);
  }, [searchFilter]);

  useEffect(() => {
    AllStudents().then((students) => {
      const filteredStudents = students?.filter((elt) =>
        user?.school?.connections?.includes(elt.id)
      );
      setStudents(filteredStudents);
      const colors = filteredStudents.reduce((acc, student) => {
        return { ...acc, [student.id]: getRandomColorById(student.id) };
      }, {});
      setColors(colors);
      setFilteredStudents(filteredStudents);
    });
    GetGroup(props.id).then((group) => {
      setMyGroupData(group);
    });
  }, []);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal_body_style"
      centered>
      <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
        <Modal.Title>Manage Youths</Modal.Title>
        <button type="button" className="btn close mr-0" onClick={props.onHide}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body className="pt-0 w-100 px-3">
        <h6 style={{ fontSize: '14px', color: '#2E394C' }}>
          Every youth added to this group gets notified when you make a recommendation.
        </h6>
        <h6
          className="my-3"
          style={{
            fontSize: '12px',
            fontWeight: 'bold',
            color: '#2E394C'
          }}>
          {myGroupData?.studentIDs?.length} of {students?.length} youth selected
        </h6>
        <div className={`input-group border bg-white rounded-3 p-0 mb-3`}>
          <span className="input-group-text bg-white">
            <i className="fas fa-search text-gray"></i>
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Find a Youth"
            value={searchFilter}
            onChange={(e) => {
              setSearchFilter(e.target.value);
            }}
          />
        </div>
        {filteredStudents?.map((elt, index) => (
          <div className="form-check pl-0 mb-3" key={index}>
            <input
              type="checkbox"
              className="form-check-input"
              id={elt?.id}
              checked={state[elt?.id]}
              onChange={handleChange}
              name={elt?.id}
            />
            <label className="form-check-label d-flex align-items-center">
              <InitialWrapper name={elt?.name} cls={'ml-2'} color={colors[elt?.id]} />
              <span className="table-body-text ml-2">{elt?.name}</span>
              <i className="fas fa-check-circle ml-1 icon-check-primary"></i>
            </label>
          </div>
        ))}
        <div className="form-group">
          <button
            type="button"
            className="btn btn-success"
            style={{ width: '100%', opacity: valid ? 1 : 0.6 }}
            onClick={updateGroupInfo}>
            Add Youth
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default function ManageStudentsModal(props) {
  const { modalShow, setModalShow, setRefetch, id } = { ...props };

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setRefetch={setRefetch}
        id={id}
      />
    </>
  );
}
