import React, { useState } from 'react';
import './style.css';
import { Link, useNavigate } from 'react-router-dom';
import Collapseone from './Collapseone';
import Collapsetwo from './Collapsetwo';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Contactform = () => {
  return (
    <div className="col-sm-12 bgimage-signup">
      <div className="row">
        <div className="col-sm-6 bgcolorsignup4">
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-8 bgcolorsignup p-2">
              <div className="col-sm-12">
                <img src="../images/logo.png" />
              </div>

              <div className="col-sm-12 pt-1">
                <p className="paragraph-text mb-0">
                  <b>
                    Junity puts your students first by placing them at the center of a collection of
                    local scholarship, college prep, and employment opportunities.
                  </b>
                </p>
              </div>
              <div className="col-sm-12">
                <hr />
              </div>

              {/* collapse one  */}
              <Collapseone />
              <div className="col-sm-12 mb-2">
                <p className="mb-0 collapseone-style">j.williams@bulkeley.org</p>
                <p className="mb-0 collapseone-style">Guidance Counselor</p>
                <p className="mb-0 collapseone-style">(406) 555-0120</p>
              </div>

              {/* collapse one  */}
              <Collapsetwo />

              <div className="col-sm-12 m-0 p-0">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="I agree to Junity’s Terms and Conditions and Privacy Policy."
                    className="m-0"
                  />
                </FormGroup>
              </div>
              <div className="col-sm-12 p-0 mt-2">
                <div className="col-sm-12 p-0">
                  <Link to="/signup-thankyou">
                    <button className="btn btn-light buttoncolorsignup1 pt-2 pb-2">
                      Get Started
                    </button>
                  </Link>
                </div>
              </div>

              <div className="col-sm-12 pt-1">
                <hr />
              </div>
              <div className="col-sm-12 paragraph-text">
                <p>
                  <b>
                    Already have an account? <span className="text-color-signup">Log In</span>
                  </b>
                </p>
              </div>
            </div>
            <div className="col-sm-2"></div>
          </div>
        </div>
        <div className="col-sm-6"></div>
      </div>
    </div>
  );
};

export default Contactform;
