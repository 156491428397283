import { gql } from '@apollo/client';
import { API } from 'aws-amplify';
import { GetSingleSchool } from './school';

export const GET_EDUCATORS = gql`
  query EducatorsQuery {
    listEducators(limit: 1000) {
      items {
        id
        bio
        createdAt
        dob
        address
        image
        personality
        email
        gender
        name
        personality
        role
        isAdmin
        phone
        schoolID
        schoolid
        twoFA
        state
        updatedAt
        zipcode
        is_verified
        stripeID
        hubspotID
        credits
        subscription
        verification_progress
        verification_status
        verification_date_started
        verification_progress_dates
        deactivation_reason
        deactivation_detailed_reason
        deactivation_period
        date_requested
        deactivated
        date_deactivated
      }

      nextToken
    }
  }
`;

export const GET_EDUCATOR_BY_EMAIL = gql`
  query SingleEducator($email: String) {
    listEducators(limit: 1000, filter: { email: { eq: $email } }) {
      items {
        id
        bio
        createdAt
        dob
        address
        image
        personality
        email
        gender
        name
        twoFA
        personality
        role
        isAdmin
        phone
        schoolID
        schoolid
        state
        updatedAt
        zipcode
        is_verified
        stripeID
        credits
        subscription
        verification_progress
        verification_status
        verification_date_started
        verification_progress_dates
        deactivation_reason
        deactivation_detailed_reason
        deactivation_period
        date_requested
        deactivated
        date_deactivated
      }
    }
  }
`;

export const GET_EDUCATOR_BY_EMAIL_WITH_TOKEN = gql`
  query SingleEducator($limit: Int, $nextToken: String, $email: String) {
    listEducators(limit: $limit, nextToken: $nextToken, filter: { email: { eq: $email } }) {
      items {
        id
        bio
        createdAt
        dob
        personality
        address
        image
        email
        gender
        name
        personality
        role
        twoFA
        isAdmin
        phone
        schoolID
        schoolid
        state
        updatedAt
        zipcode
        is_verified
        stripeID
        hubspotID
        credits
        subscription
        verification_progress
        verification_status
        verification_date_started
        verification_progress_dates
        deactivation_reason
        deactivation_detailed_reason
        deactivation_period
        date_requested
        deactivated
        date_deactivated
      }
    }
  }
`;

export const GET_EDUCATOR = gql`
  query SingleEducator($id: ID!) {
    getEducator(id: $id) {
      id
      bio
      createdAt
      dob
      address
      email
      gender
      name
      image
      personality
      role
      personality
      isAdmin
      phone
      schoolID
      schoolid
      state
      updatedAt
      zipcode
      is_verified
      stripeID
      hubspotID
      credits
      twoFA
      subscription
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      deactivated
      date_deactivated
    }
  }
`;

export async function GetEducator(id) {
  try {
    const resp = await API.graphql({
      query: GET_EDUCATOR,
      variables: { id }
    });

    const educator = resp?.data?.getEducator;
    const school = await GetSingleSchool(educator.schoolID);
    const obj = { ...educator, school };
    return obj || {};
  } catch (err) {
    console.error(err);
    return {};
  }
}

export const GET_EDUCATORS_WITH_TOKEN = gql`
  query EducatorsQuery($limit: Int, $nextToken: String) {
    listEducators(limit: $limit, nextToken: $nextToken) {
      items {
        id
        bio
        createdAt
        dob
        address
        email
        personality
        gender
        name
        personality
        role
        isAdmin
        twoFA
        phone
        schoolID
        schoolid
        state
        updatedAt
        image
        zipcode
        is_verified
        stripeID
        hubspotID
        credits
        subscription
        verification_progress
        verification_status
        verification_date_started
        verification_progress_dates
        deactivation_reason
        deactivation_detailed_reason
        deactivation_period
        date_requested
        deactivated
        date_deactivated
      }
      nextToken
    }
  }
`;

export async function AllEducators() {
  const resp = await API.graphql({
    query: GET_EDUCATORS
  });

  const data = resp?.data?.listEducators;
  let nextToken = data?.nextToken;
  let educators = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_EDUCATORS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listEducators;

    const previousData = [...educators, ...respData?.items];
    educators = [...previousData];

    nextToken = respData?.nextToken;
  }

  return await educators?.reduce(async (previousPromise, currentEducator) => {
    const school = await GetSingleSchool(currentEducator.schoolID);
    let educatorData = await previousPromise;
    const obj = { ...currentEducator, school };
    educatorData.push(obj);
    return educatorData;
  }, Promise.resolve([]));
}

export async function GetEducatorsBySchoolID(id) {
  const educators = await AllEducators();
  const filteredEducators = educators?.filter((elt) => elt.schoolID === id);
  return filteredEducators || [];
}

export async function GetEducatorsByEmail(email) {
  try {
    const resp = await API.graphql({
      query: GET_EDUCATOR_BY_EMAIL,
      variables: { email },
      cache: true
    });

    const data = resp?.data?.listEducators;
    let nextToken = data?.nextToken;
    let educators = data?.items;

    while (nextToken) {
      const resp = await API.graphql({
        query: GET_EDUCATORS_WITH_TOKEN,
        variables: { limit: 1000, nextToken, email }
      });
      const respData = resp?.data?.listEducators;

      const previousData = [...educators, ...respData?.items];
      educators = [...previousData];

      nextToken = respData?.nextToken;
    }

    const educator = educators.length > 0 ? educators[0] : {};
    if (Object.keys(educator).length > 0) {
      const school = await GetSingleSchool(educator.schoolID);
      return { ...educator, school };
    }

    return {};
  } catch (err) {
    console.error(err);
    return {};
  }
}
