import React, { useState } from 'react';
import '../Activity/Activity.css';
import { BsExclamationCircle } from 'react-icons/bs';

// const ApplicationActivity = ({ name, owner, ownerImg, status }) => {
//   return (
//     <>
//       <div className="d-flex justify-content-between align-items-center border rounded-3 p-2">
//         <div>
//           <h6 className="activity-card-header">{name}</h6>
//           <div className="activity-card-body d-flex align-items-center gap-2">
//             <img src={ownerImg} className="img-fluid activity-card-image" />
//             <span className="activity-card-text">{owner}</span>
//           </div>
//         </div>
//         <button className={`btn rounded-pill activity-card-btn activity-card-btn-${status}`}>
//           Accepted
//         </button>
//       </div>
//     </>
//   );
// };

// const UpdateActivity = ({ update }) => {
//   return (
//     <>
//       <div className="d-flex align-items-center">
//         {Array.isArray(update) &&
//           update.map((interest, index) => (
//             <button type="button" key={index} className="btn activity-card-btn mr-2">
//               {interest}
//             </button>
//           ))}
//         {!Array.isArray(update) && <h6>{update}</h6>}
//       </div>
//     </>
//   );
// };

// const ActivityItem = (props) => {
//   const { type, title, date } = props;
//
//   return (
//     <>
//       <div className="activity border-left position-relative pl-3">
//         <h6 className="activity-header">{title}</h6>
//         {type === 'application' ? (
//           <ApplicationActivity {...props} />
//         ) : (
//           <UpdateActivity {...props} />
//         )}
//         <h6 className="activity-date mb-2">{date}</h6>
//         <i className="fas fa-circle activity-timeline-dot"></i>
//       </div>
//     </>
//   );
// };

function Activity() {
  const [filter, setFilter] = useState('all');
  return (
    <div className="mt-2">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <div className="tab-heading mr-1">Activity</div>
          <BsExclamationCircle size={17} />
        </div>
        <div className="d-flex">
          <label className="filter-badge-radio mb-0 mr-1">
            <input
              type={'checkbox'}
              name={'All'}
              className="d-none"
              checked={filter === 'all'}
              onClick={() => setFilter('all')}
            />
            <span className="btn btn-outline-light background_allopp_btn ">All</span>
          </label>
          <label className="filter-badge-radio mb-0 mr-1">
            <input
              type={'checkbox'}
              name={'Updates'}
              className="d-none"
              checked={filter === 'updates'}
              onClick={() => setFilter('updates')}
            />
            <span className="btn btn-outline-light background_allopp_btn ">Updates</span>
          </label>
          <label className="filter-badge-radio mb-0 mr-1">
            <input
              type={'checkbox'}
              name={'Applications'}
              className="d-none"
              checked={filter === 'apps'}
              onClick={() => setFilter('apps')}
            />
            <span className="btn btn-outline-light background_allopp_btn ">Applications</span>
          </label>
          <label className="filter-badge-radio mb-0 mr-1">
            <input
              type={'checkbox'}
              name={'Connections'}
              className="d-none"
              checked={filter === 'connections'}
              onClick={() => setFilter('connections')}
            />
            <span className="btn btn-outline-light background_allopp_btn ">Connections</span>
          </label>
        </div>
      </div>
      {/* <ActivityItem
        type={'application'}
        title={'Bryce’s application got accepted! 🎉'}
        date={'Sep 6, 2021 2:14pm'}
        name={'Microsoft Co-op Internship (Fall 2021)'}
        owner={'Microsoft Inc. (Hartford, CT)'}
        ownerImg={'../images/micro.png'}
        status={'accepted'}
      />
      <ActivityItem
        type={'update'}
        title={'Bryce’s application got accepted! 🎉'}
        date={'Sep 6, 2021 2:14pm'}
        update={['Entrepreneurship', 'Web App & Design', 'Data Analysis']}
      />
      <ActivityItem
        type={'application'}
        title={'Bryce’s application got accepted! 🎉'}
        date={'Sep 6, 2021 2:14pm'}
        name={'Microsoft Co-op Internship (Fall 2021)'}
        owner={'Microsoft Inc. (Hartford, CT)'}
        ownerImg={'../images/micro.png'}
        status={'pending'}
      /> */}
    </div>
  );
}

export default Activity;
