import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import Table from 'react-bootstrap/Table';
import DataTable from 'react-data-table-component';

const CreditTable = ({ payments }) => {
  const columns = [
    {
      name: 'Date',
      selector: (row) => row.createdAt,
      format: (row) => new Date(row?.createdAt).toDateString().slice(4),
      sortable: true
    },
    {
      name: 'Description',
      selector: (row) => row.description
    },
    {
      name: 'Credits',
      selector: (row) => row.credits,
      sortable: true,
      cell: (row) => (
        <div className={`text-right credit-${row?.type === 'credit' ? 'success' : 'danger'}`}>
          {`${row?.type === 'credit' ? '+' : '-'}${row?.credits}`} Credits
        </div>
      )
    },
    {
      name: 'Amount',
      selector: (row) => row.amount,
      sortable: true,
      format: (row) => `$${(row.amount / 100).toFixed(2)}`
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      cell: (row) => <div className="btn-status white-space-nowrap">{row?.status}</div>
    }
  ];

  return (
    <DataTable
      columns={columns}
      data={payments}
      defaultSortFieldId={1}
      defaultSortAsc={false}
      pagination
      paginationPerPage={8}
      paginationRowsPerPageOptions={[8, 10, 15, 20, 25, 30]}
    />
  );
};

export default CreditTable;
