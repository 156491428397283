import { SET_INSTITUTION_DATA, RESET_INSTITUTION_DATA } from '../constants/institutionConstants';

const initialLoginState = {
  data: {}
};

const institutionReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case SET_INSTITUTION_DATA:
      return { ...state, data: action.payload };
    case RESET_INSTITUTION_DATA:
      state = initialLoginState;
      return state;
    default:
      return state;
  }
};

export default institutionReducer;
