import React, { useState, useEffect } from 'react';
import TableAccount from './TableAccount/TableAccount';
import './style.css';
import SubscriptionModal from './ModalSubscription/SubscriptionModal';
import AlertToast from '../../../../Components/common/Alerts/ToastAlert';
import { useMutation } from '@apollo/client';
import { CREATE_UPGRADE_REQUEST } from '../../../../Services/mutation/subscription';
import { useDispatch, useSelector } from 'react-redux';
import { GetUserUpgradeRequest } from '../../../../Services/query/subscription';
import { SUBSCRIPTIONS } from '../../../../utils/data';
import { UPDATE_EDUCATOR } from '../../../../Services/mutation/educator';
import { CREATE_PAYMENT } from '../../../../Services/mutation/payment';
import { setUserDataAction } from '../../../../Redux/actions/userAction';
import { GetEducator } from '../../../../Services/query/educator';

const SubscriptionAccount = ({ data }) => {
  const dispatch = useDispatch();
  const [updateEducator] = useMutation(UPDATE_EDUCATOR);
  const [createPayment] = useMutation(CREATE_PAYMENT);
  const [createUpgradeRequest] = useMutation(CREATE_UPGRADE_REQUEST);
  const [user, setUser] = useState(useSelector((store) => store.educatorUser.data));
  const [creditBalance, setCreditBalance] = useState(user?.credits?.toFixed(2) || 0.0);
  const [currentSubscription, setCurrentSubscription] = useState(SUBSCRIPTIONS[user?.subscription]);
  const [modalShow, setModalShow] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [upgradeRequests, setUpgradeRequests] = useState([]);
  const [isUpgraded, setIsUpgraded] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastVariant, setToastVariant] = useState('success');
  const [toastIcon, setToastIcon] = useState('check');
  const [toastInfo, setToastInfo] = useState('');
  const [refetch, setRefetch] = useState(false);
  const [upgradePrice, setUpgradePrice] = useState(70);

  const showToast = (variant, message) => {
    setToastVariant(variant);
    setToastIcon(variant === 'success' ? 'check' : 'delete');
    setToastInfo(message);
    setToastActive(true);
  };

  const savePayment = async (amount, description, credits, type = 'credit', metaData = {}) => {
    try {
      const result = await createPayment({
        variables: {
          roleID: user?.id,
          roleType: 'mentor',
          type: type,
          amount,
          description,
          credits,
          status: 'succeeded',
          metaData: JSON.stringify(metaData),
          createdAt: new Date(),
          updatedAt: new Date()
        }
      });

      return result?.data?.createPayment;
    } catch (error) {
      console.log(error);
    }
  };

  const approveUpgrade = async (upgradeId) => {
    setLoading(true);
    try {
      const res = await updateEducator({
        variables: {
          id: user?.id,
          subscription: 'growth',
          credits: creditBalance - upgradePrice
        }
      });

      const data = await GetEducator(user?.id);
      console.log(data)
      setUser({...user, ...data});
      dispatch(setUserDataAction(data));

      setCreditBalance(creditBalance - upgradePrice);
      const newPayment = await savePayment(
        0,
        `Subscription_${user?.name?.split(' ')[0]}`,
        upgradePrice,
        'debit',
        { subscriptionUpgradeRedquestID: upgradeId }
      );

      setLoading(false);
      showToast('success', 'Upgrade to growth plan successful');
    } catch (error) {
      setLoading(false);
      console.log(error);
      showToast('error', 'Upgrade unsuccessful');
    }
  };

  const requestSubscriptionUpgrade = async (cost, name) => {
    if (user?.isAdmin && user?.credits && user?.credits < upgradePrice) {
      showToast('error', 'Not enough credits for upgrade');
      return
    }
    try {
      const response = await createUpgradeRequest({
        variables: {
          name,
          cost,
          plan: 'Premium',
          organizationID: user?.schoolID,
          roleID: user?.id,
          roleType: 'mentor',
          status: user?.isAdmin ? true : false,
          approval: user?.isAdmin ? 'approved' : 'Pending',
          createdAt: new Date(),
          updatedAt: new Date()
        }
      });

      const request = response?.data?.createSubscriptionUpgradeRequest

      if (!user?.isAdmin){
        showToast('success', 'Upgrade request sent successfully');
      }
      else {
        await approveUpgrade(request?.id)
      }
      
      setLoading(false);
      setRefetch((prev) => !prev);
      setToggleModal(true);
    } catch (error) {
      console.log(error);
      showToast('error', error?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetUserUpgradeRequest(user?.id);
        setUpgradeRequests(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (user && Object.keys(user)?.length && user?.subscription) {
      if (user?.subscription === 'growth') setIsUpgraded(true);
    }
    if (user && Object.keys(user)?.length && user?.stripeID) {
      fetchData();
    }
  }, [user, refetch]);

  const FreePlanComponent = () => {
    return (
      <>
        <div className="pt-2">
          <button className="btn btn-light rounded-circle">
            <i className="fas fa-dot-circle"></i>
          </button>
        </div>
        <div className="pt-3">
          <h6>
            <b>Free Plan</b>
          </h6>
          <h6 style={{ color: '#969CA5' }}>Great for starters</h6>
        </div>
        <div className="pt-3">
          <h6>
            <b>{SUBSCRIPTIONS?.free?.ALLOWED_NOTES_LIMIT} Notes</b>
            <span style={{ color: '#969CA5' }}>/student</span>
          </h6>
          <h6 style={{ color: 'black' }}>
            {SUBSCRIPTIONS?.free?.ALLOWED_GROUPS_LIMIT} custom group
          </h6>
        </div>
        <div className="pt-3">
          <h6>
            <b>Free</b>
            <span style={{ color: '#969CA5' }}>/month</span>
          </h6>
        </div>
        <div className="p-0 pt-2">
          <div className="btn_light rounded-3">Active</div>
        </div>
        <div className="pt-3">
          <h6 style={{ fontSize: '120%' }}>
            <span style={{ color: '#226E54' }}>
              {' '}
              <i className="fas fa-check"></i>{' '}
            </span>{' '}
            Make recommendations
          </h6>
          <h6 style={{ fontSize: '120%' }}>
            <span style={{ color: '#226E54' }}>
              {' '}
              <i className="fas fa-check"></i>{' '}
            </span>{' '}
            Chat with students & resources
          </h6>
        </div>
      </>
    );
  };

  const GrowthPlanComponent = ({ isUpgraded, hasRequested, setModalShow }) => {
    return (
      <div
        className="col-sm-12 pb-4"
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          paddingTop: '6%',
          paddingBottom: '0.5%'
        }}>
        <div>{isUpgraded ? null : <h6 style={{ color: '#969CA5' }}>OTHER PLAN</h6>}</div>

        <div className="pt-2">
          <button className="btn btn-light rounded-circle">
            <i className="far fa-rocket text-success"></i>
          </button>
        </div>
        <div className="pt-3">
          <h6>
            <b>Growth</b>
          </h6>
          <h6 style={{ color: '#969CA5' }}>Supports better engagement</h6>
        </div>
        <div className="pt-3">
          <h6>
            <b>{SUBSCRIPTIONS?.growth?.ALLOWED_NOTES_LIMIT} Notes</b>
            <span style={{ color: '#969CA5' }}>/student</span>
          </h6>
          <h6 style={{ color: 'black' }}>
            {SUBSCRIPTIONS?.growth?.ALLOWED_GROUPS_LIMIT} custom group
          </h6>
        </div>
        {isUpgraded ? null : (
          <div className="pt-3">
            <h6>
              <b>{SUBSCRIPTIONS?.growth?.PRICE} Credits</b>
              <span style={{ color: '#969CA5' }}>/month</span>
            </h6>
          </div>
        )}
        <div className="pt-2">
          {isUpgraded ? (
            <div className="btn_light rounded-3">Active</div>
          ) : (
            <button
              className={`btn btn-success ${
                (upgradeRequests.filter((elt) => elt.name === 'Growth').length > 0 || loading) ? 'inactive' : ''
              }`}
              style={{ width: '100%' }}
              onClick={() => requestSubscriptionUpgrade(SUBSCRIPTIONS?.growth?.PRICE, 'Growth')}>
              <b> {loading ? <i className='fas fa-spinner fa-spin'></i> : 'Upgrade Plan'} </b>
            </button>
          )}
        </div>
        <div className="pt-3">
          <h6 style={{ fontSize: '120%' }}>
            <span style={{ color: '#226E54' }}>
              {' '}
              <i className="fas fa-check"></i>{' '}
            </span>{' '}
            Make recommendations
          </h6>
          <h6 style={{ fontSize: '120%' }}>
            <span style={{ color: '#226E54' }}>
              {' '}
              <i className="fas fa-check"></i>{' '}
            </span>{' '}
            Chat with students & resources
          </h6>
        </div>
      </div>
    );
  };

  const currentPlan = () => {
    const subscriptions = {
      growth: (
        <GrowthPlanComponent
          {...{
            isUpgraded,
            hasRequested: upgradeRequests.filter((elt) => elt.name === 'Growth').length > 0,
            setModalShow
          }}
        />
      ),
      free: <FreePlanComponent />
    };

    return Object.keys(subscriptions).includes(user?.subscription)
      ? subscriptions[user?.subscription]
      : subscriptions.free;
  };

  return (
    <>
      <div className="col-sm-12 pt-3" style={{ borderRadius: '10px' }}>
        <div className="row">
          <div className={isUpgraded ? 'col-12' : 'col-sm-9'}>
            <div
              className="col-sm-12 pt-3"
              style={{ backgroundColor: 'white', borderRadius: '10px' }}>
              <div className="row">
                <div className="col-sm-4 mb-2" style={{ borderRight: '1px solid lightgray' }}>
                  <div className={isUpgraded ? '' : 'col-sm-12'}>
                    <h6 style={{ color: '#969CA5' }}>CURRENT PLAN</h6>
                  </div>
                  <>{currentPlan()}</>
                </div>
                <div className="col-sm-8">
                  <div className="col-sm-12">
                    <h6 style={{ color: '#969CA5' }}>SUBSCRIPTION HISTORY</h6>
                  </div>
                  <div className="col-sm-12 p-0 pt-3 pb-3">
                    <TableAccount data={upgradeRequests} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isUpgraded ? null : (
            <div className="col-sm-3 ">
              <GrowthPlanComponent
                {...{
                  isUpgraded,
                  hasRequested: upgradeRequests.filter((elt) => elt.name === 'Growth').length > 0,
                  setModalShow
                }}
              />
            </div>
          )}
        </div>
      </div>
      <SubscriptionModal
        {...{
          modalShow,
          setModalShow,
          setToggleModal,
          toggleModal,
          requestSubscriptionUpgrade,
          loading
        }}
      />
      <AlertToast
        variant={toastVariant}
        icon={toastIcon}
        active={toastActive}
        setActive={setToastActive}
        info={toastInfo}
      />
    </>
  );
};

export default SubscriptionAccount;
