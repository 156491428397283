import React from 'react';
import Table from 'react-bootstrap/Table';
import { formatDate } from '../../../../utils/helpers';
import './styles.css';

const TableInstitution = ({ invites, setRoleModal, setMember }) => {
  const showRoleModal = (elt) => {
    setMember(elt);
    setRoleModal(true);
  };

  return (
    <div className="col-sm-12 p-0">
      <Table className="overflow-x-auto">
        <tbody>
          {invites?.map((elt, index) => (
            <tr key={index}>
              <td>
                <div className="d-flex align-items-center gap-2">
                  {elt?.image ? (
                    <img src={elt?.image} className="thumbnail-sm rounded-circle" alt="thumbnail" />
                  ) : (
                    <div className="thumbnail-placeholder">
                      <i className="far fa-user"></i>
                    </div>
                  )}
                  <div className="d-flex flex-column">
                    <span className="table-user-name">{elt?.name}</span>
                    <span className="table-user-email">{elt?.email || elt?.contact_email}</span>
                  </div>
                </div>
              </td>
              <td className="vertical-align-middle">
                <span className="table-user-invited">
                  Invited {formatDate(elt?.invitation_date)}
                </span>
              </td>
              <td className="vertical-align-middle">
                <span className="table-user-status">{elt?.invitation_status}</span>
              </td>
              <td className="vertical-align-middle" style={{ color: '#969CA5' }}>
                <div className="dropdown">
                  <i
                    className="fas fa-ellipsis-h text-secondary cursor-pointer"
                    id="triggerId1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"></i>
                  <div className="dropdown-menu dropdown-menu-left" aria-labelledby="triggerId1">
                    <a className="dropdown-item text-danger" href="#">
                      <i className="far fa-times-circle me-2"></i>
                      {'Remove'}
                    </a>
                    <div
                      className={`dropdown-item text-neutral ${elt?.id ? '' : 'inactive'}`}
                      onClick={() => {
                        showRoleModal(elt);
                      }}>
                      <i className="far fa-pencil me-2"></i>
                      {'Change Role'}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ))}
          <tr></tr>
        </tbody>
      </Table>
    </div>
  );
};

export default TableInstitution;
