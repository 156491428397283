import React, { useState, useEffect } from 'react';
import { AllMyGroups, GetGroupsByRoleID } from '../../../../Services/query/myGroups';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_RECOMMENDATION } from '../../../../Services/mutation/recommendations';
import {
  GetAllRecommendedOppsByOppIDAndGroupID,
  GET_RECOMMENDED_OPPORTUNITIES
} from '../../../../Services/query/recommendation';
import { ClipLoader } from 'react-spinners';
import AlertToast from '../../../../Components/common/Alerts/ToastAlert';

function Mygroupmodal({ user, opportunityID }) {
  const [active, setActive] = useState([]);
  const [filterMyGroups, setFilterMyGroups] = useState([]);
  const [activeToast, setActiveToast] = useState(false);
  const [toast, setToast] = useState('');
  // const [groupID, setGroupID] = useState([])
  const [createRecommendation] = useMutation(CREATE_RECOMMENDATION);
  const [loading, setLoading] = useState(false);
  const progress = () => {
    return <ClipLoader size={10} color={loading ? '#fff' : ''} />;
  };
  const setIds = (elt) => {
    if (active?.includes(elt?.id)) {
      const filterActive = active?.filter((id) => id !== elt?.id);
      setActive(filterActive);
    } else {
      setActive([...active, elt?.id]);
    }
    // setGroupID([...groupID, elt])
    // setActive(index)
  };

  const recommend = () => {
    setLoading(true);
    active?.forEach(async (elt) => {
      const obj = {
        groupID: elt,
        opportunityID: opportunityID,
        educatorID: user?.id,
        createdAt: new Date()
      };
      const filterRecommendedOpps = await GetAllRecommendedOppsByOppIDAndGroupID(
        opportunityID,
        obj.groupID
      );
      if (filterRecommendedOpps?.length === 1) {
        setLoading(false);
      } else {
        createRecommendation({
          variables: { ...obj }
        })
          .then(() => {
            setLoading(false);
            // setRecommended(true)
            setActiveToast(true);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    });
    setActiveToast(true);
    setToast('Opportunity recommended');
  };

  useEffect(() => {
    GetGroupsByRoleID(user?.id).then((groups) => setFilterMyGroups(groups));
  }, []);

  return (
    <>
      <AlertToast
        variant={'success'}
        icon={'check'}
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
      <div className="col-sm-12 p-0 mt-3 mb-3">
        {filterMyGroups?.map((elt, index) => (
          <div
            className="col-sm-12 mt-2 pt-3 pb-2"
            style={{
              border: '1px solid #CFD9E8',
              borderRadius: '5px',
              backgroundColor: active.includes(elt?.id) ? '#F0FCF8' : '#fff'
            }}
            onClick={() => setIds(elt)}
            key={index}>
            <h6>{elt?.name}</h6>
          </div>
        ))}
      </div>
      <div className="col-sm-12 pr-0 pl-0">
        <div className="col-sm-12 p-0">
          <button
            type="button"
            style={{ width: '100%' }}
            className="btn btn_success"
            onClick={recommend}>
            {loading ? progress() : 'Recommend'}
          </button>
        </div>
      </div>
    </>
  );
}

export default Mygroupmodal;
