import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { visuallyHidden } from '@mui/utils';
import InitialsWrapper from '../../../../Widgets/InitialsWrapper/InitialsWrapper';
import './style.css';
import { getRandomColorById } from '../../../../utils/helpers';
// import { calculateAge } from '../../../../utils/Youth/utils';

function createData(student, dob, interests, is_verified) {
  // const age = calculateAge(dob);
  return {
    student,
    // age,
    interests,
    is_verified
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'student',
    numeric: false,
    disablePadding: true,
    label: 'STUDENTS'
  },
  // {
  //   id: 'age',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'AGE',
  //   center: true
  // },
  {
    id: 'interests',
    numeric: false,
    disablePadding: false,
    label: 'INTERESTS'
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <input
            type={'checkbox'}
            className="form-check-input"
            aria-label="select all youths"
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? (headCell?.center ? 'center' : 'right') : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className={'table-head-text'}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default function EnhancedTable(props) {
  const { studentsData, setOpenSide, setData, type } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('student');
  const [selected, setSelected] = React.useState([]);
  // const [itemSelected, setItemSelected] = React.useState({}); // row selected
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [colors, setColors] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const getInitial = (name) => {
    if (name) {
      let initials = '';
      const splittedName = name.split(' ');
      splittedName.forEach((elt) => {
        if (elt.length) initials += elt[0].toUpperCase();
      });
      return initials;
    }
    return '';
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const pillBtns = (data) => {
    const res = [];
    data?.forEach((elt, index) => {
      res.push(
        <button
          className={`background_allopp_btn btn btn-outline-light mr-2 mb-2 ${
            selected === index + 1 ? 'active' : ''
          }`}
          key={index}>
          {elt}
        </button>
      );
    });

    return res;
  };

  const rows = [];

  studentsData?.forEach((elt) => {
    rows.push(createData(elt?.name, elt?.dob, pillBtns(elt?.interests), elt?.is_verified));
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.student);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_event, name, index) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setData(studentsData?.filter((elt) => elt?.name === name)[0]);
    if (type === 'abouttables') {
      setSelected(newSelected);
    } else {
      setOpen(true);
      setOpenSide(true);
    }
  };

  React.useEffect(() => {
    const colors = studentsData?.reduce((acc, student) => {
      return { ...acc, [student.name]: getRandomColorById(student.id) };
    }, {});
    setColors(colors);
  }, [studentsData]);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Grid container className="grid_container">
        <Grid sm={12} sx={{ textAlign: 'center' }}>
          <Box sx={{ boxShadow: '0 0 0 0' }}>
            <Paper sx={{ boxShadow: '0 0 0 0' }}>
              {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
              <TableContainer>
                <Table
                  // sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}>
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.student);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.student, index)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.student}
                            selected={isItemSelected}>
                            <TableCell padding="checkbox">
                              <input
                                type={'checkbox'}
                                className="form-check-input"
                                aria-labelledby={labelId}
                                checked={isItemSelected}
                              />
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              <div className="justify-start">
                                <InitialsWrapper
                                  type="small"
                                  text={getInitial(row.student)}
                                  bg={colors[row.student]}
                                />
                                <span className="ml-2 align-items table-body-text">
                                  {row.student}
                                </span>
                                {row?.is_verified && (
                                  <i
                                    className="fas fa-check-circle ml-2 table-body-check-icon"
                                    style={{ height: 16 }}></i>
                                )}
                              </div>
                            </TableCell>
                            {/*<TableCell align="center">{row.age} </TableCell>*/}
                            <TableCell align="right">
                              <div className="justify-start align-items pt-1">{row.interests}</div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows
                        }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </Grid>

        {open ? <> </> : <></>}
      </Grid>
    </>
  );
}
