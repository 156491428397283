import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteNoteModal from './Modal/DeleteNoteModal';
import CreateNoteModal from './Modal/CreateNoteModal';
import { formatDate } from '../../../../utils/MyGroups/utils';

export default function MenuNotes(props) {
  const { retreive, setRetreive, data } = { ...props };
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className="header-profile layout-1 break align-items">
        <button data-toggle="dropdown" aria-expanded="false">
          <i className="fas fa-ellipsis-h" id="elpise"></i>
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          <button className="dropdown-item" type="button" onClick={() => setShowEditModal(true)}>
            <img src="../images/edit-1.png" alt="icon" />
            Edit Note
          </button>
          <button className="dropdown-item" type="button" onClick={() => setShowModal(true)}>
            <img src="../images/delete-1.png" alt="icon" />
            Delete Note
          </button>
          <div className="dropdown-item" type="button">
            Created {formatDate(data?.createdAt)}
          </div>
        </div>
      </div>
      <DeleteNoteModal
        modalShow={showModal}
        setModalShow={setShowModal}
        id={data?.id}
        title={data?.title}
        retreive={retreive}
        setRetreive={setRetreive}
      />
      <CreateNoteModal
        modalShow={showEditModal}
        setModalShow={setShowEditModal}
        data={data}
        retreive={retreive}
        setRetreive={setRetreive}
      />
    </>
  );
}
