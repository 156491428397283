import { gql, useQuery } from '@apollo/client';
import { API } from 'aws-amplify';

export const GET_GROUPS = gql`
  query GroupsQuery {
    listMyGroups(limit: 1000) {
      items {
        id
        studentIDs
        createdAt
        description
        name
        roleID
        updatedAt
      }

      nextToken
    }
  }
`;

export const GET_GROUPS_WITH_TOKEN = gql`
  query InterestsQuery($limit: Int, $nextToken: String) {
    listMyGroups(limit: $limit, nextToken: $nextToken) {
      items {
        id
        studentIDs
        createdAt
        description
        name
        roleID
        updatedAt
      }
      nextToken
    }
  }
`;

export async function AllMyGroups() {
  const resp = await API.graphql({
    query: GET_GROUPS
  });

  const data = resp?.data?.listMyGroups;
  let nextToken = data?.nextToken;
  let groups = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_GROUPS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listMyGroups;

    const previousData = [...groups, ...respData?.items];
    groups = [...previousData];

    nextToken = respData?.nextToken;
  }

  return groups || [];
}

export const GET_GROUPS_BY_ROLE_ID = gql`
  query GroupsQuery($roleID: ID) {
    listMyGroups(limit: 1000, filter: { roleID: { eq: $roleID } }) {
      items {
        id
        studentIDs
        createdAt
        description
        name
        roleID
        updatedAt
      }

      nextToken
    }
  }
`;

export const GET_GROUPS_WITH_TOKEN_BY_ROLE_ID = gql`
  query InterestsQuery($limit: Int, $nextToken: String, $roleID: ID) {
    listMyGroups(limit: $limit, nextToken: $nextToken, filter: { roleID: { eq: $roleID } }) {
      items {
        id
        studentIDs
        createdAt
        description
        name
        roleID
        updatedAt
      }
      nextToken
    }
  }
`;

export async function GetGroupsByRoleID(roleID) {
  const resp = await API.graphql({
    query: GET_GROUPS_BY_ROLE_ID,
    variables: { roleID }
  });

  const data = resp?.data?.listMyGroups;
  let nextToken = data?.nextToken;
  let groups = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_GROUPS_WITH_TOKEN_BY_ROLE_ID,
      variables: { limit: 1000, nextToken, roleID }
    });
    const respData = resp?.data?.listMyGroups;

    const previousData = [...groups, ...respData?.items];
    groups = [...previousData];

    nextToken = respData?.nextToken;
  }

  return groups || [];
}

export const GET_GROUP = gql`
  query SingleGroup($id: ID!) {
    getMyGroup(id: $id) {
      id
      studentIDs
      createdAt
      description
      name
      roleID
      updatedAt
    }
  }
`;

export async function GetGroup(id) {
  try {
    const respGroups = await API.graphql({
      query: GET_GROUP,
      variables: { id }
    });
    const group = respGroups?.data?.getMyGroup;
    return group;
  } catch (err) {
    return {};
  }
}
