/* eslint-disable no-unused-expressions */
import { gql } from '@apollo/client';
import { API } from 'aws-amplify';
// import { GetSingleInterest } from "./interests";

export const GET_STUDENT_INTERESTS = gql`
  query StudentInterestsQuery {
    listStudentInterests(limit: 1000) {
      items {
        createdAt
        id
        interestIDs
        studentID
        updatedAt
      }
    }
  }
`;

export const GET_STUDENT_INTERESTS_WITH_TOKEN = gql`
  query StudentInterestsQuery($limit: Int, $nextToken: String) {
    listStudentInterests(limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        interestIDs
        studentID
        updatedAt
      }
      nextToken
    }
  }
`;

export async function AllStudentInterests() {
  const resp = await API.graphql({
    query: GET_STUDENT_INTERESTS
  });

  const data = resp?.data?.listStudentInterests;
  let nextToken = data?.nextToken;
  let studentInterests = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_STUDENT_INTERESTS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listStudentInterests;

    const previousData = [...studentInterests, ...respData?.items];
    studentInterests = [...previousData];
    nextToken = respData?.nextToken;
  }

  return studentInterests || [];
}

export const GET_STUDENT_INTERESTS_BY_STUDENTID = gql`
  query StudentInterestsQuery($studentID: ID) {
    listStudentInterests(limit: 1000, filter: { studentID: { eq: $studentID } }) {
      items {
        createdAt
        id
        interestIDs
        studentID
        updatedAt
      }
    }
  }
`;

export const GET_STUDENT_INTERESTS_WITH_TOKEN_BY_STUDENTID = gql`
  query StudentInterestsQuery($limit: Int, $nextToken: String, $studentID: ID) {
    listStudentInterests(
      limit: $limit
      nextToken: $nextToken
      filter: { studentID: { eq: $studentID } }
    ) {
      items {
        createdAt
        id
        interestIDs
        studentID
        updatedAt
      }
      nextToken
    }
  }
`;

export async function GetStudentInterestsByID(studentID) {
  const resp = await API.graphql({
    query: GET_STUDENT_INTERESTS,
    variables: { studentID }
  });

  const data = resp?.data?.listStudentInterests;
  let nextToken = data?.nextToken;
  let studentInterests = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_STUDENT_INTERESTS_WITH_TOKEN,
      variables: { limit: 1000, nextToken, studentID }
    });
    const respData = resp?.data?.listStudentInterests;

    const previousData = [...studentInterests, ...respData?.items];
    studentInterests = [...previousData];
    nextToken = respData?.nextToken;
  }

  return studentInterests || [];
}

export const GET_STUDENT_INTEREST = gql`
  query SingleStudentInterest($id: ID!) {
    getStudentInterest(id: $id) {
      createdAt
      id
      interestIDs
      studentID
      updatedAt
    }
  }
`;

export async function GetStudentInterest(id) {
  try {
    const respStudentInterest = await API.graphql({
      query: GET_STUDENT_INTEREST,
      variables: { id }
    });
    const studentInterest = respStudentInterest?.data?.getStudentInterest;
    return studentInterest;
  } catch (err) {
    console.error(err);
    return {};
  }
}

export const GET_STUDENT_INTEREST_BY_STUDENT_ID = gql`
  query ListStudentInterestByStudentID($studentID: ID!) {
    listStudentInterests(limit: 1000, filter: { studentID: { eq: $studentID } }) {
      items {
        createdAt
        id
        interestIDs
        studentID
        updatedAt
      }
    }
  }
`;

export async function GetStudentInterestByStudentID(studentID) {
  const studentInterests = await AllStudentInterests();
  const studentInterest = studentInterests.find((s) => s.studentID === studentID);

  const interests = await studentInterest?.interestIDs?.reduce(async (previousPromise, id) => {
    // const interest = await GetSingleInterest(id);
    let interestsData = await previousPromise;
    // interestsData.push(interest);
    return interestsData;
  }, Promise.resolve([]));

  const studentObj = { ...studentInterest, interests };
  return studentObj || {};
}
