import { useNavigate } from 'react-router-dom';
import { formatMessageTime, getInitial, getRandomColorById } from '../../../../utils/helpers';
import { Accordion } from '../../../../Components/common/Collapsible';

function TeamChat({ groupChats, user, chats, directChats, setCurrentItem, educators }) {
  const navigate = useNavigate();

  const chatTitle = (elt) => {
    const customObj = JSON.parse(elt.custom_json);
    if (customObj.creatorID === user?.id) return customObj.destinationName;
    else return customObj.senderName;
  };

  const messageLogo = (message) => {
    const customObj = JSON.parse(message.custom_json);
    let userId = '';
    if (customObj.creatorID === user?.id) userId = customObj.destinationID;
    else userId = customObj.creatorID;
    const educator = educators.find((elt) => elt.id === userId);
    if (educator?.image) {
      return (
        <img
          src={educator.image}
          className="mr-2"
          alt=""
          width={40}
          height={40}
          style={{ borderRadius: '50%' }}
        />
      );
    } else {
      return (
        <div className="user-placeholder" style={{ backgroundColor: getRandomColorById(userId) }}>
          {getInitial(chatTitle(message))}
        </div>
      );
    }
  };

  const viewChat = (id, type, name, other = '') => {
    setCurrentItem(chats?.find((elt) => elt.id === id));
    navigate(`/chat?id=${id}&type=${type}&other=${other}&name=${name}`);
  };

  return (
    <div className="mt-2 accordion" id="accordionExample">
      <Accordion title={'Group Messages'} className="bg-light border-bottom">
        <div className="users-items">
          {groupChats?.map((elt) => (
            <div className="user-view-item bg-white border-top" key={elt?.id}>
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={() => viewChat(elt?.id, 'team', user?.school?.name, '')}>
                <div className="d-flex gap-2">
                  <div className="org-img">
                    <img src={user?.school?.image} alt="" width="32px" height="32px" />
                  </div>
                  <div className="user-des d-flex flex-column">
                    <div className="user-name">{user?.school?.name}</div>
                    <div className="short-msg">{elt.last_message.text}</div>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <div className="last-msg-time">
                    {elt.last_message.created !== '' && formatMessageTime(elt.last_message.created)}
                  </div>
                  <div className="user-action justify-content-end gap-2">
                    {/* <div className="mute">
                        <img src={Mute} alt="" />
                    </div> */}
                    {/* <div class="dropdown menu" style={{marginTop: '-6px'}}>
                      <i className="fas fa-ellipsis-h text-secondary" id={`chat-${elt?.id}`} data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="false"></i>
                      <div class="dropdown-menu dropdown-menu-left" aria-labelledby={`chat-${elt?.id}`}>
                        <a class="dropdown-item" href="#">Action</a>
                        <a class="dropdown-item disabled" href="#">Disabled action</a>
                        <h6 class="dropdown-header">Section header</h6>
                        <a class="dropdown-item" href="#">Action</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">After divider action</a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Accordion>
      <Accordion title={'Direct Messages'} className="bg-light border-bottom">
        <div className="users-items">
          {directChats?.map((elt) => (
            <div className="user-view-item bg-white border-top" key={elt?.id}>
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={() => viewChat(elt?.id, 'team', chatTitle(elt), 'dm')}>
                <div className="d-flex gap-2">
                  <div className="org-img">{messageLogo(elt)}</div>
                  <div className="user-des d-flex flex-column">
                    <div className="user-name">{chatTitle(elt)}</div>
                    <div className="short-msg">{elt.last_message.text}</div>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <div className="last-msg-time">
                    {elt.last_message.created !== '' && formatMessageTime(elt.last_message.created)}
                  </div>
                  <div className="user-action justify-content-end gap-2">
                    {/* <div className="mute">
                          <img src={Mute} alt="" />
                      </div> */}
                    {/* <div class="dropdown menu" style={{marginTop: '-6px'}}>
                        <i className="fas fa-ellipsis-h text-secondary"  id={`chat-${elt?.id}`} data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false"></i>
                        <div class="dropdown-menu dropdown-menu-left" aria-labelledby={`chat-${elt?.id}`}>
                          <a class="dropdown-item" href="#">Action</a>
                          <a class="dropdown-item disabled" href="#">Disabled action</a>
                          <h6 class="dropdown-header">Section header</h6>
                          <a class="dropdown-item" href="#">Action</a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item" href="#">After divider action</a>
                        </div>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Accordion>
    </div>
  );
}

export default TeamChat;
