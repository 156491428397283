import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Avatar from '@mui/material/Avatar';

function MyVerticallyCenteredModal(props) {
  const [active, setActive] = useState('first');

  const clearAll = () => {
    setActive('first');
    props.onHide();
  };

  const FirstModal = () => {
    return (
      <>
        <div className="p-0">
          <div className="text-center">
            <img src="../images/program.png" />
          </div>
          <h5 className="info-heading">The Junity Influencer Program</h5>
          <div className="text-left p-0">
            <h6 className="info-description">
              Junity Influencers help their peers discover opportunities on Junity that will
              contribute to their growth as they explore their passions. They enjoy perks such as
              Junity swags, leadership and influencer trainings and a special Junity Influencer
              badge on their profile.
            </h6>
            <h6 className="info-description">
              Junity Influencers help their peers discover opportunities on Junity that will
              contribute to their growth as they explore their passions.
            </h6>
          </div>
        </div>
        <div className="pb-3">
          <div className="p-0">
            {' '}
            <button
              type="button"
              className="btn btn-light"
              style={{
                width: '100%',
                border: '1px solid gray',
                backgroundColor: '#AA8545',
                color: 'white'
              }}
              onClick={() => setActive('second')}>
              <b>Nominate Youth</b>
            </button>
          </div>

          <div className="p-0">
            {' '}
            <button
              type="button"
              className="btn btn-light"
              style={{
                width: '100%',
                border: '1px solid white',
                backgroundColor: 'white',
                color: '#969CA5'
              }}
              data-dismiss="modal">
              <b>
                <a href={'https://joinjunity.com'} target="_blank" rel="noreferrer">
                  Learn more about the program
                </a>
              </b>
            </button>
          </div>
        </div>
      </>
    );
  };

  const SecondModal = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between py-3">
          <h5
            className="modal-title cursor-pointer"
            id="exampleModalLabel"
            onClick={() => setActive('first')}>
            Back
          </h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="w-100">
          <div className="p-0">
            <h6 className="info-heading text-start">Nominate Youth</h6>
            <h6 className="info-description text-start">
              Each month, you can nominate up to 3 youth who you think would make great Junity
              Influencers. This gives us enough time to learn about each youth.
            </h6>
            <h6 className="institution-form-label">1 of 3 Students selected</h6>
            <div className="text-left p-0 pt-2">
              <input className="form-control" type="text" placeholder="Find a Youth" />
            </div>
            <div className="text-left p-0 pt-2">
              <div className="d-flex align-items-center gap-3 pt-2">
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                </div>
                <Avatar sx={{ bgcolor: '#226E54' }}>BD</Avatar>
                <h6> Bryce David</h6>
              </div>
              <div className="d-flex align-items-center gap-3 pt-2">
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                </div>
                <Avatar sx={{ bgcolor: '#226E54' }}>BD</Avatar>
                <h6> Bryce Lilly</h6>
              </div>
              <div className="d-flex align-items-center gap-3 pt-2">
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                </div>
                <Avatar sx={{ bgcolor: '#226E54' }}>BD</Avatar>
                <h6> Bryce Jazmeen</h6>
              </div>
              <div className="d-flex align-items-center gap-3 pt-2">
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                </div>
                <Avatar sx={{ bgcolor: '#226E54' }}>BD</Avatar>
                <h6> Bryce Walid</h6>
              </div>
              <div className="d-flex align-items-center gap-3 pt-2">
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                </div>
                <Avatar sx={{ bgcolor: '#226E54' }}>BD</Avatar>
                <h6> Bryce David</h6>
              </div>
              <hr />
            </div>
          </div>
        </div>
        <h6 className="info-description text-start mt-3">
          Your nomination is anonymous and will only be seen by the Junity Influencer Program
          Office. We will consider your nomination but there is no guarantee that the nominated
          youth will become Junity Influencers.
        </h6>
        <button
          type="button"
          className="btn btn-light my-3"
          style={{
            width: '100%',
            border: '1px solid gray',
            backgroundColor: '#AA8545',
            color: 'white'
          }}
          onClick={() => setActive('third')}>
          <b>Submit Nominate</b>
        </button>
      </>
    );
  };

  const ThirdModal = () => {
    return (
      <>
        <div className="w-100 pt-3">
          <div className="p-0">
            <div className="text-center">
              <img src="../images/check.png" />
            </div>
            <h5 className="info-heading">Thank you for your Submission!</h5>
            <h6 className="institution-form-label">You Nominated:</h6>
            <div className="d-flex gap-3 custom-flex-wrap">
              <button className="btn btn-light text-dark">
                Bruce David (you){' '}
                <i style={{ color: '#0D3575' }} className="fas fa-check-circle"></i>{' '}
              </button>
              <button className="btn btn-light text-dark"> Theresa Webb </button>
            </div>

            <h6 className="info-description text-start mt-3">
              We will consider your nomination and you will get notified if any of the selected
              youth becomes a Junity Influencer. You should encourage the nominated youth to apply
              through the Junity app to increase their chances of getting approved.
            </h6>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-light w-100 text-dark my-3 fw-bolder"
          onClick={clearAll}>
          Close
        </button>
      </>
    );
  };

  const FourthModal = () => {
    return (
      <div
        className="modal fade"
        id="info3"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="pb-3 pt-3">
              <div className="row">
                <div className="col-sm-6">
                  {' '}
                  <h5 className="modal-title" id="exampleModalLabel">
                    Back
                  </h5>
                </div>
                <div className="col-sm-6">
                  {' '}
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-body pt-0">
              <div className="p-0">
                <div className="text-center">
                  <img className="img-fluid" src="../images/program.png" />
                </div>
                <div className="text-left p-0">
                  <h5>
                    <b>The Junity Influencer Program</b>
                  </h5>
                </div>
                <div className="text-left p-0">
                  <h6 className="info-description">
                    Junity Influencers help their peers discover opportunities on Junity that will
                    contribute to their growth as they explore their passions. They enjoy perks such
                    as Junity swags, leadership and influencer trainings and a special Junity
                    Influencer badge on their profile.
                  </h6>
                  <h6 className="info-description">
                    Junity Influencers help their peers discover opportunities on Junity that will
                    contribute to their growth as they explore their passions.
                  </h6>
                </div>
                <div className="pl-0">
                  <h6 style={{ color: '#969CA5' }}>You Nominated:</h6>
                </div>

                <div className="pl-0">
                  <span>
                    {' '}
                    <button className="btn bnt-light" style={{ border: '1px solid gray' }}>
                      {' '}
                      Bruce David (you){' '}
                      <i style={{ color: '#0D3575' }} className="fas fa-check-circle"></i>{' '}
                    </button>{' '}
                  </span>
                  <span>
                    {' '}
                    <button className="btn bnt-light" style={{ border: '1px solid gray' }}>
                      {' '}
                      Theresa Webb{' '}
                    </button>{' '}
                  </span>
                </div>
              </div>
            </div>

            <div className="pb-3 mt-2">
              <div className="p-0">
                {' '}
                <button
                  type="button"
                  className="btn btn-light"
                  style={{
                    width: '100%',
                    border: '1px solid gray',
                    backgroundColor: '#AA8545',
                    color: 'white'
                  }}
                  data-dismiss="modal">
                  <b>Nominate youth to be Junity Influencers</b>
                </button>
              </div>
              <div className="p-0">
                {' '}
                <button
                  type="button"
                  className="btn btn-light"
                  style={{
                    width: '100%',
                    border: '1px solid white',
                    backgroundColor: 'white',
                    color: '#969CA5'
                  }}
                  data-dismiss="modal">
                  <b>Learn more about the program</b>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const modals = {
    first: <FirstModal />,
    second: <SecondModal />,
    third: <ThirdModal />,
    fourth: <FourthModal />
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal_body_style"
      centered>
      <Modal.Body className="modal-body p-0 px-3 w-100">{modals[active]}</Modal.Body>
    </Modal>
  );
}

export default function JunityInfluencerModal(props) {
  const { modalShow, setModalShow } = { ...props };

  return (
    <>
      <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
