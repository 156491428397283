import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { FiChevronRight } from 'react-icons/fi';
import { GetStudentsBySearch } from '../../Services/query/students';
import InitialsWrapper from '../../Widgets/InitialsWrapper/InitialsWrapper';
import { getRandomColorById } from '../../utils/helpers';
import { Link } from 'react-router-dom';
import './style.css';
import { axiosClient } from '../../libs/axiosClient';

function SearchInput(props) {
  const { user, bg, placeholder, height, width, border, style, className } = { ...props };
  const [searchKey, setSearchKey] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [filteredOpps, setFilteredOpps] = useState([]);
  const dropdownRef = useRef(null);

  const getInitial = (name) => {
    if (name) {
      let initials = '';
      const splittedName = name.split(' ');
      splittedName.forEach((elt) => {
        if (elt.length) initials += elt[0].toUpperCase();
      });
      return initials;
    }
    return '';
  };

  const boldedSearched = (searchText, data) => (
    <>
      <span className="font-weight-bold">{searchText.toTitleCase()}</span>
      {data?.name?.slice(data?.name?.indexOf(searchKey) + searchKey.length + 1)}
    </>
  );

  useEffect(() => {
    const handleSearchChange = async () => {
      const students = await GetStudentsBySearch(searchKey, user?.school?.connections);

      setFilteredStudents(students);

      try {
        const response = await axiosClient.post('/get-opportunities', {
          fields: ['id', 'name', 'expiry_date', 'schoolID', 'description'],
          filterByName: searchKey
        });
        setFilteredOpps(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    if (searchKey) handleSearchChange();
    else {
      setFilteredStudents([]);
      setFilteredOpps([]);
    }
  }, [searchKey]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSearchKey('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="search-input-container">
      <div
        className={`search_input ps-0 ${className}`}
        style={{
          ...style,
          width: width,
          backgroundColor: bg,
          height: height,
          minWidth: 300,
          border: border || 'none'
        }}>
        <div className="search_icon px-3">
          <Search fill="#515A6A" />
        </div>
        <Form.Control
          onClick={(e) => e.preventDefault()}
          type="text"
          placeholder={placeholder}
          style={{
            backgroundColor: bg,
            fontSize: 13
          }}
          onChange={(e) => setSearchKey(e.target.value)}
        />
        {/* <input type="text" placeholder="Yes" /> */}
      </div>
      {searchKey && (
        <div
          ref={dropdownRef}
          className="px-3 pb-3 rounded-lg shadow-lg bg-white cus-breadcrum pt-1 position-absolute"
          style={{ width: 350 }}>
          <ul className="nav nav-tabs gap-3" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#youths" role="tab">
                Youth
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#opportunities" role="tab">
                Opportunities
              </a>
            </li>
          </ul>
          <div className="tab-content header-search-sect">
            <div className="tab-pane active" id="youths" role="tabpanel">
              {filteredStudents.map((elt) => (
                <Link
                  className="d-flex gap-2 align-items-center justify-content-between mt-3"
                  key={elt.id}
                  to={`/youth`}>
                  <div className="d-flex align-items-center gap-2">
                    <InitialsWrapper
                      type="small"
                      text={getInitial(elt.name)}
                      bg={getRandomColorById(elt.id)}
                      cls="mr-1"
                    />
                    <p className="fs-13 my-0 text-black">{boldedSearched(searchKey, elt)}</p>
                  </div>
                  <div className="ml-auto">
                    <FiChevronRight size={16} className="text-secondary" />
                  </div>
                </Link>
              ))}
            </div>
            <div className="tab-pane" id="opportunities" role="tabpanel">
              {filteredOpps?.map((elt) => (
                <Link
                  className="d-flex gap-2 align-items-center mt-3"
                  key={elt.id}
                  to={`/all-opportunity`}>
                  <div className="d-flex align-items-center gap-2">
                    <div className="w-9 h-9 fs-13 text-white overflow-hidden bg--success rounded flex-shrink-0 mr-2 d-flex align-items-center justify-content-center">
                      <img
                        src={elt.organization.image}
                        alt="logo"
                        className="w-9 h-9 object-cover"
                        width={40}
                        height={40}
                        style={{ borderRadius: 4 }}
                      />
                    </div>
                    <p className="fs-13 my-0 text-black">
                      {boldedSearched(searchKey, elt)} - {elt.description}
                    </p>
                  </div>
                  <div className="ml-auto">
                    <FiChevronRight size={16} className="text-secondary" />
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchInput;
