import { gql } from '@apollo/client';

export const CREATE_NOTE = gql`
  mutation CreateNoteMutation(
    $color: String!
    $description: String!
    $title: String!
    $studentID: ID!
    $createdAt: AWSDateTime!
  ) {
    createNote(
      input: {
        color: $color
        description: $description
        title: $title
        studentID: $studentID
        createdAt: $createdAt
      }
    ) {
      id
      title
      color
      description
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation DeleteNoteMutation($id: ID!) {
    deleteNote(input: { id: $id }) {
      id
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation UpdateNoteMutation(
    $color: String!
    $description: String!
    $title: String!
    $id: ID!
    $updatedAt: AWSDateTime!
  ) {
    updateNote(
      input: {
        color: $color
        description: $description
        title: $title
        id: $id
        updatedAt: $updatedAt
      }
    ) {
      id
      title
      color
      description
    }
  }
`;
