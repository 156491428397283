import axios from 'axios';

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  timeout: 10000
});

export const youthAxiosClient = axios.create({
  baseURL: process.env.REACT_APP_YOUTH_URL,
  timeout: 10000
});

export const chatClient = axios.create({
  baseURL: 'https://api.chatengine.io',
  timeout: 10000,
  headers: {
    'Private-Key': process.env.REACT_APP_CHAT_ENGINE_SECRET_KEY
  }
});

export const adminClient = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_URL,
  timeout: 10000
});

export const tasksClient = axios.create({
  baseURL: process.env?.REACT_APP_SERVER_URL?.split("v1")[0]+'v1/tasks',
  timeout: 10000
});