import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Messages from '../Messages/Messages';
import { useSelector } from 'react-redux';
import Tabswitchchat from './Tabswitchchat';
import YouthChats from './YouthChats';
import TeamChat from './TeamChat';
import { chatClient } from '../../../../libs/axiosClient';
import ChatDetail from '../ChatDetail';
import { GetSingleChatHandler } from '../../../../Services/query/chatHandler';
import { formatDate, matchStartsWith } from '../../../../utils/helpers';

import './Chatmain.css';
import { AllEducators } from '../../../../Services/query/educator';

function CustomizedApp() {
  const [chats, setChats] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [chatDateStarted, setChatDateStarted] = useState('');
  const [youthChats, setYouthChats] = useState([]);
  const [groupChats, setGroupChats] = useState([]);
  const [directChats, setDirectChats] = useState([]);
  const [filteredYouthChats, setFilteredYouthChats] = useState([]);
  const [filteredGroupChats, setFilteredGroupChats] = useState([]);
  const [filteredDirectChats, setFilteredDirectChats] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [educators, setEducators] = useState([]);
  const [messages, setMessages] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const user = useSelector((store) => store.educatorUser.data);

  const search = new URLSearchParams(useLocation().search);
  const id = search.get('id');
  const type = search.get('type');
  const name = search.get('name');
  const other = search.get('other');

  const getMessages = async (id) => {
    if (id) {
      const messages = await chatClient.get(`chats/${id}/messages/`);
      setMessages(messages.data);
    }
  };

  const getUserChats = async () => {
    const chats = await chatClient.get('/chats/');
    getYouthChats(chats.data);
    getTeamChats(chats.data);
    setChats(chats.data);

    if (id) await getMessages(id);
  };

  const getYouthChats = (chats) => {
    const youthChats = chats?.filter(
      (elt) =>
        JSON.parse(elt?.custom_json)?.orgID === user?.schoolID &&
        JSON.parse(elt?.custom_json)?.creatorType === 'youth' &&
        JSON.parse(elt?.custom_json)?.destinationID === user?.id
    );

    setYouthChats(youthChats);
    setFilteredYouthChats(youthChats);
  };

  const getTeamChats = (chats) => {
    const groupChats = chats?.filter(
      (elt) =>
        JSON.parse(elt?.custom_json)?.orgID === user?.schoolID &&
        JSON.parse(elt?.custom_json)?.type === 'sameOrgGroupChat'
    );

    setGroupChats(groupChats);
    setFilteredGroupChats(groupChats);

    const directChats = chats?.filter(
      (elt) =>
        JSON.parse(elt?.custom_json)?.type === 'sameOrgDirectChat' &&
        JSON.parse(elt?.custom_json)?.creatorType === 'educator' &&
        JSON.parse(elt?.custom_json)?.destinationType === 'educator' &&
        (JSON.parse(elt?.custom_json)?.creatorID === user?.id ||
          JSON.parse(elt?.custom_json)?.destinationID === user?.id)
    );

    setDirectChats(directChats);
    setFilteredDirectChats(directChats);
  };

  useEffect(() => {
    let socket = new WebSocket(
      `wss://api.chatengine.io/person/?publicKey=${process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID}&username=${user?.id}&secret=${user?.id}`
    );

    socket.onopen = () => console.log('opened');

    if (Object.keys(user).length) {
      getUserChats();
    }

    AllEducators().then((data) =>
      setEducators(data.filter((elt) => elt.schoolID === user?.schoolID))
    );
  }, []);

  useEffect(() => {
    if (searchKey) {
      setFilteredYouthChats(
        youthChats.filter((elt) => matchStartsWith(elt.title, searchKey) === true)
      );
      setFilteredDirectChats(
        directChats.filter((elt) => matchStartsWith(elt.title, searchKey) === true)
      );
      setFilteredGroupChats(
        groupChats.filter((elt) => matchStartsWith(elt.title, searchKey) === true)
      );
    } else {
      setFilteredDirectChats(directChats);
      setFilteredGroupChats(groupChats);
      setFilteredYouthChats(youthChats);
    }
  }, [searchKey]);

  useEffect(() => {
    getUserChats();
    GetSingleChatHandler(id).then((data) => {
      let socket = new WebSocket(
        `wss://api.chatengine.io/chat/?projectID=${process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID}&chatID=${id}&accessKey=${data?.access_key}`
      );
      socket.onmessage = () => getUserChats();
      setChatDateStarted(formatDate(data.createdAt));
    });
  }, [id]);

  useEffect(() => {
    if (currentTab) getTeamChats(chats);
    else getYouthChats(chats);
  }, [currentTab]);

  return (
    <div className="col-sm-12 h-full pl-0 pr-0">
      <div className="col-sm-12 h-full p-0">
        <div className="row h-full sendbird-app">
          <div className="col-sm-4 border_chat pr-0">
            <div className="col-sm-12 pt-4 ">
              <h4 className="page-name">
                Chats
                <span className="badge badge-danger ml-1 rounded-pill">
                  {filteredYouthChats?.length +
                    filteredDirectChats?.length +
                    filteredGroupChats?.length}
                </span>
              </h4>
            </div>

            <div className="col-sm-12 pt-3 pb-2">
              <div className="searchbar mt-2 mt-md-0">
                <div className={`input-group p-0`}>
                  <span className="input-group-text bg-white">
                    <i className="fas fa-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control bg-white"
                    placeholder="Search messages"
                    name="search"
                    onChange={(e) => setSearchKey(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <Tabswitchchat
              setCurrentTab={setCurrentTab}
              youthChats={filteredYouthChats?.length}
              teamChats={filteredDirectChats?.length + filteredGroupChats?.length}
            />
            {currentTab ? (
              <div className="messages-list">
                <TeamChat
                  user={user}
                  chats={chats}
                  educators={educators}
                  groupChats={filteredGroupChats}
                  directChats={filteredDirectChats}
                  setCurrentItem={setCurrentItem}
                />
              </div>
            ) : (
              <div className="messages-list">
                {filteredYouthChats.map((elt, index) => (
                  <YouthChats
                    user={user}
                    chat={elt}
                    chats={chats}
                    key={index}
                    setCurrentItem={setCurrentItem}
                  />
                ))}
              </div>
            )}
          </div>
          {id ? (
            <div className="col-sm-8 pl-0 channel-box shown">
              <ChatDetail
                id={id}
                name={name}
                type={type}
                user={user}
                other={other}
                messages={messages}
                educators={educators}
                getMessages={getMessages}
                currentItem={currentItem}
                chatDateStarted={chatDateStarted}
              />
            </div>
          ) : (
            <div className="col-sm-8 pl-0 channel-box d-none d-sm-block">
              <Messages />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomizedApp;
