import awsmobile from '../../../aws-exports';

export const paramsWithUsername = (email) => {
  const data = {
    UserPoolId: awsmobile.aws_user_pools_id,
    Username: email
  };

  return data;
};

export const paramsUpdateAttr = (email, info) => {
  const data = {
    UserAttributes: info,
    UserPoolId: awsmobile.aws_user_pools_id,
    Username: email
  };

  return data;
};

export const paramsWithPassword = (password, username) => {
  const data = {
    Password: password,
    UserPoolId: awsmobile.aws_user_pools_id,
    Username: username,
    Permanent: true
  };
  return data;
};
