import { gql, useQuery } from '@apollo/client';
import { API } from 'aws-amplify';
import { GetSingleOpportunity } from './opportunities';

export const GET_RECOMMENDED_OPPORTUNITIES = gql`
  query RecommendedOpportunitiesQuery {
    listRecommendations(limit: 1000) {
      items {
        studentID
        opportunityID
        updatedAt
        id
        groupID
        educatorID
        createdAt
        categoryID
      }

      nextToken
    }
  }
`;

export const GET_RECOMMENDED_OPPORTUNITIES_WITH_TOKEN = gql`
  query RecommendedOpportunitiesQuery($limit: Int, $nextToken: String) {
    listRecommendations(limit: $limit, nextToken: $nextToken) {
      items {
        studentID
        opportunityID
        updatedAt
        id
        groupID
        educatorID
        createdAt
        categoryID
      }
      nextToken
    }
  }
`;

export async function AllRecommendedOpportunities() {
  const resp = await API.graphql({
    query: GET_RECOMMENDED_OPPORTUNITIES
  });

  const data = resp?.data?.listRecommendations;
  let nextToken = data?.nextToken;
  let recommendedOpps = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_RECOMMENDED_OPPORTUNITIES_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listRecommendations;

    const previousData = [...recommendedOpps, ...respData?.items];
    recommendedOpps = [...previousData];

    nextToken = respData?.nextToken;
  }

  return await recommendedOpps?.reduce(async (previousPromise, recommendation) => {
    const opportunity = await GetSingleOpportunity(recommendation.opportunityID);
    let opportunitiesData = await previousPromise;
    const obj = { ...recommendation, opportunity };
    opportunitiesData.push(obj);
    return opportunitiesData;
  }, Promise.resolve([]));
}

export const GET_RECOMMENDED_OPPORTUNITIES_BY_OPPID_AND_GROUPID = gql`
  query RecommendedOpportunitiesQuery($groupID: ID, $opportunityID: ID) {
    listRecommendations(
      limit: 1000
      filter: { groupID: { eq: $groupID }, opportunityID: { eq: $opportunityID } }
    ) {
      items {
        studentID
        opportunityID
        updatedAt
        id
        groupID
        educatorID
        createdAt
        categoryID
      }

      nextToken
    }
  }
`;

export const GET_RECOMMENDED_OPPORTUNITIES_WITH_TOKEN_BY_OPPID_AND_GROUPID = gql`
  query RecommendedOpportunitiesQuery(
    $limit: Int
    $nextToken: String
    $groupID: ID
    $opportunityID: ID
  ) {
    listRecommendations(
      limit: $limit
      nextToken: $nextToken
      filter: { groupID: { eq: $groupID }, opportunityID: { eq: $opportunityID } }
    ) {
      items {
        studentID
        opportunityID
        updatedAt
        id
        groupID
        educatorID
        createdAt
        categoryID
      }
      nextToken
    }
  }
`;

export async function GetAllRecommendedOppsByOppIDAndGroupID(oppID, groupID) {
  const resp = await API.graphql({
    query: GET_RECOMMENDED_OPPORTUNITIES_BY_OPPID_AND_GROUPID,
    variables: { opportunityID: oppID, groupID: groupID }
  });

  const data = resp?.data?.listRecommendations;
  let nextToken = data?.nextToken;
  let recommendedOpps = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_RECOMMENDED_OPPORTUNITIES_WITH_TOKEN_BY_OPPID_AND_GROUPID,
      variables: { limit: 1000, nextToken, opportunityID: oppID, groupID: groupID }
    });
    const respData = resp?.data?.listRecommendations;

    const previousData = [...recommendedOpps, ...respData?.items];
    recommendedOpps = [...previousData];

    nextToken = respData?.nextToken;
  }

  return recommendedOpps || [];
}

export const GET_RECOMMENDED_OPPORTUNITIES_BY_EDUCATOR_ID = gql`
  query RecommendedOpportunitiesQuery($educatorID: ID) {
    listRecommendations(limit: 1000, filter: { educatorID: { eq: $educatorID } }) {
      items {
        studentID
        opportunityID
        updatedAt
        id
        groupID
        educatorID
        createdAt
        categoryID
      }

      nextToken
    }
  }
`;

export const GET_RECOMMENDED_OPPORTUNITIES_WITH_TOKEN_BY_EDUCATOR_ID = gql`
  query RecommendedOpportunitiesQuery($limit: Int, $nextToken: String, $educatorID: ID) {
    listRecommendations(
      limit: $limit
      nextToken: $nextToken
      filter: { educatorID: { eq: $educatorID } }
    ) {
      items {
        studentID
        opportunityID
        updatedAt
        id
        groupID
        educatorID
        createdAt
        categoryID
      }
      nextToken
    }
  }
`;

export async function GetAllRecommendedOppsByEducatorID(educatorID) {
  const resp = await API.graphql({
    query: GET_RECOMMENDED_OPPORTUNITIES_BY_EDUCATOR_ID,
    variables: { educatorID }
  });

  const data = resp?.data?.listRecommendations;
  let nextToken = data?.nextToken;
  let recommendedOpps = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_RECOMMENDED_OPPORTUNITIES_WITH_TOKEN_BY_EDUCATOR_ID,
      variables: { limit: 1000, nextToken, educatorID }
    });
    const respData = resp?.data?.listRecommendations;

    const previousData = [...recommendedOpps, ...respData?.items];
    recommendedOpps = [...previousData];

    nextToken = respData?.nextToken;
  }

  return recommendedOpps || [];
}
