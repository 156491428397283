import React from 'react';
import Sidebar from '../Sidenav/index';
import MyAccountmain from './Components/MyAccountmain/MyAccountmain';

function MyAccount({ route }) {
  return (
    <div className="col-sm-12 p-0" style={{ overflowX: 'hidden' }}>
      <Sidebar component={<MyAccountmain route={route} />} />
    </div>
  );
}

export default MyAccount;
