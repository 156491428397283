import React from 'react';
import Sidebar from '../Sidenav/index';
import Mygroupsmain from './Components/Mygroupsmain/Mygroupsmain';
import Abouttogradmain from '../Abouttograd/Components/Abouttogradmain/Abouttogradmain';
import { useLocation } from 'react-router-dom';

function Mygroups() {
  const search = new URLSearchParams(useLocation().search);
  const groupId = search.get('id');

  return (
    <div className="col-sm-12 p-0" style={{ overflowX: 'hidden' }}>
      {groupId ? (
        <Sidebar component={<Abouttogradmain />} />
      ) : (
        <Sidebar component={<Mygroupsmain />} />
      )}
    </div>
  );
}

export default Mygroups;
