import { gql } from '@apollo/client';

export const CREATE_RECOMMENDATION = gql`
  mutation CreateRecommendationMutation(
    $educatorID: ID
    $opportunityID: ID!
    $groupID: ID
    $studentID: ID
    $categoryID: ID
    $createdAt: AWSDateTime
  ) {
    createRecommendation(
      input: {
        educatorID: $educatorID
        opportunityID: $opportunityID
        groupID: $groupID
        studentID: $studentID
        categoryID: $categoryID
        createdAt: $createdAt
      }
    ) {
      opportunityID
    }
  }
`;

export const UPDATE_RECOMMENDATION = gql`
  mutation UpdateRecommendationMutation(
    $id: ID!
    $educatorID: ID
    $opportunityID: ID
    $groupID: ID
    $studentID: ID
    $categoryID: ID
  ) {
    updateRecommendations(
      input: {
        id: $id
        educatorID: $educatorID
        opportunityID: $opportunityID
        groupID: $groupID
        studentID: $studentID
        categoryID: $categoryID
      }
    ) {
      id
    }
  }
`;
