import React from 'react';
import '../Home/Home.css';

function Homemodal() {
  return (
    <div className="col-sm-12 pl-0 ">
      <button
        type="button"
        className="btn btn-light modal_button "
        data-toggle="modal"
        data-target="#exampleModal">
        <b> add your team here </b>
      </button>

      {/* <!-- Modal --> */}
      <div
        className="modal fade modal_content"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col-sm-12  pt-3 pb-3">
                <div className="row">
                  <div className="col-sm-8 m-auto">
                    <div className="col-sm-12 pt-1">
                      <h6>Account verification</h6>
                    </div>
                  </div>
                  <div className="col-sm-4 ">
                    <div className="col-sm-12 text-right">
                      <button className="btn btn-light">
                        {' '}
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 pt-1">
                  <h3>
                    <b>Verify Your Institution</b>
                  </h3>
                </div>
                <div className="col-sm-12 pt-1">
                  <h6>
                    We want to keep Junity safe for everyone including you. Verify your account to:
                  </h6>
                </div>
                <div className="col-sm-12 pt-1">
                  <h6 className="mt-3">
                    <i className="fas fa-check-circle" id="icon_color"></i> Start recommending
                    Opportunities
                  </h6>
                  <h6 className="mt-3">
                    <i className="fas fa-check-circle" id="icon_color"></i> Start recommending
                    Opportunities
                  </h6>
                  <h6 className="mt-3">
                    <i className="fas fa-check-circle" id="icon_color"></i> Start recommending
                    Opportunities
                  </h6>
                </div>
                <div className="col-sm-12 mt-4">
                  <span>
                    <button
                      type="button"
                      className="btn btn-success   "
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#exampleModal2">
                      Request Verification
                    </button>
                  </span>
                  <span>
                    <button className="btn btn-light ml-2 pl-4 pr-4 btn_later">
                      {' '}
                      I'll do this later{' '}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal--2  */}
      <div
        className="modal fade modal_content"
        id="exampleModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col-sm-12  pt-3 pb-3">
                <div className="row">
                  <div className="col-sm-8 m-auto">
                    <div className="col-sm-12 pt-1">
                      <h6>Account verification</h6>
                    </div>
                  </div>
                  <div className="col-sm-4 ">
                    <div className="col-sm-12 text-right">
                      <button className="btn btn-light" data-dismiss="modal">
                        {' '}
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 text-center pt-4 pb-3">
                  <img className="img-fluid" src="../images/clock.png" />
                </div>
                <div className="col-sm-12 pt-1 text-center">
                  <h3>
                    <b>Verify Your Institution</b>
                  </h3>
                </div>
                <div className="col-sm-12 pt-1 text-center">
                  <h6>
                    A member of junity team will reach out to you within 48 hours to get your
                    account verified.
                  </h6>
                </div>
                <div className="col-sm-12 mt-4">
                  <span>
                    <button
                      className="btn btn-success btn_verification_progress "
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#exampleModal3">
                      Continue Exploring Junity
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal--3  */}
      <div
        className="modal fade modal_content"
        id="exampleModal3"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col-sm-12  pt-3 pb-3">
                <div className="row">
                  <div className="col-sm-8 m-auto">
                    <div className="col-sm-12 pt-1">
                      <h6>Account verification</h6>
                    </div>
                  </div>
                  <div className="col-sm-4 ">
                    <div className="col-sm-12 text-right">
                      <button className="btn btn-light" data-dismiss="modal">
                        {' '}
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 text-center pt-4 pb-3">
                  <img className="img-fluid" src="../images/hand.png" />
                </div>
                <div className="col-sm-12 pt-1 text-center">
                  <h3>
                    <b>Institution Institution Verification Successful!</b>
                  </h3>
                </div>
                <div className="col-sm-12 pt-1 text-center">
                  <h6>
                    Hey Julia Your Institution has been verified. You can now use junity with no
                    restrictions.{' '}
                  </h6>
                </div>
                <div className="col-sm-12 mt-4">
                  <span>
                    <button
                      className="btn btn-success btn_verification_progress "
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#exampleModal4">
                      Great! Continue Exploring Junity
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal--3  */}
      <div
        className="modal fade modal_content"
        id="exampleModal4"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col-sm-12  pt-3 pb-3">
                <div className="row">
                  <div className="col-sm-10 m-auto">
                    <div className="col-sm-12 pt-1">
                      <h3>
                        <b>Verify Your Institution</b>
                      </h3>
                    </div>
                  </div>
                  <div className="col-sm-2 ">
                    <div className="col-sm-12 text-right">
                      <button className="btn btn-light" data-dismiss="modal">
                        {' '}
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 pt-1 text-left">
                  <h6>
                    Hey Julia Your Institution has been verified. You can now use junity with no
                    restrictions.{' '}
                  </h6>
                </div>
                <div className="col-sm-12 mt-4">
                  <span>
                    <button type="button" className="btn btn-success   " data-dismiss="modal">
                      Verify my Institution
                    </button>
                  </span>
                  <span>
                    <button className="btn btn-light ml-2 pl-4 pr-4 btn_later">
                      {' '}
                      I'll do this later{' '}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homemodal;
