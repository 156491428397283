import React, { useRef, useEffect } from 'react'
import { Tooltip } from 'bootstrap';

export const InfoTooltip = ({
    children, 
    title,
    placement='right', 
    trigger='hover'
})=> {
    const tooltipRef = useRef();  

    useEffect(() => {
        var tooltip = new Tooltip(tooltipRef.current, {
            title,
            placement,
            trigger
        })
    })
    
    return (
      <div ref={tooltipRef}>
        {children}
      </div>
    )
  }