import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import AlertToast from '../common/Alerts/ToastAlert';
import { useSelector } from 'react-redux';
import { isRequiredFieldsPassed } from '../../utils/validators';
import { createHubspotTicket } from '../../utils/Hubspot';
import { ClipLoader } from 'react-spinners';
import './style.css';

export const ReportIssueModal = ({
  reportModalShow,
  setReportModalShow,
  type,
  module,
  title = 'Report Issue',
  description = null
}) => {
  const user = useSelector((store) => store.educatorUser?.data);
  const [state, setState] = useState({});
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastVariant, setToastVariant] = useState('success');
  const [toastIcon, setToastIcon] = useState('check');
  const [toastInfo, setToastInfo] = useState('');

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  const showToast = (variant, message) => {
    setToastVariant(variant);
    setToastIcon(variant === 'success' ? 'check' : 'delete');
    setToastInfo(message);
    setToastActive(true);
  };

  const progress = () => {
    return <ClipLoader size={10} color={loading ? '#fff' : ''} />;
  };

  useEffect(() => {
    setValid(isRequiredFieldsPassed(state, 2, 'eq'));
  }, [state]);

  const reportIssue = async () => {
    const subject = `${type} Issue Report - ${state?.title}`;
    let content = `Title: \n${state?.title}`;
    content += `\n\nContent: \n${state?.description}`;
    content += `\n\n${type} ID: \n${module?.id}`;
    if (
      type.toLowerCase() === 'opportunity' ||
      type.toLowerCase() === 'mentor' ||
      type.toLowerCase() === 'youth'
    ) {
      content += `\n\n${type} Name: \n${module?.name}`;
    }
    setLoading(true);
    try {
      await createHubspotTicket(subject, content, 'support', user?.hubspotID);
      setLoading(false);
      setReportModalShow(false);
      showToast('success', 'Issue reported successfully');
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={reportModalShow}
        onHide={() => {
          setReportModalShow(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal_body_style"
        centered>
        <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
          <Modal.Title>{title}</Modal.Title>
          <div
            className="close_btn drawer rounded-2"
            onClick={() => {
              setReportModalShow(false);
            }}>
            <IoMdClose fill="#515A6A" size={18} />
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 w-100 px-3 payment-modal institution-form">
          <h6 className="info-description mb-4">
            {description ? (
              <>{description}</>
            ) : (
              <>
                {`Don't hesitate to reach out and let us know about 
            any issues you encounter - we're here to help`}
              </>
            )}
          </h6>
          <div className="form-group">
            <label className="institution-form-label">Title</label>
            <input type="text" class="form-control" name="title" onChange={handleChange} />
          </div>
          <div class="form-group">
            <label className="institution-form-label">Description</label>
            <textarea
              rows={3}
              className="form-control"
              name="description"
              onChange={handleChange}></textarea>
          </div>
          <button
            type="button"
            className="btn btn-success btn-lg w-100"
            disabled={!valid}
            onClick={reportIssue}>
            {loading ? progress() : 'Submit'}
          </button>
        </Modal.Body>
      </Modal>
      <AlertToast
        variant={toastVariant}
        icon={toastIcon}
        active={toastActive}
        setActive={setToastActive}
        info={toastInfo}
      />
    </>
  );
};
