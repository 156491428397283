import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserDataAction } from '../../Redux/actions/userAction';
import { ValidateEmail } from '../../utils/helpers';
import { ClipLoader } from 'react-spinners';
import './ResetPassword.css';
import { GetEducatorsByEmail } from '../../Services/query/educator';
import { axiosClient } from '../../libs/axiosClient';

const ResetPassword = () => {
  const [email, setEmail] = useState();
  const [emailErr, setEmailErr] = useState("");
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const progress = () => {
    return <ClipLoader size={10} color={loading ? '#fff' : ''} />;
  };

  const requestPasswordChange = async () => {
    setLoading(true);

    try {
      const educator = await GetEducatorsByEmail(email);

      if (Object.keys(educator).length > 0) {
        setEmailErr("")
        const reqData = {
          name: educator.name,
          schoolName: educator.school.name,
          phone: educator.phone,
          email
        };

        await axiosClient.post('/reset-password', reqData);
        localStorage.setItem('remail', email)
        navigate('/check-email');
      }
      else {
        setEmailErr("Account with this email was not found")
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (email) setIsValid(ValidateEmail(email));
  }, [email]);

  return (
    <div className="col-sm-12 bgimage-signup reset-pass">
      <div className="row pl-sm-5">
        <div className="card col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-2 mt-sm-5 offset-sm-1 p-2">
          <div className="col-sm-12">
            <img 
              className='cursor-pointer' 
              src="../images/logo.png" 
              onClick={()=>{navigate('/login')}}/>
          </div>
          <div className="col-sm-12 pt-3">
            <Link to="/login" className='cursor-pointer' style={{ textDecoration: 'none' }}>
              <button className="btn btn-light btn_style_reset green-text p-0 btn-sm font-weight-bold">
                <i className="fas fa-angle-left"></i>&nbsp; Back to Log In{' '}
              </button>
            </Link>
          </div>
          <div className="col-sm-12">
            <p className="heading-text text-neutral font-weight-bold my-1">
              <b>Reset Password</b>
            </p>
          </div>
          <div className="col-sm-12 pt-1">
            <p className="paragraph-text fw-semibold opacity-75 mb-0">
              {' '}
              Please enter the email address associated with your Junity account and we'll send an
              email with instructions to reset your password.{' '}
            </p>
          </div>
          <div className="col-sm-12 mt-3">
            <div className="row">
              <div className="col-sm-12 mt-1">
                <label className="input-label-text opacity-85">Work email address</label>
                <input
                  className="form-control signupinput"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Type your email address"
                />
                {emailErr ? <p className="err">{emailErr} </p> : ''}
              </div>
            </div>
          </div>

          <div className="col-sm-12 mt-4 pb-3">
            <button
              onClick={requestPasswordChange}
              className={`btn btn-block font-weight-bolder border-0 py-2 ${
                !isValid ? 'inactive btn-neutral' : 'btn-success'
              }`}>
              {loading ? progress() : 'Send Reset Email'}
            </button>
          </div>
          {/* <div className='col-sm-12 pt-1'>
                                <hr />
                            </div>
                            <div className='col-sm-12 paragraph-text'>
                                <p><b>Already have an account? <span className='text-color-signup'>
                                    <Link to="/login" style={{ textDecoration: "none", color: "#226E54" }}>
                                        Log In
                                    </Link>
                                </span></b></p>
                            </div> */}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
