import React, { useState, useEffect} from 'react';
import { timePassed } from '../../../../../utils/helpers';
import { Link } from 'react-router-dom';
import './style.css';
import { API } from 'aws-amplify';
import { deleteAppNotification } from '../../../../../graphql/mutations';

const NotificationMsg = ({id, content, receivedTime=(new Date()), link=null, action='Click here to view'}) => {
  const [time, setTime] = useState(timePassed(receivedTime))

  const deleteNotification = async()=>{
    await API.graphql({
      query: deleteAppNotification,
      variables: {input: {id}}
    })
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(timePassed(receivedTime))
    }, 1000);
    return () => {
      clearInterval(timer)
    };
  }, [])

 return (
    <div className="message-item border-bottom mb-2">
        <div className='message-item-content'>
            <p>{content}</p>

            {link &&
            <Link to={link} onClick={deleteNotification} className='message-item-link'>{action}</Link>
            }
        </div>
      
      <div className='message-item-received'>{time} ago</div>
    </div>
 );
};

export default NotificationMsg;
