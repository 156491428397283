import { gql } from '@apollo/client';

export const CREATE_EDUCATOR = gql`
  mutation CreateEducatorMutation(
    $schoolID: ID
    $address: String
    $name: String
    $email: AWSEmail
    $phone: AWSPhone
    $isAdmin: Boolean
    $stripeID: String
    $hubspotID: String
    $credits: Float
    $subscription: String
    $createdAt: AWSDateTime
  ) {
    createEducator(
      input: {
        schoolID: $schoolID
        address: $address
        name: $name
        email: $email
        phone: $phone
        isAdmin: $isAdmin
        stripeID: $stripeID
        hubspotID: $hubspotID
        credits: $credits
        subscription: $subscription
        createdAt: $createdAt
      }
    ) {
      id
      bio
      createdAt
      dob
      address
      email
      gender
      name
      personality
      role
      isAdmin
      phone
      schoolID
      schoolid
      state
      updatedAt
      zipcode
      stripeID
      hubspotID
      credits
      subscription
      is_verified
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      deactivated
      date_deactivated
    }
  }
`;

export const UPDATE_EDUCATOR = gql`
  mutation UpdateEducatorMutation(
    $id: ID!
    $schoolID: ID
    $state: String
    $zipcode: String
    $name: String
    $email: AWSEmail
    $phone: AWSPhone
    $personality: String
    $image: String
    $role: String
    $isAdmin: Boolean
    $stripeID: String
    $hubspotID: String
    $credits: Float
    $twoFA: Boolean
    $subscription: String
    $verification_status: String
    $verification_date_started: AWSDateTime
  ) {
    updateEducator(
      input: {
        id: $id
        schoolID: $schoolID
        state: $state
        zipcode: $zipcode
        name: $name
        email: $email
        personality: $personality
        image: $image
        phone: $phone
        role: $role
        twoFA: $twoFA
        isAdmin: $isAdmin
        stripeID: $stripeID
        hubspotID: $hubspotID
        credits: $credits
        subscription: $subscription
        verification_status: $verification_status
        verification_date_started: $verification_date_started
      }
    ) {
      id
      bio
      createdAt
      dob
      address
      image
      email
      gender
      name
      personality
      role
      personality
      isAdmin
      phone
      schoolID
      schoolid
      state
      updatedAt
      zipcode
      is_verified
      stripeID
      credits
      subscription
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      deactivated
      date_deactivated
    }
  }
`;
