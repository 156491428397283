import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUp from './Auth/SignUp';
import Contactform from './Auth/Contactform';
import Signupthankyou from './Auth/Signupthankyou';
import Setupaccount from './Auth/Setupaccount';
import Creatingaccount from './Auth/Creatingaccount';
import AccountCreated from './Auth/AccountCreationComplete';
import Institution from './Auth/Insitution/Insitution';
import Login from './Auth/Login/index';
import Logout from './Auth/Logout';
import Sidenav from './Auth/Sidenav/index';
import Home from './Auth/Dashboardhome/Dashboardhome';
import ResetPassword from './Auth/ResetPassword/ResetPassword';
import Checkemail from './Auth/Checkemail/Checkemail';
import Confirmresetpassword from './Auth/Confirmresetpassword/Confirmresetpassword';
import Resetpasswordsuccessful from './Auth/Resetpasswordsuccessful/Resetpasswordsuccessful';
import Allopportunity from './Auth/Allopportunity/Allopportunity';
import Youth from './Auth/Youth/Youth';
import Mygroups from './Auth/Mygroups/Mygroups';
import Dashboard from './Auth/Dashboard/Dashboard';
import Chat from './Auth/Chats/Chat';
import MyAccount from './Auth/MyAccount/MyAccount';
import SignUpInstitution from './Auth/SignUpInstitution';
import Signupemail from './Auth/Signupemail';
import PageWrapper from './Components/common/PageWrapper/PageWrapper';
import InvitedToSignUp from './Auth/InvitedToSignUp';
import RedirectPage from './Auth/RedirectPage';
import RequiresInvitation from './Auth/RequiresInvitation';
import SocialAuthRedirectPage from './Auth/SocialAuthRedirect';

import './App.css';
import './styles/common/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from './Auth';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/sign-up" exact element={<SignUp />} />
          <Route path="/sign-up-institution" exact element={<SignUpInstitution />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/redirect" exact element={<SocialAuthRedirectPage />} />
          <Route path="/sign-up-email" exact element={<Signupemail />} />
          <Route path="/contact-form" exact element={<Contactform />} />
          <Route path="/signup-thankyou" exact element={<Signupthankyou />} />
          <Route path="/setup-account" exact element={<Setupaccount />} />
          <Route path="/creating-account" exact element={<Creatingaccount />} />
          <Route path="/account-created" exact element={<AccountCreated />} />
          <Route path="/logout" exact element={<Logout />} />
          <Route path="/invited-to-signup" exact element={<InvitedToSignUp />} />
          <Route path="/invitation-required" exact element={<RequiresInvitation />} />
          <Route path="/reset-password" exact element={<ResetPassword />} />
          <Route path="/check-email" exact element={<Checkemail />} />
          <Route path="/verify-email/redirect" exact element={<RedirectPage />} />
          <Route path="/confirm-reset-password" exact element={<Confirmresetpassword />} />
          <Route path="/reset-password-successful" exact element={<Resetpasswordsuccessful />} />
        </Routes>
        <AuthProvider>
          <Routes>
            <Route
              path="/"
              exact
              element={
                <PageWrapper>
                  <Dashboard />
                </PageWrapper>
              }
            />
            <Route
              path="/Dashboard"
              exact
              element={
                <PageWrapper>
                  <Sidenav />
                </PageWrapper>
              }
            />
            <Route
              path="/login-home"
              exact
              element={
                <PageWrapper>
                  <Home />
                </PageWrapper>
              }
            />
            <Route
              path="/all-opportunity"
              exact
              element={
                <PageWrapper>
                  <Allopportunity route="all-opportunity" />
                </PageWrapper>
              }
            />
            <Route
              path="/saved-opportunity"
              exact
              element={
                <PageWrapper>
                  <Allopportunity route="saved-opportunity" />
                </PageWrapper>
              }
            />
            <Route
              path="/youth"
              exact
              element={
                <PageWrapper>
                  <Youth />
                </PageWrapper>
              }
            />
            <Route
              path="/my-groups"
              exact
              element={
                <PageWrapper>
                  <Mygroups />
                </PageWrapper>
              }
            />
            <Route
              path="/dashboard-main"
              exact
              element={
                <PageWrapper>
                  <Dashboard />
                </PageWrapper>
              }
            />
            <Route
              path="/chat"
              exact
              element={
                <PageWrapper>
                  <Chat />
                </PageWrapper>
              }
            />
            <Route
              path="/my-account"
              exact
              element={
                <PageWrapper>
                  <MyAccount route="my-account" />
                </PageWrapper>
              }
            />
            <Route
              path="/account-settings"
              exact
              element={
                <PageWrapper>
                  <MyAccount route="account-settings" />
                </PageWrapper>
              }
            />
            <Route
              path="/notification"
              exact
              element={
                <PageWrapper>
                  <MyAccount route="notification" />
                </PageWrapper>
              }
            />
            <Route
              path="/subscription"
              exact
              element={
                <PageWrapper>
                  <MyAccount route="subscription" />
                </PageWrapper>
              }
            />
            <Route
              path="/institution"
              exact
              element={
                <PageWrapper>
                  <Institution route="institution" />
                </PageWrapper>
              }
            />
            <Route
              path="/credit-and-payment"
              exact
              element={
                <PageWrapper>
                  <Institution route="credit-and-payment" />
                </PageWrapper>
              }
            />
            <Route
              path="/team"
              exact
              element={
                <PageWrapper>
                  <Institution route="team" />
                </PageWrapper>
              }
            />
          </Routes>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
