import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ProfileInstitution from '../ProfileInstitution/ProfileInstitution';
import Team from '../Team/Team';
import Credit from '../Credit/Credit';
import { Link, useNavigate } from 'react-router-dom';
import './style.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function Tabswitchchat({ route }) {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const routes = ['institution', 'credit-and-payment', 'team'];

 useEffect(() => {
    setValue(routes.indexOf(route));
  }, [route]);

  return (
    <div className="col-sm-12 background_tab p-0" style={{ height: '100%' }}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab
              label="Profile"
              className={`tab_style ${value === 0 ? 'active' : ''}`}
              {...a11yProps(0)}
              onClick={() => navigate('/institution')}
            />
            <Tab
              label="Credit & Payments"
              className={`tab_style ${value === 1 ? 'active' : ''}`}
              {...a11yProps(1)}
              onClick={() => navigate('/credit-and-payment')}
            />
            <Tab
              label="Team"
              className={`tab_style ${value === 2 ? 'active' : ''}`}
              {...a11yProps(2)}
              onClick={() => navigate('/team')}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className="col-sm-12 pt-3 pb-3" style={{ backgroundColor: '#F0F0F0' }}>
            <ProfileInstitution />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div
            className="col-sm-12 py-3"
            style={{
              backgroundColor: '#F0F0F0',
              borderRadius: '10px',
              height: '100%'
            }}>
            <Credit />
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div
            className="col-sm-12 p-3"
            style={{
              backgroundColor: '#F0F0F0',
              borderRadius: '10px',
              height: '100%'
            }}>
            <Team />
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}
