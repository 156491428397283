import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UPDATE_OPPORTUNITY } from '../../../../Services/mutation/opportunity';
import { useMutation } from '@apollo/client';
import Sidedrawer from '../../../Allopportunity/Components/Sidedrawer/Sidedrawer';
import RecommendOppModal from './Modals/MyGroupsModal';
import AlertToast from '../../../../Components/common/Alerts/ToastAlert';
import './style.css';
import { calculateExpiryTime } from '../../../../utils/helpers';

const stopPropagation = (e) => {
  e.stopPropagation();
};
function SharedCards({ item, id }) {
  const user = useSelector((store) => store.educatorUser);
  const [itemData, setItemData] = useState([]);
  const [saved, setSaved] = useState(false);
  const [updateOpportunity] = useMutation(UPDATE_OPPORTUNITY);
  const [showModal, setShowModal] = useState(false);
  const [rightModal, setRightModal] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastVariant, setToastVariant] = useState('success');
  const [toastIcon, setToastIcon] = useState('check');
  const [toastInfo, setToastInfo] = useState('');
  const location = useLocation();

  const showToast = (variant, message) => {
    setToastVariant(variant);
    setToastIcon(variant === 'success' ? 'check' : 'delete');
    setToastInfo(message);
    setToastActive(true);
  };

  const copyOppLink = () => {
    showToast('success', 'Opportunity link copied to clipboard');
    const oppPage = process.env.REACT_APP_EXPLORE_URL + `/opportunities?id=${id}`;
    navigator.clipboard.writeText(oppPage);
  };

  const handleRightModalDraw = (status) => {
    setRightModal(status);
  };

  const toggleSaveOpportunities = async (e) => {
    stopPropagation(e);
    try {
      if (!itemData?.saved_by?.includes(user?.data?.id)) {
        const inputData = {
          id,
          saved_by: [...(itemData?.saved_by || []), user?.data?.id]
        };

        const { data } = await updateOpportunity({
          variables: { ...inputData }
        });
        setItemData({ ...itemData, ...data?.updateOpportunity });
        // setSaved(false);
        // setRefetch((prev) => !prev);
      } else {
        const inputData = {
          id,
          saved_by: itemData?.saved_by?.filter((elt) => elt !== user?.data?.id)
        };

        const { data } = await updateOpportunity({
          variables: { ...inputData }
        });
        setItemData({ ...itemData, ...data?.updateOpportunity });
        // setSaved(false);
        // setRefetch((prev) => !prev);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (item) {
      setItemData(item);
      setSaved(item?.saved_by?.includes(user?.data?.id));
    }
  }, [item]);

  return (
    <>
      <div
        className="opportunity-card mt-3 cursor-pointer"
        onClick={() => handleRightModalDraw(true)}>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <img
            src={itemData?.school?.image}
            className="img-fluid rounded-circle"
            style={{ width: 40, height: 40 }}
            alt=""
          />
          <div className="d-flex justify-start align-items-center">
            <i
              className={`${
                itemData?.saved_by?.includes(user?.data?.id) ? 'fas' : 'far'
              } fa-bookmark fa-fw`}
              style={{ fontSize: 16 }}
              onClick={toggleSaveOpportunities}></i>
            <div className="header-profile align-items">
              {location.pathname === '/all-opportunity' ? (
                <button
                  onClick={(e) => {
                    stopPropagation(e);
                    setRightModal(true);
                  }}>
                  <span className="icon_o">
                    {' '}
                    <i className="fas fa-ellipsis-h"></i>{' '}
                  </span>
                </button>
              ) : (
                <button data-toggle="dropdown" aria-expanded="false">
                  <span className="icon_o">
                    {' '}
                    <i className="fas fa-ellipsis-h"></i>{' '}
                  </span>
                </button>
              )}
              <div className="dropdown-menu dropdown-menu-right">
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={(e) => {
                    stopPropagation(e);
                    setShowModal(true);
                  }}>
                  <img src="../images/checked.png" alt="icon" />
                  Recommend opportunity
                </button>
                <button className="dropdown-item" type="button" onClick={toggleSaveOpportunities}>
                  <i
                    className={`${
                      itemData?.saved_by?.includes(user?.data?.id) ? 'fas' : 'far'
                    } fa-bookmark fa-fw`}
                    style={{ fontSize: 16 }}></i>
                  Save for later
                </button>
                <div className="dropdown-item" type="button" onClick={copyOppLink}>
                  <img src="../images/copy-link.png" alt="icon" />
                  Copy link
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h6 className="opportunity-name m-0 mt-1" style={{ whiteSpace: 'nowrap' }}>
            {itemData?.name?.substring(0, 28)}
          </h6>
          <h6 className="opportunity-owner m-0 mt-1">{itemData?.resourceName}</h6>
          <h6 className="opportunity-description m-0 mt-1">
            {`${itemData?.description?.substring(0, 65)}...`}
          </h6>
          <h6 className="font_card m-0 mt-2" style={{ fontSize: 12 }}>
            {calculateExpiryTime(itemData?.expiry_date)}
          </h6>
        </div>
        <div className="p-0 pb-3">
          <button type="button" className="opportunity-tag-badge">
            {itemData.category}
          </button>
        </div>
      </div>
      <RecommendOppModal modalShow={showModal} setModalShow={setShowModal} opportunityID={id} />
      <AlertToast
        variant={toastVariant}
        icon={toastIcon}
        active={toastActive}
        setActive={setToastActive}
        info={toastInfo}
      />
      <Sidedrawer
        user={user}
        item={itemData}
        drawOpen={rightModal}
        setDrawOpen={setRightModal}
        toggleSaveOpportunities={toggleSaveOpportunities}
      />
    </>
  );
}

export default SharedCards;
