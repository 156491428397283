import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { CREATE_MYGROUPS } from '../../../../Services/mutation/myGroups';

import InitialWrapper from '../../../../Components/common/InitialWrapper';
import { getRandomColorById } from '../../../../utils/helpers';

export default function AddStudentsDiv(props) {
  const [state, setState] = useState({});
  const [colors, setColors] = useState({});
  const [searchFilter, setSearchFilter] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [createGroup] = useMutation(CREATE_MYGROUPS);
  const user = useSelector((store) => store.educatorUser);
  const group = useSelector((store) => store.group);
  const data = user?.data;

  const [connectedStudents, setConnectedStudents] = useState(props.connections || []);

  const handleChange = (e) => {
    const obj = { ...state };
    if (Object.keys(obj || {})?.includes(e.target.name)) {
      delete obj[e.target.name];
    } else {
      obj[e.target.name] = true;
    }

    setState(obj);
  };

  const createNewGroup = () => {
    const inputData = {
      ...group?.data,
      roleID: data?.id,
      studentIDs: Object.keys(state),
      createdAt: new Date()
    };

    createGroup({
      variables: { ...inputData }
    })
      .then(() => {
        setState({});
        props.onHide();
        props.setState({});
        props.setNext('details');
        props.setRefetch(true);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (connectedStudents?.length) setFilteredStudents(connectedStudents);
  }, [connectedStudents]);

  useEffect(() => {
    const connectedStudentsData = connectedStudents.filter((elt) =>
      elt?.name?.includes(searchFilter)
    );
    const colors = connectedStudentsData.reduce((acc, student) => {
      return { ...acc, [student.id]: getRandomColorById(student.id) };
    }, {});
    setColors(colors);
    setFilteredStudents(connectedStudentsData);
  }, [searchFilter]);

  return (
    <>
      <h6 style={{ fontSize: '14px', color: '#2E394C' }}>
        Every youth added to this group gets notified when you make a recommendation.
      </h6>
      <h6
        className="my-3"
        style={{
          fontSize: '12px',
          fontWeight: 'bold',
          color: '#2E394C'
        }}>
        {Object.keys(state || {})?.length} of {connectedStudents?.length} youth selected
      </h6>
      <div className={`input-group border bg-white rounded-3 p-0 mb-3`}>
        <span className="input-group-text bg-white">
          <i className="fas fa-search text-gray"></i>
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="Find a Youth"
          value={searchFilter}
          onChange={(e) => {
            setSearchFilter(e.target.value);
          }}
        />
      </div>
      {filteredStudents?.map((elt, index) => (
        <div className="form-check pl-0 mb-3" key={index}>
          <input
            type="checkbox"
            className="form-check-input"
            id={elt?.id}
            checked={state[elt?.id]}
            onChange={handleChange}
            name={elt?.id}
          />
          <label className="form-check-label d-flex align-items-center">
            <InitialWrapper name={elt?.name} cls={'ml-2'} color={colors[elt?.id]} />
            <span className="table-body-text ml-2">{elt?.name}</span>
            <i className="fas fa-check-circle ml-1 icon-check-primary"></i>
          </label>
        </div>
      ))}
      <div className="form-group d-flex">
        <button type="button" className="btn btn-success" onClick={createNewGroup}>
          Add Youth
        </button>
      </div>
    </>
  );
}
