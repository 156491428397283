import React from 'react';
import Sidebar from '../Sidenav/index';
import Youthmain from './Components/Youthmain/Youthmain';

function Youth() {
  return (
    <div className="col-sm-12 p-0" style={{ overflowX: 'hidden' }}>
      <Sidebar component={<Youthmain />} />
    </div>
  );
}

export default Youth;
