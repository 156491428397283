import { useEffect, useState } from 'react';

import {
  AllRecommendedOpportunities,
  GetAllRecommendedOppsByEducatorID
} from '../../../../../Services/query/recommendation';
import { sameStateOppCheck } from '../../../../../utils/helpers';
import { youthAxiosClient } from '../../../../../libs/axiosClient';

export const isInCurrentMonth = (elt) => {
  const oppDate = new Date(elt?.createdAt);
  const now = new Date();

  if (now.getFullYear() === oppDate.getFullYear()) {
    return now.getMonth() === oppDate.getMonth();
  } else {
    return false;
  }
};

export const ReturnPercentage = (user) => {
  const [opps, setOpps] = useState([]);
  const [userOpps, setUserOpps] = useState([]);

  let percentage = (userOpps?.length / opps?.length) * 100;
  percentage = Math.ceil(percentage);

  const getAllOpportunities = async () => {
    const schoolAddress = user?.school?.full_address;
    const schoolAddressObj = schoolAddress ? JSON.parse(schoolAddress) : {};
    const state = schoolAddressObj?.address_components?.find(
      (elt) => elt.types[0] === 'administrative_area_level_1'
    );

    try {
      const resp = await youthAxiosClient.post('/opportunities', {
        limit: 1000,
        filter: { expiry_date: { gt: new Date() } },
        fields: [
          'id',
          'name',
          'description',
          'createdAt',
          'state',
          'expiry_date',
          'category',
          'schoolID',
          'saved_by',
          'remote',
          'interests'
        ]
      });
      const allOpps = resp?.data?.data;
      const oppsMatched = resp?.data?.data?.filter(
        (opp) => sameStateOppCheck(opp.state, state.short_name) === true
      );
      setOpps(oppsMatched);

      GetAllRecommendedOppsByEducatorID(user?.id).then((data) => {
        const uniqueOpportunities = [];
        data.forEach((opp) => {
          if (uniqueOpportunities.every((unique) => unique.opportunityID !== opp.opportunityID)) {
            uniqueOpportunities.push(opp);
          }
        });
        setUserOpps(
          uniqueOpportunities.filter((userOpp) =>
            allOpps.some((opp) => opp.id === userOpp.opportunityID)
          )
        );
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllOpportunities();
  }, []);

  return {
    percentage: percentage,
    userOpps: userOpps,
    opps: opps
  };
};

export function AlreadyRecommended(opportunityID) {
  const recommendedOpps = AllRecommendedOpportunities();
  const filterRecommendedOpps = recommendedOpps?.filter(
    (elt) => elt?.opportunityID === opportunityID
  );
  return filterRecommendedOpps?.length === 1;
}
