import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { setupAccountValidate, validatePassword } from '../../utils/validators';
import { useMutation } from '@apollo/client';
import { CREATE_EDUCATOR, UPDATE_EDUCATOR } from '../../Services/mutation/educator';
import { ClipLoader } from 'react-spinners';
import { cognitoidentity, paramsCreateUser, paramsWithPassword } from '../../userPool';
import { setUserDataAction } from '../../Redux/actions/userAction';
import { setEducatorDataAction } from '../../Redux/actions/educatorActions';
import { Auth } from 'aws-amplify';
import { GetSingleSchool } from '../../Services/query/school';
import { createStripeCustomerAccount } from '../../utils/Stripe';
import { createHubspotContact } from '../../utils/Hubspot';

import './style.css';
import AlertToast from '../../Components/common/Alerts/ToastAlert';
import { sendAppNotification, sendEmailNotification, validatePhone } from '../../utils/helpers';
import { MAXCHARACTERLENGTH } from '../../utils/data';
import { GetEducatorsByEmail } from '../../Services/query/educator';

const Setupaccount = () => {
  const [updateEducator] = useMutation(UPDATE_EDUCATOR);
  const signupData = useSelector((store) => store.signUp.data);
  const educator = useSelector((store) => store.educator.educator);

  const [state, setState] = useState({});
  const [phoneCls, setPhoneCls] = useState('');
  const [passwordCls, setPasswordCls] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [verified, setVerified] = useState(false);
  const passwordRef1 = useRef(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [createEducator] = useMutation(CREATE_EDUCATOR);
  const [activeToast, setActiveToast] = useState(false);
  const [toast, setToast] = useState('');

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const progress = () => {
    return <ClipLoader size={10} color={loading ? '#fff' : ''} />;
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value.slice(0, MAXCHARACTERLENGTH[e.target.name])
    });
  };

  const handleCheckChange = () => {
    setAgreeToTerms(!agreeToTerms);
  };

  const toggleType = (field) => {
    setPasswordVisible((prev) => !prev);
    if (field.type === 'text') {
      field.type = 'password';
    } else {
      field.type = 'text';
    }
  };

  const viewPassword = () => {
    toggleType(passwordRef1.current);
  };

  const createUser = async (data) => {
    await cognitoidentity
      .adminCreateUser(paramsCreateUser(data.contact_email, data.password, data))
      .promise();
    await cognitoidentity
      .adminSetUserPassword(paramsWithPassword(data.contact_email, data.password))
      .promise();

    const cognitoUser = await Auth.signIn(data.contact_email, data.password);
    dispatch(setEducatorDataAction(cognitoUser));
  };

  const createMentorAccount = async () => {
    const educatorName =
      (state.fname || signupData.fname) + ' ' + (state.lname || signupData.lname);
    const stripeID = await createStripeCustomerAccount(
      signupData?.email,
      educatorName,
      state?.phone,
      'mentor'
    );
    const educator = await createEducator({
      variables: {
        schoolID: signupData.schoolID,
        address: signupData.address,
        name: educatorName,
        email: signupData.email,
        phone: '+1' + state.phone,
        isAdmin: !signupData.requiresInvitation,
        stripeID: stripeID,
        credits: 0.0,
        subscription: 'free',
        createdAt: new Date()
      }
    });

    const educatorObj = { ...educator.data.createEducator };
    educatorObj.school = await GetSingleSchool(signupData.schoolID);
    const hubspotID = await createHubspotContact(
      educatorObj?.name,
      educatorObj?.email,
      educatorObj?.phone,
      'mentor',
      educatorObj?.school?.name,
      educatorObj?.website
    );
    await updateEducator({
      variables: { id: educatorObj?.id, hubspotID }
    });
    dispatch(setUserDataAction(educatorObj));

    const admin = await GetEducatorsByEmail(educatorObj?.school?.contact_email)
      await sendEmailNotification(
        educatorObj?.school?.contact_email,
        'member-joined-team',
        'New Team Member',
        {
          fname: admin?.name?.split(' ')[0],
          newMember: educatorName,
          account: admin?.name,
          organization: educatorObj?.school?.name,
          moduleUrl: window.location.origin
        }
      )

      await sendAppNotification(
        admin?.id,
        'A new colleague has joined your team on Junity.',
        'success',
        '/institution',
        'Click here to view',
        'resource',
        'inapp'
      )
    educatorObj?.school?.invitations?.forEach(async(invite, index)=>{
      if (invite !== signupData.email) {
        await sendEmailNotification(
          invite,
          'member-joined-team',
          'New Team Member',
          {
            fname: educatorObj?.school?.invitations_names[index]?.split(' ')[0],
            newMember: educatorName,
            account: educatorObj?.school?.invitations_names[index],
            organization: educatorObj?.school?.name,
            moduleUrl: window.location.origin
          }
        )

        const member = await GetEducatorsByEmail(invite)
        if (member && Object.keys(member)?.length){
          await sendAppNotification(
            member?.id,
            'A new colleague has joined your team on Junity.',
            'success',
            member?.isAdmin ? '/institution': '',
            member?.isAdmin ? 'Click here to view' : '',
            'mentor',
            'inapp'
          )
        }
      }
    })
  };

  const proceedToCreate = async () => {
    setLoading(true);
    try {
      const reqData = { ...state };
      reqData.contact_phone = '+1' + reqData.phone;
      reqData.name = (state.fname || signupData.fname) + ' ' + (state.lname || signupData.lname);
      if (educator?.username?.slice(0, 6) !== 'google') await createUser(reqData);
      await createMentorAccount();
      navigate('/creating-account');
    } catch (err) {
      if (err.code === 'UsernameExistsException') {
        setActiveToast(true);
        setToast('An account already exists');
      }
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    setState({
      ...state,
      contact_email: signupData.email,
      schoolName: signupData.schoolName,
      address: signupData.address,
      name: signupData.name
    });
  }, []);

  useEffect(() => {
    setupAccountValidate(state, setVerified, agreeToTerms);
    if (state?.phone?.length === 10) {
      const validPhone = validatePhone(state?.phone);
      setPhoneCls(validPhone ? 'border-success' : 'border-danger');
    } else {
      if (phoneCls !== '') setPhoneCls('');
    }
    if (state?.password?.length) {
      const validPassword = validatePassword(state?.password);
      setPasswordCls(validPassword ? 'border-success' : 'border-danger');
    } else {
      if (passwordCls !== '') setPasswordCls('');
    }
  }, [state, agreeToTerms]);

  return (
    <>
      <AlertToast
        variant={'error'}
        icon={'delete'}
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
      <div className="col-sm-12 bgimage-signup bg-2 overflow-auto">
        <div className="row pl-sm-5 pb-4 setup-row personal-detail">
          <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-2 mt-sm-5 offset-sm-1 p-2">
            <div className="card-wrapper">
              <div className="col-sm-12">
                <img src="../images/logo.png" alt="" />
              </div>
              <div className="col-sm-12 pt-3 ">
                <p className="account-text mb-0 gray-text fw-bold">Welcome, {signupData.fname}</p>
              </div>
              <div className="col-sm-12 mb-2">
                <p className="heading-text mb-0 opacity-85">
                  <b>Setup your account</b>
                </p>
              </div>

              <div className="col-sm-12 mt-2">
                <div className="row">
                  <div className="col-sm-6">
                    <label className="input-label-text opacity-75">First Name</label>
                    <input
                      className="form-control bg-light"
                      type="text"
                      placeholder="First Name"
                      name="fname"
                      defaultValue={signupData.fname}
                      onInput={handleChange}
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="input-label-text opacity-75">Last Name</label>
                    <input
                      className="form-control bg-light"
                      type="text"
                      name="Last Name"
                      defaultValue={signupData.lname}
                      onInput={handleChange}
                      placeholder="Last Name"
                      required
                    />
                  </div>

                  <div className="col-sm-12 mt-3">
                    <label className="input-label-text opacity-75">Institution</label>
                    <div className="input-group">
                      <input
                        className="form-control signupinput"
                        type="text"
                        name="schoolName"
                        value={signupData.schoolName}
                        placeholder="Institution Name"
                        aria-describedby="institution_name_checked"
                        onChange={handleChange}
                        required
                        disabled
                      />
                      <div className="input_checked px-2">
                        <span className="" id="institution_name_checked">
                          <CheckCircleFill size={20} fill="#2D9371" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-3">
                    <label className="input-label-text opacity-75">Official Email Address</label>
                    <div className="input-group">
                      <input
                        className="form-control signupinput"
                        type="text"
                        name="contact_email"
                        value={state.contact_email}
                        placeholder="Official Email Address"
                        aria-describedby="email_checked"
                        defaultValue={signupData.email}
                        required
                        disabled
                      />
                      <div className="input_checked px-2">
                        <span className="" id="email_checked">
                          <CheckCircleFill size={20} fill="#2D9371" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-3">
                    <label className="input-label-text opacity-75">Office Address</label>
                    <div className="input-group">
                      <input
                        className="form-control signupinput"
                        type="text"
                        name="address"
                        value={state.address}
                        defaultValue={signupData.address}
                        placeholder="Office Address"
                        aria-describedby="email_checked"
                        required
                        disabled
                      />
                      <div className="input_checked px-2">
                        <span className="" id="email_checked">
                          <CheckCircleFill size={20} fill="#2D9371" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-3">
                    <label className="input-label-text opacity-75">Work Phone</label>
                    <div className={`input-group ${phoneCls}`}>
                      <div className="country_code">+1</div>
                      <input
                        className="form-control signupinput bg-light"
                        type="text"
                        name="phone"
                        value={state.phone}
                        placeholder="Work Phone"
                        aria-describedby="email_checked"
                        onInput={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 mt-2">
                    <label className="input-label-text opacity-75">Password</label>
                    <div className={`input-group ${passwordCls}`}>
                      <input
                        className="form-control signupinput bg-light"
                        type="password"
                        name="password"
                        ref={passwordRef1}
                        onChange={handleChange}
                        placeholder="Choose a password"
                        aria-describedby="password1_view"
                        required
                      />
                      <div className="input_checked bg-light px-2 point" onClick={viewPassword}>
                        <span className="" id="password1_view">
                          {passwordVisible ? (
                            <i className="fas fa-eye-slash"></i>
                          ) : (
                            <i className="fas fa-eye"></i>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 px-0 mt-1">
                      <p className="paragraph-text-small opacity-80" style={{ fontSize: '10px' }}>
                        Password should be 8 characters long and contain a combination of alphabets,
                        numbers and special characters.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-2">
                    <Form.Check
                      type="checkbox"
                      checked={agreeToTerms}
                      onClick={handleCheckChange}
                      className="input-label-text checkbox"
                      label={
                        <span className="opacity-80">
                          I agree to Junity’s
                          <a href="https://joinjunity.com">
                            <span className="green-text bold">Terms and Conditions</span>
                          </a>{' '}
                          and
                          <a href="https://joinjunity.com">
                            <span className="green-text bold"> Privacy Policy.</span>
                          </a>
                        </span>
                      }
                      style={{
                        fontSize: 13
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-12 mt-2">
                <button
                  disabled={!verified}
                  className={`btn btn-success btn-block py-2 ${verified ? '' : 'inactive'}`}
                  style={{ fontSize: '14px' }}
                  onClick={proceedToCreate}>
                  {loading ? progress() : 'Sign Up'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setupaccount;
