import React, { useRef, useState } from 'react';
import EmojiPicker from 'emoji-picker-react';
import InitialsWrapper from '../../../../Widgets/InitialsWrapper/InitialsWrapper';
import JunityLogo from '../../../../Assets/images/logo.png';
import { ReactComponent as Emoji } from '../../../../Assets/images/emoji.svg';
import { ReactComponent as Attach } from '../../../../Assets/images/attach.svg';
import {
  formatDate,
  getInitial,
  messageCreatedDate,
  formatMessageTime,
  titleWords,
  pluralize,
  getRandomColorById
} from '../../../../utils/helpers';

import './style.css';
import { Send } from 'react-feather';
import { chatClient } from '../../../../libs/axiosClient';
import { BsChevronLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { ConfirmChatDeletionModal } from '../Modals';
import { ReportIssueModal, RemoveConnectionModal } from '../../../../Components/Modals';
import AttachmentModal from '../../../../Components/Modals/ChatAttachmentModal';

const ChatDetail = ({
  currentItem,
  name,
  type,
  messages,
  chatDateStarted,
  educators,
  user,
  id,
  getMessages,
  other
}) => {
  const chatDates = [];
  const [state, setState] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [studentOpps, setStudentOpps] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showMuteOptions, setShowMuteOptions] = useState(false);
  const [reportModalShow, setReportModalShow] = useState(false);
  const [toggleEmoji, setToggleEmoji] = useState(false);
  const [imagesSelected, setImagesSelected] = useState([]);
  const [imagesSelectedUrls, setImagesSelectedUrls] = useState([]);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);

  const bottomRef = useRef(null);
  const attachInputRef = useRef(null);

  const sendMessage = async () => {
    try {
      if (state.trim() !== '') {
        const messageData = {
          text: state.trim(),
          custom_json: JSON.stringify({
            sender_id: user?.id,
            sender_name: user?.name,
            type: 'message'
          })
        };
        if (attachments.length > 0) {
          messageData.attachments = attachments;
        }
        await chatClient.post(`chats/${id}/messages/`, messageData);
        setState('');
        setAttachments([]);
        getMessages();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const userMessagesJsx = (message) => {
    return (
      <>
        <div className="d-flex justify-content-end">
          <div className="first images-sects mt-3 mb-2">
            {message.attachments?.map((item, index) => (
              <img src={item?.file} className="m-2" alt="" key={index} width={100} height={100} />
            ))}
          </div>
        </div>
        <div className={`first ${type} mb-2`} key={message.id}>
          <div className="first-person">
            <div className="msg">{message?.text}</div>
            <div className="time">{formatMessageTime(message?.created)}</div>
          </div>
        </div>
      </>
    );
  };

  const appDetailsLarger = (app) => {
    return (
      <>
        {app && (
          <div className="second your-msg">
            <div className="post">
              <div className="com-img">
                <img src={app?.opportunity?.school?.banner || JunityLogo} alt="com-img" />
              </div>
              <div>
                <div className="title">{app?.opportunity?.name}</div>
                <div className="user-app-status">
                  <span className={`${app?.status}`}>{titleWords(app?.status)}</span>
                  <span className="time">{formatDate(app?.createdAt)}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const sentToMessagesJsx = (message) => {
    return (
      <div className="second mb-2" key={message.id}>
        <div className="first images-sects justify-content-start mt-3 mb-2">
          {message.attachments?.map((item, index) => (
            <img src={item?.file} className="m-2" alt="" key={index} width={100} height={100} />
          ))}
        </div>
        <div className="second-person">
          <div className="name">{JSON.parse(message?.custom_json)?.sender_name}</div>
          <div className="msg">{message?.text}</div>
          <div className="time">{formatMessageTime(message?.created)}</div>
        </div>
      </div>
    );
  };

  const messageType = (message) => {
    const customJsonObj = JSON.parse(message?.custom_json || '{}');
    if (Object.keys(customJsonObj)?.includes('type')) {
      if (customJsonObj.type === 'app-message') {
        const app = studentOpps.find((elt) => elt.id === customJsonObj.appId);
        return appDetailsLarger(app);
      } else if (customJsonObj.type === 'app-sub') {
        const modMessage = { ...message };
        modMessage.text = name + ' ' + modMessage.text;
        return sentToMessagesJsx(modMessage);
      } else {
        if (customJsonObj.sender_id === user?.id) {
          return userMessagesJsx(message);
        } else {
          return sentToMessagesJsx(message);
        }
      }
    }
  };

  const messageLogo = () => {
    const customObj = JSON.parse(currentItem?.custom_json || '{}');
    if (type === 'youth') {
      return (
        <InitialsWrapper
          type="medium"
          text={getInitial(name)}
          bg={getRandomColorById(customObj?.creatorID)}
        />
      );
    } else if (type === 'team' && other === 'dm') {
      let userId = '';
      if (customObj.creatorID === user?.id) userId = customObj.destinationID;
      else userId = customObj.creatorID;
      const educator = educators.find((elt) => elt.id === userId);
      return educator?.image ? (
        <img src={educator?.image} width={40} height={40} style={{ borderRadius: '50%' }} alt="" />
      ) : (
        <InitialsWrapper type="medium" text={getInitial(name)} bg={getRandomColorById(userId)} />
      );
    } else {
      return (
        <img
          src={user?.school?.image}
          width={40}
          height={40}
          style={{ borderRadius: '50%' }}
          alt=""
        />
      );
    }
  };

  const messageSectionDateLabel = (elt) => {
    let chatDayJsx = null;
    if (!chatDates.includes(messageCreatedDate(formatMessageTime(elt?.created)))) {
      chatDayJsx = (
        <div className={`day-of-chat ${type} mb-2`}>
          <div className="status">{messageCreatedDate(formatMessageTime(elt?.created))}</div>
        </div>
      );
      chatDates.push(messageCreatedDate(formatMessageTime(elt?.created)));
    } else {
      chatDayJsx = <></>;
    }
    return chatDayJsx;
  };

  const bottomMessage = (chat) => {
    if (type === 'youth' || other === 'dm') {
      if (chat?.people?.some((elt) => elt.person.username !== user.id && elt.person.is_online)) {
        return 'Online';
      }
      return 'Last seen recently';
    } else {
      const onlineMembers = chat?.people?.filter(
        (elt) => elt.person.username !== user.id && elt.person.is_online
      );
      return onlineMembers?.length
        ? pluralize(onlineMembers, 'members', 'member') + ' online'
        : '1 member online';
    }
  };

  const isOnline = (chat) => {
    if (type === 'youth' || other === 'dm') {
      if (chat?.people?.some((elt) => elt.person.username !== user.id && elt.person.is_online)) {
        return <div className={'online-indicator'}></div>;
      }
      return <div className={'offline-indicator'}></div>;
    }

    return <></>;
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      await handleEnterKey();
    }
  };

  const handleEnterKey = async () => {
    await sendMessage();
  };

  const handleEmojiClick = (item) => {
    setState(state + item.emoji);
    setToggleEmoji(false);
  };

  const handleAttachmentChange = (e) => {
    const files = e.target.files;
    setImagesSelected(files);
    const imgUrls = Array.from(files).map((elt) => URL.createObjectURL(elt));
    setImagesSelectedUrls(imgUrls);
    setShowAttachmentModal(true);
  };

  return (
    <>
      <div className="position-fixed" style={{ bottom: 0, left: 0, right: 0 }}>
        <AttachmentModal
          chatId={id}
          user={user}
          images={imagesSelected}
          sendMessage={sendMessage}
          getMessages={getMessages}
          show={showAttachmentModal}
          setImages={setImagesSelected}
          imageUrls={imagesSelectedUrls}
          setImageUrls={setImagesSelectedUrls}
          onHide={() => setShowAttachmentModal(false)}
        />
      </div>
      <div className="col-sm-12 p-0">
        <div className="col-sm-12 py-2 chat-details">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-3">
              <Link to="/chat" className="text-black-50 d-sm-none">
                <BsChevronLeft strokeWidth={1} />
              </Link>
              <div className="position-relative">
                {messageLogo()}
                {isOnline(currentItem)}
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex gap-0">
                  <span className="message-contact white-space-nowrap">{name}</span>
                  {type === 'youth' && user?.is_verified && (
                    <i id="icon_style" className="fas fa-check-circle ml-1 youth-detail"></i>
                  )}
                </div>
                <span className="last-message white-space-nowrap">
                  {bottomMessage(currentItem)}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center gap-2">
              {type === 'youth' && (
                <Link
                  to={`/youth?youthId=${user?.id}`}
                  className="btn btn-light"
                  style={{
                    border: '1px solid #DDDFE2',
                    backgroundColor: '#F4F5F7',
                    fontSize: '13px'
                  }}>
                  View Profile
                </Link>
              )}
              {/* <Dropdown show={showMuteOptions}>
                <Dropdown.Menu
                  align={'start'}
                  autoClose={true}>
                  <Dropdown.Item>Hello</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
              <div className="dropdown">
                <i
                  className="fas fa-ellipsis-v text-secondary cursor-pointer"
                  id="triggerId1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"></i>
                <div className="dropdown-menu dropdown-menu-left" aria-labelledby="triggerId1">
                  {type === 'youth' ? (
                    <>
                      <a
                        className="dropdown-item text-neutral"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowDeleteModal(true);
                        }}>
                        <i className="fas fa-trash-alt me-1"></i>
                        {'Delete Chat'}
                      </a>
                      <div className="dropdown-divider"></div>
                      <a
                        className="dropdown-item text-danger"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowRemoveModal(true);
                        }}>
                        <i className="far fa-times-circle me-1"></i>
                        {'Remove Connection'}
                      </a>
                      <a
                        href="#"
                        className="dropdown-item text-neutral"
                        onClick={(e) => {
                          e.preventDefault();
                          setReportModalShow(true);
                        }}>
                        <i className="far fa-flag-alt me-1"></i>
                        {'Report Issue'}
                      </a>
                    </>
                  ) : (
                    <a
                      className="dropdown-item text-neutral"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowMuteOptions(true);
                      }}>
                      <i className="fas fa-volume-mute me-1"></i>
                      {'Mute Chat'}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="m-0" />
        <div className="col-sm-12 chatdetail_height pt-3">
          <div className="first-alert">Chat started {chatDateStarted}</div>
          {messages.map((message, index) => (
            <>
              {messageSectionDateLabel(message)}
              {messageType(message, index)}
            </>
          ))}
          <div ref={bottomRef} />
        </div>
        <hr className="m-0" />
        <div className="col-sm-12" style={{ height: '88px' }}>
          <div className="d-flex align-items-center pb-3">
            <div className="d-flex justify-start">
              <div className="col-sm-1 m-auto pt-3" onClick={() => setToggleEmoji((prev) => !prev)}>
                <Emoji />
              </div>
              <div className="col-sm-1 m-auto pt-3">
                <Attach onClick={() => attachInputRef.current.click()} />
                <input
                  type="file"
                  name="attach"
                  accept="image/*"
                  ref={attachInputRef}
                  onChange={handleAttachmentChange}
                  style={{ display: 'none' }}
                  multiple
                />
              </div>
            </div>
            <div className="col-sm-10 pt-3 detail-msg text-right">
              <input
                className="form-control"
                value={state}
                type="text"
                placeholder="Type a message"
                onChange={(e) => setState(e.target.value)}
                style={{ borderRadius: '10px' }}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div
              className="d-flex align-items-center"
              style={{ height: '40px', marginTop: '15px' }}>
              <Send
                size={20}
                onClick={sendMessage}
                stroke={state.trim() === '' ? '#515A6A' : '#226E54'}
              />
            </div>
          </div>
        </div>
      </div>
      {toggleEmoji && (
        <div>
          <EmojiPicker onEmojiClick={handleEmojiClick} />
        </div>
      )}
      <ConfirmChatDeletionModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        username={user?.name}
        chatId={id}
      />
      <ReportIssueModal
        reportModalShow={reportModalShow}
        setReportModalShow={setReportModalShow}
        type={'Chat'}
        module={currentItem}
      />
      <RemoveConnectionModal
        showRemoveModal={showRemoveModal}
        setShowRemoveModal={setShowRemoveModal}
        userId={user?.id}
        organizationID={user?.school?.id}
      />
    </>
  );
};

export default ChatDetail;
