/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:7c591d8a-3daf-4ada-843c-b5b28d3882f1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_7Lrrwdmmo',
  aws_user_pools_web_client_id: '47vlugpsti37b1kriukm3crjfm',
  oauth: {
    domain: 'counselorsjunity.auth.us-east-1.amazoncognito.com',
    scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile'],
    redirectSignIn: 'https://counselors.joinjunity.com/redirect/',
    redirectSignOut: 'https://counselors.joinjunity.com/logout',
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_AND_IDENTITY_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['GOOGLE'],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS', 'TOTP'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_UPPERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS'
    ]
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: 'junity-counselor-storage204659-staging',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_appsync_graphqlEndpoint:
    'https://t2dqqmao3zbfrjhf4c6g564ixq.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-camafdwtn5a75bmpwir7lr7jje'
};

export default awsmobile;
