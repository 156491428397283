import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import AlertToast from '../../../../../Components/common/Alerts/ToastAlert';
import { useMutation } from '@apollo/client';
import { UPDATE_EDUCATOR } from '../../../../../Services/mutation/educator';
import { sendEmailNotification } from '../../../../../utils/helpers';

export const ChangeRoleModal = ({ modalShow, setModalShow, member, refetch }) => {
  const [toastVariant, setToastVariant] = useState('success');
  const [updateEducator] = useMutation(UPDATE_EDUCATOR);
  const [activeToast, setActiveToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState('');
  const [state, setState] = useState({});
  const toastIcons = {
    success: 'check',
    error: 'delete'
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const updateRole = async () => {
    setLoading(true);
    try {
      const isAdmin = (state?.role?.toLowerCase() === 'admin' || state?.role?.toLowerCase() === 'administrator')
      let variables = { id: member?.id, role: state?.role, isAdmin}
      if (isAdmin){
        variables = {...variables, 
          verification_status: 'verified',
          verification_progress: 100,
          verification_date_started: new Date(),
          is_verified: true
        }
      }
      await updateEducator({ variables });

      if (isAdmin){
        await sendEmailNotification(
          member?.email,
          'resource-role-changed',
          'Role Change',
          {
            fname: member?.name?.split(' ')[0],
            account: member?.name,
            organization: member?.school?.name,
            moduleUrl: window.location.origin
          }
        )
      }

      setLoading(false);
      setToast(`Updated role of ${member?.name}`);
      setToastVariant('success');
      setActiveToast(true);
      refetch();
    } catch (error) {
      setLoading(false);
      console.log(error);
      setToast('Error updating role');
      setToastVariant('error');
      setActiveToast(true);
    }
  };

  return (
    <>
      {/* Change Password Modal */}
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal_body_style"
        centered>
        <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
          <Modal.Title>Change Role</Modal.Title>
          <div
            className="close_btn drawer rounded-2"
            onClick={() => {
              setModalShow(false);
            }}>
            <IoMdClose fill="#515A6A" size={18} />
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 w-100 px-3 payment-modal text-center">
          <div className="col-sm-12 text-center">
            <img src="../images/caution.png" className="image-info" alt="" />
          </div>
          <h5 className="info-heading mt-4">Change role</h5>
          <h6 className="info-description mb-4">
            You are about to change the role of <b>{member?.name}</b>. Are you sure you want to
            continue?
          </h6>

          <div className="form-group text-start">
            <label className="institution-form-label">Role</label>
            <input
              type="text"
              name="role"
              defaultValue={member?.role}
              onChange={handleChange}
              className="form-control"
              placeholder="Enter the name of the role"
              required
            />
          </div>

          <div className="form-group text-start">
            <label className="institution-form-label">
              Why are you changing role? <span style={{ color: '#969CA5' }}> (Optional) </span>
            </label>
            <textarea className="form-control border"></textarea>
          </div>

          <div className="form-group mt-4 d-flex justify-content-between gap-3">
            <button
              type="button"
              style={{
                backgroundColor: 'white',
                border: '1px solid gray',
                color: '#000'
              }}
              className="btn btn-secondary"
              onClick={() => {
                setModalShow(false);
              }}>
              Cancel
            </button>
            <button
              type="button"
              className={`btn btn-success ${!state?.role || loading ? 'inactive' : ''}`}
              disabled={loading || !state?.role}
              onClick={updateRole}>
              {loading ? <i className="fas fa-spinner fa-spin"></i> : 'Yes! Change Role'}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <AlertToast
        variant={toastVariant}
        icon={toastIcons[toastVariant]}
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
    </>
  );
};
