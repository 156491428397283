import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AiOutlineAppstore } from 'react-icons/ai';
import { BsChevronLeft } from 'react-icons/bs';
import { GetCategoryWithInterests } from '../../../../Services/query/categories';
import DynamicReactIcon from '../../../../Components/common/CustomIcon';
import SharedCards from '../../../Dashboard/Components/SharedCards/SharedCards';
import CollapseOpportunities from './CollapseOpportunities/CollapseOpportunities';

import '../Savedopportunities/Savedopportunities.css';

function SavedOpportunities({ opps }) {
  const [selectedInterest, setSelectedInterest] = useState('');
  const [selectedInterestItems, setSelectedInterestItems] = useState([]);
  const [categoriesWithCount, setCategoriesWithCount] = useState({});
  const interestItems = useRef(null);
  const subFrame = useRef();
  const user = useSelector((store) => store?.educatorUser?.data);
  const [filteredLikeOppsData, setFilteredLikedOppsData] = useState([]);
  const [categories, setCategories] = useState([]);

  const showSubFrame = (name, id) => {
    setSelectedInterest(name);
    setSelectedInterestItems(categories.find((category) => category.id === id));
    subFrame.current.classList.remove('d-none');
    subFrame.current.style.marginRight = `0`;
  };

  const hideSubFrame = () => {
    let innerWidth = interestItems.current.innerWidth;
    subFrame.current.style.marginRight = `${-innerWidth}px`;
    subFrame.current.classList.add('d-none');
  };

  useEffect(() => {
    let innerWidth = interestItems.current.innerWidth;
    subFrame.current.style.marginRight = `${-innerWidth}px`;
    setFilteredLikedOppsData(opps?.filter((elt) => elt?.saved_by?.includes(user?.id)));
    GetCategoryWithInterests().then((categories) => {
      setCategories(categories);
      const interestsCount = opps.reduce((acc, opp) => {
        opp?.interests?.forEach((interest) => {
          if (Object.keys(acc || {})?.includes(interest)) acc[interest] += 1;
          else acc[interest] = 1;
        });
        return acc;
      }, {});
      setCategoriesWithCount(interestsCount);
    });
  }, []);

  return (
    <div className="col-sm-12 p-0">
      <div className="col-sm-12 pt-3 pb-3">
        <div className="row">
          <div className="col-sm-8" style={{ height: '80vh' }}>
            <div className="col-sm-12  backcolor_allopp">
              <div className="col-sm-12 pt-3 pb-3 pl-0">
                <h6 className="subheading">Showing {filteredLikeOppsData?.length} opportunities</h6>
              </div>
              <div
                className="opp_cards pb-2"
                style={{
                  width: '100%',
                  gridTemplateColumns: 'repeat(3, auto)'
                }}>
                {filteredLikeOppsData?.map((item, index) => (
                  <>
                    <React.Fragment key={index}>
                      <SharedCards item={item} />
                    </React.Fragment>
                  </>
                ))}
              </div>
            </div>
          </div>
          <div className="col-sm-4 position-relative">
            <div className="col-sm-12 backcolor_allopp pb-3" ref={interestItems}>
              <div className="col-sm-12 pt-3 pb-3 pl-2 ">
                <h6 className="subheading m-0">Interest</h6>
              </div>
              <div className="col-sm-12 ">
                <div className="interests-list-item heading">
                  <div className="d-flex align-items-center">
                    <AiOutlineAppstore className="mr-2" size={22} />
                    <span>All Saved Opportunities</span>
                  </div>
                  <h6>{filteredLikeOppsData?.length}</h6>
                </div>
                {categories.map((elt, index) => (
                  <React.Fragment key={index}>
                    <CollapseOpportunities
                      icon={<DynamicReactIcon name={elt.icon_name} />}
                      name={elt.name}
                      categoryId={elt.id}
                      clickAction={() => showSubFrame(elt?.name, elt?.id)}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div
              className="col-sm-12 backcolor_allopp pb-3 position-absolute subframe top-0 d-none"
              ref={subFrame}>
              <div
                className="col-sm-12 pt-3 pb-3 pl-2 d-flex align-items-center cursor-pointer"
                onClick={hideSubFrame}>
                <BsChevronLeft size={13} fill="#969CA5" />
                <h6 className="subheading m-0 ms-2">{selectedInterest}</h6>
              </div>
              <div className="col-sm-12 ">
                {selectedInterestItems?.interests?.map((elt, index) => (
                  <React.Fragment key={index}>
                    <CollapseOpportunities
                      icon={<DynamicReactIcon name={elt.icon_name} />}
                      name={elt.name}
                      sub={true}
                      count={categoriesWithCount[elt.name]}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SavedOpportunities;
