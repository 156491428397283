import axios from 'axios';

const paymentURL = process.env.REACT_APP_PAYMENT_URL;

export const getStripeCustomerAccount = async (customerID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${paymentURL}/customers?customerID=${customerID}`);
      const result = response?.data;
      const { status, message, customer } = result;

      status === 'success' ? resolve(customer) : reject(new Error(message));
    } catch (error) {
      console.log(error);
      reject(error?.message);
    }
  });
};
export const createStripeCustomerAccount = async (email, name, phone, userType) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${paymentURL}/customers`, {
        email,
        name,
        phone,
        userType
      });
      const result = response?.data;
      const { status, message, customerId } = result;

      status === 'success' ? resolve(customerId) : reject(new Error(message));
    } catch (error) {
      console.log(error);
      reject(error?.message);
    }
  });
};

export const attachPaymentMethodToCustomer = async (customerID, paymentMethodID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${paymentURL}/payments/attach`, {
        customerID,
        paymentMethodID
      });
      const result = response?.data;
      const { status, message, paymentMethod } = result;

      status === 'success' ? resolve(paymentMethod) : reject(new Error(message));
    } catch (error) {
      console.log(error);
      reject(error?.message);
    }
  });
};

export const ListCustomerPaymentMethods = async (customerID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${paymentURL}/payments?customerID=${customerID}`);

      const result = response?.data;
      const { status, message, paymentMethods } = result;

      status === 'success' ? resolve(paymentMethods) : reject(new Error(message));
    } catch (error) {
      console.log(error);
      reject(error?.message);
    }
  });
};

export const createPaymentIntent = async (
  amount,
  customerID,
  email,
  paymentMethodID,
  description,
  metadata
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${paymentURL}/payments`, {
        amount,
        customerID,
        email,
        paymentMethodID,
        description,
        metadata
      });
      const result = response?.data;
      const { status, message, paymentIntent } = result;

      status === 'success' ? resolve(paymentIntent) : reject(new Error(message));
    } catch (error) {
      console.log(error);
      reject(error?.message);
    }
  });
};

export const confirmPayment = async (paymentIntentID, paymentMethodID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${paymentURL}/payments/confirm`, {
        paymentIntentID,
        paymentMethodID
      });
      const result = response?.data;
      const { status, message, intent } = result;

      status === 'success' ? resolve(intent) : reject(new Error(message));
    } catch (error) {
      console.log(error);
      reject(error?.message);
    }
  });
};

export const ListCustomerPaymentHistory = async (customerID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${paymentURL}/payments/history?customerID=${customerID}`);

      const result = response?.data;
      const { status, message, payments } = result;

      status === 'success' ? resolve(payments) : reject(new Error(message));
    } catch (error) {
      console.log(error);
      reject(error?.message);
    }
  });
};

export const UpdatePaymentMethod = async (paymentMethodID, billing_details) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.put(`${paymentURL}/payments`, {
        paymentMethodID,
        billing_details
      });
      const result = response?.data;
      const { status, message, paymentMethod } = result;

      status === 'success' ? resolve(paymentMethod) : reject(new Error(message));
    } catch (error) {
      console.log(error);
      reject(error?.message);
    }
  });
};

export const DetachPaymentMethod = async (paymentMethodID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.delete(
        `${paymentURL}/payments?paymentMethodID=${paymentMethodID}`
      );

      const result = response?.data;
      const { status, message, paymentMethod } = result;

      status === 'success' ? resolve(paymentMethod) : reject(new Error(message));
    } catch (error) {
      console.log(error);
      reject(error?.message);
    }
  });
};

export const GenerateInvoice = async (customer, price, tax, description, quantity, metadata) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${paymentURL}/invoices`, {
        customer,
        price,
        tax,
        description,
        quantity,
        metadata
      });
      const result = response?.data;
      const { status, message, invoice } = result;

      status === 'success' ? resolve(invoice) : reject(new Error(message));
    } catch (error) {
      console.log(error);
      reject(error?.message);
    }
  });
};

export const SendPurchaseOrderToEmail = async (info) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${paymentURL}/payments/purchase-order`, { ...info });
      const result = response?.data;
      const { status, message, data } = result;

      resolve(message);
    } catch (error) {
      console.log(error);
      reject(error?.message);
    }
  });
};

export const GetPaymentSettings = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${paymentURL}/settings`);

      const result = response?.data;
      const { status, message, price, transaction_fees } = result;

      status === 'success' ? resolve({ price, transaction_fees }) : reject(new Error(message));
    } catch (error) {
      console.log(error);
      reject(error?.message);
    }
  });
};
