import { gql, useQuery } from '@apollo/client';

export const GET_NOTES = gql`
  query AllNotes {
    listNotes {
      items {
        id
        color
        description
        title
        studentID
        createdAt
        updatedAt
      }
    }
  }
`;

export function AllNotes() {
  const resp = useQuery(GET_NOTES);
  if (resp?.data) {
    return {
      data: resp?.data?.listNotes?.items,
      refetch: resp?.refetch
    };
  }

  return {};
}
