import React, { useState, useEffect } from 'react';
import './Opportunities.css';
import BasicTabs from '../Opportunitiesf/Opportunitiesf';
import { useSelector } from 'react-redux';
import { sameStateOppCheck } from '../../../../utils/helpers';
import { youthAxiosClient } from '../../../../libs/axiosClient';

function Opportunities({ route, sectHandler }) {
  const [opps, setOpps] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const user = useSelector((store) => store.educatorUser.data);

  const getAllOpportunities = async () => {
    const schoolAddress = user?.school?.full_address;
    const schoolAddressObj = schoolAddress ? JSON.parse(schoolAddress) : {};
    const state = schoolAddressObj?.address_components?.find(
      (elt) => elt.types[0] === 'administrative_area_level_1'
    );

    try {
      const resp = await youthAxiosClient.post('/opportunities', {
        limit: 1000,
        filter: { expiry_date: { gt: new Date() } },
        fields: [
          'id',
          'name',
          'description',
          'createdAt',
          'state',
          'expiry_date',
          'category',
          'schoolID',
          'saved_by',
          'remote',
          'interests'
        ]
      });
      const oppsMatched = resp?.data?.data?.filter(
        (opp) => sameStateOppCheck(opp.state, state.short_name) === true
      );
      setOpps(oppsMatched);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllOpportunities();
  }, [refetch]);

  return (
    <div className="col-sm-12 pt-2  pl-0 pr-0">
      <div className="col-sm-12 pt-3 pb-4 background_color_opportunities ">
        <h4 className="page-name">
          <b>Opportunities</b>
        </h4>
      </div>

      {opps?.length > 0 ? (
        <BasicTabs sectHandler={sectHandler} route={route} opps={opps} setRefetch={setRefetch} />
      ) : (
        <div className="col-sm-12 background_color pt-5 pb-3">
          <div className="row">
            <div className="col-sm-8 div1_height">
              <div className="col-sm-12 backcolor text-center div2_height">
                <div className="col-sm-12 pt-3  text-left ">
                  <div className="row ">
                    <div className="col-sm-6 m-auto ">
                      <div className="col-sm-12 text_color">
                        <h6>
                          <b>No Opportunities</b>
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="col-sm-12 text-right">
                        <div className="input-group ">
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-light btn-sm btn_opportunity_style"
                              type="button">
                              <i className="fas fa-search"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control form-control-sm "
                            aria-label
                            aria-describedby="basic-addon1"
                            placeholder="Search.."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  src="./images/card3.png"
                  className="img-fluid img_size_set_opportunity"
                  alt=""
                />
                <h6 className="font_home1">NO OPPORTUNITIES YET</h6>
              </div>
            </div>
            <div className="col-sm-4 div1_height">
              <div className="col-sm-12 backcolor  text-center div2_height ">
                <div className="col-sm-12 pt-3  text-left ">
                  <div className="row ">
                    <div className="col-sm-6 m-auto ">
                      <div className="col-sm-12 text_color">
                        <h6>
                          <b>Filters</b>
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="col-sm-12 text-right">
                        <button className="btn btn-light text_color">
                          <i className="fas fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <img src="./images/msg.png" className="img-fluid img_size_set_opportunity" />
                <h6 className="font_home1">NO CONTENT</h6>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Opportunities;
