import React, { useState } from 'react';
import './style.css';
import { Link, useNavigate } from 'react-router-dom';

const Signupthankyou = () => {
  return (
    <div className="col-sm-12 bgimage-signup1">
      <div className="row">
        <div className="col-sm-6 bgcolorsignup1">
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-8 bgcolorsignup p-2">
              <div className="col-sm-12">
                <img src="../images/logo.png" />
              </div>
              <div className="col-sm-12 mt-4 pl-4">
                <div className="row">
                  <span>
                    {' '}
                    <h3>
                      <b>Thank you!</b>
                    </h3>
                  </span>
                  <span>
                    {' '}
                    <img src="../images/thumbup.png" className="img-fluid" />
                  </span>
                </div>
              </div>

              {/* <div className='row' style={{overflow:"hidden"}}>
                <div className='col-sm-6'>
                 
                    <h3>Thank you!</h3>
                
                </div>
                <div className='col-sm-6'></div>
              </div> */}

              <div className="col-sm-12 pt-1">
                <p className="paragraph-text1 mb-0">
                  <b>An account manager from Junity will be in touch soon.</b>
                </p>
                <p className="paragraph-text2 mb-0">
                  <b>We also sent an email to help you get to know Junity better.</b>
                </p>
              </div>

              <div className="col-sm-12 pt-1">
                <hr />
              </div>
              <div className="col-sm-12 pl-0">
                <div className="row" style={{ overflow: 'hidden' }}>
                  <div className="col-sm-6">
                    <div className="col-sm-12 paragraph-text txtdcoration">
                      <Link to="/" className="txtdcoration">
                        <p>
                          <b>
                            <span className="text-color-signup">
                              <i className="fas fa-angle-left"></i>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Back to Home
                            </span>
                          </b>
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="col-sm-12 paragraph-text txtdcoration">
                      <Link to="/setup-account" className="txtdcoration">
                        <p>
                          <b>
                            <span className="text-color-signup">
                              <i className="fas fa-angle-left"></i>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Proceed demo
                            </span>
                          </b>
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-2"></div>
          </div>
        </div>
        <div className="col-sm-6"></div>
      </div>
    </div>
  );
};

export default Signupthankyou;
