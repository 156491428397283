import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosClient } from '../../libs/axiosClient';
import { setSignUpDataAction } from '../../Redux/actions/signUpDataAction';
import './style.css';

function RedirectPage() {
  const search = new URLSearchParams(useLocation().search);
  const token = search.get('id');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchData = async (token) => {
    try {
      const resp = await axiosClient.post('/decode-token', { token });
      const { requiresInvitation, invited } = resp.data;
      dispatch(setSignUpDataAction(resp.data));

      if (requiresInvitation) {
        if (invited) navigate('/invited-to-signup');
        else navigate('/invitation-required');
      } else {
        navigate('/sign-up-institution');
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData(token);
    }
  }, [token]);

  return (
    <div className="container">
      <h3>Redirecting...</h3>
    </div>
  );
}

export default RedirectPage;
