import React, { useState, useEffect } from 'react';
import './style.css';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { axiosClient } from '../../libs/axiosClient';

const Signupemail = () => {
  const signup = useSelector((store) => store.signUp);
  const [timer, setTimer] = useState(45);

  const resendConfirmationEmail = async () => {
    try {
      await axiosClient.post('/verify-email', signup.data);
      setTimer(45);
    } catch (err) {
      console.error(err);
    }
  };

  const startCountDown = () => {
    setTimeout(() => {
      if (timer !== 0) {
        setTimer((prev) => prev - 1);
      }
    }, 1000);
  };

  useEffect(() => {
    startCountDown();
  }, [timer]);

  return (
    <div className="col-sm-12 bgimage-signup1">
      <div className="row pl-sm-5">
        <div className="card col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-2 mt-sm-5 offset-sm-1 p-2">
          <div className="col-sm-12">
            <img src="../images/logo.png" />
          </div>
          <div className="col-sm-12 mt-4 pl-3">
            <div className="row">
              <span style={{ color: '#2E394C' }}>
                <h3>
                  <b>Check your email.</b>
                </h3>
              </span>
            </div>
          </div>

          <div className="col-sm-12 pt-1">
            <p className="paragraph-text1 mb-0" style={{ fontWeight: 500 }}>
              <span className="gray-text">We just sent a confirmation email to </span>
              <span className="green-text">
                <b>{signup.data.email}</b>
              </span>
              <span className="gray-text"> with a link to continue the sign up process.</span>
            </p>
            <p className="paragraph-text2 mb-0 mt-2">
              <span className="gray-text fw-semibold">
                If you can’t find the email, check your spam folder.
              </span>
            </p>
          </div>

          <div className="col-sm-12 pt-1">
            <hr />
          </div>
          <div className="col-sm-12 pl-0">
            <div className="row" style={{ overflow: 'hidden' }}>
              <div className="col-sm-12">
                <div className="col-sm-12 paragraph-text txtdcoration">
                  <div className="txtdcoration" style={{ textDecoration: 'none' }}>
                    <p>
                      <b>
                        <span
                          className="text-color-signup font-weight-bold"
                          style={{ color: '#515A6A' }}>
                          Didn’t get the email?{' '}
                          <span
                            onClick={resendConfirmationEmail}
                            className={`${timer ? 'inactive' : 'text-success cursor-pointer'}`}>
                            Resend{timer ? <span className="ps-1">in 0:{timer}</span> : null}
                          </span>
                        </span>
                      </b>
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className='col-sm-6'>
              <div className='col-sm-12 paragraph-text txtdcoration'>
                  <Link to="/setup-account" className='txtdcoration'>
                    <p><b><span className='text-color-signup'><i className="fas fa-angle-left"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Proceed demo</span></b></p>
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signupemail;
