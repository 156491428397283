import React, { useState } from 'react';
import Switch from 'react-switch';
import './style.css';

function SwitchWithText(props) {
  const { bg, setState, text, height, width, state, switchBg, className } = { ...props };

  const handleSwitch = (state) => {
    setState(state);
  };

  return (
    <div
      className={`switch_with_text ${className}`}
      style={{
        border: 'none'
      }}>
      <div className="search_icon">
        <Switch
          onChange={handleSwitch}
          checked={state}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor={switchBg}
          height={height}
          width={width}
        />
      </div>
      <div className="ml-2 switch_text d-none d-md-inline">{text}</div>
    </div>
  );
}

export default SwitchWithText;
