import React, { useEffect, useState } from 'react';
import NotificationMsg from './NotificationMsg';
import EmptyNotificationsImage from '../../../../Assets/images/emptyNotifications.png';
import { useAuth } from '../../../../Auth';
import { getUserNotifications } from '../../../../Services/query/notifications';
import { API } from 'aws-amplify';
import { deleteAppNotification } from '../../../../graphql/mutations';

const Notifications = (props) => {
  const { user } = useAuth()
  const [userNotifications, setUserNotifications] = useState([]);

  const clearNotifications = ()=>{
    const promises = userNotifications.map(async(notification)=>{
      return await API.graphql({ query: deleteAppNotification, variables: {input: {id: notification.id}}})
    });
    Promise.all(promises)
    setUserNotifications([])
  }

  useEffect(() => {
    const retrieveNotifications = async()=>{
      const results = await getUserNotifications(user?.id)
      setUserNotifications(results?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)))
    }

    retrieveNotifications()
  }, [])

  return (
    <>
      <div className="justify-between">
        <p className="fs-14 font-weight-500 text-dark cursor-pointer">Showing {userNotifications?.length} Notification{userNotifications?.length === 1 ? '' : 's'}</p>
        <p className="fs-14 font-weight-500 text-dark cursor-pointer" onClick={()=>{clearNotifications()}}>
          Clear all
        </p>
      </div>
      {userNotifications?.reverse().map((notification, index) => (
        <NotificationMsg
          id={notification?.id}
          key={index}
          content={notification?.text}
          link={notification?.link}
          action={notification?.action}
          receivedTime={notification?.createdAt}
        />
      ))}
      {!userNotifications || !userNotifications?.length && (
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <div className="mb-5 text-center">
            <img src={EmptyNotificationsImage} alt="Empty Notifications"/>
            <p className="fs-16 font-weight-500 gray text-center mb-5 pb-5 col-9 mx-auto">
              <span className="d-inline-block mb-5">
                You do not have any notification at the moment
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Notifications;
