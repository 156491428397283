import React from 'react';
import OpportunityModal2 from './OpportunityModal2';

function OpportunityModal({ data }) {
  return (
    <div className="col-sm-12">
      {data?.map((elt, index) => (
        <div className="col-sm-12 pt-3" key={index}>
          <div className="row">
            <div className="col-sm-2">
              <img className="img-fluid" src="../images/opp.png" />
            </div>
            <div className="col-sm-8 ">
              <h6>{elt?.name}</h6>
            </div>
            <div className="col-sm-2 m-auto text-right">
              <h6>
                <i style={{ color: '#969CA5' }} className="fas fa-angle-right"></i>
              </h6>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default OpportunityModal;
