import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { ReturnPercentage } from './utils/index';
import Dashmodal from '../Dashmodal/Dashmodal';
import { useNavigate } from 'react-router-dom';

function Recommendation({ user }) {
  const data = ReturnPercentage(user);
  const navigate = useNavigate();

  const { percentage, userOpps, opps } = { ...data };

  return (
    <>
      {!isNaN(Number(percentage)) && Number(percentage) !== Infinity && (
        <div className="cursor-pointer" onClick={() => navigate('/my-groups')}>
          <CircularProgressbar
            value={percentage || 0}
            text={`${percentage || 0}%`}
            styles={buildStyles({
              pathColor: '#226E54',
              textColor: '#226E54'
            })}
          />
        </div>
      )}
      {isNaN(Number(percentage)) || Number(percentage) === Infinity || Number(percentage) === 0 ? (
        <div className="card-content">
          <img src="../../images/no-report.png" alt="" />
          <p className="mt-2 card-text">No data yet</p>
        </div>
      ) : (
        <div className="col-sm-12">
          <h6 className="date_color1 pt-1">
            You've recommended{' '}
            <b>
              {userOpps?.length} of {opps?.length}
            </b>{' '}
            opportunities this month
          </h6>
          <h6 className="date_color3 pt-1 ml-4">
            <Dashmodal userRecommendedOpps={userOpps} />
          </h6>
        </div>
      )}
    </>
  );
}

export default Recommendation;
