import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { BsExclamationCircle, BsCheckLg } from 'react-icons/bs';
import { SUBSCRIPTIONS } from '../../../../../utils/data';
import './style.css';

function MyVerticallyCenteredModal(props) {
  const { requestSubscriptionUpgrade, setToggleModal, toggleModal, loading } = props;

  const UpgradeModalContent = () => {
    return (
      <>
        <div className="col-sm-12 p-0 mt-2">
          <h6 className="modal-description">
            Junity’s additional, growth features support your engagement with students at Bulkeley
            High School.
          </h6>
        </div>
        <div className="row pt-3">
          <div className="col-sm-2 m-auto">
            <img src="../images/edit.png" className="img-fluid" />
          </div>
          <div className="col-sm-10 pl-0">
            <h6 className="modal-label m-0" id="exampleModalLongTitle">
              <b>{SUBSCRIPTIONS?.growth?.ALLOWED_NOTES_LIMIT} Notes per Student</b>
            </h6>
            <h6 className="modal-text">Create more notes to keep track of each student.</h6>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-sm-2 m-auto">
            <img src="../images/group.png" className="img-fluid" />
          </div>
          <div className="col-sm-10 pl-0">
            <h6 className="modal-label m-0" id="exampleModalLongTitle">
              <b>{SUBSCRIPTIONS?.growth?.ALLOWED_GROUPS_LIMIT} Custom Groups</b>
            </h6>
            <h6 className="modal-text">
              Target a group of students at a time... and even more groups.
            </h6>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-sm-2 m-auto">
            <img src="../images/paper.png" className="img-fluid" />
          </div>
          <div className="col-sm-10 pl-0">
            <h6 className="modal-label m-0" id="exampleModalLongTitle">
              <b>60-Day Student Activity History</b>
            </h6>
            <h6 className="modal-text">More details on what your students have been up to.</h6>
          </div>
        </div>
        <div className="modal-info-box">
          <h6 className="modal-info-title">
            Upgrade for {SUBSCRIPTIONS?.growth?.PRICE} Credits per month
          </h6>
          <div className="modal-info-text">
            Don't worry, you're not paying for this. By clicking
            <span className="bold ml-1">
              "Request to Upgrade", a request will be sent to your school's Junity administrator.
            </span>
          </div>
          <div className="justify-start mt-3 mb-2">
            <BsExclamationCircle className="mr-2" />
            <h6 className="modal-info-link">Learn how this works</h6>
          </div>
        </div>
        <div className="col-sm-12 p-0 pt-2">
          <button
            className="btn_success bold"
            style={{ width: '100%' }}
            onClick={() => requestSubscriptionUpgrade()}>
            {loading ? (
              <>
                Requesting upgrade <i className="fas fa-spinner fa-spin"></i>
              </>
            ) : (
              'Request to Upgrade'
            )}
          </button>
        </div>
        <h6 className="modal-prompt mt-3">No, thanks! I don’t need these features now</h6>
      </>
    );
  };

  const RequestSentContent = ({ hide }) => {
    return (
      <>
        <div className="col-sm-12 justify-center mt-4">
          <div className="circle_check justify-center align-items">
            <BsCheckLg fill="#226E54" size={40} />
          </div>
        </div>
        <h3 className="info-heading mt-4">Request Sent!</h3>
        <h6 className="info-description mb-4">
          Your school’s Junity administrator has been informed about your request. If they approve,
          your plan will be upgraded.
        </h6>
        <button type="button" className="btn btn-light text-dark w-100" onClick={hide}>
          Close
        </button>
      </>
    );
  };

  return (
    <Modal
      show={props?.show}
      onHide={props?.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal_body_style"
      centered>
      {toggleModal ? null : (
        <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
          <Modal.Title>
            <div className="modal-title-lg">
              Upgrade to <span style={{ color: '#226E54' }}>Growth</span>
            </div>
          </Modal.Title>
          <div className="close_btn drawer rounded-2" onClick={props.onHide}>
            <IoMdClose fill="#515A6A" size={18} />
          </div>
        </Modal.Header>
      )}
      <Modal.Body className="pt-0 w-100 px-3">
        {toggleModal ? (
          <RequestSentContent hide={props.onHide} />
        ) : (
          <UpgradeModalContent {...props} />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default function SubscriptionModal({
  modalShow,
  setModalShow,
  requestSubscriptionUpgrade,
  setToggleModal,
  toggleModal,
  loading
}) {
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setToggleModal={setToggleModal}
        toggleModal={toggleModal}
        requestSubscriptionUpgrade={requestSubscriptionUpgrade}
        loading={loading}
      />
    </>
  );
}
