import './style.css';
import { useNavigate } from 'react-router-dom';

const RequiresInvitation = () => {
  const navigate = useNavigate();

  return (
    <div className="col-sm-12 bgimage-signup1 bg-2 invitation-required">
      <div className="row pl-sm-5 setup-row">
        <div className="card col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-2 mt-sm-5 offset-sm-1 p-2">
          <div className="col-sm-12">
            <img src="../images/logo.png" />
          </div>
          <div className="col-sm-12 mt-4 pl-3">
            <div className="row">
              <span style={{ color: '#2E394C' }}>
                <h3>
                  <b>You need an invitation</b>
                </h3>
              </span>
            </div>
          </div>

          <div className="col-sm-12 pt-1">
            <p className="paragraph-text2 mb-0 mt-2">
              <span className="gray-text fw-semibold">
                Your institution has an account at your location. Contact your account administrator
                at your location to be invited.
              </span>
            </p>
          </div>

          <div className="col-sm-12 pt-1">
            <hr />
          </div>
          <div className="col-sm-12">
            <div className="row" style={{ overflow: 'hidden' }}>
              <div className="col-sm-6">
                <p>
                  <span className="text-color-signup fw-bold" onClick={() => navigate('/sign-up')}>
                    <i className="fas fa-angle-left"></i>
                    &nbsp;&nbsp;&nbsp;&nbsp; Back to Sign Up
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequiresInvitation;
