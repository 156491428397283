import React, { useState, useEffect } from 'react';
import { AllCategories } from '../../../../Services/query/categories';
import { AllRecommendedOpportunities } from '../../../../Services/query/recommendation';

const InterestedGroups = ({ user, oppId }) => {
  const [selected, setSelected] = useState(0);

  // const tag = (elt, index) => {
  //   return (
  //     <button
  //       className={`btn mygroups_btn rounded-pill ml-2 mb-2 ${
  //         selected === index + 1 ? 'active' : ''
  //       }`}
  //       key={index}
  //       onClick={() => setSelected(index + 1)}>
  //       {elt}
  //     </button>
  //   );
  // };

  const [categoriesRecommended, setCategoriesRecommended] = useState([]);

  const handleSetInterests = async () => {
    const categories = await AllCategories();
    const recommendedOpps = await AllRecommendedOpportunities();
    const categoryRecommendations = recommendedOpps.filter(
      (opp) => opp?.opportunityID === oppId && opp?.educatorID === user?.id
    );
    const categoriesRecommended = categoryRecommendations.reduce((acc, recommendation) => {
      const category = categories.find((elt) => elt.id === recommendation.categoryID);
      if (Object.keys(category || {})?.length > 0) {
        acc.push(category?.name);
      }
      return acc;
    }, []);

    setCategoriesRecommended(categoriesRecommended);
  };

  useEffect(() => {
    handleSetInterests();
  }, [oppId]);

  return (
    <div className="col-sm-12 pt-2 p-0">
      <div className="col-sm-12 mt-1 p-0">
        {categoriesRecommended?.map((elt, index) => (
          <button
            className={`btn background_allopp_btn mygroups_btn rounded-pill ml-2 mb-2`}
            key={index}
            onClick={() => setSelected(index + 1)}>
            {elt}
          </button>
        ))}
      </div>
    </div>
  );
};

export default InterestedGroups;
