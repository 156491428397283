import { gql } from '@apollo/client';

export const CREATE_PAYMENT = gql`
  mutation CreatePaymentMutation(
    $roleID: ID!
    $roleType: String!
    $type: String!
    $amount: Int!
    $description: String!
    $credits: Float!
    $status: String!
    $metaData: String
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    createPayment(
      input: {
        roleID: $roleID
        roleType: $roleType
        type: $type
        amount: $amount
        description: $description
        credits: $credits
        status: $status
        metaData: $metaData
        createdAt: $createdAt
        updatedAt: $updatedAt
      }
    ) {
      id
      roleID
      roleType
      type
      amount
      description
      credits
      status
      metaData
      createdAt
      updatedAt
    }
  }
`;
