import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_SCHOOL } from '../../../../Services/mutation/school';
import { getObjectsDifference, sendEmailNotification, titleCase } from '../../../../utils/helpers';
import { BsCheckCircleFill } from 'react-icons/bs';
import { useMutation } from '@apollo/client';
import { setUserDataAction } from '../../../../Redux/actions/userAction';
import { GetEducator, GetEducatorsByEmail } from '../../../../Services/query/educator';
import { Storage } from 'aws-amplify';
import NoImage from '../../../../Assets/images/noimageavailable.png';
import AlertToast from '../../../../Components/common/Alerts/ToastAlert';

const ProfileInstitution = () => {
  const user = useSelector((store) => store.educatorUser.data);
  const imgRef = useRef(null);
  const imgRef1 = useRef(null);
  const [imageProps, setImageProps] = useState({});
  const [bannerProps, setBannerProps] = useState({});
  const [loading, setLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastVariant, setToastVariant] = useState('success');
  const [toastIcon, setToastIcon] = useState('check');
  const [toastInfo, setToastInfo] = useState('');

  const [state, setState] = useState({
    contact_email: user?.school?.contact_email,
    name: user?.school?.name,
    about: user?.school?.about,
    website: user?.school?.website,
    contact_phone: user?.phone
  });

  const dispatch = useDispatch();
  const [updateSchool] = useMutation(UPDATE_SCHOOL);

  const showToast = (variant, message) => {
    setToastVariant(variant);
    setToastIcon(variant === 'success' ? 'check' : 'delete');
    setToastInfo(message);
    setToastActive(true);
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  const uploadImages = async () => {
    setProfileLoading(true);
    if (bannerProps.imageFile) {
      const bannerImage = bannerProps.imageFile;
      Storage.put(`${user.school.contact_email}-${bannerImage.name}`, bannerImage)
        .then((data) => {
          updateSchool({
            variables: {
              id: user.school.id,
              banner: `https://junity-counselor-storage204659-staging.s3.amazonaws.com/public/${data.key}`
            }
          })
            .then(async () => {
              const educator = await GetEducator(user.id);
              dispatch(setUserDataAction(educator));
              if (!imageProps.imageFile) {
                setProfileLoading(false);
                showToast('success', 'Changes saved');
              }
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
    }

    if (imageProps.imageFile) {
      const profileImage = imageProps.imageFile;
      Storage.put(`${user.school.contact_email}-${profileImage.name}`, profileImage)
        .then((data) => {
          updateSchool({
            variables: {
              id: user.school.id,
              banner: `https://junity-counselor-storage204659-staging.s3.amazonaws.com/public/${data.key}`
            }
          })
            .then(async () => {
              const educator = await GetEducator(user.id);
              dispatch(setUserDataAction(educator));
              setProfileLoading(false);
              showToast('success', 'Changes saved');
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
    }
  };

  const handleImageChange = (e, type) => {
    const image = e.target.files[0];
    const imageSize = image.size / 1024 / 1024;
    if (imageSize > 1) {
      toast.error('Image size is more than 1mb');
    } else {
      const imgUrl = window.URL.createObjectURL(image);

      if (type === 'banner') {
        setBannerProps({
          image: imgUrl,
          imageFile: image
        });
      } else {
        setImageProps({
          image: imgUrl,
          imageFile: image
        });
      }
    }
  };

  const saveChanges = async () => {
    setLoading(true);
    try {
      const obj = { ...state };
      const changedInfo = getObjectsDifference(user?.school, obj);
      const changes = Object.entries(changedInfo).map(([key, value]) => [key, value]);

      if (changes?.length) {
        obj.id = user?.schoolID;
        await updateSchool({ variables: { ...obj } });
        const educator = await GetEducator(user.id);
        dispatch(setUserDataAction(educator));

        const accountAdmin = await GetEducatorsByEmail(user?.school?.contact_email);
        await sendEmailNotification(
          accountAdmin?.email,
          'account-info-changed',
          'Account Information Change',
          {
            fname: accountAdmin?.name?.split(' ')[0],
            account: accountAdmin?.name,
            organization: user?.school?.name,
            moduleUrl: window.location.origin,
            changes
          }
        );

        user?.school?.invitations?.forEach(async (invite) => {
          const member = await GetEducatorsByEmail(invite);
          if (member?.isAdmin) {
            await sendEmailNotification(
              member?.email,
              'account-info-changed',
              'Account Information Change',
              {
                fname: member?.name?.split(' ')[0],
                account: member?.name,
                organization: user?.school?.name,
                moduleUrl: window.location.origin,
                changes
              }
            );
          }
        });
        showToast('success', 'Changes saved');
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      showToast('error', 'Error saving changes');
    }
  };

  return (
    <>
      <div
        className="col-sm-12 institution-form"
        style={{ backgroundColor: 'white', borderRadius: '10px' }}>
        <div className="col-sm-12 pt-3 pb-1">
          <h5 className="subheading">
            <b>INSTITUTION PROFILE</b>
          </h5>
        </div>
        <div className="row add_border p-3">
          <div className="col-md-5">
            <h6 className="section-heading">PUBLIC PROFILE</h6>
            <h6 className="section-subheading">This information will be displayed publicly.</h6>
          </div>
          <div className="col-md-5">
            <div className="form-group">
              <label className="institution-form-label">Institution Name</label>
              <div className={`input-group p-0`}>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Bulkeley High School"
                  value={titleCase(state.name)}
                  aria-describedby="email_checked"
                  required
                />
                <span className="input-group-text">
                  <BsCheckCircleFill size={22} fill="#2D9371" />
                </span>
              </div>
              {!user?.isAdmin ? (
                <div className="form-group-info mt-1">
                  Please contact your <span className="admin">account manager</span> if you would
                  like to change this name.
                </div>
              ) : null}
            </div>
            <div className="form-group">
              <label className="institution-form-label">About this Institution</label>
              <textarea
                rows={3}
                className="form-control"
                value={state.about}
                onChange={handleChange}
                name="about"
                placeholder="Write a few sentences about the school mission and/or goal"></textarea>
            </div>

            <div className="form-group">
              <label className="institution-form-label">Institution Website</label>
              <input
                type="url"
                className="form-control "
                style={{
                  backgroundColor: 'rgba(244, 245, 247, 0.64)',
                  color: '#515A6A'
                }}
                name="website"
                onChange={handleChange}
                value={state.website}
                placeholder="http://achievehartford.org"
              />
            </div>

            <div className="form-group">
              <label className="institution-form-label">Phone Number</label>
              <input
                type="phone"
                className="form-control "
                style={{
                  backgroundColor: 'rgba(244, 245, 247, 0.64)',
                  color: '#515A6A'
                }}
                name="contact_phone"
                onChange={handleChange}
                value={state.contact_phone}
                placeholder="+1 860-695-1009"
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-save-changes"
                disabled={loading}
                style={{ opacity: loading ? 0.5 : 1 }}
                onClick={saveChanges}>
                {loading ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-2 add_border p-3">
          <div className="col-md-5">
            <h6 className="section-heading">APPEARANCE</h6>
            <h6 className="section-subheading">Institution logo</h6>
          </div>
          <div className="col-sm-7 row">
            <div className="col-md-5">
              <div className="col-md-10">
                <img
                  className="img-fluid lg"
                  src={imageProps?.image || user?.school?.image}
                  alt=""
                />
                <h6 style={{ fontSize: '120%' }} className="pt-2">
                  <b>Organization's Logo</b>
                </h6>
                <h6 style={{ fontSize: '110%', color: '#969CA5' }} className="pt-2">
                  PNG, JPG or GIF,
                  <br />
                  min. 400px x 400px
                </h6>
              </div>
              <input
                id="select_logo"
                type="file"
                size="10"
                ref={imgRef}
                onChange={(e) => handleImageChange(e, 'image')}
              />
              <div className="ml-3 point" onClick={() => imgRef.current.click()}>
                <p
                  style={{
                    fontSize: '13px',
                    lineHeight: '18px',
                    color: '#10408C'
                  }}
                  className="pt-2">
                  <b> Change Logo </b>
                </p>{' '}
              </div>
            </div>
            <div className="col-md-7">
              <div className="col-md-10">
                <img
                  className="img-fluid lg-2x object-fit-cover"
                  src={bannerProps.image || user.school.banner || NoImage}
                  alt=""
                />
                <h6 style={{ fontSize: '120%' }} className="pt-2">
                  <b>Organization’s banner</b>
                </h6>
                <h6 style={{ fontSize: '110%', color: '#969CA5' }} className="pt-2">
                  PNG, JPG or GIF,
                  <br />
                  min. 400px x 400px
                </h6>
              </div>
              <input
                id="select_logo"
                type="file"
                size="10"
                ref={imgRef1}
                onChange={(e) => handleImageChange(e, 'banner')}
              />
              <div className="ml-3 point" onClick={() => imgRef1.current.click()}>
                <p
                  style={{
                    fontSize: '13px',
                    lineHeight: '18px',
                    color: '#10408C'
                  }}
                  className="pt-2">
                  <b> Change Logo </b>
                </p>{' '}
              </div>
            </div>
            <div className="form-group">
              <button
                className="btn btn-save-changes"
                disabled={profileLoading}
                style={{ opacity: profileLoading ? 0.5 : 1 }}
                onClick={uploadImages}>
                {profileLoading ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-2 p-3">
          <div className="col-md-5">
            <h6 className="section-heading">LOCATION</h6>
            <h6 className="section-subheading">
              Zip code, city and state and office street address
            </h6>
          </div>
          <div className="col-md-5">
            <div className="form-group">
              <label className="institution-form-label">Institution Address</label>
              <div className={`input-group p-0`}>
                <input
                  type="text"
                  name="contact_email"
                  className="form-control"
                  placeholder="585 Wethersfield Ave, Hartford, CT 06114"
                  value={user.school.address}
                  aria-describedby="address_checked"
                  required
                  disabled
                />
                <span className="input-group-text">
                  <BsCheckCircleFill size={22} fill="#2D9371" />
                </span>
                {!user?.isAdmin ? (
                  <div className="form-group-info mt-1">
                    Please contact your <span className="admin">account manager</span> if you would
                    like to change this name.
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertToast
        variant={toastVariant}
        icon={toastIcon}
        active={toastActive}
        setActive={setToastActive}
        info={toastInfo}
      />
    </>
  );
};

export default ProfileInstitution;
