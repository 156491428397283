import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IoMdClose } from 'react-icons/io';
import AlertToast from '../common/Alerts/ToastAlert';
import { Files, Share } from 'react-bootstrap-icons';
import { ClipLoader } from 'react-spinners';
import { ReactComponent as FacebookLogo } from '../../Assets/images/facebook.svg';
import { ReactComponent as LinkedInLogo } from '../../Assets/images/linkedin.svg';
import { ReactComponent as TwitterLogo } from '../../Assets/images/twitter.svg';
import './style.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export const ShareOpportunityModal = ({ shareModalShow, setShareModalShow, opportunityID }) => {
  const [emails, setEmails] = useState('');
  const [loading, setLoading] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastVariant, setToastVariant] = useState('success');
  const [toastIcon, setToastIcon] = useState('check');
  const [toastInfo, setToastInfo] = useState('');
  const oppPage = `${process.env.REACT_APP_EXPLORE_URL}/opportunities?id=${opportunityID}`;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showToast = (variant, message) => {
    setToastVariant(variant);
    setToastIcon(variant === 'success' ? 'check' : 'delete');
    setToastInfo(message);
    setToastActive(true);
  };

  const copyOppLink = () => {
    navigator.clipboard.writeText(oppPage);
    showToast('success', 'Opportunity link copied to clipboard');
  };

  const socialShare = (e) => {
    e.preventDefault();
    if (navigator.share) {
      navigator
        .share({
          title: 'Opportunity Share',
          text: 'Check out this opportunity on Junity',
          url: oppPage
        })
        .then(() => {})
        .catch((error) => showToast('warning', 'Error sharing'));
    }
  };

  const progress = () => {
    return <ClipLoader size={10} color={loading ? '#fff' : ''} />;
  };

  return (
    <>
      <Modal
        show={shareModalShow}
        onHide={() => {
          setShareModalShow(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal_body_style"
        centered>
        <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
          <Modal.Title>Share this Opportunity</Modal.Title>
          <div
            className="close_btn drawer rounded-2"
            onClick={() => {
              setShareModalShow(false);
            }}>
            <IoMdClose fill="#515A6A" size={18} />
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 w-100 px-3 payment-modal institution-form">
          <div className="form-group d-flex gap-2">
            <input type="text" class="form-control form-control-lg" value={oppPage} readOnly />
            <button type="button" class="btn btn-success" onClick={copyOppLink}>
              <Files />
              Copy Link
            </button>
          </div>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab
                  className={`tab_style ${value === 0 ? 'active' : ''}`}
                  label="Social Media"
                  {...a11yProps(0)}
                />
                <Tab
                  className={`tab_style ${value === 1 ? 'active' : ''}`}
                  label="Send To Email"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="share_modal-tabpane-social_media d-flex gap-3 py-3">
                <a href="#" className="logo_wrapper d-flex flex-column align-items-center">
                  <TwitterLogo />
                  <span>Twitter</span>
                </a>
                <a href="#" className="logo_wrapper d-flex flex-column align-items-center">
                  <FacebookLogo />
                  <span>Twitter</span>
                </a>
                <a href="#" className="logo_wrapper d-flex flex-column align-items-center">
                  <LinkedInLogo />
                  <span>Twitter</span>
                </a>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="institution-form pt-3">
                <div class="form-group">
                  <label className="institution-form-label">Who should we send this to?</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder=""
                    aria-describedby="helpId"
                    onChange={(e) => {
                      setEmails(e.target.value);
                    }}
                  />
                  <small id="helpId" class="text-muted">
                    Separate multiple emails with comma(,)
                  </small>
                </div>
                <button type="button" class="btn btn-success btn-lg w-100" disabled={!emails}>
                  Send To Email(s)
                </button>
              </div>
            </TabPanel>
          </Box>
        </Modal.Body>
      </Modal>
      <AlertToast
        variant={toastVariant}
        icon={toastIcon}
        active={toastActive}
        setActive={setToastActive}
        info={toastInfo}
      />
    </>
  );
};
