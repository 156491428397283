import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { ClipLoader } from 'react-spinners';

export const ConfirmUpgradeDeclineModal = ({
  upgradeDecline,
  setUpgradeDecline,
  declineUpgrade,
  loading,
  progress
}) => {
  return (
    <Modal
      show={upgradeDecline}
      onHide={() => {
        setUpgradeDecline(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="mx-auto"
      centered>
      <Modal.Body className="pt-0 w-100 px-3 payment-modal institution-form">
        <div className="w-100 d-flex justify-content-center">
          <img src="../images/caution1.png" className="image-info mx-auto mt-3" />
        </div>
        <h5 className="info-heading mt-3">Decline Subscription Upgrade Request?</h5>
        <div className="d-flex justify-content-between align-items-center gap-2 my-4">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              declineUpgrade();
            }}>
            {loading ? progress() : 'Yes, Decline'}
          </button>
          <button
            type="button"
            className="btn btn-light text-dark"
            onClick={() => {
              setUpgradeDecline(false);
            }}>
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
