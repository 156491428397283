import * as Icons from "react-icons/fa";

function DynamicReactIcon ({ name }) {
  const IconComponent = Icons[name];

  if (!IconComponent) { 
    return <Icons.FaBeer size={22} fill="#969CA5" />;
  }

  return <IconComponent size={22} fill="#969CA5" />;
}

export default DynamicReactIcon;