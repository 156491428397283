import React, { useState } from 'react';
import { useCollapse } from 'react-collapsed';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

export const Accordion = ({ title, className = '', children = '' }) => {
  const [isExpanded, setExpanded] = useState(true);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <div className={className}>
      <div
        className="w-100 px-3 py-2 d-flex justify-content-between align-items-center"
        {...getToggleProps({
          onClick: () => setExpanded((prevExpanded) => !prevExpanded)
        })}>
        <div className="youth-detail-accordion-title">{title}</div>
        <div className="text-right angle-down-contact-icon">
          {isExpanded ? <BsChevronUp /> : <BsChevronDown />}
        </div>
      </div>
      <section {...getCollapseProps()}>{children}</section>
    </div>
  );
};
