import { gql } from '@apollo/client';

export const CREATE_NOTIFICATION_SETTING = gql`
  mutation CreateNotificationSettingMutation(
    $new_chat: Boolean
    $new_reply: Boolean
    $new_student_joining: Boolean
    $roleID: ID!
    $saved_closing_date: Boolean
    $createdAt: AWSDateTime!
    $updatedAt: AWSDateTime
  ) {
    createNotificationSetting(
      input: {
        new_chat: $new_chat
        new_reply: $new_reply
        new_student_joining: $new_student_joining
        roleID: $roleID
        saved_closing_date: $saved_closing_date
        createdAt: $createdAt
        updatedAt: $updatedAt
      }
    ) {
      id
      roleID
    }
  }
`;

export const UPDATE_NOTIFICATION_SETTING = gql`
  mutation UpdateNotificationSettingMutation(
    $id: ID!
    $new_chat: Boolean
    $new_reply: Boolean
    $new_student_joining: Boolean
    $roleID: ID
    $saved_closing_date: Boolean
  ) {
    updateNotificationSetting(
      input: {
        id: $id
        new_chat: $new_chat
        new_reply: $new_reply
        new_student_joining: $new_student_joining
        roleID: $roleID
        saved_closing_date: $saved_closing_date
      }
    ) {
      id
      roleID
    }
  }
`;
