import React from 'react';
import Sidebar from '../Sidenav/index';
import Dashboardmain from './Components/Dashboardmain/Dashboardmain';

function Dashboard() {
  return (
    <div
      className="col-sm-12 p-0 full-height"
      style={{
        overflowY: 'scroll',
        overflowX: 'hidden'
      }}>
      <Sidebar component={<Dashboardmain />} />
    </div>
  );
}

export default Dashboard;
