import { SET_EDUCATOR_DATA, RESET_EDUCATOR_DATA } from '../constants/educatorConstants';

const initialLoginState = {
  educator: {}
};

const educatorReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case SET_EDUCATOR_DATA:
      return { ...state, educator: action.payload };
    case RESET_EDUCATOR_DATA:
      state = initialLoginState;
      return state;
    default:
      return state;
  }
};

export default educatorReducer;
