import { ReactComponent as Person } from '../../../../Assets/icon/alert/person.svg';
import { ReactComponent as CheckMark } from '../../../../Assets/icon/alert/checkMark.svg';
import { ReactComponent as Delete } from '../../../../Assets/icon/alert/delete-icon.svg';
import { ReactComponent as JCoin } from '../../../../Assets/icon/alert/jCoin.svg';
import { ReactComponent as Lock } from '../../../../Assets/icon/alert/lock.svg';
import { ReactComponent as Message } from '../../../../Assets/icon/alert/message.svg';
import { ReactComponent as Settings } from '../../../../Assets/icon/alert/settings.svg';
import { ReactComponent as CreditCard } from '../../../../Assets/icon/alert/credit-card.svg';
import { ReactComponent as AddUser } from '../../../../Assets/icon/alert/add-user.svg';
import { ReactComponent as Shopping } from '../../../../Assets/icon/alert/shopping.svg';

export const iconFind = (iconID) => {
  const icons = {
    person: <Person />,
    check: <CheckMark />,
    creditCard: <CreditCard />,
    delete: <Delete />,
    jC: <JCoin />,
    lock: <Lock />,
    message: <Message />,
    addUser: <AddUser />,
    settings: <Settings />,
    shopping: <Shopping />
  };

  return icons[iconID];
};
