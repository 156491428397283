import React, { Component, useState } from 'react';
import OTPInput, { ResendOTP } from 'otp-input-react';
import './otp.css';

function Otpscreen({otp, setOtp}) {

  return (
    <OTPInput
      value={otp}
      onChange={setOtp}
      autoFocus
      OTPLength={6}
      otpType="number"
      disabled={false}
      className="otp"
    />
  );
}

export default Otpscreen;
