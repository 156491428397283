import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Otpscreen from './Otpscreen';
import { IoMdClose } from 'react-icons/io';
import { useAuth } from '../../../..';
import { Compass } from 'react-feather';
import { axiosClient } from '../../../../../libs/axiosClient';
import { API } from 'aws-amplify';
import { UPDATE_EDUCATOR } from '../../../../../Services/mutation/educator';
import { sendAppNotification, sendEmailNotification } from '../../../../../utils/helpers';
import { setEducatorDataAction } from '../../../../../Redux/actions/educatorActions';
import { useDispatch } from 'react-redux';

const TwoFactactorAuthenticationModal = ({ show, onHide, otpToken, setTwoFA }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState([]);
  const { user } = useAuth();
  const dispatch = useDispatch()

  const verifyCode = async() => {
    setLoading(true);
    try {
      await axiosClient.post('/authenticate-otp',{
        otp: Number(otp),
        otpToken: otpToken
      });
      const { data } = await API.graphql({
        query: UPDATE_EDUCATOR,
        variables: { id: user?.id, twoFA: true }
      });

      await sendAppNotification(
        user?.id,
        'You have successfully enabled 2FA',
        'success',
        `/my-account`,
        'Click here to view',
        'mentor',
        'inapp',
      )
      await sendEmailNotification(
        user?.email,
        '2fa-enabled',
        '2FA Enabled',
        {
          account: user?.name,
          organization: user?.school?.name,
          moduleUrl: window.location.origin
        }
      )
      
      dispatch(setEducatorDataAction({ ...user, ...data?.UpdateEducatorMutation }));
      setTwoFA(true)
      onHide(false)
      setLoading(false);
      // props.setMAStatus(prev => !prev);
    } catch (err) {
      console.log(err)
      setLoading(false)
      // setActiveToast(true);
      // setToast(err?.response?.data?.message);
      // setLoading(false);
    }
  };

  return (
    <>
      {/* 2FA Modal */}
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal_body_style"
        centered>
        <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
          <Modal.Title>Two-Factor Authentication</Modal.Title>
          <div
            className="close_btn drawer rounded-2"
            onClick={() => {
              onHide(false);
            }}>
            <IoMdClose fill="#515A6A" size={18} />
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 w-100 px-3 payment-modal">
          <h5 className="section-subheading text-uppercase">Set up two-factor authentication</h5>
          <h6 className="info-description text-left">
            Enter the verification code we just sent to your email address <b>{user?.email}</b>.
          </h6>
          {/* <h6 className="change-logo-link text-success cursor-pointer mt-2">Change phone number</h6> */}
          <div className="my-3 form-group">
            <label className="institution-form-label">Verification Code</label>
            <Otpscreen otp={otp} setOtp={setOtp}/>
          </div>

          <button 
            className="btn btn-success w-100 mb-3 border-bottom" 
            onClick={verifyCode}
            disabled={otp?.length < 6 || loading}
          >
            <b>{loading ? 'Confirming...' : 'Confirm'}</b>
          </button>
          <div className="w-100 border-bottom"></div>
          <h6 className="mt-3 purchase-subheading">
            Didn’t get the code? <span style={{ color: '#969CA5' }}>Resend in 0:45</span>
          </h6>
        </Modal.Body>
      </Modal>

      {/* 2FA Succcess Modal */}
      <Modal
        show={success}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal_body_style"
        centered>
        <Modal.Body className="modal-body modal-info w-100 text-center">
          <img src="../images/shield.png" className="image-info mx-auto mt-3" />
          <h3 className="info-heading mt-4">Two-Factor Authentication Verified!</h3>
          <h6 className="info-description mb-4">
            Your phone will now be used as an authentication device when you try to log in or
            authorize transactions.
          </h6>
          <button
            type="button"
            className="btn btn-light text-dark w-100"
            onClick={() => {
              setSuccess(false);
            }}>
            Close
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TwoFactactorAuthenticationModal;
