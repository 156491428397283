import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import awsExports from './aws-exports';
import { Amplify, API } from 'aws-amplify';
import { ApolloProvider } from '@apollo/client';
import { client } from './Services/client';
import { store, persistor } from './Redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-bootstrap';
import ElementsProvider from './stripe';

Amplify.configure(awsExports);

API.configure({
  cache: {
    dataStore: 'memory',
    cachePolicy: {
      maxAge: 10 * 60 * 1000,
      caching: 'cache'
    }
  }
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer />
        <ElementsProvider>
          <App />
        </ElementsProvider>
      </PersistGate>
    </Provider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
