import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import Abouttables from '../Abouttables/Abouttables';
// import Aboutgraddropdown from './Aboutgraddropdown/Aboutgraddropdown';
import Aboutgraddropdowninterst from './Aboutdropdowninterst/Aboutdropdowninterst';
import Recomendgrad from '../Recomendgrad/Recomendgrad';
import EnhancedTable from '../../../Youth/Components/Youthtables/Youthtables';
import { filterYouthsByInterests } from '../../../../utils/Youth/utils';
// import Allopportunities from '../Allopportunities/Allopportunities';
// import "../Opportunitiesf/Opportunitiesf.css"
// import Savedopportunities from '../Savedopportunities/Savedopportunities';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function BasicTabs({ data, groupID }) {
  const [value, setValue] = useState(0);
  const [filteredInterest, setFilterInterest] = useState('All');
  const [filteredAge, setFilterAge] = useState('All');
  const [youthsFiltered, setYouthsFiltered] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [itemSelected, setItemSelected] = React.useState({});

  useEffect(() => {
    setYouthsFiltered(data);
  }, [data]);

  useEffect(() => {
    if (filteredInterest !== 'All') {
      const filteredData = filterYouthsByInterests(data, [filteredInterest]);
      setYouthsFiltered(filteredData);
    } else if (filteredAge !== 'All') {
      const filteredData = data.filter((item) => item.age === filteredAge);
      setYouthsFiltered(filteredData);
    } else {
      setYouthsFiltered(data);
    }
  }, [filteredAge, filteredInterest]);

  return (
    <div
      className="col-sm-12 background_tab pb-3 p-0"
      style={{
        backgroundColor: '#F0F0F0',
        height: '88vh'
      }}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: 'white' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab
              label="Youth"
              className={`tab-btn ml-3 ${!value ? 'active' : ''}`}
              {...a11yProps(0)}
            />
            <Tab
              label="Recommendations"
              className={`tab-btn ${value ? 'active' : ''}`}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel className="background_tab1 p-3" style={{ height: '88%' }} value={value} index={0}>
          <div className="p-3 bg-white rounded-3" style={{ height: '95%' }}>
            <div className="w-100 d-flex align-items-center justify-content-between flex-wrap">
              <div className="d-flex align-items-center gap-2">
                <span className="filter-label">Filters:</span>
                {/*<Aboutgraddropdown label="Ages" onChange={setFilterAge} />*/}
                <Aboutgraddropdowninterst label="Interests" onChange={setFilterInterest} />
              </div>
              <div className="searchbar mt-2">
                <div className={`input-group p-0`}>
                  <span className="input-group-text bg-white">
                    <i className="fas fa-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control bg-white"
                    placeholder="Search for youth..."
                  />
                </div>
              </div>
            </div>
            <EnhancedTable
              setData={setItemSelected}
              studentsData={youthsFiltered}
              type="abouttables"
            />
          </div>
        </TabPanel>
        <TabPanel className="background_tab1 p-2" style={{ height: '88%' }} value={value} index={1}>
          <Recomendgrad groupID={groupID} />
        </TabPanel>
      </Box>
    </div>
  );
}
