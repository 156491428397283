import React, { useState, useLayoutEffect } from 'react';
import { useMutation } from '@apollo/client';
import { toast, ToastContainer } from 'react-toastify';
import { CREATE_RECOMMENDATION } from '../../../../Services/mutation/recommendations';
import { AllRecommendedOpportunities } from '../../../../Services/query/recommendation';
import { AllCategories } from '../../../../Services/query/categories';
import { ClipLoader } from 'react-spinners';
import { sortDataByField } from '../../../../utils/helpers';
import { BsBriefcase, BsBox, BsGear, BsBook, BsChevronLeft } from 'react-icons/bs';
import { FiMusic, FiShoppingBag } from 'react-icons/fi';
import { TbFlask2 } from 'react-icons/tb';
import { MdDeviceHub, MdOutlineArchitecture, MdOutlineColorLens } from 'react-icons/md';
import './style.css';
import { AiOutlineAccountBook, AiOutlineBulb, AiOutlineExclamationCircle } from 'react-icons/ai';
import { ArchitectureOutlined } from '@mui/icons-material';
import AlertToast from '../../../../Components/common/Alerts/ToastAlert';

function Defaultgroupmodal({ user, opportunityID, setModalShow }) {
  const [categoryIDs, setCategoryIDs] = useState({});
  const [categories, setCategories] = useState([]);
  const [recommendedOpps, setRecommendedOpps] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const [createRecommendation] = useMutation(CREATE_RECOMMENDATION);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const progress = () => {
    return <ClipLoader size={10} color={loading ? '#fff' : ''} />;
  };
  const [state, setState] = useState({});
  const [toastActive, setToastActive] = useState(false);
  const [toastVariant, setToastVariant] = useState('success');
  const [toastIcon, setToastIcon] = useState('check');
  const [toastInfo, setToastInfo] = useState('');

  const showToast = (variant, message) => {
    setToastVariant(variant);
    setToastIcon(variant === 'success' ? 'check' : 'delete');
    setToastInfo(message);
    setToastActive(true);
  };

  const icons = {
    Business: <BsBriefcase size={22} fill="#969CA5" />,
    Sports: <BsBox size={22} fill="#969CA5" />,
    Engineering: <BsGear size={22} fill="#969CA5" />,
    'Music & Entertainment': <FiMusic size={22} stroke="#969CA5" strokeWidth={1} />,
    Science: <TbFlask2 size={22} stroke="#969CA5" strokeWidth={1} />,
    Technology: <MdDeviceHub size={22} fill="#969CA5" />,
    Art: <MdOutlineColorLens size={22} fill="#969CA5" />,
    'Trade Skills': <FiShoppingBag size={22} stroke="#969CA5" strokeWidth={1} />,
    Law: <BsBook size={22} stroke="#969CA5" strokeWidth={1} />,
    Politics: <AiOutlineBulb size={22} fill="#969CA5" />,
    'Civil Service': <MdOutlineArchitecture size={22} fill="#969CA5" />
  };

  const setIds = (elt, index) => {
    if (!state[index]) {
      setCategoryIDs({
        ...categoryIDs,
        [index]: elt
      });
    } else {
      const categories = { ...categoryIDs };
      delete categories[index];
      setCategoryIDs(categories);
    }
    setState({
      ...state,
      [index]: !state[index]
    });
  };

  const recommend = () => {
    if (recommendedOpps?.length >= 10) {
      showToast('error', 'Recommendations limit reached');
      return;
    }
    setLoading(true);
    Object.values(categoryIDs || {})?.forEach((elt, index) => {
      const obj = {
        categoryID: elt,
        opportunityID: opportunityID,
        educatorID: user?.id,
        createdAt: new Date()
      };
      const filterRecommendedOpps = recommendedOpps?.findIndex(
        (opp) => opp?.opportunityID === opportunityID && opp?.categoryID === elt
      );

      if (filterRecommendedOpps !== -1) {
        showToast('warning', 'Opportunity already recommended');
        setModalShow(false);
        setLoading(false);
      } else {
        createRecommendation({
          variables: { ...obj }
        })
          .then(() => {
            setLoading(false);
            // setRecommended(true)
            showToast('success', 'Opportunity recommended');
            setModalShow(false);
          })
          .catch((err) => {
            setLoading(false);
            showToast('error', 'Opportunity not recommended, An error occured');
            console.log(err);
          });
      }
    });
  };

  const handleSetInterest = async () => {
    const categories = await AllCategories();
    const sortedCategories = sortDataByField(categories, 'name');
    setCategories(sortedCategories);
    const recommendedOpps = await AllRecommendedOpportunities();
    const categoryRecommendations = recommendedOpps.filter(
      (opp) =>
        opp?.opportunityID === opportunityID && categories.some((elt) => elt.id === opp?.categoryID)
    );
    setRecommendedOpps(categoryRecommendations);
    const checked = categoryRecommendations.reduce((prev, cur) => {
      const category = sortedCategories.findIndex(c => c.id === cur.categoryID);
      return [...prev, { [category]: true }];
    }, []);
    setState(checked);
  };

  useLayoutEffect(() => {
    handleSetInterest();
  }, [refetch]);

  return (
    <>
      <div className="col-sm-12 p-0 mt-3">
        <div className="col-sm-12 default-group-header">
          <span>
            <b>{10 - recommendedOpps?.length}</b> Recommendations Left
          </span>
        </div>
        <div className="col-sm-12">
          {categories.map((elt, index) => (
            <div
              className="d-flex align-items-center justify-content-between mt-2"
              style={{ fontSize: 12, color: '#2E394C' }}
              key={index}>
              <div className="d-flex align-items-center gap-2">
                <input
                  className="form-check-input m-0"
                  type="checkbox"
                  id="defaultCheck1"
                  checked={state[index]}
                  onChange={() => setIds(elt?.id, index)}
                />
                {icons[elt?.name]}
                <span className="mb-0">
                  <b>{elt?.name}</b>
                </span>
              </div>
              <AiOutlineExclamationCircle
                size={20}
                fill="#969CA5"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={elt?.description}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="col-sm-12 mt-3 pl-0 pr-0">
        <div className="col-sm-12 p-0">
          <button
            type="button"
            style={{ width: '100%' }}
            className="btn btn_success"
            disabled={recommendedOpps?.length >= 10}
            onClick={recommend}>
            {loading ? progress() : 'Recommend'}
          </button>
        </div>
      </div>
      <AlertToast
        variant={toastVariant}
        icon={toastIcon}
        active={toastActive}
        setActive={setToastActive}
        info={toastInfo}
      />
    </>
  );
}

export default Defaultgroupmodal;
