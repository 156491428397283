import React from 'react';
import Sidebar from '../Sidenav/index';
import Chatmain from './Components/Chatmain/Chatmain';

function Mygroups() {
  return (
    <div className="col-sm-12 p-0 h-full" style={{ overflowX: 'hidden' }}>
      <Sidebar component={<Chatmain />} />
    </div>
  );
}

export default Mygroups;
