import './style.css';

function InitialsWrapper(props) {
  const { type, text, bg } = { ...props };

  return (
    <div className={`initials_wrapper ${type}`} style={{ backgroundColor: bg }}>
      {text}
    </div>
  );
}

export default InitialsWrapper;
