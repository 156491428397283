import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setEducatorDataAction } from '../../Redux/actions/educatorActions';
import { setUserDataAction } from '../../Redux/actions/userAction';
import { GetEducatorsByEmail } from '../../Services/query/educator';
import { ClipLoader } from 'react-spinners';
import { ValidateEmail } from '../../utils/helpers';
import google from '../../Assets/images/google.png';
import { isRequiredFieldsPassed } from '../../utils/validators';
import WarnIcon from '../../Assets/images/warn-icon.svg';

const Login = () => {
  const [state, setState] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(false);
  const passwordRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailErr, setEmailErr] = useState(false);

  const [passErr, setPassErr] = useState(false);

  const progress = () => {
    return <ClipLoader size={10} color={loading ? '#fff' : ''} />;
  };

  async function signIn() {
    if (state?.password.length < 8) {
      setPassErr(true);
      return;
    }
    if (!ValidateEmail(state?.email)) {
      setEmailErr(true);
      return;
    }

    setLoading(true);

    try {
      const user = await Auth.signIn(state?.email, state?.password);
      const userInfo = { ...user };
      userInfo.isAuthenticated = true;
      const educator = await GetEducatorsByEmail(state?.email);
      dispatch(setEducatorDataAction(userInfo));
      dispatch(setUserDataAction(educator));
      setLoading(false);
      navigate('/dashboard-main');
    } catch (error) {
      setLoading(false);
      setError(error.code === 'NotAuthorizedException');
    }
  }

  const handleChange = (e) => {
    setEmailErr(false);
    setPassErr(false);
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  const loginWithGoogle = () => {
    Auth.signOut();
    Auth.federatedSignIn({ provider: 'Google' });
  };

  useEffect(() => {
    setVerified(isRequiredFieldsPassed(state, 2, 'eq'));
  }, [state]);

  useEffect(() => {
    if ('Notification' in window && Notification.permission !== 'granted') {
      Notification.requestPermission().then((permission) => {
        console.log('')
      });
    }
  }, [])

  return (
    <>
      <div className="col-sm-12 bgimage-login">
        <div className="row pl-sm-5">
          <div className="card col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-2 mt-sm-5 offset-sm-1 p-2">
            <div className="col-sm-12">
              <img src="../images/logo.png" alt="" />
            </div>
            <div className="col-sm-12 pt-3 ">
              <p className="heading-text mb-0 text-neutral">
                <b>Welcome Back</b>
              </p>
            </div>
            {/*<div className="col-sm-12 my-3">*/}
            {/*  <div*/}
            {/*    className="d-block btn btn-light mb-2 fontsize text-left rounded-3"*/}
            {/*    onClick={loginWithGoogle}>*/}
            {/*    <img src={google} className="border-right pr-3" alt="google logo" />*/}
            {/*    <span className="text-neutral pl-3">*/}
            {/*      <b> Log in with Google </b>*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="col-sm-12 ">
              <p className="logintext mb-0">Log in with Email</p>
            </div>
            {error && (
              <div className="col-sm-12 mt-2 error-banner">
                <div>
                  <img src={WarnIcon} alt="" />
                </div>
                <div className="banner-text">Incorrect email or password</div>
              </div>
            )}
            <div className="col-sm-12 mt-3">
              <p className="logintext1 text-neutral mb-0 mt-0">Work Email Address</p>
            </div>
            <div className="col-sm-12 mt-2 mb-2">
              <input
                type="text"
                className="form-control loginfield"
                placeholder="Enter work email address"
                onChange={handleChange}
                name="email"
              />
              {emailErr ? <p className="err">Email address is not Valid </p> : ''}
            </div>
            <div className="col-sm-12 mt-3">
              <p className="logintext1 text-neutral mb-0 mt-0">Password</p>
            </div>
            <div className="col-sm-12 mb-2 mt-2 login">
              <div className="flex">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control loginfield inputstyl3 "
                  placeholder="Enter Password"
                  aria-label="Password input"
                  ref={passwordRef}
                  aria-describedby="basic-addon1"
                  onChange={handleChange}
                  name="password"
                />
                <div className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <i className="fas fa-eye-slash"></i>
                  ) : (
                    <i className="fas fa-eye"></i>
                  )}
                </div>
              </div>
              {passErr ? <p className="err">Password is not 8 digit long... </p> : ''}
            </div>
            <div className="col-sm-12 mt-4">
              <button
                className={`btn btn-success btn-block pt-2 pb-2 ${
                  !verified || loading ? 'inactive' : ''
                }`}
                onClick={signIn}>
                {loading ? progress() : 'Log in'}
              </button>
            </div>
            <div className="col-sm-12 pt-1">
              <hr />
            </div>
            <div className="col-sm-12 paragraph-text point">
              <p className="mb-0">
                <b>
                  <span className="opacity-75">Can't remember your password?</span>{' '}
                  <span className="text-color-signup" onClick={() => navigate('/reset-password')}>
                    Reset Password
                  </span>
                </b>
              </p>
            </div>
            <div
              className="col-sm-12 paragraph-text point mt-1"
              onClick={() => navigate('/sign-up')}>
              <p className="mt-1">
                <b>
                  <span className="opacity-75">Don't have an account?</span>{' '}
                  <span className="text-color-signup">Get Started</span>
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
