import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { ClipLoader } from 'react-spinners';
import jsPDF from 'jspdf';
import { GetSingleSchool } from '../../../../../Services/query/school';
import './style.css';

export const PurchaseOrderModal = ({
  purchaseOrder,
  setPurchaseOrder,
  selectedPackage,
  sendPurchaseOrder,
  getPDFTemplate,
  createInvoice,
  educator,
  totalCost,
  loading,
  progress
}) => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [purchaseForm, setPurchaseForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    institution: '',
    vendorName: 'Junity',
    vendorEmail: 'sales@joinjunity.com',
    item: 'Credit'
  });

  useEffect(() => {
    if (educator && Object.keys(educator)) {
      let name = educator?.name?.split(' ');
      setPurchaseForm({
        ...purchaseForm,
        firstName: name[0],
        lastName: name[name.length - 1],
        institution: educator?.school?.name,
        email: educator?.email,
        quantity: selectedPackage,
        amount: totalCost
      });
    }
  }, [educator]);

  const handleChange = (e) => {
    setPurchaseForm({ ...purchaseForm, [e.target.name]: e.target.value });
  };

  const handleDownload = async () => {
    setDownloadLoading(true);
    const content = await getPDFTemplate({
      ...purchaseForm,
      amount: totalCost,
      quantity: selectedPackage
    });

    const pdf = new jsPDF('p', 'pt', 'a4');

    pdf.html(content, {
      callback: (doc) => {
        doc.save('Purchase Order.pdf');
        setDownloadLoading(false);
      }
    });
  };

  return (
    <Modal
      show={purchaseOrder}
      onHide={() => {
        setPurchaseOrder(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="mx-auto"
      centered>
      <Modal.Header className="w-100 d-flex justify-content-between align-items-center border-0">
        <Modal.Title>Purchase Order</Modal.Title>
        <div
          className="close_btn drawer rounded-2"
          onClick={() => {
            setPurchaseOrder(false);
          }}>
          <IoMdClose fill="#515A6A" size={18} />
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0 w-100 px-3 payment-modal institution-form">
        <h6 className="section-subheading" style={{ fontWeight: 500, fontSize: 14 }}>
          {'Please fill this form and submit to purchase credits'}
        </h6>
        <label className="institution-form-label mt-3">Requisitioner’s Information</label>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="institution-form-label">First Name</label>
              <input
                type="text"
                className="form-control"
                name="firstName"
                value={purchaseForm?.firstName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="institution-form-label">Last Name</label>
              <input
                type="text"
                className="form-control"
                name="lastName"
                value={purchaseForm?.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="institution-form-label">Institution</label>
              <input
                type="text"
                className="form-control"
                name="institution"
                value={purchaseForm?.institution}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="institution-form-label">Work Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={purchaseForm?.email}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <label className="institution-form-label mt-3">Vendor’s Information</label>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="institution-form-label">Name</label>
              <input
                type="text"
                className="form-control"
                name="vendorName"
                value={purchaseForm?.vendorName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="institution-form-label">Email Address</label>
              <input
                type="email"
                className="form-control"
                name="vendorEmail"
                value={purchaseForm?.vendorEmail}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <label className="institution-form-label mt-3 mb-2">Item Information</label>
        <table className="table table-hover payment-table mb-3">
          <thead>
            <th>Item</th>
            <th>Quantity</th>
            <th>Cost</th>
          </thead>
          <tbody>
            <tr>
              <td>Credit</td>
              <td>{selectedPackage}</td>
              <td>${`${totalCost}`}</td>
            </tr>
          </tbody>
        </table>
        <div
          className="text-center fw-bolder"
          style={{ lineHeight: '18px', fontSize: 12, color: '#212529' }}>
          By filling out and submitting this form, you accept Junity's
          <a
            href="https://joinjunity.com"
            style={{ textDecoration: 'none' }}
            target="_blank"
            rel="noreferrer">
            {' '}
            Terms & Condition{' '}
          </a>
          and
          <a
            href="https://www.joinjunity.com/privacy-policy"
            style={{ textDecoration: 'none' }}
            target="_blank"
            rel="noreferrer">
            {' '}
            Privacy Policy.
          </a>
        </div>
        <div className="d-flex justify-content-end gap-3 pt-3">
          <button
            type="button"
            className="btn btn-outline-dark"
            style={{ flexGrow: 0, width: 'fit-content' }}
            onClick={() => {
              createInvoice();
            }}>
            {loading ? progress(loading, 'black') : 'Download'}
          </button>
          {/*
          <button
            type="button"
            className="btn btn-outline-dark"
            style={{flexGrow: 0, width: 'fit-content'}}
            onClick={()=>{handleDownload()}}
          >
            { downloadLoading ? progress(downloadLoading, "black") : 'Download'}
          </button>
          <button
            type="button"
            className="btn btn-success"
            style={{flexGrow: 0, width: 'fit-content'}}
            onClick={()=>{createInvoice()}}
          >
            { loading ? progress(loading) : 'Send PDF'}
          </button>
*/}
        </div>
      </Modal.Body>
    </Modal>
  );
};
