import React, { useState } from 'react';
import { useCollapse } from 'react-collapsed';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import '../../Components/Tabyouth/Youthtab.css';
import { formatDate } from '../../../../utils/MyGroups/utils';
import { titleWords } from '../../../../utils/helpers';

function Youthcollapse({ data }) {
  const [isExpanded, setExpanded] = useState(true);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  // const user = useSelector((store) => store.educator);

  return (
    <div>
      <div
        className="w-100 d-flex justify-content-between align-items-center"
        {...getToggleProps({
          onClick: () => setExpanded((prevExpanded) => !prevExpanded)
        })}>
        <div className="youth-detail-accordion-title">Personal Information</div>
        <div className="text-right angle-down-contact-icon">
          {isExpanded ? <BsChevronUp /> : <BsChevronDown />}
        </div>
      </div>
      <div className="pl-0 py-2">
        <section {...getCollapseProps()}>
          {/*<div className="youth-info">*/}
          {/*  <h6 className="youth-info-property">Age:</h6>*/}
          {/*  <h6 className="youth-info-value">{data?.age}</h6>*/}
          {/*</div>*/}
          <div className="youth-info">
            <h6 className="youth-info-property">Identifies As:</h6>
            <h6 className="youth-info-value">{titleWords(data?.gender)}</h6>
          </div>
          <div className="youth-info">
            <h6 className="youth-info-property">Grade:</h6>
            <h6 className="youth-info-value">Grade 12</h6>
          </div>
          <div className="youth-info">
            <h6 className="youth-info-property">Location:</h6>
            <h6 className="youth-info-value">{data?.state}</h6>
          </div>
          <div className="youth-info">
            <h6 className="youth-info-property">Account Created:</h6>
            <h6 className="youth-info-value">{formatDate(data?.createdAt)}</h6>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Youthcollapse;
