import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { API } from 'aws-amplify';
// import Demo from '../Chart/Chart';
import 'react-circular-progressbar/dist/styles.css';
import './Dashboardmain.css';
import NotificationModal from '../Tabmodal/NotificationModal';
import SharedCards from '../SharedCards/SharedCards';
import Interests from '../Interest/Interests';
import { calculateAge } from '../../../../utils/Youth/utils';
import { formatDateMonth } from '../../../../utils/MyGroups/utils';
// import { ageChartData } from '../Chart/utils/index';
import Recommendation from '../Recommendation/Recommendation';
import VerificationBanner from '../VerificationBanner/index';
import { ReturnPercentage } from '../Recommendation/utils';
import { setUserDataAction } from '../../../../Redux/actions/userAction';
import { useMutation } from '@apollo/client';
import { UPDATE_EDUCATOR } from '../../../../Services/mutation/educator';
import { AllStudents } from '../../../../Services/query/students';
import { GetEducator } from '../../../../Services/query/educator';
import { onUpdateEducator, onUpdateSchool } from '../../../../graphql/subscriptions';
import AlertToast from '../../../../Components/common/Alerts/ToastAlert';
import {
  sameStateOppCheck,
  sendAppNotification,
  sendEmailNotification
} from '../../../../utils/helpers';
import { adminClient, youthAxiosClient } from '../../../../libs/axiosClient';

function Dashboardmain() {
  const [updateEducator] = useMutation(UPDATE_EDUCATOR);
  const [drawSide, setDrawSide] = useState(false);
  const dispatch = useDispatch();

  const user = useSelector((store) => store.educatorUser.data);
  const [educatorUserData, setEducatorUserData] = useState(user);
  const [hideBanner, setHideBanner] = useState(false);

  const [students, setStudents] = useState([]);
  const [ages, setAges] = useState([]);
  const [opps, setOpps] = useState([]);
  const [toastVariant, setToastVariant] = useState('success');
  const [toastIcon, setToastIcon] = useState('check');
  const [toastActive, setToastActive] = useState(false);
  const [toastInfo, setToastInfo] = useState('');

  // const chartData = ageChartData(ages);
  const percentageData = ReturnPercentage(user);
  const percentage = percentageData?.percentage;

  const RequestVerification = async (status) => {
    const inputData = {
      verification_status: status,
      verification_date_started: new Date(),
      id: user?.id
    };
    updateEducator({
      variables: { ...inputData }
    });

    await sendAppNotification(
      user?.id,
      'You have successfully requested to verify your account. 🎉',
      'warning',
      `/dashboard`,
      'Click here to view',
      'mentor',
      'inapp'
    );

    await sendAppNotification(
      '',
      'You have received a new verification request.',
      'warning',
      `/account-verification`,
      'Click here to view',
      'admin',
      'push',
      'checkmark'
    );

    await sendEmailNotification(user?.email, 'main-verification-request', 'Account Verification', {
      fname: user?.name?.split(' ')[0],
      account: user?.name,
      organization: user?.school?.name,
      moduleUrl: window.location.origin
    });

    await adminClient.post('/email', {
      template: 'admin-verification-request',
      subject: 'Account Verification',
      email: user?.email,
      context: {
        accountName: user?.name
      },
      sendTo: 'all'
    });
  };

  const showToast = (variant, message) => {
    setToastVariant(variant);
    setToastIcon(variant === 'success' ? 'check' : 'delete');
    setToastInfo(message);
    setToastActive(true);
  };

  const handleAgeSetting = async (students) => {
    const ages = [];
    const filteredStudents = students?.filter((elt) =>
      educatorUserData?.school?.connections?.includes(elt.id)
    );
    filteredStudents?.map((elt) => ages.push(calculateAge(elt?.dob)));
    setAges(ages);
    setStudents(filteredStudents);
  };

  const getAllOpportunities = async () => {
    const schoolAddress = user?.school?.full_address;
    const schoolAddressObj = schoolAddress ? JSON.parse(schoolAddress) : {};
    const state = schoolAddressObj?.address_components?.find(
      (elt) => elt.types[0] === 'administrative_area_level_1'
    );

    try {
      const resp = await youthAxiosClient.post('/opportunities', {
        limit: 1000,
        filter: { expiry_date: { gt: new Date() } },
        fields: [
          'id',
          'name',
          'description',
          'createdAt',
          'state',
          'expiry_date',
          'category',
          'schoolID',
          'saved_by',
          'remote',
          'interests'
        ]
      });
      const oppsMatched = resp?.data?.data?.filter(
        (opp) => sameStateOppCheck(opp.state, state.short_name) === true
      );
      setOpps(oppsMatched.slice(0, 5));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    AllStudents().then((students) => handleAgeSetting(students));
    dispatch(setUserDataAction(educatorUserData));
  }, [educatorUserData]);

  useEffect(() => {
    let subscription1;
    let subscription2;

    // Fetch initial educator data
    GetEducator(user?.id).then((data) => setEducatorUserData(data));
    getAllOpportunities();

    // Subscription 1: onUpdateEducator
    subscription1 = API.graphql({
      query: onUpdateEducator,
      variables: { id: user?.id }
    }).subscribe({
      next: async (data) => {
        const educatorData = data.value.data.onUpdateEducator;
        const educator = await GetEducator(educatorData?.id);
        if (!user?.school?.is_verified && educator?.school?.is_verified) {
          showToast('success', 'Congrats!🎉  Your account has successfully been verified.');
        }
        setEducatorUserData(educator);
      },
      error: (error) => console.log(error)
    });

    // Subscription 2: onUpdateSchool
    subscription2 = API.graphql({
      query: onUpdateSchool,
      variables: { id: user?.schoolID }
    }).subscribe({
      next: async () => {
        const educator = await GetEducator(user?.id);
        setEducatorUserData(educator);
      },
      error: (error) => console.log(error)
    });

    // Return cleanup functions to unsubscribe from subscriptions
    return () => {
      subscription1.unsubscribe();
      subscription2.unsubscribe();
    };
  }, []);

  return (
    <>
      <div className="col-sm-12 background_color ">
        <div className="col-sm-12 pt-3 pb-2">
          <h4 style={{ fontSize: 20 }} className="font-weight-bolder">
            Hi, {educatorUserData?.name?.split(' ')[0]}
          </h4>
        </div>
        <div className="col-sm-12 mt-3 mb-3">
          {!hideBanner && !educatorUserData?.school?.is_verified && (
            <VerificationBanner
              setHideBanner={setHideBanner}
              status={educatorUserData.verification_status}
              callback={RequestVerification}
            />
          )}
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-6 col-lg-6 mb-3 position-relative">
              <div className="col-sm-12 background_card_1 students-number-card text-center">
                {students?.length === 0 ? (
                  <div className="card-content">
                    <div className="flex-v align-items">
                      <img src="../../images/people-outline.png" alt="" />
                      <p className="mt-2 fw-bolder" style={{ width: 150, fontSize: 12 }}>
                        No students have been added yet
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="col-sm-12">
                      <NotificationModal data={educatorUserData} />
                      <i className="fas fa-users" id="icon_size"></i>
                      <p className="mt-2 youth-count">{students?.length}</p>
                      <h6>Youth Connections</h6>
                    </div>
                    <div className="col-sm-12">
                      <Link to="/youth" style={{ textDecoration: 'none' }}>
                        <p className="text_style">
                          See all youth <i className="fas fa-angle-right"></i>
                        </p>
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-6 col-lg-6 mb-3 position-relative">
              <div className="col-sm-12 background_card_3 pt-3">
                <div className="row pb-3">
                  <div className="col-sm-6">
                    <h6 className="text_color">
                      <b>TOP INTERESTS</b>
                    </h6>
                  </div>
                  <div className="col-sm-6 text-right">
                    {/* {<h6 className="text_greyed">
                    Last 30 Days
                  </h6>} */}
                  </div>
                </div>
                <Interests students={students} />
              </div>
            </div>
          </div>
          {/*<div className="col-md-6 col-lg-5 mb-3 position-relative">*/}
          {/*  <div className="col-sm-12 background_card_3 pt-3">*/}
          {/*    <div className="row pb-3">*/}
          {/*      <div className="col-sm-12">*/}
          {/*        <h6 className="text_color">*/}
          {/*          <b>AGE DISTRIBUTION</b>*/}
          {/*        </h6>*/}
          {/*      </div>*/}
          {/*      <div className="card-content">*/}
          {/*        {Object.keys(chartData || {})?.length === 0 ? (*/}
          {/*          <div className="flex-v align-items">*/}
          {/*            <img src="../../images/no-report.png" alt="" />*/}
          {/*            <p className="mt-2 card-text">No age distribution to display yet</p>*/}
          {/*          </div>*/}
          {/*        ) : (*/}
          {/*          <>*/}
          {/*            <div className="justify-between" style={{ width: '100%' }}>*/}
          {/*              <div className="col-sm-12 height_chart" style={{ width: '70%' }}>*/}
          {/*                <Demo chartData={chartData} />*/}
          {/*              </div>*/}
          {/*            </div>*/}
          {/*          </>*/}
          {/*        )}*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="col-12">
            <div className="row">
              <div className="col-md-6 col-lg-3 mb-3 position-relative">
                <div className="col-sm-12 background_card_5 text-center pb-2">
                  <div className="col-sm-12 pt-4 pb-2">
                    <h6 className="text_primary">RECOMMENDATIONS</h6>
                    {!isNaN(Number(percentage)) &&
                    Number(percentage) !== Infinity &&
                    percentage === 0 ? (
                      ''
                    ) : (
                      <h6 className="date_color">{formatDateMonth(new Date())}</h6>
                    )}
                  </div>
                  {!isNaN(Number(percentage)) &&
                  Number(percentage) !== Infinity &&
                  percentage === 0 ? (
                    <div className="card-content">
                      <img src="../../images/no-report.png" alt="" />
                      <p className="mt-2 card-text">No data yet</p>
                    </div>
                  ) : (
                    <Recommendation user={educatorUserData} />
                  )}
                </div>
              </div>
              <div className="col-lg-9 mb-3">
                <div className="col-sm-12 background_card_2 pt-4">
                  <div className="row pb-3">
                    <div className="col-sm-7">
                      <h6 className="text_primary">
                        OPPORTUNITIES YOUR CONNECTED YOUTH MAY BE INTERESTED IN
                      </h6>
                    </div>
                    {opps?.length > 0 && (
                      <div className="col-sm-5 text-right">
                        <Link to="/all-opportunity" style={{ textDecoration: 'none' }}>
                          <h6 className="text_color">
                            <b>
                              See all opportunities <i className="fas fa-chevron-right"></i>
                            </b>
                          </h6>
                        </Link>
                      </div>
                    )}
                  </div>
                  {!educatorUserData?.school?.is_verified || opps?.length === 0 ? (
                    <div className="card-content">
                      <div className="flex-v align-items justify-center">
                        <img src="../../images/no-opp.png" alt="" />
                        <p className="mt-2 card-text">No Opportunities yet</p>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex gap-2" style={{ overflowX: 'auto' }}>
                      {opps.map((item, index) => (
                        <React.Fragment key={index}>
                          <SharedCards
                            item={item}
                            drawSide={drawSide}
                            setDrawSide={setDrawSide}
                            id={item?.id}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 pt-4 mb-3 position-relative">
            <div className="col-sm-12 background_card_4">
              <div className="col-sm-12 pt-3">
                <div className="row ">
                  <div className="col-sm-6">
                    <h6 className="text_color">
                      <b>ANALYTICS</b>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-12 pl-0 pr-0 pb-2">
                {/* {<AnalyticCards />} */}
                <div className="text-center">
                  <img src="../../images/no-report.png" alt="" width={120} />
                  <p className="mt-2 card-text">No data yet</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertToast
        variant={toastVariant}
        icon={toastIcon}
        active={toastActive}
        setActive={setToastActive}
        info={toastInfo}
      />
    </>
  );
}

export default Dashboardmain;
