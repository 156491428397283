import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Tabswitchside from '../Tabswitchside/Tabswitchside';
import { IoMdClose } from 'react-icons/io';
import { BsCheck2Circle } from 'react-icons/bs';
import CalendarOutline from '../../../../Assets/images/calendar-outline.svg';
import Collapsemygroups from '../Mygroups/Collapsemygroups';
import { ClipLoader } from 'react-spinners';
import { calculateTime, formatDate, getState } from '../../../../utils/helpers';
import RecommendOppModal from '../../../Dashboard/Components/SharedCards/Modals/MyGroupsModal';
import { ShareOpportunityModal, ReportIssueModal } from '../../../../Components/Modals';
import './style.css';

export default function Sidedrawer({ user, drawOpen, setDrawOpen, item, toggleSaveOpportunities }) {
  const [loading, setLoading] = useState(false);
  const [recommendModalShow, setRecommendModalShow] = useState(false);
  const [shareModalShow, setShareModalShow] = useState(false);
  const [reportModalShow, setReportModalShow] = useState(false);

  const progress = () => {
    return <ClipLoader size={10} color={loading ? '#fff' : ''} />;
  };

  const showRecommendModal = () => {
    setDrawOpen(false);
    setRecommendModalShow(true);
  };

  const handleModalClose = () => {
    setDrawOpen(false);
  };

  return (
    <div>
      <React.Fragment key="right">
        <Drawer anchor="right" open={drawOpen} onClose={handleModalClose}>
          <Box sx={{ width: 550 }} role="presentation" onKeyDown={handleModalClose}>
            <List>
              <div className="col-sm-12">
                <div className="col-sm-12">
                  <div className="w-100 d-flex justify-content-between align-items-center add_border pb-1">
                    <div className="d-flex align-items-center">
                      <img src={item?.school?.image} className="img-fluid md mr-1 opp_img" alt="" />
                      <div>
                        <h6 className="m-0 opportunity-detail-owner">
                          <b>{item?.school?.name}</b>
                        </h6>
                        <h6 className="opportunity-detail-state mt-1">
                          {getState(item?.school?.full_address)}
                        </h6>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn close drawer d-flex align-items-center justify-content-center"
                      onClick={handleModalClose}>
                      <IoMdClose fill="#515A6A" size={18} />
                    </button>
                  </div>
                  <div className="col-sm-12 pl-0 mt-2">
                    <h2 className="opportunity-detail-name">
                      <b>{item?.name}</b>
                    </h2>
                    <div className="my-3 d-flex align-items-center gap-2">
                      <div className="d-flex align-items-center">
                        <img src={CalendarOutline} alt="calendar" width={16} height={16} />
                        <span className="opportunity-detail-posted pl-1">
                          Posted {calculateTime(item?.createdAt)}
                        </span>
                      </div>
                      <div className="opportunity-detail-expiry d-flex align-items-center">
                        <i className="far fa-clock"></i>
                        <span className="pl-1 opportunity">
                          Closes {formatDate(item?.expiry_date)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 pl-0 mt-2 pb-3 add_border">
                    <div className="d-flex justify-between gap-2">
                      <button
                        className="btn opportunity-detail-recommend-btn"
                        onClick={showRecommendModal}>
                        {loading ? (
                          progress()
                        ) : (
                          <>
                            Recommend this Opportunity
                            <BsCheck2Circle className="ml-1" />
                          </>
                        )}
                      </button>
                      <button
                        className={`btn opportunity-detail-btn save ${
                          item?.saved_by?.includes(user?.data?.id) ? 'saved' : ''
                        }`}
                        onClick={toggleSaveOpportunities}>
                        <i
                          className={`${
                            item?.saved_by?.includes(user?.data?.id) ? 'fas' : 'far'
                          } fa-bookmark`}></i>
                      </button>
                      <div className="dropdown header-profile ms-0">
                        <button
                          data-toggle="dropdown"
                          aria-expanded="false"
                          className="btn btn opportunity-detail-btn options">
                          <i className="fas fa-ellipsis-v"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={() => {
                              setDrawOpen(false);
                              setShareModalShow(true);
                            }}>
                            <img src="../images/people.png" alt="icon" />
                            Share this Opportunity
                          </button>
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              setDrawOpen(false);
                              setReportModalShow(true);
                            }}>
                            <img src="../images/flag.png" alt="icon" />
                            Report this Opportunity
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-3 mb-2 p-0 pb-1 add_border">
                    <h6 className="opportunity-detail-group-intro">
                      <b>GROUPS YOU RECOMMENDED THIS TO</b>
                    </h6>
                  </div>
                  <div className="col-sm-12 p-0">
                    <Tabswitchside oppId={item?.id} />
                  </div>
                  <div className="col-sm-12 p-0 mt-4 add_border">
                    <Collapsemygroups data={item} />
                  </div>
                </div>
              </div>
            </List>
          </Box>
        </Drawer>
      </React.Fragment>
      <RecommendOppModal
        modalShow={recommendModalShow}
        setModalShow={setRecommendModalShow}
        opportunityID={item?.id}
      />
      <ShareOpportunityModal
        shareModalShow={shareModalShow}
        setShareModalShow={setShareModalShow}
        opportunityID={item?.id}
      />
      <ReportIssueModal
        reportModalShow={reportModalShow}
        setReportModalShow={setReportModalShow}
        title="Report this opportunity"
        type={'Opportunity'}
        module={item}
      />
    </div>
  );
}
