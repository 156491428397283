import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';
import { setUserDataAction, resetUserDataAction } from '../../Redux/actions/userAction';
import { ClipLoader } from 'react-spinners';
import './Checkemail.css';

const Checkemail = () => {
  const userData = useSelector((store) => store.educatorUser?.data);
  const email = localStorage.getItem('remail');
  
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(45);
  const [loading, setLoading] = useState(false);

  const progress = () => {
    return <ClipLoader size={10} color={loading ? '#fff' : ''} />;
  };

  const requestPasswordChange = async () => {
    setLoading(true);
    try {
      const data = await Auth.forgotPassword(email);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setTimer(45);
  };

  const startCountDown = () => {
    setTimeout(() => {
      if (timer > 0) {
        setTimer((prev) => prev - 1);
      }
    }, 1000);
  };

  useEffect(() => {
    dispatch(resetUserDataAction());
  }, []);

  useEffect(() => {
    startCountDown();
  }, [timer]);

  return (
    <div className="col-sm-12 bgimage-signup">
      <div className="row pl-sm-5">
        <div className="card col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-2 mt-sm-5 offset-sm-1 p-2">
          <div className="col-sm-12">
            <img src="../images/logo.png" />
          </div>

          <div className="col-sm-12 mt-3 ">
            <p className="heading-text text-neutral font-weight-bold mb-0">
              <b>Check your email</b>
            </p>
          </div>
          <div className="col-sm-12 pt-1">
            <p className="paragraph-text fw-semibold opacity-75 mb-0">
              We just sent an email to <b className="text-success">{email}</b> with instruction on
              how to reset your password.
            </p>
          </div>
          <div className="col-sm-12 pt-3 pb-2">
            <p className="paragraph-text fw-semibold opacity-75 mb-0">
              <b> If you can't find email. Check your spam folder. </b>
            </p>
          </div>
          {/* <div className='col-sm-12 mt-3'>
                                  <div className='row'>
                                      <div className='col-sm-12 mt-1'>
                                          <label className='input-label-text'>Work email address</label>
                                          <input className='form-control signupinput' onChange={(e) => setName(e.target.value)} type="text" placeholder="Type your email address" />
                                      </div>
                                  </div>
                              </div> */}

          {/* <div className='col-sm-12 mt-4 pb-3'>
                              <Link to="/contact-form">
                                  <button disabled={!name} className='btn btn-success buttoncolorsignup1 pt-2 pb-2'>Send an email</button>
                              </Link
                          </div> */}
          <div className="col-sm-12 pt-1">
            <hr />
          </div>
          <div className="col-sm-12 paragraph-text">
            <p>
              <b>
                <span className="text-color-signup font-weight-bold" style={{ color: '#515A6A' }}>
                  Didn’t get the email?{' '}
                  {loading ? (
                    <span className={'text-success inactive'}>{progress()}</span>
                  ) : (
                    <span
                      onClick={requestPasswordChange}
                      className={`${timer ? 'inactive' : 'text-success cursor-pointer'}`}>
                      Resend{timer ? <span className="ps-1">in 0:{timer}</span> : null}
                    </span>
                  )}
                </span>
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkemail;
