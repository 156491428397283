import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Send } from 'react-feather';
import { ClipLoader } from 'react-spinners';
import { Storage } from 'aws-amplify';
import EmojiPicker from 'emoji-picker-react';
import Smile from '../../Assets/images/face-emoji.svg';
import { chatClient } from '../../libs/axiosClient';

export default function AttachmentModal({
  show,
  user,
  chatId,
  onHide,
  images,
  setImages,
  imageUrls,
  getMessages,
  setImageUrls
}) {
  const [state, setState] = useState('');
  const [toggleEmoji, setToggleEmoji] = useState(false);
  const [loading, setLoading] = useState(false);

  const progress = () => {
    return <ClipLoader size={20} color={loading ? '#969CA5' : ''} />;
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      await handleEnterKey();
    }
  };

  const handleEnterKey = async () => {
    await sendMessage();
  };

  const handleEmojiClick = (item) => {
    setState(state + item.emoji);
    setToggleEmoji(false);
  };

  const cleanFilename = (name) => {
    return name.split(' ').join('');
  };

  const saveImages = async (images) => {
    return await Array.from(images).reduce(async (acc, file) => {
      const prev = await acc;
      const data = await Storage.put(cleanFilename(file.name), file);
      return [
        ...prev,
        `https://junity-counselor-storage204659-staging.s3.amazonaws.com/public/${data.key}`
      ];
    }, Promise.resolve([]));
  };

  const sendMessage = async () => {
    try {
      if (state.trim() !== '') {
        setLoading(true);
        const messageData = {
          text: state.trim(),
          custom_json: JSON.stringify({
            sender_id: user?.id,
            sender_name: user?.name,
            type: 'message'
          })
        };
        if (images.length > 0) {
          messageData.attachment_urls = await saveImages(images);
          await chatClient.post(`chats/${chatId}/messages/`, messageData);
        } else {
          await chatClient.post(`chats/${chatId}/messages/`, messageData);
        }
        setLoading(false);
        setState('');
        setImageUrls([]);
        setImages([]);
        onHide();
        getMessages();
      }
    } catch (err) {
      setLoading(false);
      console.error('an error', err);
    }
  };

  return (
    <Modal className="bottom-modal" show={show} onHide={onHide}>
      <Modal.Body>
        <div className="horizontal-items mb-3">
          {imageUrls.map((elt, index) => (
            <div className="item" key={index}>
              <img src={elt} alt="" />
            </div>
          ))}
        </div>
        <div className="chat-footer">
          <div className="inner-sec">
            <div className="emoji">
              <img src={Smile} alt="" />
            </div>
            <div className="msg">
              <input
                type="text"
                placeholder="Type a message"
                value={state}
                onChange={(e) => setState(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div
              className="icon d-flex align-items-center"
              onClick={sendMessage}
              style={{ pointerEvents: state.trim() === '' ? 'none' : 'auto' }}>
              {loading ? progress() : <Send stroke={state.trim() === '' ? '#515A6A' : '#10408C'} />}
            </div>
          </div>
        </div>
      </Modal.Body>
      {toggleEmoji && (
        <div>
          <EmojiPicker onEmojiClick={handleEmojiClick} />
        </div>
      )}
    </Modal>
  );
}
