import React, { useState, useEffect } from 'react';
import { useCollapse } from 'react-collapsed';
import '../../Recomendation/Recomendation.css';
import { AllRecommendedOpportunities } from '../../../../../Services/query/recommendation';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { AllMyGroups } from '../../../../../Services/query/myGroups';
import { formatDate } from '../../../../../utils/helpers';
import { useSelector } from 'react-redux';
import QuestionsOne from '../../../../../Assets/images/questionsone.svg';
import { Link } from 'react-router-dom';
import { AllOpportunitiesAPI } from '../../../../../Services/query/opportunities';

function CollapseRecommend({ data }) {
  const [isExpanded, setExpanded] = useState(true);
  const user = useSelector((store) => store.educatorUser);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const [recommendedOpps, setRecommendedOpps] = useState([]);
  const [filteredRecommendedOpps, setFilteredRecommendedOpps] = useState([]);
  const [sameGroupsRecommend, setSameGroupsRecommend] = useState([]);
  const [allOpps, setAllOpps] = useState([]);

  const groups = AllMyGroups();
  const studentGroups = groups?.data?.filter((elt) => elt?.studentIDs?.includes(data?.id));

  useEffect(() => {
    AllRecommendedOpportunities().then((opps) => {
      setRecommendedOpps(opps);
      setFilteredRecommendedOpps(opps?.filter((elt) => elt?.educatorID === user?.data?.id));
    });
  }, []);

  useEffect(() => {
    let groups = [];
    filteredRecommendedOpps?.forEach((elt) => {
      const filterGroups = studentGroups?.filter((group) => group?.id === elt?.groupID);
      if (filterGroups?.length === 1) {
        groups.push(elt);
      }
    });
    setSameGroupsRecommend(groups);
  }, [filteredRecommendedOpps]);

  const getOpportunity = (id) => {
    return allOpps?.find((elt) => elt?.id === id);
  };

  const getApp = (id) => {
    return allOpps?.filter((elt) => (elt?.opportunityID === id) & (elt?.studentID === data?.id))[0];
  };

  // const badges = ['Approved', 'Pending', 'Rejected'];

  const RecommendedOpp = ({ image, recommendedOn, appliedOn = '', oppName, status = '' }) => {
    return (
      <div className="d-flex align-items-center p-2 mb-3 rounded-3 border">
        <img src={image} width={72} height={72} alt="" />
        <div className="ml-2">
          <h6 className="recommended-on mb-1">Recomended on {recommendedOn}</h6>
          <h6 className="recommended-opp-name mb-1">{oppName}</h6>
          <div className="d-flex align-items-center">
            {appliedOn ? (
              <>
                <button
                  className={`btn rounded-pill activity-card-btn activity-card-btn-${status}`}>
                  Accepted
                </button>
                <h6 className="ml-2 applied-on">Applied on {appliedOn}</h6>
              </>
            ) : (
              <h6 className="not-applied-yet">Not Applied Yet</h6>
            )}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    AllOpportunitiesAPI().then((opps) => {
      setAllOpps(opps.filter((elt) => new Date(elt.expiry_date) > new Date()));
    });
  }, []);

  return (
    <>
      {sameGroupsRecommend?.length ? (
        <>
          <div
            className="w-100 d-flex justify-content-between align-items-center"
            {...getToggleProps({
              onClick: () => setExpanded((prevExpanded) => !prevExpanded)
            })}>
            <div className="youth-detail-accordion-title">OPPORTUNITIES YOU RECOMMENDED</div>
            <div className="text-right angle-down-contact-icon">
              {isExpanded ? <BsChevronUp /> : <BsChevronDown />}
            </div>
          </div>
          <div className="pl-0 py-2">
            <section {...getCollapseProps()}>
              {sameGroupsRecommend?.map((elt, index) => (
                <RecommendedOpp
                  image={'../images/o1.png'}
                  recommendedOn={formatDate(elt?.createdAt)}
                  appliedOn={formatDate(getApp(elt?.opportunityID)?.createdAt)}
                  oppName={getOpportunity(elt?.opportunityID)?.name}
                  status={getApp(elt?.opportunityID)?.status}
                  key={index}
                />
              ))}
            </section>
          </div>
        </>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center text-center">
          <img src={QuestionsOne} alt="question-one" />
          <h5 className="empty-state-header">Nothing here yet</h5>
          <h6 className="empty-state-text px-5">
            You have not recommended any opportunity to any group(s) {data?.name} belongs to.
          </h6>
          <Link to="/all-opportunity" className="empty-state-link">
            Explore Opportunities
            <i className="ml-1 fas fa-arrow-right"></i>
          </Link>
        </div>
      )}
    </>
  );
}

export default CollapseRecommend;
