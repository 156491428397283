import React, { Component, useState } from 'react';
import { AllStudents } from '../../../../Services/query/students';

function NotificationModal({ data, students }) {
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);

  return (
    <div className="col-sm-12 p-0">
      {/* Button trigger modal */}
      <button
        className="btn btn-light p-0"
        style={{
          width: '100%',
          backgroundColor: 'white',
          border: '1px solid white'
        }}
        data-toggle="modal"
        data-target="#requestsent211">
        <h4 className="mt-2">{students?.length}</h4>
      </button>
      {/* Modal 5 */}
      <div
        className="modal fade mt-5"
        id="requestsent211"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body ">
              <div className="col-sm-12 mb-3">
                <div className="row">
                  <div className="col-sm-6 text-left">
                    <h5
                      className="modal-title"
                      id="exampleModalLongTitle"
                      onClick={() => setShow(!show) || setHide(!hide)}>
                      Notifications
                    </h5>
                  </div>
                  <div className="col-sm-6  text-right pr-4">
                    <button
                      type="button"
                      className="btn btn-light"
                      data-dismiss="modal"
                      aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                </div>
              </div>

              {show ? (
                <div className="col-sm-12 p-0">
                  <div className="col-sm-12 mb-3">
                    <div className="row">
                      <div className="col-sm-6 text-left">
                        <h5
                          style={{ fontSize: '100%' }}
                          className="modal-title"
                          id="exampleModalLongTitle">
                          Showing 6 Notifications
                        </h5>
                      </div>
                      <div className="col-sm-6  text-right pr-4">
                        <h5
                          style={{ fontSize: '100%' }}
                          className="modal-title"
                          id="exampleModalLongTitle">
                          Clear
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 mb-3 text-left">
                    <div className="row">
                      <div className="col-sm-6 text-left">
                        <h5
                          style={{ fontSize: '100%' }}
                          className="modal-title"
                          id="exampleModalLongTitle">
                          New Student on Junity 🎉
                        </h5>
                      </div>
                      <div className="col-sm-6  text-right pr-4">
                        <h5
                          style={{ fontSize: '100%' }}
                          className="modal-title"
                          id="exampleModalLongTitle">
                          2h ago
                        </h5>
                      </div>
                    </div>

                    <div className="col-sm-12 pl-0">
                      <h5
                        style={{ fontSize: '100%' }}
                        className="modal-title"
                        id="exampleModalLongTitle">
                        Karla Anthony in grade 8 just signed up to Junity
                      </h5>
                    </div>
                    <div className="col-sm-12 pl-0">
                      <h5
                        style={{ fontSize: '100%', color: 'green' }}
                        className="modal-title"
                        id="exampleModalLongTitle">
                        View Profile
                      </h5>
                    </div>
                  </div>
                  <hr className="m-1" />

                  <div
                    className="col-sm-12  text-left pt-2 pb-2 mb-0"
                    style={{ backgroundColor: '#F4F5F7' }}>
                    <div className="row">
                      <div className="col-sm-6 text-left">
                        <h5
                          style={{ fontSize: '100%' }}
                          className="modal-title"
                          id="exampleModalLongTitle">
                          New Student on Junity 🎉
                        </h5>
                      </div>
                      <div className="col-sm-6  text-right pr-4">
                        <h5
                          style={{ fontSize: '100%' }}
                          className="modal-title"
                          id="exampleModalLongTitle">
                          2h ago
                        </h5>
                      </div>
                    </div>

                    <div className="col-sm-12 pl-0">
                      <h5
                        style={{ fontSize: '100%' }}
                        className="modal-title"
                        id="exampleModalLongTitle">
                        Karla Anthony in grade 8 just signed up to Junity
                      </h5>
                    </div>
                    <div className="col-sm-12 pl-0">
                      <h5
                        style={{ fontSize: '100%', color: 'green' }}
                        className="modal-title mb-0"
                        id="exampleModalLongTitle">
                        View Profile
                      </h5>
                    </div>
                  </div>
                  <hr className="m-1" />
                </div>
              ) : (
                <div className="col-sm-12 mb-4">
                  <img className="img-fluid" src="../images/notii.png" />
                  <h6 style={{ color: '#969CA5' }}>
                    You do not have any notification
                    <br /> at the moment
                  </h6>
                </div>
              )}

              <div className="col-sm-12 p-3">
                <div className="row">
                  <div className="col-sm-6">
                    {/* <button type="button" className="btn btn-light" style={{backgroundColor:"white", border:"1px solid gray", width:"100%", color:"gray"}} data-dismiss="modal">cancel</button> */}
                  </div>
                  <div className="col-sm-6 pr-4">
                    <button type="button" className="btn btn-success" style={{ width: '100%' }}>
                      Save changes
                    </button>
                  </div>
                </div>
                {/* <div className='col-sm-12 p-0' >
                                   <button type="button" style={{backgroundColor:"white", border:"1px solid gray", width:"100%", color:"gray"}} className="btn btn-secondary" data-dismiss="modal" data-toggle="modal" data-target="#auth4">Close</button>
                                   </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationModal;
