import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { IoMdClose } from 'react-icons/io';
import Tabyouth from '../Tabyouth/Tabyouth';
import './Youthchat.css';
import EnhancedTable from '../Youthtables/Youthtables';
import InitialsWrapper from '../../../../Widgets/InitialsWrapper/InitialsWrapper';
import { getInitial } from '../../../../utils/helpers';
import FilterIcon from '../../../../Assets/images/filter.svg';
import { ReportIssueModal, RemoveConnectionModal } from '../../../../Components/Modals';
import { FilterCheckItem } from '../../../../Components/common/Filter';
import { Modal } from 'react-bootstrap';
import { AllCategories } from '../../../../Services/query/categories';

function Youthchat({
  studentsData,
  onSearch,
  filters,
  setFilters,
  onFilter,
  filtersCount,
  clearFilters
}) {
  const [openSide, setOpenSide] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [reportModalShow, setReportModalShow] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [interests, setInterests] = useState([]);
  const [data, setData] = useState({});

  // const ages = ['13', '14', '15', '16', '17', '18', '19'];
  // const categories = ''

  const handleSelect = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: Array.from(new Set([...filters[e.target.name], e.target.value]))
    });
  };

  const resetFilter = () => {
    clearFilters();
    setShowFilterModal(false);
  };

  const applyFilters = () => {
    onFilter();
    setShowFilterModal(false);
  };

  useEffect(() => {
    const getInterests = async () => {
      const categories = await AllCategories();
      setInterests(categories);
    };

    getInterests();
  }, []);

  return (
    <>
      {/* <!-- Modal --> */}
      <Modal
        show={showFilterModal}
        onHide={() => {
          setShowFilterModal(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal_body_style"
        centered>
        <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
          <Modal.Title>Change Role</Modal.Title>

          <div
            className="close_btn drawer rounded-2"
            onClick={() => {
              setShowFilterModal(false);
            }}>
            <IoMdClose fill="#515A6A" size={18} />
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 w-100 px-3 payment-modal">
          {/*<div className="mb-2">*/}
          {/*  <h6 className="filter-modal-subheading">Age</h6>*/}
          {/*  <div className="w-100 border rounded-3 p-2">*/}
          {/*    {ages?.map((age) => (*/}
          {/*      <FilterCheckItem*/}
          {/*        key={`age${age}`}*/}
          {/*        name={'ages'}*/}
          {/*        label={`${age} Years`}*/}
          {/*        value={age}*/}
          {/*        data={filters}*/}
          {/*        onChange={handleSelect}*/}
          {/*      />*/}
          {/*    ))}*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="mb-2 mt-3">
            <h6 className="filter-modal-subheading">Interests</h6>
            <div className="w-100 border rounded-3 p-2">
              {interests?.map((interest) => (
                <FilterCheckItem
                  key={`interest_${interest?.id}`}
                  name={'interests'}
                  label={`${interest?.name}`}
                  value={interest?.name}
                  data={filters}
                  onChange={handleSelect}
                />
              ))}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
            <button
              type="button"
              className="btn btn-action border text-neutral"
              onClick={resetFilter}>
              Clear Filter
            </button>
            <button type="button" className="btn btn-success btn-action" onClick={applyFilters}>
              Apply
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="justify-between bg_full_height">
        <div className="bg-white rounded rounded-3" style={{ width: openSide ? '49%' : '100%' }}>
          <div className="pt-4 px-3" style={{ height: '95%' }}>
            <div className="w-100 d-flex align-items-center justify-content-between flex-wrap p-0 mb-4 px-3">
              <div className="d-flex align-items-center">
                <button
                  type="button"
                  className="btn btn-success btn-filter"
                  onClick={() => {
                    setShowFilterModal(true);
                  }}>
                  <span>Filter</span>
                  <img src={FilterIcon} className="ml-1" alt="" />
                </button>
                <span className="ml-2" style={{ fontSize: 13 }}>
                  <b>{filtersCount} filters selected</b>
                </span>
              </div>
              <div className="searchbar mt-2 mt-md-0">
                <div className={`input-group p-0`}>
                  <span className="input-group-text bg-white">
                    <i className="fas fa-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control bg-white search-input"
                    placeholder="Search for youth..."
                    onChange={(e) => {
                      onSearch(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="youth-table">
              <EnhancedTable
                setOpenSide={setOpenSide}
                setData={setData}
                studentsData={studentsData}
              />
            </div>
          </div>
        </div>
        {openSide && Object.keys(data).length ? (
          <div
            style={{
              width: '50%',
              overflowY: 'auto',
              backgroundColor: 'white'
            }}
            className="rounded rounded-3">
            <Grid>
              <div className="pl-0 bg_full_height">
                <div className="pt-4 pb-1 px-3 border-bottom">
                  <div className="d-flex align-items justify-between">
                    <div className="d-flex gap-2">
                      <div className="position-relative">
                        <InitialsWrapper type="large" text={getInitial(data?.name)} bg="#2D9371" />
                      </div>
                      <div>
                        <div>
                          <span className="youth-detail-owner">
                            {data?.name}
                            <i
                              id="icon_style"
                              className="fas fa-check-circle ml-2 youth-detail"></i>
                          </span>
                        </div>
                        <p className="youth-detail-posted">Last seen recently</p>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="close_btn drawer border-0"
                      onClick={() => setOpenSide(false)}>
                      <IoMdClose fill="#515A6A" size={18} />
                    </button>
                  </div>
                  <div className="d-flex align-items justify-between gap-2 py-2">
                    <Link
                      to={`/chat?id=${data?.id}&name=${data?.name}`}
                      className="btn youth-detail-btn-chat">
                      Chat with {data?.name}
                    </Link>
                    <button
                      type="button"
                      className="btn youth-detail-btn youth-detail-btn-add-to-group">
                      <i className="fas fa-users fa-fw"></i> Add to Group
                    </button>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn youth-detail-btn youth-detail-btn-options"
                        data-toggle="dropdown">
                        <i className="fas fa-ellipsis-v"></i>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-left"
                        aria-labelledby="triggerId1">
                        <a
                          className="dropdown-item text-danger"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowRemoveModal(true);
                          }}>
                          <i className="far fa-times-circle me-1"></i>
                          {'Remove Connection'}
                        </a>
                        <a
                          href="#"
                          className="dropdown-item text-neutral"
                          onClick={(e) => {
                            e.preventDefault();
                            setReportModalShow(true);
                          }}>
                          <i className="far fa-flag-alt me-1"></i>
                          {'Report Issue'}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 p-0 px-4">
                  <Tabyouth data={data} />
                </div>
              </div>
            </Grid>
          </div>
        ) : (
          ''
        )}
      </div>
      <ReportIssueModal
        reportModalShow={reportModalShow}
        setReportModalShow={setReportModalShow}
        type={'Youth'}
        module={data}
      />
      <RemoveConnectionModal
        showRemoveModal={showRemoveModal}
        setShowRemoveModal={setShowRemoveModal}
        userId={data?.id}
        organizationID={data?.school?.id}
      />
    </>
  );
}

export default Youthchat;
