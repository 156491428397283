import { isMobilePhone } from 'validator';

export const validatePassword = (password) => {
  const re = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^'\\"_-]).{8,}/);
  return re.test(password);
};

export const validatePhone = (phone) => {
  const codeWithPhone = '+1' + phone;
  return isMobilePhone(codeWithPhone, 'en-US', {
    strictMode: true
  });
};

export const setupAccountValidate = (state, setVerified, agreeToTerms) => {
  setVerified(
    Object.keys(state).length === 6 &&
      Object.values(state).every((elt) => elt.trim() !== '') &&
      validatePassword(state.password) &&
      validatePhone(state.phone) &&
      agreeToTerms
  );
};

export const isRequiredFieldsPassed = (obj, fields, type) => {
  if (type === 'eq') {
    return Object.keys(obj).length === fields && Object.values(obj).every((elt) => elt !== '');
  }

  return false;
};

export const isEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

export const checkSameDomain = (orgEmail, userEmail) => {
  if (String(orgEmail).includes('@') && String(userEmail).includes('@'))
    return orgEmail?.split('@')[1] === userEmail?.split('@')[1];
  return false;
};

export const validateEmail = (email) => {
  if (email) {
    const domains = [
      'yahoo.com',
      'gmail.com',
      'google.com',
      'hotmail.com',
      'me.com',
      'aol.com',
      'mac.com',
      'live.com',
      'comcast.com',
      'googlemail.com',
      'msn.com',
      'hotmail.co.uk',
      'yahoo.co.uk',
      'facebook.com',
      'verizon.net',
      'att.net',
      'gmz.com',
      'mail.com'
    ];
    const atSignIndex = email.indexOf('@') + 1;
    const domain = String(email).slice(atSignIndex);
    return domains.includes(domain);
  }

  return true;
};
