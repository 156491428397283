import React, { useRef, useState } from 'react';
import AlertToast from '../../../../../Components/common/Alerts/ToastAlert';

function Changepicturemodal({ image, saveImage }) {
  const [imageProps, setImageProps] = useState({ image: image });
  const [activeToast, setActiveToast] = useState(false);
  const [toast, setToast] = useState('');
  const imgRef = useRef(null);

  const handleImageChange = (e) => {
    const image = e.target.files[0];
    const imageSize = image.size / 1024 / 1024;
    if (imageSize > 1) {
      setActiveToast(true);
      setToast('Image size is more than 1mb');
    } else {
      const fileReader = new FileReader();
      fileReader.onloadend = function () {
        setImageProps({
          image: fileReader.result,
          imageFile: image
        });
      };
      fileReader.readAsDataURL(image);
    }
  };

  const handleSave = () => {
    saveImage(imageProps);
    setImageProps({});
  };

  return (
    <div className="col-sm-12 p-0">
      <AlertToast
        variant={'error'}
        icon={'delete'}
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
      {/* Button trigger modal */}
      <input id="select_logo" type="file" ref={imgRef} onChange={handleImageChange} />
      <h6
        className="change-logo-link text-success cursor-pointer"
        data-toggle="modal"
        data-target="#changeLogoModal">
        Change Profile Picture
      </h6>
      {/* Modal 5 */}
      <div
        className="modal fade mt-5"
        id="changeLogoModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="changeLogoModal"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header w-100 d-flex justify-content-between align-items-center p-3">
              <h5 className="modal-title">Crop Image</h5>
              <button type="button" className="close mr-1" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-0 w-100 text-center">
              <img src={imageProps?.image} className="image-preview" />
              <div className="d-flex justify-content-between align-items-center gap-3 mt-2">
                <button
                  type="button"
                  className="btn btn-outline-light btn-sm border text-dark"
                  onClick={() => imgRef.current.click()}>
                  Change Picture
                </button>
                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  onClick={handleSave}
                  data-dismiss="modal">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Changepicturemodal;
