import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { ClipLoader } from 'react-spinners';

export const PaymentMethodsModal = ({
  paymentsModal,
  setPaymentsModal,
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  selectedPackage,
  purchaseOrder,
  setPurchaseOrder,
  getCardLogo,
  initiatePayment,
  proceedToNewCardModal,
  transactionFee,
  totalCost,
  loading,
  progress
}) => {
  const [selectPurchaseOrder, setSelectPurchaseOrder] = useState(false);
  const changePaymentMethod = (paymentID) => {
    setSelectedPaymentMethod(paymentID);
    setSelectPurchaseOrder(false);
  };

  const goToPurchaseOrder = () => {
    setPaymentsModal(false);
    setPurchaseOrder(true);
  };

  return (
    <Modal
      show={paymentsModal}
      onHide={() => {
        setPaymentsModal(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="mx-auto"
      centered>
      <Modal.Header className="w-100 d-flex justify-content-between align-items-center">
        <Modal.Title>Purchase Credits</Modal.Title>
        <div
          className="close_btn drawer rounded-2"
          onClick={() => {
            setPaymentsModal(false);
          }}>
          <IoMdClose fill="#515A6A" size={18} />
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0 w-100 px-3 payment-modal institution-form">
        <h6 className="purchase-subheading">Choose a payment method</h6>
        {paymentMethods &&
          paymentMethods.map((elt, index) => (
            <div className="border rounded-3 mt-2 p-3 cursor-pointer" key={`card${index}`}>
              <div
                className="d-flex align-items-center gap-3 cursor-pointer"
                onClick={() => {
                  changePaymentMethod(elt?.id);
                }}>
                <div className="form-check p-0 m-0">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input rounded-circle m-0"
                      name="payment_method"
                      value={elt?.id}
                      checked={selectedPaymentMethod === elt?.id}
                      aria-label="Payment Card"
                    />
                  </label>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <h6 className="mb-0">{getCardLogo(elt?.card?.brand)}</h6>
                  <div className="d-flex flex-column justify-content-center">
                    <span className="payment-card-number">xxxx xxxx xxxx {elt?.card?.last4}</span>
                    <h6 className="mb-0 payment-card-expiry">
                      Expires {elt?.card?.exp_month}/{elt?.card?.exp_year}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          ))}
        <div
          className="border rounded-3 mt-2 p-3 cursor-pointer"
          onClick={() => {
            setSelectPurchaseOrder(true);
          }}>
          <div className="d-flex align-items-center gap-3">
            <div className="form-check p-0 m-0">
              <label className="form-check-label">
                <input
                  type="radio"
                  className="form-check-input rounded-circle m-0"
                  name="payment_method"
                  checked={selectPurchaseOrder}
                  value="purchase_order"
                  aria-label="invoice"
                />
              </label>
            </div>
            <div className="d-flex flex-column justify-content-center">
              <span className="payment-card-number">Purchase order</span>
              <h6 className="mb-0 payment-card-expiry">
                Create a purchase order for this transaction
              </h6>
            </div>
          </div>
        </div>
        <h6 className="my-3 btn-add-new-card" data-dismiss="modal">
          <i className="far fa-plus-circle fa-fw"></i>
          <span onClick={proceedToNewCardModal}>Add New Card</span>
        </h6>
        <div className="py-2">
          <h6 className="purchase-summary-label">ORDER SUMMARY</h6>
          <div className="d-flex flex-column justify-content-center gap-1">
            <div className="purchase-summary-list">
              <span className="property">{selectedPackage} Credits</span>
              <span className="value">${(selectedPackage * 0.5).toFixed(2)}</span>
            </div>
            <div className="purchase-summary-list">
              <span className="property">Transaction Fee (2%)</span>
              <span className="value">${transactionFee}</span>
            </div>
            <div className="purchase-summary-list mb-3">
              <span className="total-label">Total</span>
              <span className="total-value">${totalCost}</span>
            </div>
          </div>
        </div>
        {selectPurchaseOrder ? (
          <button
            type="button"
            className="btn btn-tertiary w-100"
            onClick={() => {
              goToPurchaseOrder();
            }}>
            <i className="fas fa-download fa-fw"></i>
            Download purchase order
          </button>
        ) : (
          <button
            type="button"
            className={`btn btn-success w-100 ${!selectedPaymentMethod && 'inactive'}`}
            onClick={initiatePayment}>
            {loading ? progress() : 'Purchase Credits'}
          </button>
        )}
      </Modal.Body>
    </Modal>
  );
};
