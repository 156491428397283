import { ApolloLink, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

const httpLink = new HttpLink({
  uri: 'https://t2dqqmao3zbfrjhf4c6g564ixq.appsync-api.us-east-1.amazonaws.com/graphql',
  headers: {
    'x-api-key': process.env.REACT_APP_GRAPHQL_API_KEY
  }
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([httpLink])
});
