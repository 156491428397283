import React, { useState, useEffect } from 'react';
import { ChevronDown } from 'react-bootstrap-icons';
import { AllCategories } from '../../../../../Services/query/categories';

export default function Aboutgraddropdowninterst({ label, onChange }) {
  const [interest, setInterest] = useState('All');
  const [interests, setInterests] = useState([]);

  const handleSelect = (item) => {
    setInterest(item);
    onChange(item);
  };

  useEffect(() => {
    const getInterests = async () => {
      const categories = await AllCategories();
      setInterests(categories);
    };

    getInterests();
  }, []);

  return (
    <React.Fragment>
      <div>
        <button
          className="options-box"
          type="button"
          id="triggerId"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false">
          <span className="options-title">{label}:</span>
          <span className="options-selected">{interest}</span>
          <ChevronDown fill="#969CA5" />
        </button>
        <div className="dropdown-menu dropdown-menu-left options-menu" aria-labelledby="triggerId">
          <div
            className="dropdown-item options-item"
            onClick={() => {
              handleSelect('All');
            }}
            style={{ textTransform: 'capitalize' }}>
            All
          </div>
          {interests?.map((interest, index) => (
            <div
              key={`interest_${index}`}
              className="dropdown-item options-item"
              onClick={() => {
                handleSelect(interest?.name);
              }}
              style={{ textTransform: 'capitalize' }}>
              {interest?.name}
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}
