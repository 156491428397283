import React from 'react';
import '../../Savedopportunities/Savedopportunities.css';
import { BsChevronRight } from 'react-icons/bs';

function CollapseOpportunities({ icon, name, sub = false, count = 0, clickAction = () => {} }) {
  return (
    <div className="interests-list-item" onClick={clickAction}>
      <div className="d-flex align-items-center">
        <span>{icon}</span>
        <span className="ml-2">{name}</span>
      </div>
      <div>{sub ? <h6>{count}</h6> : <BsChevronRight size={15} fill="#969CA5" />}</div>
    </div>
  );
}

export default CollapseOpportunities;
