import React from 'react';
import CustomizedApp from './CustomizedApp';

import './Chatmain.css';

function Chatmain() {
  return (
    <div className="col-sm-12 h-full pl-0 pr-0">
      <CustomizedApp />
    </div>
  );
}

export default Chatmain;
