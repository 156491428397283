import { useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { axiosClient } from '../../../../libs/axiosClient';
import { setUserDataAction } from '../../../../Redux/actions/userAction';
import { UPDATE_SCHOOL } from '../../../../Services/mutation/school';
import { GetEducator } from '../../../../Services/query/educator';
import {
  isRequiredFieldsPassed,
  validateEmail,
  isEmail,
  checkSameDomain
} from '../../../../utils/validators';
import TabTeam from './TabTeam/TabTeam';
import AlertToast from '../../../../Components/common/Alerts/ToastAlert';
import { matchDomain, sendAppNotification } from '../../../../utils/helpers';

const Team = () => {
  const user = useSelector((store) => store.educatorUser.data);
  const [formData, setFormData] = useState({});
  const [activeToast, setActiveToast] = useState(false);
  const [toastVariant, setToastVariant] = useState('error');
  const [toastIcon, setToastIcon] = useState('delete');
  const [toast, setToast] = useState('');
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [updateSchool] = useMutation(UPDATE_SCHOOL);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const showToast = (variant, info) => {
    setToastVariant(variant);
    setToastIcon(variant === 'error' ? 'delete' : 'success');
    setToast(info);
    setActiveToast(true);
  };

  const inviteTeam = async() => {
    setLoading(true);
    try {
      const schoolInvites = user.school.invitations;
      const schoolInvitationsNames = user.school.invitations_names;
      const schoolInvitationsDates = user.school.invitations_dates;

      if (!matchDomain(user.school.contact_email, formData?.email)) {
        showToast('error', 'Work email domain does not match school email domain');
        setLoading(false);
        return;
      }

      if (validateEmail(formData?.email)) {
        showToast('error', 'Email is incorrect');
        setLoading(false);
        return;
      }
      if (schoolInvites?.includes(formData?.email)) {
        showToast('error', 'Email already invited');
        setLoading(false);
        return;
      }

      if (schoolInvites == null || schoolInvites?.length < 4) {
        const schoolReqData = {
          id: user.schoolID,
          invitations_dates:
            schoolInvitationsDates != null ? [...schoolInvitationsDates, new Date()] : [new Date()],
          invitations_names:
            schoolInvitationsNames != null
              ? [...schoolInvitationsNames, formData?.fname + ' ' + formData?.lname]
              : [formData?.fname + ' ' + formData?.lname],
          invitations:
            schoolInvites != null ? [...schoolInvites, formData?.email] : [formData?.email]
        };

        updateSchool({
          variables: { ...schoolReqData }
        })
          .then(async () => {
            const educator = await GetEducator(user.id);
            dispatch(setUserDataAction(educator));
            const reqData = {
              address: user.school.address,
              email: formData?.email,
              fname: formData?.fname,
              lname: formData?.lname,
              invited: true,
              senderFName: user?.name?.split(' ')[0],
              name: formData?.fname + ' ' + formData?.lname,
              requiresInvitation: true,
              schoolID: user.schoolID,
              schoolName: user.school.name
            };

            await axiosClient.post('/send-invitation', reqData);
            await sendAppNotification(
              user?.id,
              `An Invitation to join your team has been sent to ${formData.email}`,
              'success',
              `/organization`,
              'Click here to view',
              'mentor',
              'inapp',
            )
            setLoading(false);
            setActiveToast(true);
            showToast('success', `${reqData?.name} has been invited`);
            setFormData({});
          })
          .catch((error) => console.log(error));
      } else {
        showToast('error', 'Only 4 invitations are allowed');
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    setValid(
      isRequiredFieldsPassed(formData, 3, 'eq') &&
        isEmail(formData?.email) &&
        checkSameDomain(user?.school?.contact_email, formData?.email)
    );
  }, [formData]);

  return (
    <>
      <div className="col-sm-12 institution-form d-flex justify-content-center">
        <div
          className="p-3"
          style={{
            backgroundColor: 'white',
            borderRadius: '10px',
            width: 480,
            maxWidth: '100%'
          }}>
          <h6 className="fw-bold">INVITE A STAFF MEMBER</h6>
          <h6 style={{ fontSize: '100%', color: '#969CA5' }}>
            {' '}
            {4 - (user?.school?.invitations?.length || 0)} seats left{' '}
          </h6>
          <div className="row mt-3">
            <div className="col-sm-6 form-group">
              <label className="institution-form-label">First Name</label>
              <input
                type="text"
                placeholder="First Name"
                className="form-control"
                name="fname"
                value={formData.fname}
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-6 form-group">
              <label className="institution-form-label">Last Name</label>
              <input
                type="text"
                placeholder="Last Name"
                className="form-control"
                onChange={handleChange}
                value={formData?.lname}
                name="lname"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="institution-form-label">Email Address</label>
            <input
              type="email"
              placeholder="Enter email address"
              className="form-control"
              name="email"
              value={formData?.email}
              onChange={handleChange}
              style={{ backgroundColor: '#F4F5F7A3' }}
            />
          </div>
          <div className="form-group my-2">
            <button 
              className={`btn btn-success ${!valid ? 'inactive' : ''}`} 
              style={{opacity: (!valid || loading)? 0.5 : 1}}
              disabled={!valid || loading}
              onClick={inviteTeam}>
              {loading ? 'Loading...' : 'Invite to Team'}
            </button>
          </div>
          <div className="mt-3">
            <TabTeam />
          </div>
        </div>
      </div>
      <AlertToast
        variant={toastVariant}
        icon={toastIcon}
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
    </>
  );
};

export default Team;
