import { useEffect, useState } from 'react';
import './style.css';
import { filterYouthsByInterests } from '../../../../utils/Youth/utils';

function Interests({ students }) {
  const [interests, setInterests] = useState([]);
  const createdInterests = [];

  const createInterest = (elt, index) => {
    return (
      <div className="row add_border mb-3" key={index}>
        <div className="col-sm-6">
          <h5 className="text_color interest_text">{elt}</h5>
        </div>
        <div className="col-sm-6 text-right">
          <h6 className="text_color interest_text">
            {interests[elt]} youth
            {interests[elt] === 1 ? '' : 's'}
          </h6>
        </div>
      </div>
    );
  };

  useEffect(() => {
    let interests = {};
    students?.forEach((student) => {
        const interestData = student?.interests?.reduce((innerAcc, interest) => {
        const innerResp = { ...innerAcc };
        innerResp[interest] = filterYouthsByInterests(students, [interest])?.length;
        return innerResp;
      }, {});

      interests = { ...interests, ...interestData };
    });

    const interestsSorted = Object.entries(interests)
      .sort(([, a], [, b]) => b - a)
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    setInterests(interestsSorted);
  }, [students]);

  return (
    <>
      {Object.keys(interests || {})
        ?.slice(0, 5)
        ?.forEach((elt, index) => {
          createdInterests.push(createInterest(elt, index));
        })}
      <div className="">
        {createdInterests?.length === 0 ? (
          <div className="card-content">
            <div className="flex-v align-items">
              <img src="../../images/no-interest.png" alt="" />
              <p className="mt-2 card-text">No interest yet</p>
            </div>
          </div>
        ) : (
          createdInterests
        )}
      </div>
    </>
  );
}

export default Interests;
