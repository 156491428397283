import React, { Component } from 'react';
import Homemodal from '../Homemodal/Homemodal';
import './Home.css';

function Home() {
  return (
    <div className="col-sm-12 background_color ">
      <div className="col-sm-12 pt-3 pb-2">
        <h4>
          <b>Hi, Julia 👋</b>
        </h4>
      </div>
      <div className="col-sm-12 mb-3 ">
        <div className="background_color_notify col-sm-12">
          <div className="row ">
            <div className="col-sm-8">
              <div className="col-sm-12 py-3 ">
                <div className="row">
                  <div className="col-sm-9 text-right">
                    <span>
                      {' '}
                      <button className="btn btn-success btn-sm rounded-pill background_color_notify1">
                        {' '}
                        <i className="fas fa-check-circle"></i>&nbsp;&nbsp; Verify Your Institution{' '}
                      </button>{' '}
                    </span>{' '}
                    <span className="font_set">
                      {' '}
                      Verify your account to have your account public. In meantime{' '}
                    </span>
                  </div>
                  <div className="col-sm-3 pl-0">
                    <a className="a_tag_customise">
                      <span>
                        {' '}
                        <Homemodal />
                      </span>{' '}
                    </a>
                  </div>
                </div>
                {/* <span> <button className='btn btn-success btn-sm rounded-pill background_color_notify1' >  <i className="fas fa-check-circle"></i>&nbsp;&nbsp; Verify Your Institution </button> </span>
                <span className='font_set'> Verify your account to have your account public. In meantime <a href='#' className="a_tag_customise"><span> <Homemodal /></span> </a> </span> */}
              </div>
            </div>
            <div className="col-sm-4 m-auto">
              <div className="col-sm-12 text-right">
                <i className="fas fa-times"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-2">
            <div className="col-sm-12 backcolor text-center">
              <img src="./images/user.png" className="img-fluid card_padding_home" />
              <h6 className="font_home">
                No students have been <br /> added yet
              </h6>
            </div>
          </div>
          <div className="col-sm-5">
            <div className="col-sm-12 backcolor text-center">
              <div className="col-sm-12 pt-3  text-left ">
                <h6>
                  <b>TOP INTERESTS</b>
                </h6>
              </div>
              <img src="./images/card1.png" className="img-fluid img_size_set" />
              <h6 className="font_home1">NO INTERST YET</h6>
            </div>
          </div>
          <div className="col-sm-5">
            <div className="col-sm-12 backcolor text-center">
              <div className="col-sm-12 pt-3  text-left ">
                <h6>
                  <b>GRADE DISTRIBUTION</b>
                </h6>
              </div>
              <img src="./images/card2.png" className="img-fluid img_size_set" />
              <h6 className="font_home1">GRADES WILL SHOW HER</h6>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-2">
            <div className="col-sm-12 backcolor ">
              <div className="col-sm-12 backcolor pl-0  text-center">
                <div className="col-sm-12  pt-3  text-center ">
                  <h6 className="font_size_heading">
                    <b>RECOMMENDATIONS</b>
                  </h6>
                </div>
                <img src="./images/card2.png" className="img-fluid img_size_set1" />
                <h6 className="font_home2">NO DATA YET</h6>
              </div>
            </div>
          </div>
          <div className="col-sm-10">
            <div className="col-sm-12 backcolor text-center">
              <div className="col-sm-12 pt-3  text-left ">
                <h6>
                  <b>OPPORTUNITIES YOUR STUDENTS MAY BE INTERSTED IN</b>
                </h6>
              </div>
              <img src="./images/card3.png" className="img-fluid img_size_set3" />
              <h6 className="font_home1">NO INTERST YET</h6>
            </div>
          </div>
        </div>
        <div className="col-sm-12 p-0 pb-4 mt-3">
          <div className="col-sm-12 backcolor text-center">
            <div className="col-sm-12 pt-3  text-left ">
              <h6>
                <b>ANALYTICS</b>
              </h6>
            </div>
            <img src="./images/card2.png" className="img-fluid img_size_set4" />
            <h6 className="font_home1">NO DATA YET</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
