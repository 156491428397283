import React, { useState } from 'react';
import { useCollapse } from 'react-collapsed';
import '../../Components/Tabyouth/Youthtab.css';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

function Collpasetwo({ data }) {
  const [isExpanded, setExpanded] = useState(true);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <div>
      <div
        className="w-100 d-flex justify-content-between align-items-center"
        {...getToggleProps({
          onClick: () => setExpanded((prevExpanded) => !prevExpanded)
        })}>
        <div className="youth-detail-accordion-title">Interest</div>
        <div className="text-right angle-down-contact-icon">
          {isExpanded ? <BsChevronUp /> : <BsChevronDown />}
        </div>
      </div>
      <div className="pl-0 py-2">
        <section {...getCollapseProps()}>
          <div>
            <h6 className="youth-detail-accordion-subtitle">Selected Interests:</h6>
            <div className="d-flex">
              {data?.interests?.map((elt, index) =>
              <label className="filter-badge-radio mb-0 mr-1" key={index}>
                <input type={'checkbox'} name={'Entrepreneurship'} className="d-none" checked />
                <span className="btn btn-outline-light background_allopp_btn ">
                  {elt}
                </span>
              </label>
              )}
            </div>
          </div>
          <div className="mt-2">
            <h6 className="youth-detail-accordion-subtitle">Based on Activity and Engagement:</h6>
            {/* <div className="d-flex">
              <label className="filter-badge-radio mb-0 mr-1">
                <input type={'checkbox'} name={'Marketing'} className="d-none" />
                <span className="btn btn-outline-light background_allopp_btn ">1. Marketing</span>
              </label>
              <label className="filter-badge-radio mb-0 mr-1">
                <input type={'checkbox'} name={'Web & App Design'} className="d-none" />
                <span className="btn btn-outline-light background_allopp_btn ">
                  2. Web & App Design
                </span>
              </label>
              <label className="filter-badge-radio mb-0 mr-1">
                <input type={'checkbox'} name={'Data Analysis'} className="d-none" />
                <span className="btn btn-outline-light background_allopp_btn ">
                  3. Data Analysis
                </span>
              </label>
            </div> */}
          </div>
        </section>
      </div>
    </div>
  );
}

export default Collpasetwo;
