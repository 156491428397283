import React, { useEffect, useState } from 'react';

export const FilterCheckItem = ({
  label, 
  value, 
  name="", 
  onChange=()=>{},
  data={}
})=>{
  const [checked, setchecked] = useState(false)

  useEffect(() => {
    console.log(data)
    setchecked(data[name]?.includes(value))
  }, [data])
  
  return (
    <label className="filter-badge-radio mb-0 mr-1">
      <input 
        type={'checkbox'} 
        name={name} 
        value={value} 
        className="d-none" 
        checked={checked}
        onChange={onChange} />
      <span className="btn btn-outline-light background_allopp_btn ">{label}</span>
    </label>
  )
}