import { gql } from '@apollo/client';

export const CREATE_CHAT_HANDLER = gql`
  mutation CreateHandler($chat_id: ID!, $access_key: String, $createdAt: AWSDateTime) {
    createChatHandler(
      input: { chat_id: $chat_id, access_key: $access_key, createdAt: $createdAt }
    ) {
      chat_id
      access_key
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CHAT_HANDLER = gql`
  mutation UpdateHandler($chat_id: ID!, $access_key: String, $updatedAt: AWSDateTime) {
    updateChatHandler(
      input: { chat_id: $chat_id, access_key: $access_key, updatedAt: $updatedAt }
    ) {
      chat_id
      access_key
      createdAt
      updatedAt
    }
  }
`;
