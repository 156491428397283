import { Modal } from 'react-bootstrap';
import Tabmodal from '../../Tabmodal/Tabmodal';
import '../style.css';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal_body_style"
      centered>
      <Modal.Body>
        <div className="col-sm-12 p-0 pb-3">
          <Tabmodal data={props?.data} />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default function RecommendedOppsModal(props) {
  const { modalShow, setModalShow, data } = { ...props };

  return (
    <>
      <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} data={data} />
    </>
  );
}
