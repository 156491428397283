export const calculateAge = (date) => {
  date = new Date(date);
  const currentDate = new Date();
  try {
    return currentDate.getFullYear() - date.getFullYear();
  } catch (err) {
    return -1;
  }
};

export function filterYouthsByAges(data, filterAges) {
  const currentDate = new Date();
  return data.filter((item) => {
    if (item.dob) {
      const birthDate = new Date(item.dob);
      const age = currentDate.getFullYear() - birthDate.getFullYear();

      return filterAges.includes(age.toString());
    }
    return false;
  });
}

export function filterYouthsByInterests(data, interests) {
  return data.filter((item) => interests.some((item1) => item?.interests?.includes(item1)));
}

export function filterByAgesAndInterests(data, ageList, interestList) {
  return data.filter((profile) => {
    const birthYear = new Date(profile.dob).getFullYear();
    const currentYear = new Date().getFullYear();
    const age = currentYear - birthYear;
    return (
      ageList.includes(age.toString()) &&
      profile.interests.some((interest) => interestList.includes(interest))
    );
  });
}
