import { useState, useEffect } from 'react';
import { AllCategories } from '../../Services/query/categories';
import { AllStudentInterests } from '../../Services/query/studentInterests';
import { AllStudents } from '../../Services/query/students';

export const formatDate = (date) => {
  if (date) {
    const newDate = new Date(date);
    const monthStr = `${newDate}`.split(' ')[1];
    return monthStr + ' ' + newDate.getDate() + ', ' + newDate.getFullYear();
  }

  return 'Not specified';
};

export const formatDateMonth = (date) => {
  if (date) {
    const newDate = new Date(date);
    const monthStr = `${newDate}`.split(' ')[1];
    return monthStr + ' ' + newDate.getFullYear();
  }

  return 'Not specified';
};

export async function GetInterests(user) {
  const categories = await AllCategories();
  const studentInterests = await AllStudentInterests();
  let students = [];

  try {
    students = await AllStudents();
  } catch (error) {
    console.log(error);
  }

  const sameSchool = students?.filter((elt) => elt?.schoolID === user?.schoolID);
  const studentIds = [];
  sameSchool?.forEach((elt) => {
    studentIds.push(elt?.id);
  });
  const sameSchoolStudentInterests = studentInterests?.filter((elt) =>
    studentIds.includes(elt?.studentID)
  );
  const interests = {};
  const captured = {};

  sameSchoolStudentInterests?.forEach((interest) => {
    const interestName = interest?.interest?.name;
    const categoryID = interest?.interest?.categoryID;
    const category = categories?.filter((elt) => elt?.id === categoryID)[0];

    if (Object.keys(captured || {})?.includes(interestName)) {
      if (!captured[interestName]?.includes(interest?.studentID)) {
        interests[category?.name] += 1;
        captured[interestName] = [...captured[interestName], interest?.studentID];
      }
    } else {
      interests[category?.name] = 1;
      captured[interestName] = [interest?.studentID];
    }
  });

  return interests;
}
