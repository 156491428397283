import React from 'react';
// import Table from 'react-bootstrap/Table';

const TableApproval = ({ user, setRoleModal, setMember }) => {
  return (
    <div className="col-sm-12 p-0">
      {/* <Table hover>
        <tbody>
        <tr>
            <td>
              <div className="d-flex align-items-center gap-2">
                {user?.school?.image
                ?(
                  <img 
                  src={user?.school?.image} 
                  className="thumbnail-sm"
                  alt="thumbnail"/>
                )
                : (
                  <div className="thumbnail-placeholder">
                    <i className="far fa-user"></i>
                  </div>
                )}
                <div className="d-flex flex-column">
                  <span className="table-user-name">{user?.name}</span>
                  <span className="table-user-email">{user?.email}</span>
                </div>
              </div>
            </td>
            <td className="vertical-align-middle">
              <span className="table-user-invited">
                Counselor
              </span>
            </td>
            <td className="vertical-align-middle">
              <div className="d-flex gap-2">
                <button
                  type="button"
                  className="btn btn-outline-light border btn-sm text-gray">
                    Deny
                </button>
                <button
                  type="button"
                  className="btn btn-success btn-sm">
                    Accept
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </Table> */}
    </div>
  );
};

export default TableApproval;
