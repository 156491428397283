import { getInitial } from '../../../utils/helpers';
import './style.css';

function InitialWrapper({ name, size, cls, color }) {
  return (
    <div
      className={`initials-wrapper ${cls} ${size}`}
      style={{ backgroundColor: color, color: color !== '#FFF' ? '#FFF' : '#000' }}>
      {getInitial(name)}
    </div>
  );
}

export default InitialWrapper;
