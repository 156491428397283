import { SET_SIGNUP_DATA, RESET_SIGNUP_DATA } from '../constants/signUpDataConstants';

const initialLoginState = {
  data: {}
};

const signUpDataReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case SET_SIGNUP_DATA:
      return { ...state, data: { ...state.data, ...action.payload } };
    case RESET_SIGNUP_DATA:
      state = initialLoginState;
      return state;
    default:
      return state;
  }
};

export default signUpDataReducer;
