import React, { useState } from 'react';
import RecommendedOppsModal from './Modals/RecommendedOppsModal';

function Dashmodal({ userRecommendedOpps }) {
  const [showRecommendedOppsModal, setShowRecommendedOppsModal] = useState(false);

  return (
    <div className="col-sm-12 text-left" style={{ color: 'black' }}>
      {/* <!-- Modal --> */}
      <RecommendedOppsModal
        modalShow={showRecommendedOppsModal}
        setModalShow={setShowRecommendedOppsModal}
        data={userRecommendedOpps}
      />

      {/* <!-- Modal2 --> */}
      <div
        className="modal fade"
        id="authh1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col-sm-12 p-0">
                <div className="row">
                  <div className="col-sm-6 m-auto">
                    <h6 className="modal-title" id="exampleModalLongTitle">
                      Purchase Credits
                    </h6>
                  </div>
                  <div className="col-sm-6 text-right">
                    <button
                      type="button"
                      className="btn btn-light btn-close close"
                      data-dismiss="modal"
                      aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 text-left pb-1 p-2">
                <h6>Choose a payment method</h6>
              </div>
              <div
                className="col-sm-12 p-2"
                style={{ border: '1px solid gray', borderRadius: '10px' }}>
                <div className="row">
                  <div className="col-sm-1 m-auto pt-1">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                    </div>
                  </div>
                  <div className="col-sm-3 pl-0 m-auto">
                    <img src="../images/visa.png" className="img-fluid" />
                  </div>
                  <div className="col-sm-8 text-left pl-0">
                    <h6>xxxx xxxx xxxx 6372</h6>
                    <h6>Expires 10/2022</h6>
                  </div>
                </div>
              </div>
              <div
                className="col-sm-12 p-2 mt-2"
                style={{ border: '1px solid gray', borderRadius: '10px' }}>
                <div className="row">
                  <div className="col-sm-1 m-auto pt-1">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                    </div>
                  </div>
                  <div className="col-sm-11 text-left ">
                    <h6>Purchase Order</h6>
                    <h6 style={{ color: '#969CA5' }}>
                      Create a purchase order for this transaction
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 text-left pt-2 p-2">
                <h6 style={{ color: '#969CA5' }}>ORDER SUMMARY</h6>
              </div>
              <div className="col-sm-12 text-left pt-2 p-2">
                <div className="row">
                  <div className="col-sm-6">
                    <h6 style={{ color: '#969CA5' }}>30 Credits</h6>
                  </div>
                  <div className="col-sm-6 text-right">
                    <h6 style={{ color: '#969CA5' }}>$15</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <h6 style={{ color: '#969CA5' }}>Transaction Fee (2%)</h6>
                  </div>
                  <div className="col-sm-6 text-right">
                    <h6 style={{ color: '#969CA5' }}>$15</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <h6 style={{ color: '#969CA5' }}>Total</h6>
                  </div>
                  <div className="col-sm-6 text-right">
                    <h6 style={{ color: '#969CA5' }}>$15</h6>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 p-2">
                {/* <div className='row'>
                                <div className='col-sm-6' >
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                                <div className='col-sm-6' >
                                <button type="button" className="btn btn-primary">Save changes</button>
                                </div>
                            </div> */}
                <div className="col-sm-12 p-0">
                  <button
                    type="button"
                    style={{ width: '100%' }}
                    className="btn btn-success"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#authh3">
                    Proceed to payment methods
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal3 --> */}
      <div
        className="modal fade"
        id="authh3"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ overflowY: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col-sm-12 p-0">
                <div className="row">
                  <div className="col-sm-6 m-auto">
                    <h6 className="modal-title" id="exampleModalLongTitle">
                      Add Credit Card
                    </h6>
                  </div>
                  <div className="col-sm-6 text-right">
                    <button
                      type="button"
                      className="btn btn-light btn-close close"
                      data-dismiss="modal"
                      aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 text-left pb-1 p-2">
                <h6 style={{ color: '#969CA5' }}>CARD DETAILS</h6>
              </div>
              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Card Number</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>
              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Cardholder Name</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>

              <div className="col-sm-12 p-0">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                      <h6>Card Number</h6>
                      <input className="form-control" type="text" placeholder="CARD NUMBER" />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                      <h6>Card Number</h6>
                      <input className="form-control" type="text" placeholder="CARD NUMBER" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Card Number</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>
              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Cardholder Name</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>

              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Card Number</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>
              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Cardholder Name</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>

              <div className="col-sm-12 p-2">
                {/* <div className='row'>
                                <div className='col-sm-6' >
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                                <div className='col-sm-6' >
                                <button type="button" className="btn btn-primary">Save changes</button>
                                </div>
                            </div> */}
                <div className="col-sm-12 p-0">
                  <button
                    type="button"
                    style={{ width: '100%' }}
                    className="btn btn-success"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#authh7">
                    Add credit card
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" data-toggle="modal" data-target="#auth4">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div> */}
          </div>
        </div>
      </div>

      {/* <!-- Modal4 --> */}
      <div
        className="modal fade"
        id="authh7"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col-sm-12 p-0">
                <div className="row">
                  <div className="col-sm-6 m-auto">
                    <h6 className="modal-title" id="exampleModalLongTitle">
                      Purchase Creditss
                    </h6>
                  </div>
                  <div className="col-sm-6 text-right">
                    <button
                      type="button"
                      className="btn btn-light btn-close close"
                      data-dismiss="modal"
                      aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 text-left pb-1 p-2">
                <h6>Choose a payment method</h6>
              </div>
              <div
                className="col-sm-12 p-2"
                style={{ border: '1px solid gray', borderRadius: '10px' }}>
                <div className="row">
                  <div className="col-sm-1 m-auto pt-1">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                    </div>
                  </div>
                  <div className="col-sm-3 pl-0 m-auto">
                    <img src="../images/visa.png" className="img-fluid" />
                  </div>
                  <div className="col-sm-8 text-left pl-0">
                    <h6>xxxx xxxx xxxx 6372</h6>
                    <h6>Expires 10/2022</h6>
                  </div>
                </div>
              </div>
              <div
                className="col-sm-12 p-2 mt-2"
                style={{ border: '1px solid gray', borderRadius: '10px' }}>
                <div className="row">
                  <div className="col-sm-1 m-auto pt-1">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                    </div>
                  </div>
                  <div className="col-sm-3 pl-0 m-auto">
                    <img src="../images/visa.png" className="img-fluid" />
                  </div>
                  <div className="col-sm-8 text-left pl-0">
                    <h6>xxxx xxxx xxxx 6372</h6>
                    <h6>Expires 12/2022</h6>
                  </div>
                </div>
              </div>
              <div
                className="col-sm-12 p-2 mt-2"
                style={{ border: '1px solid gray', borderRadius: '10px' }}>
                <div className="row">
                  <div className="col-sm-1 m-auto pt-1">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                    </div>
                  </div>
                  <div className="col-sm-11 text-left ">
                    <h6>Purchase Order</h6>
                    <h6 style={{ color: '#969CA5' }}>
                      Create a purchase order for this transaction
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 text-left pt-2 p-2">
                <h6 style={{ color: '#969CA5' }}>ORDER SUMMARY</h6>
              </div>
              <div className="col-sm-12 text-left pt-2 p-2">
                <div className="row">
                  <div className="col-sm-6">
                    <h6 style={{ color: '#969CA5' }}>30 Credits</h6>
                  </div>
                  <div className="col-sm-6 text-right">
                    <h6 style={{ color: '#969CA5' }}>$15</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <h6 style={{ color: '#969CA5' }}>Transaction Fee (2%)</h6>
                  </div>
                  <div className="col-sm-6 text-right">
                    <h6 style={{ color: '#969CA5' }}>$15</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <h6 style={{ color: '#969CA5' }}>Total</h6>
                  </div>
                  <div className="col-sm-6 text-right">
                    <h6 style={{ color: '#969CA5' }}>$15</h6>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 p-2">
                {/* <div className='row'>
                                <div className='col-sm-6' >
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                                <div className='col-sm-6' >
                                <button type="button" className="btn btn-primary">Save changes</button>
                                </div>
                            </div> */}
                <div className="col-sm-12 p-0">
                  <button
                    type="button"
                    style={{ width: '100%' }}
                    className="btn btn-success"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#authh8">
                    Proceed to payment methods
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal5 --> */}
      <div
        className="modal fade"
        id="authh8"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6 m-auto">
                  {/* <h6 className="modal-title" id="exampleModalLongTitle">
                                Change Password
                                </h6> */}
                </div>
                <div className="col-sm-6 text-right">
                  <button
                    type="button"
                    className="btn btn-light btn-close close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>

              <div className="col-sm-12 text-center">
                <img src="../images/check.png" className="img-fluid" />
              </div>
              <div className="col-sm-12 text-center pt-4">
                <h5>Payment Details Updated!</h5>
              </div>
              <div className="col-sm-12 text-center pb-2">
                <h6>Your new payment details will be used to process future credit purchases. </h6>
              </div>

              <div className="col-sm-12 ">
                <div className="row">
                  <div className="col-sm-12 p-0">
                    <button
                      type="button"
                      style={{
                        width: '100%',
                        backgroundColor: 'white',
                        border: '1px solid gray',
                        color: '#969CA5'
                      }}
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#authh9">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" data-toggle="modal" data-target="#auth5">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div> */}
          </div>
        </div>
      </div>

      {/* <!-- Modal6 --> */}
      <div
        className="modal fade"
        id="authh9"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6 m-auto">
                  {/* <h6 className="modal-title" id="exampleModalLongTitle">
                                Change Password
                                </h6> */}
                </div>
                <div className="col-sm-6 text-right">
                  <button
                    type="button"
                    className="btn btn-light btn-close close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>

              <div className="col-sm-12 text-center">
                <img src="../images/caution1.png" className="img-fluid" alt="img-crashed" />
              </div>
              <div className="col-sm-12 text-center pt-4">
                <h5>Approve Subscription Upgradee?</h5>
              </div>
              <div className="col-sm-12 text-center pb-2">
                <h6>
                  You are about to approve Julia’s request to upgrade her subscription to the Growth
                  Plan. This will cost 70 credits/month and will be charged monthly.{' '}
                </h6>
                <h6>You may cancel this subscription at any time.</h6>
              </div>

              <div className="col-sm-12 ">
                <div className="row">
                  <div className="col-sm-6 ">
                    <button
                      type="button"
                      style={{
                        width: '100%',
                        backgroundColor: 'white',
                        border: '1px solid gray',
                        color: '#969CA5'
                      }}
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#authh11">
                      Cancel
                    </button>
                  </div>
                  <div className="col-sm-6 ">
                    <button
                      type="button"
                      style={{ width: '100%' }}
                      className="btn btn-success"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#authh9">
                      Approve
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal7 --> */}
      <div
        className="modal fade"
        id="authh11"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6 m-auto">
                  {/* <h6 className="modal-title" id="exampleModalLongTitle">
                                Change Password
                                </h6> */}
                </div>
                <div className="col-sm-6 text-right">
                  <button
                    type="button"
                    className="btn btn-light btn-close close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>

              <div className="col-sm-12 text-center">
                <img src="../images/wallet.png" className="img-fluid" alt="img-crashed" />
              </div>
              <div className="col-sm-12 text-center pt-4">
                <h5>Not Enough Credits</h5>
              </div>
              <div className="col-sm-12 text-center pb-2">
                <h6>You need 100 more credits to approve this request.</h6>
              </div>

              <div className="col-sm-12 ">
                <div className="row">
                  <div className="col-sm-6 ">
                    <button
                      type="button"
                      style={{
                        width: '100%',
                        backgroundColor: 'white',
                        border: '1px solid gray',
                        color: '#969CA5'
                      }}
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#authh22">
                      Purchase Credits
                    </button>
                  </div>
                  <div className="col-sm-6 ">
                    <button
                      type="button"
                      style={{ width: '100%' }}
                      className="btn btn-success"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#authh22">
                      Approve
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal71 --> */}
      <div
        className="modal fade"
        id="authh22"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6 m-auto">
                  {/* <h6 className="modal-title" id="exampleModalLongTitle">
                                Change Password
                                </h6> */}
                </div>
                <div className="col-sm-6 text-right">
                  <button
                    type="button"
                    className="btn btn-light btn-close close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>

              <div className="col-sm-12 text-center">
                <img src="../images/check.png" className="img-fluid" alt="img-crashed" />
              </div>
              <div className="col-sm-12 text-center pt-4">
                <h5>Subscription Upgrade Approved!</h5>
              </div>
              <div className="col-sm-12 text-center pb-2">
                <h6>Julia is now on the Growth Plan and can enjoy more features from Junity! 🎉</h6>
              </div>

              <div className="col-sm-12 ">
                <div className="row">
                  <div className="col-sm-12 ">
                    <button
                      type="button"
                      style={{
                        width: '100%',
                        backgroundColor: 'white',
                        border: '1px solid gray',
                        color: '#969CA5'
                      }}
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#authh23">
                      Close
                    </button>
                  </div>
                  {/* <div className='col-sm-6 ' >
                                            <button type="button" style={{width:"100%"}} className="btn btn-success" data-dismiss="modal" data-toggle="modal" data-target="#authh22">Approve</button>
                                            </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal72 --> */}
      <div
        className="modal fade"
        id="authh23"
        style={{ overflowY: 'scroll' }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col-sm-12 p-0">
                <div className="row">
                  <div className="col-sm-6 m-auto">
                    <h6 className="modal-title" id="exampleModalLongTitle">
                      Add Credit Card
                    </h6>
                  </div>
                  <div className="col-sm-6 text-right">
                    <button
                      type="button"
                      className="btn btn-light btn-close close"
                      data-dismiss="modal"
                      aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 text-left pb-1 p-2">
                <h6 style={{ color: '#969CA5' }}>CARD DETAILS</h6>
              </div>
              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Card Number</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>
              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Cardholder Name</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>

              <div className="col-sm-12 p-0">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                      <h6>Card Number</h6>
                      <input className="form-control" type="text" placeholder="CARD NUMBER" />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                      <h6>Card Number</h6>
                      <input className="form-control" type="text" placeholder="CARD NUMBER" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Card Number</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>
              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Cardholder Name</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>

              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Card Number</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>
              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Cardholder Name</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>

              <div className="col-sm-12 p-2">
                {/* <div className='row'>
                                <div className='col-sm-6' >
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                                <div className='col-sm-6' >
                                <button type="button" className="btn btn-primary">Save changes</button>
                                </div>
                            </div> */}
                <div className="col-sm-12 p-0">
                  <button
                    type="button"
                    style={{ width: '100%' }}
                    className="btn btn-success"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#authh24">
                    Add credit card
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal73 --> */}
      <div
        className="modal fade"
        id="authh24"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6 m-auto">
                  {/* <h6 className="modal-title" id="exampleModalLongTitle">
                                Change Password
                                </h6> */}
                </div>
                <div className="col-sm-6 text-right">
                  <button
                    type="button"
                    className="btn btn-light btn-close close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>

              <div className="col-sm-12 text-center">
                <img src="../images/check.png" className="img-fluid" alt="img-crashed" />
              </div>
              <div className="col-sm-12 text-center pt-4">
                <h5>New Card Created!</h5>
              </div>
              <div className="col-sm-12 text-center pb-2">
                <h6>
                  This card will now be available for credit purchases by Bulkeley High School.
                </h6>
              </div>

              <div className="col-sm-12 ">
                <div className="row">
                  <div className="col-sm-12 ">
                    <button
                      type="button"
                      style={{
                        width: '100%',
                        backgroundColor: 'white',
                        border: '1px solid gray',
                        color: '#969CA5'
                      }}
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#authh25">
                      Close
                    </button>
                  </div>
                  {/* <div className='col-sm-6 ' >
                                            <button type="button" style={{width:"100%"}} className="btn btn-success" data-dismiss="modal" data-toggle="modal" data-target="#authh22">Approve</button>
                                            </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal74 --> */}
      <div
        className="modal fade"
        id="authh25"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col-sm-12 p-0">
                <div className="row">
                  <div className="col-sm-6 m-auto">
                    <h6 className="modal-title" id="exampleModalLongTitle">
                      Purchase Credits
                    </h6>
                  </div>
                  <div className="col-sm-6 text-right">
                    <button
                      type="button"
                      className="btn btn-light btn-close close"
                      data-dismiss="modal"
                      aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 text-left pb-1 p-2">
                <h6>Choose a payment method</h6>
              </div>
              <div
                className="col-sm-12 p-2"
                style={{ border: '1px solid gray', borderRadius: '10px' }}>
                <div className="row">
                  <div className="col-sm-1 m-auto pt-1">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                    </div>
                  </div>
                  <div className="col-sm-3 pl-0 m-auto">
                    <img src="../images/visa.png" className="img-fluid" />
                  </div>
                  <div className="col-sm-8 text-left pl-0">
                    <h6>xxxx xxxx xxxx 6372</h6>
                    <h6>Expires 10/2022</h6>
                  </div>
                </div>
              </div>
              <div
                className="col-sm-12 p-2 mt-2"
                style={{ border: '1px solid gray', borderRadius: '10px' }}>
                <div className="row">
                  <div className="col-sm-1 m-auto pt-1">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                    </div>
                  </div>
                  <div className="col-sm-11 text-left ">
                    <h6>Purchase Order</h6>
                    <h6 style={{ color: '#969CA5' }}>
                      Create a purchase order for this transaction
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 text-left pt-2 p-2">
                <h6 style={{ color: '#969CA5' }}>ORDER SUMMARY</h6>
              </div>
              <div className="col-sm-12 text-left pt-2 p-2">
                <div className="row">
                  <div className="col-sm-6">
                    <h6 style={{ color: '#969CA5' }}>30 Credits</h6>
                  </div>
                  <div className="col-sm-6 text-right">
                    <h6 style={{ color: '#969CA5' }}>$15</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <h6 style={{ color: '#969CA5' }}>Transaction Fee (2%)</h6>
                  </div>
                  <div className="col-sm-6 text-right">
                    <h6 style={{ color: '#969CA5' }}>$15</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <h6 style={{ color: '#969CA5' }}>Total</h6>
                  </div>
                  <div className="col-sm-6 text-right">
                    <h6 style={{ color: '#969CA5' }}>$15</h6>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 p-2">
                {/* <div className='row'>
                                <div className='col-sm-6' >
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                                <div className='col-sm-6' >
                                <button type="button" className="btn btn-primary">Save changes</button>
                                </div>
                            </div> */}
                <div className="col-sm-12 p-0">
                  <button
                    type="button"
                    style={{ width: '100%', backgroundColor: '#FFDA9A' }}
                    className="btn btn-warning"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#authh26">
                    Download purchase order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal73 --> */}
      <div
        className="modal fade"
        id="authh26"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6 m-auto">
                  {/* <h6 className="modal-title" id="exampleModalLongTitle">
                                Change Password
                                </h6> */}
                </div>
                <div className="col-sm-6 text-right">
                  <button
                    type="button"
                    className="btn btn-light btn-close close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>

              <div className="col-sm-12 text-center">
                <img src="../images/delete.png" className="img-fluid" alt="img-crashed" />
              </div>
              <div className="col-sm-12 text-center pt-4">
                <h5>Are you sure you want to delete this card?</h5>
              </div>
              <div className="col-sm-12 text-center pb-2">
                <h6>
                  Your organization will not be able to purchase any more credits until you add a
                  new payment method.
                </h6>
              </div>

              <div className="col-sm-12 ">
                <div className="row">
                  <div className="col-sm-6 ">
                    <button
                      type="button"
                      style={{ width: '100%' }}
                      className="btn btn-danger"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#authh27">
                      Yes, delete
                    </button>
                  </div>
                  <div className="col-sm-6 ">
                    <button
                      type="button"
                      style={{
                        width: '100%',
                        backgroundColor: 'white',
                        border: '1px solid gray',
                        color: '#969CA5'
                      }}
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#authh25">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal73 --> */}
      <div
        className="modal fade"
        id="authh27"
        style={{ overflowY: 'scroll' }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col-sm-12 p-0">
                <div className="row">
                  <div className="col-sm-6 m-auto">
                    <h6 className="modal-title" id="exampleModalLongTitle">
                      Add Credit Card
                    </h6>
                  </div>
                  <div className="col-sm-6 text-right">
                    <button
                      type="button"
                      className="btn btn-light btn-close close"
                      data-dismiss="modal"
                      aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 text-left pb-1 p-2">
                <h6 style={{ color: '#969CA5' }}>CARD DETAILS</h6>
              </div>
              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Card Number</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>
              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Cardholder Name</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>

              <div className="col-sm-12 p-0">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                      <h6>Card Number</h6>
                      <input className="form-control" type="text" placeholder="CARD NUMBER" />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                      <h6>Card Number</h6>
                      <input className="form-control" type="text" placeholder="CARD NUMBER" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Card Number</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>
              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Cardholder Name</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>

              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Card Number</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>
              <div className="col-sm-12 p-0 pt-1 pb-3 text-left">
                <h6>Cardholder Name</h6>
                <input className="form-control" type="text" placeholder="CARD NUMBER" />
              </div>

              <div className="col-sm-12 p-2">
                {/* <div className='row'>
                                <div className='col-sm-6' >
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                                <div className='col-sm-6' >
                                <button type="button" className="btn btn-primary">Save changes</button>
                                </div>
                            </div> */}
                <div className="col-sm-12 text-right">
                  <span>
                    {' '}
                    <button
                      type="button"
                      className="btn btn-light"
                      data-dismiss="modal"
                      data-toggle="modal">
                      Download
                    </button>{' '}
                  </span>
                  <span>
                    {' '}
                    <button
                      type="button"
                      className="btn btn-success ml-2"
                      data-dismiss="modal"
                      data-toggle="modal">
                      Send as PDF
                    </button>{' '}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashmodal;
